import React from "react";
import logo from "../../../assets/img/MyCommandLogoWhite.png";
import BMLogo from "../../../assets/img/BM_logo.svg";
import RetailerLogo from "../../../assets/img/Retailer_logo.svg";
import CarrierLogo from "../../../assets/img/Carrier_logo.svg";
import CustomerLogo from "../../../assets/img/Customer_logo.svg";
import { Link } from "react-router-dom";

function Signup() {
  return (
    <div className="bg-bg-signin min-h-screen bg-no-repeat bg-cover font-sans">
      <div className="text-center">
        <img src={logo} className="w-32 m-auto pt-40" alt="Logo" />
        <div className="self-center">
          <h1 className="text-white mt-8 font-bold text-3xl">
            Choose your profile
          </h1>
        </div>
      </div>
      <div className="flex flex-col mx-4 py-2 lg:py-10">
        <Link
          to="/signupcustomer"
          className="flex mx-auto w-full text-white text-lg"
        >
          <button
            className="bg-bg-button my-5 px-20 py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-white text-lg hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={CustomerLogo}
              alt="Logo Customer"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Customer/Resident </p>
          </button>
        </Link>
        <Link
          to="/signupretailer"
          className="flex mx-auto w-full text-white text-lg"
        >
          <button
            className="bg-bg-button my-5 px-20 py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-white text-lg hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={RetailerLogo}
              alt="Logo Retailer"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Retailer </p>
          </button>
        </Link>
        <Link
          to="/signupcarrier"
          className="flex mx-auto w-full text-white text-lg"
        >
          <button
            className="bg-bg-button my-5 px-20 py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-white text-lg hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={CarrierLogo}
              alt="Logo Carrier"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Carrier/Contractor  </p>
          </button>
        </Link>
        <Link
          to="/signupbuildingmanager"
          className="flex mx-auto w-full text-white text-lg"
        >
          <button
            className="bg-bg-button my-5 px-20 py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-white text-lg hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={BMLogo}
              alt="Logo Building Manager"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Building Manager </p>
          </button>
        </Link>
      </div>
      <div className="text-center text-white font-normal md:mt-10 lg:mt-0">
        <p className="py-2"> Already have an account ? </p>
        <button
          className="hover:font-bold underline underline-offset-2 flex mx-auto"
          type="button"
        >
          <Link to="/loginas" className="flex mx-auto">
            {" "}
            Log in{" "}
          </Link>
        </button>
      </div>
    </div>
  );
}

export default Signup;
