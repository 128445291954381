function checkApiKey(role) {
  switch (role) {
    case 'retailer':
      return process.env.REACT_APP_X_API_KEY_RETAILER;
    case 'customer':
      return process.env.REACT_APP_X_API_KEY_CUSTOMER;
    case 'buildingManager':
      return process.env.REACT_APP_X_API_KEY_BUILDINGMANAGER;
    case 'carrier':
      return process.env.REACT_APP_X_API_KEY_CARRIER;
    default:
      return null;
  }
}
export default checkApiKey;
