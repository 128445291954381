import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import RequestForm from "../../components/ForgotPasswordForm/RequestForm";
import SubmitForm from "../../components/ForgotPasswordForm/SubmitForm";
import {
  requestPasswordCodeLaunched,
  submitNewPasswordLaunched,
} from "../../service/reducer";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { forgotPasswordStep } = useSelector((state) => ({
    forgotPasswordStep: state.getIn(["app", "forgotPasswordStep"]),
  }));
  const [email, setEmail] = useState("");

  //  Forms Data
  const RequestInitialValues = {
    email: "",
  };

  const SubmissionInitialValues = {
    code: "",
    password: "",
    confirmPassword: "",
  };

  // Forms Submitting Functions
  const requestCode = (payload) => {
    setEmail(payload.email);
    dispatch(requestPasswordCodeLaunched(payload));
  };

  const submitNewPassword = (payload) => {
    const data = { username: email.toLowerCase(), ...payload };
    dispatch(submitNewPasswordLaunched(data));
  };

  // Forms Validation Schemas
  const RequestValidationSchema = Yup.object().shape({
    email: Yup.string().email("emailNotValid").required("emailRequired"),
  });

  const SubmissionValidationSchema = Yup.object().shape({
    code: Yup.string().required("Code required"),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .required("Password required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Password Mismatch")
      .required("Confirm password required"),
  });

  const renderForms = () => {
    if (forgotPasswordStep === 1) {
      return (
        // Requesting Code
        <>
          <div>
            <div className="my-5">
              <h2 className="mt-1 block text-s text-center tracking-tight py-2">
                No problem, type your email below and follow the instruction to
                create a new one
              </h2>
            </div>
          </div>
          <Formik
            render={(props) => <RequestForm {...props} />}
            initialValues={RequestInitialValues}
            validationSchema={RequestValidationSchema}
            onSubmit={requestCode}
          />
        </>
      );
    }
    return (
      // Sending New Password
      <Formik
        render={(props) => <SubmitForm {...props} />}
        initialValues={SubmissionInitialValues}
        validationSchema={SubmissionValidationSchema}
        onSubmit={submitNewPassword}
      />
    );
  };

  return (
    <div className="bg-bg-color h-screen bg-no-repeat bg-cover w-full py-24  flex flex-col justify-center">
      <div className="bg-white mx-auto w-5/6 md:w-3/5 lg:max-w-xl py-8 px-4 shadow sm:rounded-lg sm:px-10 ">
        <h2 className="text-xl mb-2">Password change</h2>
        <hr />
        {renderForms()}
      </div>
      <style>{`
    p {
      margin-top: 0.5rem;
    }
  `}</style>
    </div>
  );
};

export default ForgotPassword;
