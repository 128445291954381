import { all, put, takeLatest } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import checkApiKey from '../../utils/apiKeys';
import { config } from '../../config/amplify';

import {
  getUserEventsSuccess,
  getUserEventsFailure
} from './reducer';


function* getUserEvents(action) {
  const role = action.payload.role;
  const eventStatus = action.payload.eventStatus;
  const typeOfUser = action.payload.typeOfUser;
  const userEmail = action.payload.userEmail;
  const buildingId = action.payload.buildingId;
  let apiUrl;
  if (buildingId === undefined || buildingId === null) {
    apiUrl = `/deliveryEvents?eventStatus=${eventStatus}&${typeOfUser}=${userEmail}`
  } else {
    apiUrl = `/deliveryEvents?eventStatus=${eventStatus}&buildingId=${buildingId}`
  }
  try {
    const apiURL = apiUrl;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
    };
    const result = yield API.get(config.apiGateway.NAME, apiURL, params)
    yield put(getUserEventsSuccess(result));
  } catch (error) {
    yield put(getUserEventsFailure())
  }
}

export default function* saga() {
  yield all([
    takeLatest('eventStore/getUserEventsLaunched', getUserEvents),
  ]);
}