import { combineReducers } from 'redux-immutable';
import { connectRouter } from 'connected-react-router/immutable';
import AppReducer from '../service/reducer';
import StoreReducer from '../store/reducer';
import EventReducer from '../pages/EventsManager/reducer';
import getEventStore from '../pages/UpdateEvent/reducer';
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    app: AppReducer,
    store: StoreReducer,
    eventStore: EventReducer,
    getEventStore: getEventStore,
  });
