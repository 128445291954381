import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import add from '../../../assets/img/Add-button.svg';
import trash from '../../../assets/img/trash.svg';
import {
  deleteBuildingLoading,
  disabledResidentLoading,
  getBuildingLoading,
  getBuildingResidentLoading,
  invitResidentLoading,
  uploadPictureFileLauched,
  resetCreateBuilding,
  resetUpdateBuilding,
} from '../../../service/reducer';
import checkCurrentRole from '../../../utils/checkCurrentRole';
import DropDownMenu from '../../DropDownMenu';
import AddResident from './AddResident';
import BuildingForm from './BuildingForm';
import BuildingResident from './BuildingResident';
import CreateBuildingForm from './CreateBuildingForm';
import DefaultBuilding from '../../../assets/img/default_building_picture.png';
import UploadImgIcon from '../../../assets/img/upload_img_button.svg';
import { deleteFileS3 } from '../../../utils/awsLib';

function Buildinglist() {
  const dispatch = useDispatch();
  const {userDynamo} = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));
  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);
  let currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  const userInfo = currUserInfo[currentRole];

  const getBuilding = (buildingId) => {
    const role = currentRole;
    const body = {role, buildingId};
    dispatch(getBuildingLoading(body));
  };
  const deleteBuilding = (id) => {
    const role = currentRole;
    const buildingManagerId = userInfo.id;
    const body = {
      role,
      id,
      buildingManagers: [{buildingManagerId, isActive: false}],
    };
    dispatch(deleteBuildingLoading(body));
  };

  const {
    buildingInfo,
    buildingLoading,
    getBuildingError,
    uploadPictureFileLoading,
    uploadPictureFileSuccess,
    uploadPictureFileError,
    invitResidentInProgress,
    invitResidentErrorMessage,
    invitResidentSuccessMessage,
    deleteBuildingProgress,
    deleteBuildingErrorMessage,
  } = useSelector((state) => ({
    buildingInfo: state.getIn(['app', 'getCurrentBuilding']),
    buildingLoading: state.getIn(['app', 'getBuildingLoading']),
    getBuildingError: state.getIn(['app', 'getBuildingError']),
    uploadPictureFileLoading: state.getIn(['app', 'uploadPictureFileLoading']),
    uploadPictureFileSuccess: state.getIn(['app', 'uploadPictureFileSuccess']),
    uploadPictureFileError: state.getIn(['app', 'uploadPictureFileError']),
    invitResidentInProgress: state.getIn(['app', 'invitResidentLoading']),
    invitResidentErrorMessage: state.getIn([
      'app',
      'invitResidentErrorMessage',
    ]),
    invitResidentSuccessMessage: state.getIn([
      'app',
      'invitResidentSuccessMessage',
    ]),
    deleteBuildingLoading: state.getIn(['app', 'deleteBuildingLoading']),
    deleteBuildingErrorMessage: state.getIn([
      'app',
      'deleteBuildingErrorMessage',
    ]),
  }));

  // Delete Building if we have 2 BM //
  const [deleteBuildingConfirmation, setDeleteBuildingConfirmation] =
    useState();
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!deleteBuildingProgress && deleteBuildingErrorMessage) {
      setDeleteBuildingConfirmation(
        'You cannot leave this building because you are the only building manager. Ask a second manager to join the building.'
      );
      const timer = setTimeout(() => {
        setDeleteBuildingConfirmation();
      }, 4000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [deleteBuildingProgress, deleteBuildingErrorMessage]);

  const [isActive, setIsActive] = useState('information');

  const activeButton = (e) => {
    const {name} = e.target;
    setIsActive(name);
  };

  const [isActiveForm, setIsActiveForm] = useState('getBuilding');

  const activeForm = (e) => {
    if (isActive === 'resident') {
      setIsActive('information');
    }
    const {name} = e.target;
    setIsActiveForm(name);
  };

  // get resident information for a building //
  const getBuildingResident = (buildingId) => {
    const role = currentRole;
    const body = {role, buildingId};
    dispatch(getBuildingResidentLoading(body));
  };
  useEffect(() => {
    if (buildingInfo)
      getBuildingResident(buildingInfo?.id);
  }, [buildingInfo?.id]);

  // Delete Building if we have 2 BM //

  const [residentEmail, setResidentEmail] = useState('');
  const invitResident = () => {
    const role = currentRole;
    const buildingId = buildingInfo.id;
    const buildingManagerId = userInfo.id;
    const body = {
      buildingId,
      buildingManagerId,
      residentEmail,
      role,
    };
    dispatch(invitResidentLoading(body));
  };

  // Disabled resident from a building //
  const disabledResident = (id, customerId) => {
    const buildingId = buildingInfo.id;
    const role = currentRole;
    const body = {
      role,
      id,
      buildingId,
      customerId,
      isActive: false,
    };
    dispatch(disabledResidentLoading(body));
  };

  const [currentBuilding, setCurrentBuilding] = useState();
  // get default info building
  useEffect(() => {
    if (
      userInfo?.buildings !== undefined &&
      userInfo?.buildings[0]?.buildingId
    ) {
      getBuilding(userInfo?.buildings[0]?.buildingId);
      setCurrentBuilding(userInfo?.buildings[0]?.buildingName);
    } else {
      setIsActiveForm('newBuilding');
    }
  }, [userInfo]);

  const [updateBuildingSuccessMessage, setUpdateBuildingSuccessMessage] =
    useState(null);

  // building picture
  const [buildingFileKeyId, setBuildingFileKeyId] = useState();
  const [buildingFile, setBuildingFile] = useState();
  const [buildingFileError, setBuildingFileError] = useState();
  const [uploadFileSuccess, setUploadFileSuccess] = useState();
  const [uploadFileError, setUploadFileError] = useState();
  const [previewToDisplay, setPreviewToDisplay] = useState();

  const uploadFile = (file) => {
    // delete previous file
    if (buildingFileKeyId) {
      deleteFileS3(buildingFileKeyId);
    }

    // reset previous File value
    setBuildingFileKeyId();
    setBuildingFile();
    setBuildingFileError();
    setUploadFileError();
    setUploadFileSuccess();

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      setBuildingFileError('Forbidden type file ! PNG and JPG only accepted.');
    } else if (file.size < 32000 || file.size > 3000000) {
      setBuildingFileError(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 3mb)`
      );
    } else {
      const id = nanoid();
      setBuildingFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setBuildingFile(file);
    }
  };

  const sendFile = (file, fileKeyId) => {
    dispatch(uploadPictureFileLauched({file, fileKeyId}));
  };
  // send file
  useEffect(() => {
    if (buildingFileKeyId && buildingFile) {
      sendFile(buildingFile, buildingFileKeyId);
    }
  }, [buildingFileKeyId, buildingFile]);

  // set error or success message
  useEffect(() => {
    if (uploadPictureFileError) {
      setUploadFileError(uploadPictureFileError);
    }
    if (uploadPictureFileSuccess) {
      setUploadFileSuccess(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadPictureFileError, uploadPictureFileSuccess]);

  // function to reset all info about file
  const resetFileInfo = () => {
    setBuildingFile();
    setPreviewToDisplay();
    setBuildingFileKeyId();
    setBuildingFileError();
    setUploadFileSuccess();
    setUploadFileError();
  };
  // reset previous info file when building changed
  useEffect(() => {
    resetFileInfo();
  }, [buildingInfo]);

  return (
    <div className="flex flex-col mt-10 lg:w-full">
      <h1 className="text-white text-4xl font-bold mb-5 ml-10 md:ml-20">
        {' '}
        Complements{' '}
      </h1>
      <div className="flex w-full justify-center px-2 md:px-8">
        <div
          className="flex flex-col-reverse lg:flex-none lg:grid lg:grid-cols-8 bg-white rounded-lg w-full max-w-screen-2xl px-8 mx-auto py-10 space-x-0 lg:gap-x-6">
          {/* building list on desktop */}
          <div className="hidden lg:block col-span-2 mr-6">
            <p className="text-[#2260DD] border-b-[#3C66E4]	border-b-2 ">
              Your building(s)
            </p>
            {userInfo?.buildings?.map((building, index) => (
              <div
                key={index}
                className={`flex justify-between py-1.5 ${
                  building.buildingName !== currentBuilding
                    ? 'bg-white'
                    : 'bg-[#D9D9D9]'
                }`}
              >
                <button
                  type="submit"
                  name="getBuilding"
                  onClick={(e) => {
                    setIsActiveForm('getBuilding');
                    getBuilding(building.buildingId);
                    getBuildingResident(building.buildingId);
                    setCurrentBuilding(building.buildingName);
                    setUpdateBuildingSuccessMessage(null);
                    dispatch(resetUpdateBuilding());
                  }}
                  className="flex flex-start w-5/6 pl-0.5"
                >
                  <span className=" truncate">{building.buildingName}</span>
                </button>
                <button
                  type="submit"
                  onClick={() => deleteBuilding(building.buildingId)}
                  className="pr-0.5"
                >
                  <img src={trash} alt="delete" className="w-5"/>
                </button>
              </div>
            ))}
            {deleteBuildingConfirmation ? (
              <p className="pt-5 text-error text-s">
                {deleteBuildingConfirmation}
              </p>
            ) : null}
            <button
              type="button"
              name="newBuilding"
              className="text-[#2260DD] flex mt-6"
              onClick={(e) => {
                activeForm(e);
                resetFileInfo();
                dispatch(resetCreateBuilding());
              }}
            >
              {' '}
              <img src={add} alt="add icon"/>
              Add a building
            </button>
          </div>
          {/* form */}
          <div className="w-full lg:col-span-4 flex flex-col">
            <div className="flex">
              <button
                name="information"
                type="button"
                className={`py-4 text-xl lg:text-2xl font-bold ${
                  isActive === 'information'
                    ? 'text-[#2260DD] underline'
                    : 'text-[#97979752]'
                } mr-10`}
                onClick={activeButton}
              >
                Informations
              </button>
              {isActiveForm === 'newBuilding' ? null : (
                <button
                  name="resident"
                  type="button"
                  className={`py-4 text-xl lgtext-2xl font-bold ${
                    isActive === 'resident'
                      ? 'text-[#2260DD] underline'
                      : 'text-[#97979752]'
                  }`}
                  onClick={activeButton}
                >
                  Resident
                </button>
              )}
            </div>
            <div
              className={`${
                isActiveForm === 'newBuilding' && isActive === 'information'
                  ? 'visible'
                  : 'hidden'
              } flex-auto`}
            >
              <CreateBuildingForm buildingFileKeyId={buildingFileKeyId}/>
            </div>
            <div
              className={`${
                isActiveForm === 'getBuilding' && isActive === 'information'
                  ? 'visible'
                  : 'hidden'
              } flex-auto`}
            >
              {!buildingLoading && buildingInfo && (
                <BuildingForm
                  info={buildingInfo}
                  buildingFileKeyId={buildingFileKeyId}
                  successMessage={updateBuildingSuccessMessage}
                  setSuccessMessage={setUpdateBuildingSuccessMessage}
                />
              )}
              {buildingLoading && <p>Loading</p>}
            </div>
            <div
              className={`${
                isActive === 'resident' ? 'visible' : 'hidden'
              } flex-auto`}
            >
              <BuildingResident
                buildingInfo={buildingInfo}
                disabledResident={disabledResident}
              />
            </div>
          </div>
          {/* dropwdown and picture */}
          <div className="flex flex-col w-full lg:col-span-2">
            {userInfo?.buildings?.length !== 0 && (
              <DropDownMenu
                userInfo={userInfo}
                setForm={setIsActiveForm}
                getBuilding={getBuilding}
                getBuildingResident={getBuildingResident}
                activeForm={activeForm}
                currentBuilding={currentBuilding}
                setCurrentBuilding={setCurrentBuilding}
                resetFileInfo={resetFileInfo}
                resetUpdateState={resetUpdateBuilding()}
              />
            )}

            <div
              className={`${
                isActive === 'resident' ? 'visible' : 'hidden'
              } flex flex-row justify-end  mt-16`}
            >
              <AddResident
                invitResident={invitResident}
                setResidentEmail={setResidentEmail}
                invitResidentInProgress={invitResidentInProgress}
                invitResidentErrorMessage={invitResidentErrorMessage}
                invitResidentSuccessMessage={invitResidentSuccessMessage}
              />
            </div>
            <div
              className={`${
                isActive === 'information' ? 'visible' : 'hidden'
              } flex-auto mt-4 lg:mt-10`}
            >
              {!uploadPictureFileLoading && (
                <label
                  htmlFor="input_file"
                  className="mx-auto relative w-40 lg:w-full h-32 block cursor-pointer mb-6 lg:mb-0"
                >
                  {/* // placeholder */}
                  {!previewToDisplay &&
                    ((isActiveForm === 'newBuilding' &&
                        isActive === 'information') ||
                      uploadPictureFileError ||
                      buildingFileError ||
                      ((!previewToDisplay || !buildingFile) &&
                        (!buildingInfo?.pictureFileKey ||
                          buildingInfo?.pictureFileKey === ''))) && (
                      <img
                        src={DefaultBuilding}
                        className="absolute object-scale-down w-full h-full"
                        alt="Building Placeholder"
                      />
                    )}
                  {/* // preview */}
                  {uploadPictureFileSuccess &&
                    previewToDisplay &&
                    buildingFile?.name && (
                      <img
                        src={previewToDisplay}
                        className="absolute object-scale-down w-full h-full"
                        alt={buildingFile?.name}
                      />
                    )}
                  {/* // image from database */}
                  {isActiveForm !== 'newBuilding' &&
                    !previewToDisplay &&
                    buildingInfo?.pictureFileKey &&
                    buildingInfo?.pictureFileKey !== '' && (
                      <img
                        src={
                          'https://' +
                          process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                          '.s3.' +
                          process.env.REACT_APP_AWS_REGION +
                          '.amazonaws.com/' +
                          buildingInfo?.pictureFileKey
                        }
                        className="absolute object-scale-down w-full h-full"
                        alt={buildingInfo?.name}
                      />
                    )}
                  <img
                    src={UploadImgIcon}
                    className="absolute w-8 h-8 translate-x-32 lg:translate-x-44 xl:translate-x-48	translate-y-28"
                    alt="Add File"
                  />
                  <input
                    id="input_file"
                    accept="image/*"
                    type="file"
                    className="hidden"
                    onInput={(e) => {
                      uploadFile(e.target.files[0]);

                      // preview
                      var reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onload = function () {
                        setPreviewToDisplay(reader.result);
                      };
                    }}
                  />
                </label>
              )}
              {/* // upload in progress */}
              {uploadPictureFileLoading && (
                <div className="flex justify-center w-full h-full text-sm">
                  <p>Upload in progress...</p>
                </div>
              )}
              {/* error */}
              {(buildingFileError || uploadFileError) && (
                <div className="pt-8 flex justify-center w-full h-full">
                  <p className="text-sm text-error">
                    {buildingFileError
                      ? buildingFileError
                      : uploadFileError
                        ? uploadFileError
                        : null}{' '}
                  </p>
                </div>
              )}
              {/* success */}
              {uploadFileSuccess && (
                <div className="pt-8 flex justify-center w-full h-full">
                  <p className="text-sm text-primary">{uploadFileSuccess} </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buildinglist;
