const getStyles = (theme) => ({
    retailer: {
        width: "16px",
        height: "16px",
        background: "#DE2B56",
        borderColor: "white",
        borderWidth: "1px",
        borderRadius: "50%",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        lineHeight: "20px",
        verticalAlign: "middle",
        textAlign: "center",
        color: "white"
    },
    customer: {
        width: "16px",
        height: "16px",
        background: "#E2AA18",
        borderColor: "white",
        borderWidth: "1px",
        borderRadius: "50%",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        lineHeight: "20px",
        verticalAlign: "middle",
        textAlign: "center",
        color: "white"
    },
    buildingManager: {
        width: "16px",
        height: "16px",
        background: "#2260DD",
        borderColor: "white",
        borderWidth: "1px",
        borderRadius: "50%",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        lineHeight: "20px",
        verticalAlign: "middle",
        textAlign: "center",
        color: "white"
    },
    carrier: {
        width: "16px",
        height: "16px",
        background: "#8940B5",
        borderColor: "white",
        borderWidth: "1px",
        borderRadius: "50%",
        MozBorderRadius: "50%",
        WebkitBorderRadius: "50%",
        lineHeight: "20px",
        verticalAlign: "middle",
        textAlign: "center",
        color: "white"
    }
});

export default getStyles;