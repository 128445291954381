import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import AccountField from '../../components/AccountField';
import * as Yup from 'yup';
import 'yup-phone-lite';
import Picture from '../../assets/img/Icon_button.svg';
import ModalEditPassword from '../../components/ModalEditPassword';
import checkCurrentRole from '../../utils/checkCurrentRole';
import {
  updateUserLaunched,
  uploadPictureFileLauched,
} from '../../service/reducer';
import UploadImgIcon from '../../assets/img/upload_img_button.svg';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { deleteFileS3 } from '../../utils/awsLib';
import { nanoid } from 'nanoid';
import defaultIcon from '../../assets/img/DefaultIcon.svg';

const AccountInfo = () => {
  const dispatch = useDispatch();
  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));

  // Upload for profil Picture //
  const {
    updateUserLoading,
    updateUserSuccessMessage,
    uploadPictureFileLoading,
    uploadPictureFileSuccess,
    uploadPictureFileError,
  } = useSelector((state) => ({
    updateUserLoading: state.getIn(['app', 'updateUserLoading']),
    updateUserSuccessMessage: state.getIn(['app', 'updateUserSuccessMessage']),
    uploadPictureFileLoading: state.getIn(['app', 'uploadPictureFileLoading']),
    uploadPictureFileSuccess: state.getIn(['app', 'uploadPictureFileSuccess']),
    uploadPictureFileError: state.getIn(['app', 'uploadPictureFileError']),
  }));

  const [successMessage, setSuccessMessage] = useState();

  // set and reset sucessMessage //

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!updateUserLoading && updateUserSuccessMessage) {
      setSuccessMessage('Your info was updated !');
    }
  }, [updateUserLoading, updateUserSuccessMessage]);

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);

  let currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  const userInfo = currUserInfo[currentRole];

  const updateUser = (formik) => {
    if (profilFileKeyId) {
      formik.pictureFileKey = profilFileKeyId;
    }
    dispatch(updateUserLaunched(formik));
  };

  const validate = Yup.object({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email is invalid').required('Email required'),
    phone: Yup.string()
      .optional()
      .phone('US', 'Please enter a valid US phone number'),
  });

  // Modale
  const [openModalEditPassword, setOpenModalEditPassword] = useState(false);
  let body;
  if (typeof window !== 'undefined') {
    // during build window is unavailable
    body = document.querySelector('body');
  }
  const showModal = () => {
    setOpenModalEditPassword(true);
    if (typeof window !== 'undefined') {
      body.style.overflow = 'hidden'; // block scroll on body when modal is open
    }
  };
  const hideModal = () => {
    setOpenModalEditPassword(false);
    if (typeof window !== 'undefined') {
      body.style.overflow = 'auto';
    }
  };

  const isDisabled = validate ? false : true;

  // Profil picture
  const [profilFileKeyId, setProfilFileKeyId] = useState();
  const [profilFile, setProfilFile] = useState();
  const [profilFileError, setProfilFileError] = useState();
  const [uploadFileSuccess, setUploadFileSuccess] = useState();
  const [uploadFileError, setUploadFileError] = useState();
  const [previewToDisplay, setPreviewToDisplay] = useState();

  const uploadFile = (file) => {
    // delete previous file
    if (profilFileKeyId) {
      deleteFileS3(profilFileKeyId);
    }

    // reset previous File value
    setProfilFileKeyId();
    setProfilFile();
    setProfilFileError();
    setUploadFileError();
    setUploadFileSuccess();

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      setProfilFileError('Forbidden type file ! PNG and JPG only accepted.');
    } else if (file.size < 32000 || file.size > 3000000) {
      setProfilFileError(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 3mb)`
      );
    } else {
      const id = nanoid();
      setProfilFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setProfilFile(file);
    }
  };

  const sendFile = (file, fileKeyId) => {
    dispatch(uploadPictureFileLauched({ file, fileKeyId }));
  };
  // send file
  useEffect(() => {
    if (profilFileKeyId && profilFile) {
      sendFile(profilFile, profilFileKeyId);
    }
  }, [profilFileKeyId, profilFile]);

  // set error or success message
  useEffect(() => {
    if (uploadPictureFileError) {
      setUploadFileError(uploadPictureFileError);
    }
    if (uploadPictureFileSuccess) {
      setUploadFileSuccess(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadPictureFileError, uploadPictureFileSuccess]);

  // function to reset all info about file
  const resetFileInfo = () => {
    setProfilFile();
    setPreviewToDisplay();
    setProfilFileKeyId();
    setProfilFileError();
    setUploadFileSuccess();
    setUploadFileError();
  };
  // reset previous info file when profil picture changed
  useEffect(() => {
    resetFileInfo();
  }, [userInfo]);

  return (
    <div>
      <Formik
        initialValues={{
          id: userInfo.id,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          email: userInfo.email,
          phone: userInfo.phone,
          role: currentRole,
        }}
        validationSchema={validate}
        onSubmit={(formik) => {
          updateUser(formik);
          setSuccessMessage(updateUserSuccessMessage);
        }}
      >
        {(formik) => (
          <div className='flex flex-col mt-10 w-full'>
            <h1 className='text-white text-4xl font-bold mb-5 lg:ml-20 ml-10 md:ml-40'>
              {' '}
              Account{' '}
            </h1>
            <div className='relative px-2 md:px-8 w-full'>
              <Form className='bg-white shadow-md rounded-lg px-4 lg:px-8 pt-6 pb-8 w-full max-w-screen-2xl mx-auto'>
                <div className='flex justify-between'>
                  <div>
                    <h2 className='text-xl'> Personal information </h2>
                    <p className='text-sm text-gray-500 py-2'>
                      {' '}
                      Use a permanent email where you can receive mail.{' '}
                    </p>
                  </div>
                  <div className='relative flex-end'>
                    {!uploadPictureFileLoading && (
                      <label
                        htmlFor='input_file'
                        className='relative w-40 h-32 block cursor-pointer mb-6 lg:mb-0 mx-auto'
                      >
                        {/* // placeholder */}
                        {!previewToDisplay &&
                          (uploadPictureFileError ||
                            profilFileError ||
                            ((!previewToDisplay || !profilFile) &&
                              (!userInfo?.pictureFileKey ||
                                userInfo?.pictureFileKey === ''))) && (
                            <div>
                              <img
                                src={defaultIcon}
                                className='absolute object-cover w-full h-full'
                                alt='Profil Placeholder'
                              />
                            </div>
                          )}
                        {/* // preview */}
                        {uploadPictureFileSuccess &&
                          previewToDisplay &&
                          profilFile?.name && (
                            <img
                              src={previewToDisplay}
                              className='absolute object-scale-down w-full h-full'
                              alt={profilFile?.name}
                            />
                          )}
                        {/* // image from database */}
                        {!previewToDisplay &&
                          userInfo?.pictureFileKey &&
                          userInfo?.pictureFileKey !== '' && (
                            <img
                              src={
                                'https://' +
                                process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                '.s3.' +
                                process.env.REACT_APP_AWS_REGION +
                                '.amazonaws.com/' +
                                userInfo?.pictureFileKey
                              }
                              className='absolute object-scale-down w-full h-full'
                              alt={userInfo?.name}
                            />
                          )}
                        <img
                          src={UploadImgIcon}
                          className='absolute w-8 h-8 translate-x-16 translate-y-28'
                          alt='Add File'
                        />
                        <input
                          id='input_file'
                          accept='image/*'
                          type='file'
                          className='hidden'
                          onInput={(e) => {
                            uploadFile(e.target.files[0]);

                            // preview
                            var reader = new FileReader();
                            reader.readAsDataURL(e.target.files[0]);
                            reader.onload = function () {
                              setPreviewToDisplay(reader.result);
                            };
                          }}
                        />
                      </label>
                    )}
                    {/* // upload in progress */}
                    {uploadPictureFileLoading && (
                      <div className='flex justify-center w-full h-full text-sm'>
                        <p>Upload in progress...</p>
                      </div>
                    )}
                    {/* error */}
                    {(profilFileError || uploadFileError) && (
                      <div className='pt-8 flex justify-center w-full h-full'>
                        <p className='text-sm text-error'>
                          {profilFileError
                            ? profilFileError
                            : uploadFileError
                            ? uploadFileError
                            : null}{' '}
                        </p>
                      </div>
                    )}
                    {/* success */}
                    {uploadFileSuccess && (
                      <div className='pt-8 flex justify-center w-full h-full'>
                        <p className='text-sm text-primary'>
                          {uploadFileSuccess}{' '}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className='lg:flex'>
                  <div className='w-full md:w-5/6'>
                    <AccountField
                      label='First Name*'
                      name='firstName'
                      type='text'
                      placeholder='John'
                    />
                    <AccountField
                      label='Last Name*'
                      name='lastName'
                      type='text'
                      placeholder='Doe'
                    />
                    <AccountField
                      label='Email*'
                      name='email'
                      type='email'
                      disabled
                      placeholder='johndoe@mycommand.com'
                    />
                    <AccountField
                      label='Phone'
                      name='phone'
                      type='tel'
                      placeholder='213-509-6995'
                    />
                    <AccountField
                      label='Password'
                      name='password'
                      type='password'
                      placeholder='Password'
                      disabled={true}
                    />
                    <button
                      id='editPassword'
                      name='editPassword'
                      type='button'
                      onClick={showModal}
                      className='hover:font-bold underline underline-offset-2 text-[#1a5dda]'
                    >
                      <EditIcon /> Edit Password{' '}
                    </button>
                  </div>
                </div>
                <div className='flex items-center'>
                  {/* disabled button until loading is not finish to prevent spam click */}
                  {updateUserLoading && profilFileError && (
                    <button
                      type='submit'
                      disabled={isDisabled}
                      className='bg-bg-button rounded-lg group flex mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 text-white text-lg hover:scale-105 ease-out duration-300'
                    >
                      <CircularProgress
                        size={20}
                        style={{ color: 'white' }}
                        className='mx-auto'
                      />
                    </button>
                  )}

                  {!updateUserLoading && !successMessage && (
                    <button
                      type='submit'
                      className='bg-bg-button rounded-lg group flex mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 text-white text-lg hover:scale-105 ease-out duration-300'
                    >
                      <p className='mx-auto group-hover:font-bold'>
                        <SaveIcon className='mr-2' /> Save changes{' '}
                      </p>
                    </button>
                  )}
                  {successMessage && (
                    <p className='text-green-700 text-center text-white rounded-lg bg-green-100 mt-4 md:w-3/4 lg:w-2/6 mx-auto'>
                      {successMessage}
                    </p>
                  )}
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
      <ModalEditPassword
        showModal={openModalEditPassword}
        hideModal={hideModal}
      />
    </div>
  );
};

export default AccountInfo;
