const translateSignUpError = (errorCode) => {
  switch (errorCode) {
    case 'NetworkError':
      return 'Please check your internet connection.';
    case 'InvalidPasswordException':
    case 'InvalidParameterException':
      return 'Invalid password.';
    case 'TooManyRequestsException':
      return 'Too many requests made.';
    case 'UsernameExistsException':
      return 'This email is already in use.';
    default:
      return 'Error';
  }
};

const translateConfirmSignUpError = (errorCode) => {
  switch (errorCode) {
    case 'CodeMismatchException':
      return 'Incorrect code.';
    case 'ExpiredCodeException':
      return 'Code expired.';
    case 'InternalErrorException':
      return 'Internal cognito error.';
    case 'TooManyFailedAttemptsException':
      return 'Limit of attempt exceeded.';
    default:
      return 'Error';
  }
};

const translateSignInError = (errorCode) => {
  switch (errorCode) {
    case 'UserNotFoundException':
      return "No account with this email exists, please check your email.";
    case 'NotAuthorizedException':
      return 'Incorrect password.';
    case 'FormNotComplete':
      return 'Please enter your email and password.';
    default:
      return 'Error';
  }
};

const translateForgotPassword = (errorCode) => {
  switch (errorCode) {
    case 'NetworkError':
      return 'Please check your internet connection.';
    case 'UserNotFoundException':
      return "No account with this email exists, please check your email.";
    case 'FormNotComplete':
      return 'Please enter your email.';
    case 'LimitExceededException':
    case 'TooManyRequestsException':
      return 'Limit of attempts exceeded, please try again later.';
    default:
      return 'Error';
  }
};

const translateConfirmForgotPassword = (errorCode) => {
  switch (errorCode) {
    case 'PasswordDoNotMatch':
      return 'Your passwords do not match.';
    case 'CodeMismatchException':
      return 'Incorrect code.';
    case 'ExpiredCodeException':
      return 'Code expired.';
    case 'NetworkError':
      return 'Please check your internet connection.';
    case 'FormNotComplete':
      return 'Please complete all required fields.';
    case 'LimitExceededException':
    case 'TooManyRequestsException':
      return 'Limit of attempts exceeded, please try again later.';
    default:
      return 'Error';
  }
};

const translateErrorChangePassword = (errorCode) => {
  switch (errorCode) {
    case 'LimitExceededException':
      return 'Attempt limit exceeded, please try again later.';
    case 'InvalidPasswordException':
      return 'Invalid password, please try again.';
    case 'NotAuthorizedException':
      return 'Failed: Incorrect old password !';
    case 'PasswordResetRequiredException':
      return 'A password reset is required.';
    case 'ResourceNotFoundException':
      return 'An error has occurred, please try again later.';
    case 'TooManyRequestsException':
      return 'Attempt limit exceeded, please try again later.';
    case 'UserNotConfirmedException':
      return 'We were unable to identify you, please try again later.';
    default:
      return 'Unkown error occured.'
  }
}

export {
  translateSignUpError,
  translateConfirmSignUpError,
  translateSignInError,
  translateForgotPassword,
  translateConfirmForgotPassword,
  translateErrorChangePassword
};
