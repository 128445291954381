import { all, delay, put, takeLatest } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import { push } from 'connected-react-router';
import checkApiKey from '../utils/apiKeys';
import {
  translateSignInError,
  translateSignUpError,
  translateConfirmForgotPassword,
  translateForgotPassword,
} from '../utils/cognito';
import {
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  getCurrentSessionLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  openPopup,
  changePasswordSuccess,
  changePasswordFailure,
  requestPasswordCodeFailure,
  requestPasswordCodeSuccess,
  signupSuccess,
  signupFailure,
  submitNewPasswordSuccess,
  submitNewPasswordFailure,
  updateUserFailure,
  updateUserSuccess,
  createBuildingSuccess,
  getBuildingSuccess,
  invitResidentFailure,
  getBuildingFailure,
  getBuildingResidentSuccess,
  getBuildingResidentFailure,
  updateBuildingFailure,
  updateBuildingSuccess,
  createEventSuccess,
  createEventFailure,
  uploadInsuranceFileSuccess,
  uploadInsuranceFileFailure,
  getBuildingLoading,
  uploadPictureFileSuccess,
  uploadPictureFileFailure,
  uploadEntranceDoorPhotoFileSuccess,
  uploadEntranceDoorPhotoFileFailure,
  uploadFreightDoorPhotoFileSuccess,
  uploadFreightDoorPhotoFileFailure,
  uploadCOIDocumentFilePhotoFileSuccess,
  uploadCOIDocumentFilePhotoFileFailure,
  uploadBuildingDocumentFilePhotoFileSuccess,
  uploadBuildingDocumentFilePhotoFileFailure,
  invitResidentSuccess,
  deleteBuildingFailure,
  uploadInvoiceFileSuccess,
  uploadInvoiceFileFailure,
  resetGetBuilding,
} from './reducer';

import { config } from '../config/amplify';
import roleToUppercase from '../utils/roleToUppercase';
import { addFile } from '../utils/awsLib';

function* getCurrentSession(action) {
  const { fromPath, role } = action.payload || {};
  const apiKey = checkApiKey(role);
  const roleUP = roleToUppercase(role);
  try {
    yield Auth.currentSession();
    const userInfo = yield Auth.currentUserInfo();
    const userDynamo = yield API.post(config.apiGateway.NAME, '/sessions', {
      headers: {
        'x-api-key': apiKey,
      },
      body: {
        email: userInfo.attributes.email,
        role: roleUP,
      },
    });

    yield put(getCurrentSessionSuccess({ userInfo, userDynamo }));
    if (fromPath) {
      // yield delay(30000);
      yield put(push(fromPath));
    }
  } catch (error) {
    yield put(getCurrentSessionFailure(error?.response?.data));
  }
}

function* doSignIn(action) {
  const { email, password, role, from } = action.payload;
  try {
    yield Auth.signIn(email.toLowerCase(), password);
    yield put(loginSuccess());
    if (typeof !window) {
      window.localStorage.setItem('role', role);
    }
    const result = yield put(
      getCurrentSessionLaunched({
        fromPath: '/account/eventsmanager',
        role: role,
      })
    );
  } catch (err) {
    yield put(loginFailure(translateSignInError(err.code)));
  }
}

function* doSignOut() {
  try {
    yield Auth.signOut();
    if (typeof !window) {
      window.localStorage.removeItem('role');
    }
    yield put(logoutSuccess());
  } catch (err) {
    yield put(logoutFailure());
  }
  yield put(getCurrentSessionLaunched('/loginas'));
}

function* doSignUp(action) {
  const role = action.payload.role;
  const body = action.payload;
  delete body.role;
  try {
    const result = yield API.post(config.apiGateway.NAME, `/${role}`, {
      headers: {
        'x-api-key': checkApiKey(role),
        'content-type': 'multipart/form-data',
      },
      body: action.payload,
    });
    // We need operator validation -> user cannot autologged after Signup
    // yield call(doSignIn, { payload: { email: action.payload.email, password: action.payload.password } });
    // yield put(getCurrentSessionLaunched({ fromPath: '/login' }));
    yield put(push('/thankregister'));
    yield put(signupSuccess(result));
  } catch (error) {
    // console.log(error);
    yield put(signupFailure(error?.response?.data));
    yield put(
      openPopup({
        open: true,
        message: translateSignUpError(error.code),
        error: true,
        popupId: 'signupFailure',
      })
    );
  }
}

function* doChangePassword(action) {
  const data = action.payload;
  try {
    const currentUser = yield Auth.currentAuthenticatedUser();
    const result = yield Auth.changePassword(
      currentUser,
      data.oldPassword,
      data.password
    );
    yield put(changePasswordSuccess(result));
    yield put(push('/account'));
  } catch (error) {
    yield put(changePasswordFailure(error));
  }
}

function* doRequestPasswordCode(action) {
  const { email } = action.payload;

  try {
    yield Auth.forgotPassword(email);
    yield put(requestPasswordCodeSuccess());
  } catch (error) {
    yield put(requestPasswordCodeFailure(translateForgotPassword(error.code)));
  }
}

function* doSubmitNewPassword(action) {
  const { username, code, password } = action.payload;
  try {
    yield Auth.forgotPasswordSubmit(username, code, password);
    yield put(push('/confirmpasswordchange'));
    yield put(submitNewPasswordSuccess());
  } catch (error) {
    yield put(
      submitNewPasswordFailure(translateConfirmForgotPassword(error.code))
    );
  }
}

function* doUpdateUser(action) {
  const role = action.payload.role;
  const userId = action.payload.id;
  const body = action.payload;
  delete body.role;
  delete body.email;
  try {
    const apiURL = `/${role}/${userId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    yield API.put(config.apiGateway.NAME, apiURL, params);
    yield put(updateUserSuccess());
    yield delay(2000);
    yield put(getCurrentSessionLaunched({ fromPath: null, role: role }));
  } catch (error) {
    yield put(updateUserFailure());
  }
}

function* AddBuilding(action) {
  const role = action.payload.role;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildings`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    yield API.post(config.apiGateway.NAME, apiURL, params);
    yield put(createBuildingSuccess());
    yield delay(3000);
    yield put(getCurrentSessionLaunched({ fromPath: null, role: role }));
  } catch (error) {
    yield put(updateUserFailure());
  }
}

function* getBuilding(action) {
  const role = action.payload.role;
  const buildingId = action.payload.buildingId;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildings/${buildingId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
    };
    const result = yield API.get(config.apiGateway.NAME, apiURL, params);
    yield put(getBuildingSuccess(result));
  } catch (error) {
    yield put(getBuildingFailure());
  }
}

function* desactiveBuilding(action) {
  const role = action.payload.role;
  const buildingId = action.payload.id;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildings/${buildingId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    yield API.put(config.apiGateway.NAME, apiURL, params);
    yield put(getCurrentSessionLaunched({ fromPath: null, role: role }));
  } catch (error) {
    yield put(deleteBuildingFailure(error));
  }
}

function* doUpdateBuilding(action) {
  const role = action.payload.role;
  const buildingId = action.payload.id;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildings/${buildingId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    const result = yield API.put(config.apiGateway.NAME, apiURL, params);
    // yield delay(2000);
    yield put(updateBuildingSuccess(result));
    yield put(
      getBuildingLoading({ buildingId: action.payload.id, role: role })
    );
  } catch (error) {
    yield put(updateBuildingFailure());
  }
}

function* getBuildingResident(action) {
  const role = action.payload.role;
  const buildingId = action.payload.buildingId;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildingResidents?buildingId=${buildingId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
    };
    const result = yield API.get(config.apiGateway.NAME, apiURL, params);
    yield put(getBuildingResidentSuccess(result));
  } catch (error) {
    yield put(getBuildingResidentFailure());
  }
}

function* doInvitResident(action) {
  const role = action.payload.role;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildingResidents/invit`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    yield API.post(config.apiGateway.NAME, apiURL, params);
    yield put(invitResidentSuccess());
  } catch (error) {
    yield put(invitResidentFailure());
  }
}

function* desactiveResident(action) {
  const role = action.payload.role;
  const buildingResidentId = action.payload.id;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/buildingResidents/${buildingResidentId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    yield API.put(config.apiGateway.NAME, apiURL, params);
    yield put(getCurrentSessionLaunched({ fromPath: null, role: role }));
  } catch (error) {
    yield put(updateUserFailure());
  }
}

function* CreateEvent(action) {
  const role = action.payload.role;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/deliveryEvents`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    const result = yield API.post(config.apiGateway.NAME, apiURL, params);
    yield put(createEventSuccess(result));
    yield put(resetGetBuilding())
    yield put(push(`/account/eventsmanager`, { from: '/account/events' }));
  } catch (error) {
    yield put(createEventFailure(error?.response?.data));
  }
}

function* uploadInsuranceFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadInsuranceFileSuccess());
    } else {
      yield put(uploadInsuranceFileFailure());
    }
  } catch (error) {
    yield put(uploadInsuranceFileFailure());
  }
}
function* uploadEntranceDoorPhotoFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadEntranceDoorPhotoFileSuccess());
    } else {
      yield put(uploadEntranceDoorPhotoFileFailure());
    }
  } catch (error) {
    yield put(uploadEntranceDoorPhotoFileFailure());
  }
}
function* uploadFreightDoorPhotoFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadFreightDoorPhotoFileSuccess());
    } else {
      yield put(uploadFreightDoorPhotoFileFailure());
    }
  } catch (error) {
    yield put(uploadFreightDoorPhotoFileFailure());
  }
}
function* uploadCOIDocumentFilePhotoFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadCOIDocumentFilePhotoFileSuccess());
    } else {
      yield put(uploadCOIDocumentFilePhotoFileFailure());
    }
  } catch (error) {
    yield put(uploadCOIDocumentFilePhotoFileFailure());
  }
}
function* uploadBuildingDocumentFilePhotoFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadBuildingDocumentFilePhotoFileSuccess());
    } else {
      yield put(uploadBuildingDocumentFilePhotoFileFailure());
    }
  } catch (error) {
    yield put(uploadBuildingDocumentFilePhotoFileFailure());
  }
}

function* uploadPictureFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadPictureFileSuccess());
    } else {
      yield put(uploadPictureFileFailure());
    }
  } catch (error) {
    yield put(uploadPictureFileFailure());
  }
}

function* uploadInvoiceFile(action) {
  const { file, fileKeyId } = action.payload;
  try {
    const result = yield addFile(file, fileKeyId);
    if (Number(result.$metadata.httpStatusCode) === 200) {
      yield put(uploadInvoiceFileSuccess());
    } else {
      yield put(uploadInvoiceFileFailure());
    }
  } catch (error) {
    yield put(uploadInvoiceFileFailure());
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest('App/getCurrentSessionLaunched', getCurrentSession),
    takeLatest('App/loginLaunched', doSignIn),
    takeLatest('App/logoutLaunched', doSignOut),
    takeLatest('App/signupLaunched', doSignUp),
    takeLatest('App/requestPasswordCodeLaunched', doRequestPasswordCode),
    takeLatest('App/submitNewPasswordLaunched', doSubmitNewPassword),
    takeLatest('App/updateUserLaunched', doUpdateUser),
    takeLatest('App/changePasswordLaunched', doChangePassword),
    takeLatest('App/createBuildingLauched', AddBuilding),
    takeLatest('App/invitResidentLoading', doInvitResident),
    takeLatest('App/getBuildingLoading', getBuilding),
    takeLatest('App/deleteBuildingLoading', desactiveBuilding),
    takeLatest('App/disabledResidentLoading', desactiveResident),
    takeLatest('App/updateBuildingLaunched', doUpdateBuilding),
    takeLatest('App/getBuildingResidentLoading', getBuildingResident),
    takeLatest('App/createEventLoading', CreateEvent),
    takeLatest('App/uploadInsuranceFileLauched', uploadInsuranceFile),
    takeLatest('App/uploadEntranceDoorPhotoFileLaunched', uploadEntranceDoorPhotoFile),
    takeLatest('App/uploadFreightDoorPhotoFileLaunched', uploadFreightDoorPhotoFile),
    takeLatest('App/uploadCOIDocumentFilePhotoFileLaunched', uploadCOIDocumentFilePhotoFile),
    takeLatest('App/uploadBuildingDocumentFilePhotoFileLaunched', uploadBuildingDocumentFilePhotoFile),
    takeLatest('App/uploadPictureFileLauched', uploadPictureFile),
    takeLatest('App/uploadInvoiceFileLauched', uploadInvoiceFile)
  ]);
}
