import React from 'react';
import { ErrorMessage, useField } from 'formik';


function LoginForm({ label, ...props }) {

  const [field, meta] = useField(props);

  return (
    <div>

      <input
        className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
          meta.touched && meta.error && 'border-red-500'
        }`}
        type='text'
        {...field}
        {...props}
      />

      <ErrorMessage
        component='div'
        name={field.name}
        className='text-red-500'
      />
    </div>
  );
}

export default LoginForm;
