import React from 'react';
import Header from '../../components/Header';
import AccountInfo from '../../components/AccountInfo';

export default function Account() {
  return (
    <div>
      <div className='static'>
        <Header />
      </div>
      <div className='absolute lg:top-40 top-32 inset-x-0'>
        <AccountInfo />
      </div>
    </div>
  );
}
