const getStyles = () => ({
  retailer: {
    background: "#faeaf3",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
  retailerEnd: {
    background: "#faeaf3",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "0px",
  },
});

export default getStyles;