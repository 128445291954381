import React from 'react';
import { Link } from 'react-router-dom';
import { sortByCreatedDateDesc } from '../../../utils/format';
import { commonDateFormat } from '../../../utils/formatCommonDate';
import VisibilityIcon from '@mui/icons-material/Visibility';

const TableEvent = ({ role, events, eventSelectedId, setEventSelectedId }) => {
  return (
    <div>
      <div className="-mx-4 mt-8 overflow-hidden lg:overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-neutral-dark sm:pl-6 xl:hidden">
                EVENT
              </th>
              <th scope="col" className="hidden py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-neutral-dark sm:pl-6 xl:block">
                EVENT ID
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell"

              >
                INTERNAL REF
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell"
              >
                BUILDING
              </th>
              <th scope="col" className={`px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark ${role === 'customer' ? "hidden" : "hidden xl:table-cell"}`}>
                CUSTOMER/RESIDENT
              </th>
              <th scope="col" className={`px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark ${role === 'buildingManager' ? "hidden" : "hidden xl:table-cell"}`}>
                BUILDING MANAGER
              </th>
              <th scope="col" className={`px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark ${role === 'retailer' ? "hidden" : "hidden xl:table-cell"}`}>
                RETAILER
              </th>
              <th scope="col" className={`px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark ${role === 'carrier' ? "hidden" : "hidden xl:table-cell"}`}>
              CARRIER/CONTRACTOR
              </th>
              <th scope="col" className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell">
                INSURANCE
              </th>
              <th scope="col" className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell">
                DATE
              </th>
              <th scope="col" className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell">
                HOUR
              </th>
              <th scope="col" className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell">
                BOOKING
              </th>
              <th scope="col" className="hidden px-3 py-3.5 text-left text-xs font-semibold text-neutral-dark xl:table-cell">
                C02
              </th>
              <th scope="col" className="relative py-3.5 px-1 md:px-3 text-xs font-semibold text-neutral-dark">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {events.sort(sortByCreatedDateDesc).map((event) => (
              <tr key={event.id} onClick={() => setEventSelectedId(event.id)} className={`${eventSelectedId === event.id ? "bg-primary-lighter" : "bg-white"}`}>
                <td className={`w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium sm:w-auto sm:max-w-none sm:pl-6 ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"}`}>
                  {event.eventNumber}
                  {/* Mobile */}
                  <dl className="font-normal xl:hidden space-y-2">
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} block flex flex-col`}>
                      {(role === 'customer') && (
                        <>
                          <span className="font-semibold">Internal Ref: {event.customer?.internalRef ? null : "N/A"} </span>
                          {event.customer?.internalRef ? <span>{event.customer?.internalRef}</span> : null}
                        </>
                      )}
                      {(role === 'buildingManager') && (
                        <>
                          <span className="font-semibold">Internal Ref: {event.buildingManager?.internalRef ? null : "N/A"} </span>
                          {event.buildingManager?.internalRef ? <span>{event.buildingManager?.internalRef}</span> : null}
                        </>
                      )}
                      {(role === 'retailer') && (
                        <>
                          <span className="font-semibold">Internal Ref: {event.retailer?.internalRef ? null : "N/A"} </span>
                          {event.retailer?.internalRef ? <span>{event.retailer?.internalRef}</span> : null}
                        </>
                      )}
                      {(role === 'carrier') && (
                        <>
                          <span className="font-semibold">Internal Ref: {event.carrier?.internalRef ? null : "N/A"} </span>
                          {event.carrier?.internalRef ? <span>{event.carrier?.internalRef}</span> : null}
                        </>
                      )}
                    </dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"}`}>Building: {event.building?.name ? event.building?.name : "N/A"}</dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'customer' ? "hidden" : "block flex flex-col"}`}>
                      <span>Customer/Resident: {event.customerEmail ? null : "N/A"} </span>
                      {event.customerEmail ? <span>{event.customerEmail}</span> : null}
                    </dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'buildingManager' ? "hidden" : "block flex flex-col"}`}>
                      <span>BuildingManager: {event.buildingManagerEmail ? null : "N/A"} </span>
                      {event.buildingManagerEmail ? <span>{event.buildingManagerEmail}</span> : null}
                    </dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'retailer' ? "hidden" : "block flex flex-col"}`}>
                      <span>Retailer: {event.retailerEmail ? null : "N/A"} </span>
                      {event.retailer?.company ? <span>{event.retailer.company}</span> : null}
                      {event.retailerEmail ? <span>{event.retailerEmail}</span> : null}
                    </dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'carrier' ? "hidden" : "block flex flex-col"}`}>
                      <span>Carrier/Contractor: {event.carrierEmail ? null : "N/A"} </span>
                      {event.carrier?.company ? <span>{event.carrier.company}</span> : null}
                      {event.carrierEmail ? <span>{event.carrierEmail}</span> : null}</dd>
                    <dd className={`mt-1 truncate ${event.insuranceFileKey && event.insuranceFileKey !== "" ? "text-success" : "text-pending"}`}>Insurance: {event.insuranceFileKey && event.insuranceFileKey !== "" ? "OK" : "Waiting"}</dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"}`}>Common Date: {event.commonDate && event.commonDate.length !== 0 ? commonDateFormat(event.commonDate[0])?.date : "N/A"}</dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"}`}>Common Hour: {event.commonDate && event.commonDate.length !== 0 ? commonDateFormat(event.commonDate[0]).timeStart : "N/A"}</dd>
                    <dd className={`mt-1 truncate ${event.commonDate && event.commonDate.length !== 0 ? "text-success" : "text-pending"}`}>Booking: {event.commonDate && event.commonDate.length !== 0 ? "OK" : "Pending"}</dd>
                    <dd className={`mt-1 truncate ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"}"}`}>CO2: N/A</dd>
                  </dl>
                </td>
                {/* Desktop */}
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'customer' ? "hidden xl:table-cell" : "hidden"}`}>{event.customer?.internalRef ? event.customer?.internalRef : "N/A"}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'buildingManager' ? "hidden xl:table-cell" : "hidden"}`}>{event.buildingManager?.internalRef ? event.buildingManager?.internalRef : "N/A"}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'retailer' ? "hidden xl:table-cell" : "hidden"}`}>{event.retailer?.internalRef ? event.retailer?.internalRef : "N/A"}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'carrier' ? "hidden xl:table-cell" : "hidden"}`}>{event.carrier?.internalRef ? event.carrier?.internalRef : "N/A"}</td>
                <td className={`hidden px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} xl:table-cell`}>{event.building?.name ? event.building?.name : "N/A"}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'customer' ? "hidden" : "hidden xl:table-cell"}`}>{event.customerEmail}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'buildingManager' ? "hidden" : "hidden xl:table-cell"}`}>{event.buildingManagerEmail ? event.buildingManagerEmail : "N/A"}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'retailer' ? "hidden" : "hidden xl:table-cell"}`}>{event.retailer?.company ? event.retailer.company : "N/A"}</td>
                <td className={`px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} ${role === 'carrier' ? "hidden" : "hidden xl:table-cell"}`}>{event.carrier.company ? event.carrier.company : "N/A"}</td>
                <td className={`hidden px-3 py-4 text-sm xl:table-cell ${event.insuranceFileKey && event.insuranceFileKey !== "" ? "text-success" : "text-pending"}`}>{event.insuranceFileKey && event.insuranceFileKey !== "" ? "OK" : "Waiting"}</td>
                <td className={`hidden px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} xl:table-cell`}>{event.commonDate && event.commonDate.length !== 0 ? commonDateFormat(event.commonDate[0])?.date : "N/A"}</td>
                <td className={`hidden px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} xl:table-cell`}>{event.commonDate && event.commonDate.length !== 0 ? commonDateFormat(event.commonDate[0])?.timeStart : "N/A"}</td>
                <td className={`hidden px-3 py-4 text-sm xl:table-cell ${event.commonDate && event.commonDate.length !== 0 ? "text-success" : "text-pending"}`}>{event.commonDate && event.commonDate.length !== 0 ? "OK" : "Pending"}</td>
                <td className={`hidden px-3 py-4 text-sm ${eventSelectedId === event.id ? "text-primary" : "text-gray-700"} xl:table-cell`}>N/A</td>
                <td className="px-1 md:px-3 py-4 text-sm text-center font-medium">
                  <Link to={`/update-events/${event.id}`} className="text-indigo-600 hover:text-indigo-900">
                    {event.eventStatus === "FINISHED" ? "See" : "Edit"}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default TableEvent;
