import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoginForm from '../../components/LoginForm/index';
import Logo from '../../assets/img/MyCommandLogoWhite.png';

const NewPassword = () => {
  const validate = Yup.object({
    password: Yup.string()
      .required('Required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        'Your password must contain at least one upper case letter, one lower case letter, one number and one special character'
      )
      .nullable(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  return (
    <div className='bg-bg-color h-screen bg-no-repeat bg-cover font-sans bg-center relative'>
      <div className='py-10 text-center pt-32'>
        <img src={Logo} className='h-12 m-auto my-8' alt='MyCommand'></img>
        <h1 className='text-white text-2xl'> Create your new password </h1>
      </div>

      <Formik
        initialValues={{
          password: '',
          confirmPassword: '',
        }}
        validationSchema={validate}
        onSubmit={(values) => {
        }}
      >
        {(formik) => (
          <div className='flex flex-col items-center mt-10 px-4'>
            <Form className='bg-white shadow-md rounded-lg py-2 px-2 lg:w-2/5 md:w-3/4 w-full'>
              <LoginForm
                label='New Password'
                name='newpassword'
                placeholder='Password'
                type='password'
              />
              <LoginForm
                label='Confirm Password'
                name='confirmPassword'
                placeholder='Confirm Password'
                type='password'
              />
            </Form>
            <button
              className='bg-[#1A5DDA] hover:bg-blue-700 text-white font-bold py-4 mt-5 rounded rounded-lg focus:outline-none focus:shadow-outline w-full md:w-3/4 lg:w-1/5'
              type='submit'
            >
              Log in
            </button>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default NewPassword;
