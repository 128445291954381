import React, { useState, useEffect } from 'react';
import { Field, ErrorMessage } from 'formik';
import ComplementField from '../../Complements/ComplementField';
import { useDispatch, useSelector } from 'react-redux';
import { uploadInvoiceFileLauched } from '../../../service/reducer';
import { deleteFileS3 } from '../../../utils/awsLib';
import { nanoid } from 'nanoid';
import { useRef } from 'react';
import { useStyles } from 'react-styles-hook';
import getStyles from './style';

const EventRetailer = ({
  setFormValue,
  handleChange,
  invoiceFileKeyId,
  setInvoiceFileKeyId,
  invoiceFileName,
  setInvoiceFileName,
  invoiceFile,
  setInvoiceFile,
  errorInvoiceFileMessage,
  setErrorInvoiceFileMessage,
  deleteInvoiceFile,
  uploadFileSuccessMessage,
  setUploadFileSuccessMessage,
  uploadFileErrorMessage,
  setUploadFileErrorMessage,
  formValue,
  currentRole,
  errors,
}) => {
  const dispatch = useDispatch();

  const {
    uploadInvoiceFileLoading,
    uploadInvoiceFileError,
    uploadInvoiceFileSuccess,
  } = useSelector((state) => ({
    uploadInvoiceFileLoading: state.getIn(['app', 'uploadInvoiceFileLoading']),
    uploadInvoiceFileError: state.getIn(['app', 'uploadInvoiceFileError']),
    uploadInvoiceFileSuccess: state.getIn(['app', 'uploadInvoiceFileSuccess']),
  }));

  const uploadFile = (file, fileKeyId) => {
    dispatch(uploadInvoiceFileLauched({ file, fileKeyId }));
  };

  useEffect(() => {
    if (invoiceFile && invoiceFileKeyId) {
      uploadFile(invoiceFile, invoiceFileKeyId);
    }
  }, [invoiceFile, invoiceFileKeyId]);

  const onFileChange = (file) => {
    // delete previous file
    if (invoiceFileKeyId) {
      deleteFileS3(invoiceFileKeyId);
    }

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'application/pdf'
    ) {
      setErrorInvoiceFileMessage(
        'Forbidden type file ! PNG , JPG and PDF only accepted.'
      );
    } else if (file.size < 32000 || file.size > 5000000) {
      setErrorInvoiceFileMessage(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 5mb)`
      );
    } else {
      // reset previous File value
      setInvoiceFileKeyId();
      setInvoiceFile();
      setInvoiceFileName();
      setErrorInvoiceFileMessage();
      setUploadFileErrorMessage();
      setUploadFileSuccessMessage();

      const id = nanoid();
      setInvoiceFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setInvoiceFile(file);
      setInvoiceFileName(file.name);
    }
  };

  // set error or success message
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (uploadInvoiceFileError) {
      setUploadFileErrorMessage(uploadInvoiceFileError);
    }
    if (uploadInvoiceFileSuccess) {
      setUploadFileSuccessMessage(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadInvoiceFileError, uploadInvoiceFileSuccess]);
  const classes = useStyles(getStyles());

  return (
    <div>
      <div className='flex flex-col mt-10 w-full bg-white relative px-2 md:px-8 pt-6 pb-8 mb-4'>
        <div className='flex-col'>
          <div className='w-full md:w-5/6 lg:w-3/5 mx-auto'>
            <div style={classes.retailer}>
              <h3 className='text-black  text-xl font-bold'>
                Your informations
              </h3>
              <ComplementField
                label='Company'
                name='retailerCompany'
                type='text'
                placeholder='Company Name'
                value={formValue?.retailerCompany}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='First Name'
                name='retailerFirstName'
                type='text'
                value={formValue?.retailerFirstName}
                placeholder='My First Name'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Last Name'
                name='retailerLastName'
                type='text'
                placeholder='My Last Name'
                value={formValue?.retailerLastName}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Phone'
                name='retailerPhone'
                type='text'
                placeholder='##########'
                value={formValue?.retailerPhone}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Company address'
                name='retailerAddress'
                type='text'
                placeholder='123 E Example St'
                value={formValue?.retailerAddress}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Complement address'
                name='retailerComplement'
                type='text'
                placeholder='Unit,Apt,Suite...'
                value={formValue?.retailerComplement}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='City'
                name='retailerCity'
                type='text'
                placeholder='City Name'
                value={formValue?.retailerCity}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='State'
                name='retailerState'
                type='text'
                placeholder='State Name'
                value={formValue?.retailerState}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Zip Code'
                name='retailerZipcode'
                type='text'
                placeholder='#####'
                value={formValue?.retailerZipcode}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
            </div>
            <div style={classes.retailerEnd}>
              <h3 className='text-black py-4 text-xl font-bold'> Product </h3>

              <ComplementField
                label='Reference'
                name='retailerReference'
                type='text'
                placeholder='Retailer product ref'
                value={formValue?.retailerReference}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-sm'>
                  {' '}
                  Dimension item{' '}
                </p>
                <div className='flex w-full'>
                  <div className='lg:w-2/5 w-2/4 mx-2'>
                    <Field
                      type='text'
                      name='retailerLenght'
                      placeholder='LL'
                      value={formValue?.retailerLenght}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className='shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    />
                    <small className='block mt-1 text-xs text-[#6B7280]'>
                      {' '}
                      Length (inches){' '}
                    </small>
                    <ErrorMessage
                      component='div'
                      name='retailerLenght'
                      className='text-red-500'
                    />
                  </div>
                  <div className='lg:w-2/5 w-2/4 mx-2'>
                    <Field
                      type='text'
                      name='retailerHeight'
                      placeholder='HH'
                      value={formValue?.retailerHeight}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className='shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    />
                    <small className='block mt-1 text-xs text-[#6B7280]'>
                      {' '}
                      Height (inches){' '}
                    </small>
                    <ErrorMessage
                      component='div'
                      name='retailerHeight'
                      className='text-red-500'
                    />
                  </div>
                  <div className='lg:w-2/5 w-2/4 mx-2'>
                    <Field
                      type='text'
                      name='retailerWidth'
                      placeholder='WW'
                      value={formValue?.retailerWidth}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className='shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                    />
                    <small className='block mt-1 text-xs text-[#6B7280]'>
                      {' '}
                      Width (inches){' '}
                    </small>
                    <ErrorMessage
                      component='div'
                      name='retailerWidth'
                      className='text-red-500'
                    />
                  </div>
                </div>
              </div>
              <ComplementField
                label='Product weight'
                name='retailerWeight'
                type='text'
                placeholder='WW'
                value={formValue?.retailerWeight}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <small className='block mt-1 text-xs text-[#6B7280]'>
                (Pounds){' '}
              </small>

              <ComplementField
                label='Internal reference'
                name='retailerInternalRef'
                type='text'
                placeholder='Customer order follow up ref'
                value={formValue?.retailerInternalRef}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              {/*<ComplementField*/}
              {/*  label="EIN number"*/}
              {/*  name="retailerEINNumber"*/}
              {/*  type="text"*/}
              {/*  placeholder="12-3456789"*/}
              {/*  value={formValue?.retailerEINNumber}*/}
              {/*  onChange={(e) => {*/}
              {/*    handleChange(e);*/}
              {/*    setFormValue((prevState) => ({*/}
              {/*      ...prevState,*/}
              {/*      [e.target.name]: e.target.value,*/}
              {/*    }));*/}
              {/*  }}*/}
              {/*/>*/}

              <div className='mt-8'>
                <div className='flex flex-col sm:flex-row w-full'>
                  <div className='w-full sm:w-2/5 self-center sm:self-start'>
                    <p className='block text-gray-700 text-smc'>Invoice</p>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div
                      className={`w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    >
                      {/* todo adapter affichage fileName ou formFileKey */}
                      {!uploadInvoiceFileLoading &&
                        formValue.invoiceFileKey &&
                        invoiceFileName && (
                          <div className='text-neutral-dark'>
                            <p>Chosen file:</p>
                            <div className='flex flex-row items-center'>
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  (formValue.invoiceFileKey === invoiceFileName
                                    ? invoiceFileName
                                    : invoiceFileKeyId)
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                {invoiceFileName}
                              </a>
                              {currentRole === 'retailer' && (
                                <button
                                  type='button'
                                  onClick={() =>
                                    deleteInvoiceFile(
                                      formValue.invoiceFileKey ===
                                        invoiceFileName
                                        ? invoiceFileName
                                        : invoiceFileKeyId
                                    )
                                  }
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='pl-2 w-6 h-6 text-error'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      {!formValue.invoiceFileKey &&
                        invoiceFileName &&
                        invoiceFileName}
                      {!invoiceFileName && 'No invoice file'}
                      {/* {(formValue.invoiceFileKey && !invoiceFileName) && "No invoice file"} */}

                      {/* todo add eye pour voir le fichier */}
                    </div>
                    {/* // upload in progress */}
                    {uploadInvoiceFileLoading && (
                      <div className='flex justify-center w-full h-full text-sm'>
                        <p>Upload in progress...</p>
                      </div>
                    )}
                    {/* error */}
                    {(errorInvoiceFileMessage || uploadFileErrorMessage) && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-error'>
                          {errorInvoiceFileMessage
                            ? errorInvoiceFileMessage
                            : uploadFileErrorMessage
                            ? uploadFileErrorMessage
                            : null}{' '}
                        </p>
                      </div>
                    )}
                    {/* success */}
                    {uploadFileSuccessMessage && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-primary'>
                          {uploadFileSuccessMessage}{' '}
                        </p>
                      </div>
                    )}
                    {currentRole === 'retailer' && (
                      <label className='cursor-pointer' htmlFor='invoiceFile'>
                        <input
                          id='invoiceFile'
                          className='hidden'
                          accept='image/*,.pdf'
                          name='invoiceFile'
                          type='file'
                          onInput={(e) => {
                            if (e.target.files[0]) {
                              onFileChange(e.target.files[0]);
                            }
                          }}
                        />

                        <p className='mt-4 text-center bg-bg-button hover:scale-105 ease-out duration-300  w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg'>
                          {invoiceFileName ? 'Change File' : 'Upload file'}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
    input {
      vertical-align: middle;
    }
    hr {
      margin-bottom: 0.5rem;
    }
  `}</style>
    </div>
  );
};

export default EventRetailer;
