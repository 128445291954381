import React from "react";
import { nanoid } from 'nanoid';
import { useDropzone } from "react-dropzone";
import { useDispatch } from 'react-redux';
// import { addFile } from '../../../utils/awsLib';
import { uploadInsuranceFileLauched } from '../../../service/reducer';
import { useEffect } from 'react';

const DropFile = ({
  fileKeyId,
  setFileKeyId,
  setFileName,
  file,
  setFile,
  errorFileMessage,
  setErrorFileMessage,
  deleteFile,
  uploadErrorMessage,
  uploadInProgress }) => {

  const dispatch = useDispatch();
  const uploadFile = (file, fileKeyId) => {
    dispatch(uploadInsuranceFileLauched({ file, fileKeyId }));
  };

  useEffect(() => {
    if (file && fileKeyId) {
      uploadFile(file, fileKeyId)
    }
  }, [fileKeyId, file])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/png': ['.png'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'application/pdf': ['.pdf']
    },
    onDrop: (acceptedFiles) => {
      // reset previous value
      setErrorFileMessage(null);
      setFileKeyId();
      setFile();
      setFileName();
      // delete previous file
      if (fileKeyId) { deleteFile(fileKeyId); }

      if (acceptedFiles.length === 0) {
        setErrorFileMessage("Forbidden type file ! PNG, JPG, PDF only accepted")
      } else if (acceptedFiles[0].size < 32000 || acceptedFiles[0].size > 5000000) {
        setErrorFileMessage(`Invalid file size : ${(acceptedFiles[0].size / 1000000).toFixed(2)} mb (min 0.03mb, max 5mb)`)
      } else {
        const id = nanoid();
        setFileKeyId(id + '.' + acceptedFiles[0].type.slice(acceptedFiles[0].type.indexOf("/") + 1, acceptedFiles[0].type.length));
        setFile(acceptedFiles[0])
        setFileName(acceptedFiles[0]?.path);
      }
    }
  });

  return (
    <div {...getRootProps({ className: `md:flex flex-col items-center` })}>
      <div className={`${uploadInProgress ? 'hidden' : 'block'} w-64 sm:w-96 flex justify-center px-6 pt-5 pb-6 border-dashed rounded-md ${(isDragActive === true) ? "border-2 border-grey-extraDark" : "border-2 border-gray-300"} `}>
        <div className="space-y-1 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex text-sm text-gray-600">
            <input {...getInputProps()} />
            {isDragActive ? <p className="md:w-80 md:min-w-min text-primary">Drop Here</p> : null}
            {(!isDragActive) && (
              <>
                <p className="relative cursor-pointer rounded-md hover:text-primary focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary font-medium textprimary">
                  Upload a file here
                </p>
                <p className="pl-1 hidden md:block">or drag and drop</p>
              </>
            )}
          </div>
          <p className="text-xs text-gray-500">PNG, JPG, PDF up to 5mb</p>
        </div>
      </div>
      {!uploadInProgress && errorFileMessage ? <p className="pt-6 text-error font-bold">{errorFileMessage}</p> : null}
      {!uploadInProgress && uploadErrorMessage ? <p className="pt-6 text-error font-bold">{uploadErrorMessage}</p> : null}
    </div>
  );
};

export default DropFile;
