import { Navigate, Route, Routes } from 'react-router-dom';
import Signup from './pages/Register/Signup/index';
import SignUpRetailer from './pages/Register/SignUpRetailer/index';
import SignUpCarrier from './pages/Register/SignUpCarrier';
import SignUpCustomer from './pages/Register/SignUpCustomer';
import SignUpBuildingManager from './pages/Register/SignUpBuildingManager';
import ThankRegister from './pages/ThankRegister';
import ConfirmPasswordChange from './pages/ConfirmPasswordChange';
import NewPassword from './pages/NewPassword';
import CarrierInfo from './pages/Complements/CarrierInfo';
import RetailerInfo from './pages/Complements/RetailerInfo';
import CustomerInfo from './pages/Complements/CustomerInfo';
import BuildingManagerInfo from './pages/Complements/BuildingManagerInfo';
import LoginAs from './pages/LoginAs';
import LoginAsCustomer from './pages/LoginAs/LoginAsCustomer';
import LoginAsCarrier from './pages/LoginAs/LoginAsCarrier';
import LoginAsRetailer from './pages/LoginAs/LoginAsRetailer';
import LoginAsBuildingManager from './pages/LoginAs/LoginAsBuildingManager';
import ForgotPassword from './pages/ForgotPassword';
import Account from './pages/Home/index';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getCurrentSessionLaunched } from './service/reducer';
import PrivateRoute from './components/PrivateRoute';
import SpinnerLoader from './components/Loader';
import Events from './pages/Events/index';
import EventsManager from './pages/EventsManager';
import UpdateEvents from './pages/UpdateEvent';

function App() {
  const role = window.localStorage.getItem('role');
  const dispatch = useDispatch();

  const { isAuthenticating, isAuthenticated, userDynamo } = useSelector(
    (state) => ({
      isAuthenticating: state.getIn(['app', 'isAuthenticating']),
      isAuthenticated: state.getIn(['app', 'isAuthenticated']),
      userDynamo: state.getIn(['app', 'userDynamo']),
    })
  );

  useEffect(() => {
    dispatch(getCurrentSessionLaunched({ fromPath: null, role: role }));
  }, [dispatch]);

  const appSwitch = (
    <Routes>
      <Route
        path='/'
        element={<Navigate to='/account/eventsmanager' replace />}
      />

      <Route
        exact
        path='/account'
        element={
          <PrivateRoute user={isAuthenticated} userDynamo={userDynamo}>
            <Account />
          </PrivateRoute>
        }
      />

      <Route exact path='/signup' element={<Signup />} />
      <Route exact path='/signupretailer' element={<SignUpRetailer />} />
      <Route exact path='/signupcustomer' element={<SignUpCustomer />} />
      <Route exact path='/signupcarrier' element={<SignUpCarrier />} />
      <Route
        exact
        path='/signupbuildingmanager'
        element={<SignUpBuildingManager />}
      />
      <Route
        exact
        path='/confirmpasswordchange'
        element={<ConfirmPasswordChange />}
      />
      <Route exact path='/thankregister' element={<ThankRegister />} />
      <Route exact path='/loginas' element={<LoginAs />} />
      <Route exact path='/newpassword' element={<NewPassword />} />
      <Route
        exact
        path='/account/carrierinfo'
        element={
          <PrivateRoute user={isAuthenticated}>
            <CarrierInfo />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/account/retailerinfo'
        element={
          <PrivateRoute user={isAuthenticated}>
            <RetailerInfo />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/account/eventsmanager'
        element={
          <PrivateRoute user={isAuthenticated}>
            <EventsManager
              role={role}
              isAuthenticated={isAuthenticated}
              userDynamo={userDynamo}
            />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/account/events'
        element={
          <PrivateRoute user={isAuthenticated}>
            <Events />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/account/customerinfo'
        element={
          <PrivateRoute user={isAuthenticated}>
            <CustomerInfo />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/account/buildingManagerinfo'
        element={
          <PrivateRoute user={isAuthenticated}>
            <BuildingManagerInfo />
          </PrivateRoute>
        }
      />

      <Route
        exact
        path='/update-events/:deliveryEventId'
        element={
          <PrivateRoute user={isAuthenticated}>
            <UpdateEvents role={role} />
          </PrivateRoute>
        }
      />

      <Route exact path='/loginascustomer' element={<LoginAsCustomer />} />
      <Route exact path='/loginascarrier' element={<LoginAsCarrier />} />
      <Route exact path='/loginasretailer' element={<LoginAsRetailer />} />
      <Route
        exact
        path='/loginasbuildingmanager'
        element={<LoginAsBuildingManager />}
      />
      <Route exact path='/password' element={<ForgotPassword />} />
    </Routes>
  );

  return <> {isAuthenticating ? <SpinnerLoader /> : appSwitch} </>;
}
export default App;
