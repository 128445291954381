import retailIcon from '../assets/img/retailerIcon.svg';
import carrierIcon from '../assets/img/carrierIcon.svg';
import customerIcon from '../assets/img/customerIcon.svg';
import iconBM from '../assets/img/iconBM.svg';

function checkUserLogo(role) {
  switch (role) {
    case 'retailer':
      return retailIcon;
    case 'customer':
      return customerIcon;
    case 'buildingManager':
      return iconBM;
    case 'carrier':
      return carrierIcon;
    default:
      return null;
  }
}
export default checkUserLogo;
