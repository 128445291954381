import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ComplementField from '../ComplementField';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserLaunched } from '../../../service/reducer';
import checkCurrentRole from '../../../utils/checkCurrentRole';
import { CircularProgress } from '@mui/material';
import { useStyles } from "react-styles-hook";
import getStyles from "./style";

const ComplementRetailer = () => {
  const dispatch = useDispatch();
  const {userDynamo, updateUserSuccessMessage, updateUserLoading} =
    useSelector((state) => ({
      userDynamo: state.getIn(['app', 'userDynamo']),
      updateUserSuccessMessage: state.getIn([
        'app',
        'updateUserSuccessMessage',
      ]),
      updateUserLoading: state.getIn(['app', 'updateUserLoading']),
    }));

  // set and reset sucess message //
  const [successMessage, setSuccessMessage] = useState();
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!updateUserLoading && updateUserSuccessMessage) {
      setSuccessMessage('Your info was updated !');
    }
  }, [updateUserLoading, updateUserSuccessMessage]);

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);
  let currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  const userInfo = currUserInfo[currentRole];

  const updateComplement = (formik) => {
    setSuccessMessage(updateUserSuccessMessage);
    dispatch(updateUserLaunched(formik));
  };

  const validate = Yup.object({
    address: Yup.string().required('Address is required'),
    addressState: Yup.string().required('State is required'),
    zipcode: Yup.string().required('Zipcode is required'),
  });
  const classes = useStyles(getStyles());

  return (
    <div className="h-screen">
      <Formik
        initialValues={{
          id: userInfo.id || '',
          company: userInfo.company || '',
          address: userInfo.address || '',
          complement: userInfo.complement || '',
          addressState: userInfo.addressState || '',
          city: userInfo.city || '',
          zipcode: userInfo.zipcode || '',
          EINNumber: userInfo.EINNumber || '',
          carrierCompany: userInfo.carrierCompany || '',
          carrierFirstName: userInfo.carrierFirstName || '',
          carrierLastName: userInfo.carrierLastName || '',
          carrierPhone: userInfo.carrierPhone || '',
          carrierEmail: userInfo.carrierEmail || '',
          billingCompany: userInfo.billingCompany || '',
          billingAddress: userInfo.billingAddress || '',
          billingComplement: userInfo.billingComplement || '',
          billingState: userInfo.billingState || '',
          billingCity: userInfo.billingCity || '',
          billingZipcode: userInfo.billingZipcode || '',
          billingEmail: userInfo.billingEmail || '',
          billingPhone: userInfo.billingPhone || '',
          role: currentRole,
        }}
        onSubmit={(formik) => {
          updateComplement(formik);
        }}
        validate={(values) => {
          // set errors message //
          const errors = {};
          if (values.company === '') {
            errors.company = 'Company Name is required';
          }
          if (values.address === '') {
            errors.address = 'Address is required';
          }
          if (values.addressState === '') {
            errors.addressState = 'State is required';
          }
          if (values.city === '') {
            errors.city = 'City is required';
          }
          if (values.zipcode === '') {
            errors.zipcode = 'Zipcode is required';
          }
          return errors;
        }}
      >
        {(formik, errors) => (
          <div className="flex flex-col mt-10 w-full">
            <h1 className="text-white text-4xl font-bold mb-5 ml-10 md:ml-20">
              {' '}
              Complements{' '}
            </h1>
            <div className="relative px-2 md:px-8 w-full">
              <Form className="bg-white shadow-md rounded-lg px-4 lg:px-8 pt-6 pb-8 w-full max-w-screen-2xl mx-auto">
                <div className="flex-col">
                  <div className="w-full md:w-5/6 lg:w-3/5 mx-auto">
                    <h2 className="text-black py-4 text-2xl font-bold text-[#2260DD]">
                      {' '}
                      Informations{' '}
                    </h2>
                    <div style={classes.company}>

                      <h3 className="text-black py-4 text-xl"> Company </h3>
                      <ComplementField
                        label="Company Name*"
                        name="company"
                        type="text"
                        placeholder="Company Name"
                      />
                      <ComplementField
                        label="Address*"
                        name="address"
                        type="text"
                        placeholder="123 E Example St"
                      />
                      <ComplementField
                        label="Complement"
                        name="complement"
                        type="text"
                        placeholder="Unit, Apt, Suite..."
                      />
                      <ComplementField
                        label="City*"
                        name="city"
                        type="text"
                        placeholder="City Name"
                      />
                      <ComplementField
                        label="State*"
                        name="addressState"
                        type="text"
                        placeholder="State Name"
                      />
                      <ComplementField
                        label="Zip Code*"
                        name="zipcode"
                        type="text"
                        placeholder="#####"
                      />
                      <ComplementField
                        label="EIN Number"
                        name="EINNumber"
                        type="text"
                        placeholder="12-3456789"
                      />
                      <ComplementField
                        label="What is your carrier for bulky home deliveries ?"
                        name="carrierCompany"
                        type="text"
                        placeholder="Carrier Name"
                      />
                    </div>
                    <div style={classes.contact}>

                      <h3 className="text-black py-4 text-xl mt-14">
                        {' '}
                        Carrier contact{' '}
                      </h3>

                      <ComplementField
                        label="First Name"
                        name="carrierFirstName"
                        type="text"
                        placeholder="Contact First Name"
                      />
                      <ComplementField
                        label="Last Name"
                        name="carrierLastName"
                        type="text"
                        placeholder="Contact Last Name"
                      />
                      <ComplementField
                        label="Phone"
                        name="carrierPhone"
                        type="string"
                        placeholder="##########"
                      />
                      <ComplementField
                        label="Email"
                        name="carrierEmail"
                        type="email"
                        placeholder="carrierMail@example.com"
                      />
                    </div>
                    <div style={classes.billing}>
                      <h3 className="text-black py-4 text-xl mt-14"> Billing </h3>

                      <ComplementField
                        label="Company Name"
                        name="billingCompany"
                        type="text"
                        placeholder="Company Name for billing"
                      />
                      <ComplementField
                        label="Address"
                        name="billingAddress"
                        type="text"
                        placeholder="123 E Example St"
                      />
                      <ComplementField
                        label="Complement"
                        name="billingComplement"
                        type="text"
                        placeholder="Unit, Apt, Suite..."
                      />
                      <ComplementField
                        label="City"
                        name="billingCity"
                        type="text"
                        placeholder="City Name"
                      />
                      <ComplementField
                        label="State"
                        name="billingState"
                        type="text"
                        placeholder="State Name"
                      />
                      <ComplementField
                        label="Zip Code"
                        name="billingZipcode"
                        type="text"
                        placeholder="#####"
                      />
                      <ComplementField
                        label="Email"
                        name="billingEmail"
                        type="email"
                        placeholder="billingMail@example.com"
                      />
                      <ComplementField
                        label="Phone"
                        name="billingPhone"
                        type="string"
                        placeholder="##########"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col space-y-1 items-center">
                  {errors?.company && (
                    <p className="text-red-600 text-center">
                      {' '}
                      {errors?.company}
                    </p>
                  )}
                  {errors?.address && (
                    <p className="text-red-600 text-center">
                      {' '}
                      {errors?.address}
                    </p>
                  )}
                  {errors?.addressState && (
                    <p className="text-red-600 text-center">
                      {' '}
                      {errors?.addressState}
                    </p>
                  )}
                  {errors?.city && (
                    <p className="text-red-600 text-center"> {errors?.city}</p>
                  )}
                  {errors?.zipcode && (
                    <p className="text-red-600 text-center">
                      {' '}
                      {errors?.zipcode}
                    </p>
                  )}
                  {/* disabled btn until loading to stop spam request*/}
                  {updateUserLoading && (
                    <button
                      disabled={true}
                      type="submit"
                      className="bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-10 text-white text-lg hover:scale-105 ease-out duration-300"
                    >
                      <CircularProgress style={{color: 'white'}} size={20}/>
                    </button>
                  )}
                  {!updateUserLoading && !successMessage && (
                    <button
                      type="submit"
                      className="bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-5 text-white text-lg hover:scale-105 ease-out duration-300"
                      onChange={updateComplement}
                    >
                      {' '}
                      Save changes{' '}
                    </button>
                  )}
                </div>
                {/* success */}
                {successMessage && (
                  <p
                    className="text-green-700 text-center text-white rounded-lg bg-green-100 mt-4 md:w-3/4 lg:w-2/6 mx-auto">
                    {successMessage}
                  </p>
                )}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ComplementRetailer;
