import { all, delay, put, takeLatest } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import checkApiKey from '../../utils/apiKeys';
import { config } from '../../config/amplify';
import { push } from 'connected-react-router';

import {
  getDeliveryEventsSuccess,
  getDeliveryEventsFailure,
  updateDeliveryEventSuccess,
  updateDeliveryEventFailure,
} from './reducer';
import { resetGetBuilding } from '../../service/reducer';

function* getDeliveryEvents(action) {
  const role = action.payload.role;
  const deliveryEventId = action.payload.deliveryEventId;
  try {
    const apiURL = `/deliveryEvents/${deliveryEventId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
    };
    const result = yield API.get(config.apiGateway.NAME, apiURL, params);
    yield put(getDeliveryEventsSuccess(result));
  } catch (error) {
    yield put(getDeliveryEventsFailure());
  }
}

function* doUpdateEvent(action) {
  const role = action.payload.role;
  const deliveryEventId = action.payload.id;
  const body = action.payload;
  delete body.role;
  try {
    const apiURL = `/deliveryEvents/${deliveryEventId}`;
    const params = {
      headers: {
        'x-api-key': checkApiKey(role),
      },
      body: body,
    };
    const result = yield API.put(config.apiGateway.NAME, apiURL, params);
    yield put(updateDeliveryEventSuccess(result));
    yield put(resetGetBuilding())
    yield delay(2000);
    yield put(push('/account/eventsmanager'));
  } catch (error) {
    yield put(updateDeliveryEventFailure());
  }
}

export default function* saga() {
  yield all([
    takeLatest('getEventStore/getDeliveryEventsLaunched', getDeliveryEvents),
    takeLatest('getEventStore/updateDeliveryEventLaunched', doUpdateEvent),
  ]);
}
