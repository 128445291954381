import React, { useEffect, useRef, useState } from 'react';
import ComplementField from '../../Complements/ComplementField';
import { Field } from 'formik';
import { useStyles } from 'react-styles-hook';
import getStyles from './style';
import {
  uploadEntranceDoorPhotoFileLaunched,
  uploadFreightDoorPhotoFileLaunched,
} from '../../../service/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFileS3 } from '../../../utils/awsLib';
import { nanoid } from 'nanoid';

const EventCustomer = ({
  setFormValue,
  handleChange,
  formValue,
  errors,
  // Upload part
  currentRole,
  // Entrance
  entranceDoorFileKeyId,
  setEntranceDoorFileKeyId,
  entranceDoorFileName,
  setEntranceDoorFileName,
  entranceDoorFile,
  setEntranceDoorFile,
  errorEntranceFileMessage,
  setErrorEntranceFileMessage,
  deleteEntranceFile,
  uploadEntranceDoorFileSuccessMessage,
  setUploadEntranceDoorFileSuccessMessage,
  uploadEntranceDoorFileErrorMessage,
  setUploadEntranceDoorFileErrorMessage,
  // Freight
  freightDoorFileKeyId,
  setFreightDoorFileKeyId,
  freightDoorFileName,
  setFreightDoorFileName,
  freightDoorFile,
  setFreightDoorFile,
  errorFreightFileMessage,
  setErrorFreightFileMessage,
  deleteFreightFile,
  uploadFreightDoorFileSuccessMessage,
  setUploadFreightDoorFileSuccessMessage,
  uploadFreightDoorFileErrorMessage,
  setUploadFreightDoorFileErrorMessage,
}) => {
  const [showFreightDoorSize, setShowFreightDoorSize] = useState(
    formValue?.specificFreightDoor || false
  );
  const [showFreightAddress, setShowFreightAddress] = useState(
    formValue?.sameFreightAddress || false
  );
  const [showPassengerElevatorSize, setShowPassengerElevatorSize] = useState(
    formValue?.serviceElevator || false
  );
  const [showFreightElevatorSize, setShowFreightElevatorSize] = useState(
    formValue?.freightElevator || false
  );
  const [showElevator, setShowElevator] = useState(
    formValue?.elevator || false
  );
  const [showBuildingManager, setShowBuildingManager] = useState(
    formValue?.buildingManager || false
  );
  const [passedAlready, setPassedAlready] = useState(false);

  // File upload
  const dispatch = useDispatch();
  const {
    uploadEntranceDoorPhotoFileLoading,
    uploadEntranceDoorPhotoFileError,
    uploadEntranceDoorPhotoFileSuccess,
    uploadFreightDoorPhotoFileLoading,
    uploadFreightDoorPhotoFileError,
    uploadFreightDoorPhotoFileSuccess,
  } = useSelector((state) => ({
    uploadEntranceDoorPhotoFileLoading: state.getIn([
      'app',
      'uploadEntranceDoorPhotoFileLoading',
    ]),
    uploadEntranceDoorPhotoFileError: state.getIn([
      'app',
      'uploadEntranceDoorPhotoFileError',
    ]),
    uploadEntranceDoorPhotoFileSuccess: state.getIn([
      'app',
      'uploadEntranceDoorPhotoFileSuccess',
    ]),
    uploadFreightDoorPhotoFileLoading: state.getIn([
      'app',
      'uploadFreightDoorPhotoFileLoading',
    ]),
    uploadFreightDoorPhotoFileError: state.getIn([
      'app',
      'uploadFreightDoorPhotoFileError',
    ]),
    uploadFreightDoorPhotoFileSuccess: state.getIn([
      'app',
      'uploadFreightDoorPhotoFileSuccess',
    ]),
  }));

  const uploadEntranceFile = (file, fileKeyId) => {
    dispatch(uploadEntranceDoorPhotoFileLaunched({ file, fileKeyId }));
  };
  const uploadFreightFile = (file, fileKeyId) => {
    dispatch(uploadFreightDoorPhotoFileLaunched({ file, fileKeyId }));
  };

  useEffect(() => {
    if (entranceDoorFile && entranceDoorFileKeyId) {
      uploadEntranceFile(entranceDoorFile, entranceDoorFileKeyId);
    }
  }, [entranceDoorFile, entranceDoorFileKeyId]);

  useEffect(() => {
    if (freightDoorFile && freightDoorFileKeyId) {
      uploadFreightFile(freightDoorFile, freightDoorFileKeyId);
    }
  }, [freightDoorFile, freightDoorFileKeyId]);

  const onEntranceFileChange = (file) => {
    // delete previous file
    if (entranceDoorFileKeyId) {
      deleteFileS3(entranceDoorFileKeyId);
    }

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'application/pdf'
    ) {
      setErrorEntranceFileMessage(
        'Forbidden type file ! PNG , JPG and PDF only accepted.'
      );
    } else if (file.size < 32000 || file.size > 5000000) {
      setErrorEntranceFileMessage(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 5mb)`
      );
    } else {
      // reset previous File value
      setEntranceDoorFileKeyId();
      setEntranceDoorFile();
      setEntranceDoorFileName();
      setErrorEntranceFileMessage();
      setUploadEntranceDoorFileErrorMessage();
      setUploadEntranceDoorFileSuccessMessage();

      const id = nanoid();
      setEntranceDoorFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setEntranceDoorFile(file);
      setEntranceDoorFileName(file.name);
    }
  };

  const onFreightFileChange = (file) => {
    // delete previous file
    if (freightDoorFileKeyId) {
      deleteFileS3(freightDoorFileKeyId);
    }

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'application/pdf'
    ) {
      setErrorFreightFileMessage(
        'Forbidden type file ! PNG , JPG and PDF only accepted.'
      );
    } else if (file.size < 32000 || file.size > 5000000) {
      setErrorFreightFileMessage(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 5mb)`
      );
    } else {
      // reset previous File value
      setFreightDoorFileKeyId();
      setFreightDoorFile();
      setFreightDoorFileName();
      setErrorFreightFileMessage();
      setUploadFreightDoorFileErrorMessage();
      setUploadFreightDoorFileSuccessMessage();

      const id = nanoid();
      setFreightDoorFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setFreightDoorFile(file);
      setFreightDoorFileName(file.name);
    }
  };

  // set error or success message for Entrance
  const isInitialMountEntrance = useRef(true);
  useEffect(() => {
    if (isInitialMountEntrance.current) {
      isInitialMountEntrance.current = false;
      return;
    }
    if (uploadEntranceDoorPhotoFileError) {
      setUploadEntranceDoorFileErrorMessage(uploadEntranceDoorPhotoFileError);
    }
    if (uploadEntranceDoorPhotoFileSuccess) {
      setUploadEntranceDoorFileSuccessMessage(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadEntranceDoorPhotoFileError, uploadEntranceDoorPhotoFileSuccess]);

  // set error or success message for Freight
  const isInitialMountFreight = useRef(true);
  useEffect(() => {
    if (isInitialMountFreight.current) {
      isInitialMountFreight.current = false;
      return;
    }
    if (uploadFreightDoorPhotoFileError) {
      setUploadFreightDoorFileErrorMessage(uploadFreightDoorPhotoFileError);
    }
    if (uploadFreightDoorPhotoFileSuccess) {
      setUploadFreightDoorFileSuccessMessage(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadFreightDoorPhotoFileError, uploadFreightDoorPhotoFileSuccess]);

  // ! This block permits to put every boolean field from my profile details to The creation of the event
  // Donc => Faut faire gaffe 😅
  useEffect(() => {
    if (typeof formValue.elevator === 'boolean' && !passedAlready) {
      // permits to be done only one time when formValue is loaded
      setShowFreightDoorSize(formValue?.specificFreightDoor || false);
      setShowFreightAddress(formValue?.sameFreightAddress || false);
      setShowPassengerElevatorSize(formValue?.serviceElevator || false);
      setShowElevator(formValue?.elevator || false);
      setShowFreightElevatorSize(formValue?.freightElevator || false);
      setShowBuildingManager(formValue?.buildingManager || false);

      setFormValue((prevState) => ({
        ...prevState,
        specificFreightDoor: formValue?.specificFreightDoor,
      }));
      setFormValue((prevState) => ({
        ...prevState,
        sameFreightAddress: formValue?.sameFreightAddress,
      }));
      setFormValue((prevState) => ({
        ...prevState,
        serviceElevator: formValue?.serviceElevator,
      }));
      setFormValue((prevState) => ({
        ...prevState,
        elevator: formValue?.elevator,
      }));
      setFormValue((prevState) => ({
        ...prevState,
        freightElevator: formValue?.freightElevator,
      }));
      setFormValue((prevState) => ({
        ...prevState,
        buildingManager: formValue?.buildingManager,
      }));

      setPassedAlready(true);
    }
  }, [formValue]);

  const classes = useStyles(getStyles());
  return (
    <div>
      <div className='flex flex-col mt-1 w-full'></div>
      <div className='flex flex-col mt-10 w-full bg-white relative px-2 md:px-8 pt-6 pb-8 mb-4'>
        <div className='flex-col'>
          <div className='w-full md:w-5/6 lg:w-3/5 mx-auto'>
            <div style={classes.deliveryAddress}>
              <h3 className='text-black text-xl font-bold'>
                {' '}
                Your informations{' '}
              </h3>
              <ComplementField
                label='First Name*'
                name='customerFirstname'
                type='text'
                value={formValue?.customerFirstname}
                placeholder='My First Name'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Last Name*'
                name='customerLastname'
                type='text'
                value={formValue?.customerLastname}
                placeholder='My Last Name'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Phone'
                name='customerPhone'
                type='text'
                value={formValue?.customerPhone}
                placeholder='##########'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />

              <h3 className='text-black py-4 text-xl mt-14 font-bold'>
                {' '}
                Customer/Resident Delivery Address{' '}
              </h3>

              <ComplementField
                label='Address*'
                name='customerAddress'
                type='text'
                value={formValue?.customerAddress}
                placeholder='123 E Example St'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Address Complement'
                name='customerComplement'
                type='text'
                value={formValue?.customerComplement}
                placeholder='Unit, Apt, Suite...'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='City*'
                name='customerCity'
                type='text'
                placeholder='City Name'
                value={formValue?.customerCity}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='State*'
                name='customerState'
                type='text'
                value={formValue?.customerState}
                placeholder='State Name'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Zip Code*'
                name='customerZipcode'
                type='text'
                placeholder='#####'
                value={formValue?.customerZipcode}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Internal Reference'
                name='customerInternalRef'
                type='text'
                placeholder='My follow up reference/description'
                value={formValue?.customerInternalRef}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />

              <div className='flex my-8 w-full '>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Type of residence{' '}
                </p>
                <ul className='w-full flex-col text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3 ${
                        formValue?.typeOfResidence === 'building' &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='building'
                        checked={formValue?.typeOfResidence === 'building'}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        name='typeOfResidence'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='typeOfResidence'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Building{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        formValue?.typeOfResidence === 'house' &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='house'
                        name='typeOfResidence'
                        checked={formValue?.typeOfResidence === 'house'}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='typeOfResidence'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        House{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <ComplementField
                label='Access information / Parking information'
                name='accessInformationDeliveryAddress'
                type='text'
                placeholder='Any information relevant (road, parking, restrictions, areas...)'
                value={formValue?.accessInformationDeliveryAddress}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />

              <ComplementField
                label='Access code, if several, indicate so'
                name='accessCodeDeliveryAddress'
                type='text'
                placeholder='Descriptions & Codes'
                value={formValue?.accessCodeDeliveryAddress}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />

              <div className={`flex my-8 w-full`}>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Entrance door size{' '}
                </p>
                <div className='flex w-full'>
                  <div className='lg:w-2/5 w-2/4 mr-6'>
                    <Field
                      type='text'
                      name='specificDoorLenght'
                      placeholder='WW'
                      className='shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      value={formValue?.specificDoorLenght}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                    <small className='block mt-1 text-xs text-[#6B7280]'>
                      {' '}
                      Width (inches){' '}
                    </small>
                  </div>
                  <div className='lg:w-2/5 w-2/4 lg:mr-6'>
                    <Field
                      type='text'
                      name='specificDoorHeight'
                      placeholder='HH'
                      className='shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      value={formValue?.specificDoorHeight}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                    <small className='block mt-1 text-xs text-[#6B7280]'>
                      {' '}
                      Height (inches){' '}
                    </small>
                  </div>
                </div>
              </div>

              <div className='flex my-8 w-full '>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Delivery door opening direction{' '}
                </p>
                <ul className='w-full flex-col text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3 ${
                        formValue?.entranceDoorOpeningDirection ===
                          'notMentioned' && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='notMentioned'
                        checked={
                          formValue?.entranceDoorOpeningDirection ===
                          'notMentioned'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        name='entranceDoorOpeningDirection'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='entranceDoorOpeningDirection'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Not mentioned{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        formValue?.entranceDoorOpeningDirection === 'inward' &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='inward'
                        name='entranceDoorOpeningDirection'
                        checked={
                          formValue?.entranceDoorOpeningDirection === 'inward'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='entranceDoorOpeningDirection'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        Inward{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        formValue?.entranceDoorOpeningDirection === 'outward' &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='outward'
                        name='entranceDoorOpeningDirection'
                        checked={
                          formValue?.entranceDoorOpeningDirection === 'outward'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='entranceDoorOpeningDirection'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        Outward{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div className='mt-8'>
                <div className='flex flex-col sm:flex-row w-full'>
                  <div className='w-full sm:w-2/5 self-center sm:self-start'>
                    <p className='block text-gray-700 text-smc'>
                      Entrance Door Picture
                    </p>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div
                      className={`w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    >
                      {/* todo adapter affichage fileName ou formFileKey */}
                      {!uploadEntranceDoorPhotoFileLoading &&
                        entranceDoorFileKeyId &&
                        entranceDoorFileName && (
                          <div className='text-neutral-dark'>
                            <p>Chosen file:</p>
                            <div className='flex flex-row items-center'>
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  (entranceDoorFileKeyId ===
                                  entranceDoorFileName // TODO ?
                                    ? entranceDoorFileName
                                    : entranceDoorFileKeyId)
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                {entranceDoorFileName}
                              </a>
                              {currentRole === 'customer' && (
                                <button
                                  type='button'
                                  onClick={() =>
                                    deleteEntranceFile(
                                      entranceDoorFileKeyId ===
                                        entranceDoorFileName
                                        ? entranceDoorFileName
                                        : entranceDoorFileKeyId
                                    )
                                  }
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='pl-2 w-6 h-6 text-error'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      {!entranceDoorFileKeyId &&
                        entranceDoorFileName &&
                        entranceDoorFileName}
                      {/* . . . . . . . what am i doing . . . . . . */}

                      {!entranceDoorFileName && 'No entrance door file'}
                      {/* {(formValue.entranceDoorFileKeyId && !entranceDoorFileName) && "No entrance door file"} */}

                      {/* todo add eye pour voir le fichier */}
                    </div>
                    {/* // upload in progress */}
                    {uploadEntranceDoorPhotoFileLoading && (
                      <div className='flex justify-center w-full h-full text-sm'>
                        <p>Upload in progress...</p>
                      </div>
                    )}
                    {/* error */}
                    {(errorEntranceFileMessage ||
                      uploadEntranceDoorFileErrorMessage) && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-error'>
                          {errorEntranceFileMessage
                            ? errorEntranceFileMessage
                            : uploadEntranceDoorFileErrorMessage
                            ? uploadEntranceDoorFileErrorMessage
                            : null}{' '}
                        </p>
                      </div>
                    )}
                    {/* success */}
                    {uploadEntranceDoorFileSuccessMessage && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-primary'>
                          {uploadEntranceDoorFileSuccessMessage}{' '}
                        </p>
                      </div>
                    )}
                    {currentRole === 'customer' && (
                      <label
                        className='cursor-pointer'
                        htmlFor='entranceDoorFile'
                      >
                        <input
                          id='entranceDoorFile'
                          className='hidden'
                          accept='image/*,.pdf'
                          name='entranceDoorFile'
                          type='file'
                          onInput={(e) => {
                            if (e.target.files[0]) {
                              onEntranceFileChange(e.target.files[0]);
                            }
                          }}
                        />

                        <p className='mt-4 text-center bg-bg-button hover:scale-105 ease-out duration-300  w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg'>
                          {entranceDoorFileName ? 'Change File' : 'Upload file'}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div style={classes.freightEntranceTitle}>
              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  {/*was freight door before*/}
                  Do you have a specific freight entrance ?{' '}
                </p>
                <ul className='w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] '>
                    <div
                      className={`flex items-center pl-3  ${
                        showFreightDoorSize && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='true'
                        checked={showFreightDoorSize === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                          setShowFreightDoorSize(true);
                        }}
                        name='specificFreightDoor'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='specificFreightDoor'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        showFreightDoorSize === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='false'
                        checked={showFreightDoorSize === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: false,
                          }));
                          setShowFreightDoorSize(false);
                        }}
                        name='specificFreightDoor'
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='specificFreightDoor'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={classes.freightBlock}
              className={`${
                showFreightDoorSize === false ? 'hidden' : 'visible'
              }`}
            >
              <h3 className='text-black text-xl font-bold'>
                {' '}
                Freight entrance{' '}
              </h3>

              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  At the same address ?{' '}
                </p>
                <ul className='w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] '>
                    <div
                      className={`flex items-center pl-3  ${
                        showFreightAddress && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='true'
                        checked={showFreightAddress === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                          setShowFreightAddress(true);
                        }}
                        name='sameFreightAddress'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='sameFreightAddress'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        showFreightAddress === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='false'
                        checked={showFreightAddress === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: false,
                          }));
                          setShowFreightAddress(false);
                        }}
                        name='sameFreightAddress'
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='sameFreightAddress'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div
                className={`${
                  showFreightAddress === true ? 'hidden' : 'visible'
                }`}
              >
                <ComplementField
                  label='Address'
                  name='freightAddress'
                  type='text'
                  placeholder='123 E Example St'
                  value={formValue?.freightAddress}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />

                <ComplementField
                  label='Complement'
                  name='freightComplement'
                  type='text'
                  placeholder='Complement address'
                  value={formValue?.freightComplement}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />

                <ComplementField
                  label='City'
                  name='freightCity'
                  type='text'
                  placeholder='City Name'
                  value={formValue?.freightCity}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />

                <ComplementField
                  label='State'
                  name='freightState'
                  type='text'
                  placeholder='State Name'
                  value={formValue?.freightState}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />

                <ComplementField
                  label='Zip Code'
                  name='freightZipcode'
                  type='text'
                  placeholder='#####'
                  value={formValue?.freightZipcode}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />

                <ComplementField
                  label='Access information / Parking information'
                  name='accessInformationFreightEntrance'
                  type='text'
                  placeholder='Any information relevant (road, parking, restrictions, areas...)'
                  value={formValue?.accessInformationFreightEntrance}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className={`my-8 w-full`}>
                <div className='flex my-8 w-full'>
                  <p className='self-center w-2/5 block text-gray-700 text-smc'>
                    {' '}
                    Freight entrance door size (<i>optional </i>){' '}
                  </p>
                  <div className='flex w-full'>
                    <div className='lg:w-2/5 w-2/4 mr-6'>
                      <Field
                        type='text'
                        name='freightDoorLenght'
                        placeholder='WW'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.freightDoorLenght}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Width (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-2/5 w-2/4 lg:mr-6'>
                      <Field
                        type='text'
                        name='freightDoorHeight'
                        placeholder='HH'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.freightDoorHeight}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Height (inches){' '}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <ComplementField
                label='Access code of freight entrance, if several, indicate so'
                name='accessCodeFreightEntrance'
                type='text'
                placeholder='Descriptions & Codes'
                value={formValue?.accessCodeFreightEntrance}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />

              <div className='flex my-8 w-full '>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Freight door opening direction{' '}
                </p>
                <ul className='w-full flex-col text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3 ${
                        formValue?.freightDoorOpeningDirection ===
                          'notMentioned' && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='notMentioned'
                        checked={
                          formValue?.freightDoorOpeningDirection ===
                          'notMentioned'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        name='freightDoorOpeningDirection'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='freightDoorOpeningDirection'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Not mentioned{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        formValue?.freightDoorOpeningDirection === 'inward' &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='inward'
                        name='freightDoorOpeningDirection'
                        checked={
                          formValue?.freightDoorOpeningDirection === 'inward'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='freightDoorOpeningDirection'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        Inward{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        formValue?.freightDoorOpeningDirection === 'outward' &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='outward'
                        name='freightDoorOpeningDirection'
                        checked={
                          formValue?.freightDoorOpeningDirection === 'outward'
                        }
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='freightDoorOpeningDirection'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        Outward{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div className='mt-8'>
                <div className='flex flex-col sm:flex-row w-full'>
                  <div className='w-full sm:w-2/5 self-center sm:self-start'>
                    <p className='block text-gray-700 text-smc'>
                      Freight Door Picture
                    </p>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div
                      className={`w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    >
                      {/* todo adapter affichage fileName ou formFileKey */}
                      {!uploadFreightDoorPhotoFileLoading &&
                        freightDoorFileKeyId &&
                        freightDoorFileName && (
                          <div className='text-neutral-dark'>
                            <p>Chosen file:</p>
                            <div className='flex flex-row items-center'>
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  (freightDoorFileKeyId === freightDoorFileName // TODO ?
                                    ? freightDoorFileName
                                    : freightDoorFileKeyId)
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                {freightDoorFileName}
                              </a>
                              {currentRole === 'customer' && (
                                <button
                                  type='button'
                                  onClick={() =>
                                    deleteFreightFile(
                                      freightDoorFileKeyId ===
                                        freightDoorFileName
                                        ? freightDoorFileName
                                        : freightDoorFileKeyId
                                    )
                                  }
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='pl-2 w-6 h-6 text-error'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      {!freightDoorFileKeyId &&
                        freightDoorFileName &&
                        freightDoorFileName}
                      {/* . . . . . . . what am i doing . . . . . . */}

                      {!freightDoorFileName && 'No freight door file'}
                      {/* {(formValue.freightDoorFileKeyId && !freightDoorFileName) && "No freight door file"} */}

                      {/* todo add eye pour voir le fichier */}
                    </div>
                    {/* // upload in progress */}
                    {uploadFreightDoorPhotoFileLoading && (
                      <div className='flex justify-center w-full h-full text-sm'>
                        <p>Upload in progress...</p>
                      </div>
                    )}
                    {/* error */}
                    {(errorFreightFileMessage ||
                      uploadFreightDoorFileErrorMessage) && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-error'>
                          {errorFreightFileMessage
                            ? errorFreightFileMessage
                            : uploadFreightDoorFileErrorMessage
                            ? uploadFreightDoorFileErrorMessage
                            : null}{' '}
                        </p>
                      </div>
                    )}
                    {/* success */}
                    {uploadFreightDoorFileSuccessMessage && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-primary'>
                          {uploadFreightDoorFileSuccessMessage}{' '}
                        </p>
                      </div>
                    )}
                    {currentRole === 'customer' && (
                      <label
                        className='cursor-pointer'
                        htmlFor='freightDoorFile'
                      >
                        <input
                          id='freightDoorFile'
                          className='hidden'
                          accept='image/*,.pdf'
                          name='freightDoorFile'
                          type='file'
                          onInput={(e) => {
                            if (e.target.files[0]) {
                              onFreightFileChange(e.target.files[0]);
                            }
                          }}
                        />

                        <p className='mt-4 text-center bg-bg-button hover:scale-105 ease-out duration-300  w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg'>
                          {freightDoorFileName ? 'Change File' : 'Upload file'}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div style={classes.elevatorTitle}>
              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Do you have an elevator ?{' '}
                </p>
                <ul className='w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] '>
                    <div
                      className={`flex items-center pl-3  ${
                        showElevator && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='true'
                        checked={showElevator === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                          setShowElevator(true);
                        }}
                        name='elevator'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='elevator'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        showElevator === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='false'
                        checked={showElevator === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: false,
                          }));
                          setShowElevator(false);
                        }}
                        name='elevator'
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='elevator'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={classes.elevatorBlock}
              className={`${showElevator === false ? 'hidden' : 'visible'}`}
            >
              <h3 className='text-black text-xl font-bold'> Elevator </h3>

              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Passenger elevator ?{' '}
                </p>
                <ul className='w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] '>
                    <div
                      className={`flex items-center pl-3  ${
                        showPassengerElevatorSize &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='true'
                        checked={showPassengerElevatorSize === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                          setShowPassengerElevatorSize(true);
                        }}
                        name='serviceElevator'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='serviceElevator'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        showPassengerElevatorSize === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='false'
                        checked={showPassengerElevatorSize === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: false,
                          }));
                          setShowPassengerElevatorSize(false);
                        }}
                        name='serviceElevator'
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='serviceElevator'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              <div
                className={`${
                  showPassengerElevatorSize === false ? 'hidden' : 'visible'
                }`}
              >
                <div className={`flex my-8 w-full`}>
                  <p className='self-center w-2/5 block text-gray-700 text-smc'>
                    {' '}
                    Passenger elevator size{' '}
                  </p>
                  <div className='flex w-full'>
                    <div className='lg:w-1/5 w-1/3 lg:mr-6 mr-2'>
                      <Field
                        type='text'
                        name='passengerElevatorLength'
                        placeholder='LL'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.passengerElevatorLength}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Length (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-1/5 w-1/3 lg:mr-6 mr-2'>
                      <Field
                        type='text'
                        name='passengerElevatorHeight'
                        placeholder='HH'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.passengerElevatorHeight}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Height (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-1/5 w-1/3 lg:mr-6'>
                      <Field
                        type='text'
                        name='passengerElevatorWidth'
                        placeholder='WW'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.passengerElevatorWidth}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Width (inches){' '}
                      </small>
                    </div>
                  </div>
                </div>

                <div className={`flex my-8 w-full`}>
                  <p className='self-center w-2/5 block text-gray-700 text-smc'>
                    {' '}
                    Passenger door size (<i>optional </i>){' '}
                  </p>
                  <div className='flex w-full'>
                    <div className='lg:w-1/5 w-2/4 lg:mr-6 mr-2'>
                      <Field
                        type='text'
                        name='entranceLengthPassengerElevator'
                        placeholder='WW'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.entranceLengthPassengerElevator}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Width (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-1/5 w-2/4 lg:mr-6'>
                      <Field
                        type='text'
                        name='entranceHeightPassengerElevator'
                        placeholder='HH'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.entranceHeightPassengerElevator}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Height (inches){' '}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Freight elevator ?{' '}
                </p>
                <ul className='w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] '>
                    <div
                      className={`flex items-center pl-3  ${
                        showFreightElevatorSize &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='true'
                        checked={showFreightElevatorSize === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                          setShowFreightElevatorSize(true);
                        }}
                        name='freightElevator'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='freightElevator'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        showFreightElevatorSize === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='false'
                        checked={showFreightElevatorSize === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: false,
                          }));
                          setShowFreightElevatorSize(false);
                        }}
                        name='freightElevator'
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='freightElevator'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={`${
                  showFreightElevatorSize === false ? 'hidden' : 'visible'
                }`}
              >
                <div className={`flex my-8 w-full`}>
                  <p className='self-center w-2/5 block text-gray-700 text-smc'>
                    {' '}
                    Freight elevator size{' '}
                  </p>
                  <div className='flex w-full'>
                    <div className='lg:w-1/5 w-1/3 lg:mr-6 mr-2'>
                      <Field
                        type='text'
                        name='freightElevatorLength'
                        placeholder='LL'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.freightElevatorLength}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Length (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-1/5 w-1/3 lg:mr-6 mr-2'>
                      <Field
                        type='text'
                        name='freightElevatorHeight'
                        placeholder='HH'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.freightElevatorHeight}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Height (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-1/5 w-1/3 lg:mr-6'>
                      <Field
                        type='text'
                        name='freightElevatorWidth'
                        placeholder='WW'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.freightElevatorWidth}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Width (inches){' '}
                      </small>
                    </div>
                  </div>
                </div>

                <div className={`flex my-8 w-full`}>
                  <p className='self-center w-2/5 block text-gray-700 text-smc'>
                    {' '}
                    Freight door size (<i>optional </i>){' '}
                  </p>
                  <div className='flex w-full'>
                    <div className='lg:w-1/5 w-2/4 lg:mr-6 mr-2'>
                      <Field
                        type='text'
                        name='entranceLengthFreightElevator'
                        placeholder='WW'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.entranceLengthFreightElevator}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Width (inches){' '}
                      </small>
                    </div>
                    <div className='lg:w-1/5 w-2/4 lg:mr-6'>
                      <Field
                        type='text'
                        name='entranceHeightFreightElevator'
                        placeholder='HH'
                        className='shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300'
                        value={formValue?.entranceHeightFreightElevator}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                      />
                      <small className='block mt-1 text-xs text-[#6B7280]'>
                        {' '}
                        Height (inches){' '}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={classes.buildingManagerTitle}>
              <div className='flex my-8 w-full'>
                <p className='self-center w-2/5 block text-gray-700 text-smc'>
                  {' '}
                  Do you have a Building Manager ?{' '}
                </p>
                <ul className='w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white '>
                  <li className='w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] '>
                    <div
                      className={`flex items-center pl-3  ${
                        showBuildingManager && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='true'
                        checked={showBuildingManager === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                          setShowBuildingManager(true);
                        }}
                        name='buildingManager'
                        className='w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='buildingManager'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300'
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className='w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]'>
                    <div
                      className={`flex items-center pl-3  ${
                        showBuildingManager === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type='radio'
                        value='false'
                        checked={showBuildingManager === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: false,
                          }));
                          setShowBuildingManager(false);
                        }}
                        name='buildingManager'
                        className='w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]'
                      />
                      <label
                        htmlFor='buildingManager'
                        className='py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 '
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              style={classes.buildingManagerBlock}
              className={`w-full p-8 rounded-lg  ${
                showBuildingManager === true ? 'visible' : 'hidden'
              }`}
            >
              <h3 className='text-black text-xl'> Building manager </h3>

              <div className='mt-10'>
                <div className='flex w-full flex-col '>
                  <div className='flex'>
                    <div className='w-2/5 '>Company</div>
                    <div className='w-full'>
                      <Field
                        name='bmCompany'
                        type='text'
                        placeholder='Building Manager Company Name'
                        value={formValue?.bmCompany}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='shadow appearance-none border placeholder:text-slate-300 rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                      />

                      {errors?.bmCompany && (
                        <p className='text-red-600 text-center md:text-left'>
                          {' '}
                          {errors.bmCompany}{' '}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='flex mt-4'>
                    <div className='w-2/5 '>Firstname</div>
                    <div className='w-full'>
                      <Field
                        name='bmFirstName'
                        type='text'
                        placeholder='Building Manager First Name'
                        value={formValue?.bmFirstName}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                      />

                      {errors?.bmFirstName && (
                        <p className='text-red-600 text-center md:text-left'>
                          {' '}
                          {errors.bmFirstName}{' '}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='flex mt-4'>
                    <div className='w-2/5 '>Lastname</div>
                    <div className='w-full'>
                      <Field
                        name='bmName'
                        type='text'
                        placeholder='Building Manager Last Name'
                        value={formValue?.bmName}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                      />

                      {errors?.bmName && (
                        <p className='text-red-600 text-center md:text-left'>
                          {' '}
                          {errors.bmName}{' '}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='flex mt-4'>
                    <div className='w-2/5 '>Email</div>
                    <div className='w-full'>
                      <Field
                        name='bmEmail'
                        type='email'
                        placeholder='buildingmanager@example.com'
                        value={formValue?.bmEmail}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                      />

                      {errors?.bmEmail && (
                        <p className='text-red-600 text-center md:text-left'>
                          {' '}
                          {errors.bmEmail}{' '}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='flex mt-4'>
                    <div className='w-2/5 '>Phone</div>
                    <div className='w-full'>
                      <Field
                        name='bmPhone'
                        type='text'
                        placeholder='#####'
                        value={formValue?.bmPhone}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className='shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline '
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{`
    input {
      vertical-align: middle;
    }
    hr {
      margin-bottom: 0.5rem;
    }
  `}</style>
    </div>
  );
};

export default EventCustomer;
