import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import logo from '../../../assets/img/MyCommandLogoWhite.png';
import FormField from '../../../components/FormField';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signupLaunched } from '../../../service/reducer';
import ReCAPTCHA from 'react-google-recaptcha';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

function SignUpCustomer() {
  const dispatch = useDispatch();

  const { signupLoading, signupErrorMessage } = useSelector((state) => ({
    signupLoading: state.getIn(['app', 'signupLoading']),
    signupErrorMessage: state.getIn(['app', 'signupErrorMessage']),
  }));
  const signup = (formik) => {
    formik.email = formik.email.toLowerCase();
    delete formik.confirmPassword;
    delete formik.acceptPrivacy;
    formik.role = 'customer';
    dispatch(signupLaunched(formik));
  };

  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  function isAccept() {
    setAcceptPrivacy(!acceptPrivacy);
  }

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    phone: '',
    role: 'customer',
    acceptPrivacy: acceptPrivacy,
  };

  const [isEnable, setIsEnable] = useState(false);

  function RecaptchaEnable() {
    setIsEnable(true);
  }

  const validate = Yup.object({
    firstName: Yup.string().required('First name required'),
    lastName: Yup.string().required('Last name required'),
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        'Your password must contain at least one upper case letter, one lower case letter, one number and one special character.'
      )
      .nullable(),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
    phone: Yup.string().min(10, 'Must be 10 characters'),
    acceptPrivacy: Yup.bool().oneOf(
      [true],
      'You must agree with our Privacy and Policy'
    ),
  });
  return (
    <div className='bg-bg-customer min-h-screen bg-no-repeat md:bg-cover md:h-screen lg:h-auto lg:bg-fit'>
      <div className='flex flex-col items-center pt-20'>
        <img src={logo} alt='Logo MyCommand' className='w-32'></img>
        <h1 className='text-white font-extrabold text-2xl lg:text-3xl  mt-8 mb-2'>
          Sign up as a Customer/Resident
        </h1>
        <p className='text-white font-normal text-sm'>
          Or{' '}
          <Link to='/signup' className='underline hover:font-bold'>
            change your profil
          </Link>
        </p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validate}
        onSubmit={(formik) => {
          const errors = {};
          if (isEnable) {
            errors.captchaRequired = 'Captcha is required';
          }
          signup(formik);
        }}
      >
        {({ values, errors, handleChange }) => (
          <container className='flex flex-col items-center mt-10'>
            <Form className='container bg-white shadow-md rounded-lg px-8 pt-6 pb-6 mb-2 flex flex-col justify-center items-center lg:w-2/6'>
              <div className='flex flex-col justify-center items-center pt-2'>
                <div className='flex items-center mb-2 justify-center items-center'>
                  <Box>
                    <Grid container columns={{ xs: 6, md: 12 }}>
                      <Grid item xs={6}>
                        <div className='mr-3 ml-3 block middle justify-center'>
                          <label className='block text-xxms font-medium text-gray-700 mb-2'>
                            {'First Name'}
                          </label>
                          <FormField
                            name='firstName'
                            type='text'
                            placeholder='First name'
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className='mr-3 ml-3 block middle justify-center'>
                          <label className='block text-xxms font-medium text-gray-700 mb-2'>
                            {'Last Name'}
                          </label>
                          <FormField
                            name='lastName'
                            type='text'
                            placeholder='Last name'
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div className='flex items-center mb-2 justify-center items-center'>
                  <Grid container columns={{ xs: 6, md: 12 }}>
                    <Grid item xs={6}>
                      <div className='mr-3 ml-3 block middle justify-center'>
                        <label className='block text-xxms font-medium text-gray-700 mb-2'>
                          {'Email'}
                        </label>
                        <FormField
                          name='email'
                          type='email'
                          placeholder='email@example.com'
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className='mr-3 ml-3 block middle justify-center'>
                        <label className='block text-xxms font-medium text-gray-700 mb-2'>
                          {'Phone'}
                        </label>
                        <FormField
                          name='phone'
                          type='tel'
                          placeholder='##########'
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div className='flex items-center mb-2 justify-center items-center'>
                  <Grid container columns={{ xs: 6, md: 12 }}>
                    <Grid item xs={6}>
                      <div className='mr-3 ml-3 block middle justify-center'>
                        <label className='block text-xxms font-medium text-gray-700 mb-2'>
                          {'Password'}
                        </label>
                        <FormField
                          name='password'
                          type='password'
                          placeholder='********'
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className='mr-3 ml-3 block middle justify-center'>
                        <label className='block text-xxms font-medium text-gray-700 mb-2'>
                          {'Confirm Password'}
                        </label>
                        <FormField
                          name='confirmPassword'
                          type='password'
                          placeholder='********'
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className='flex flex-col justify-center items-center'>
                <div className='flex items-center mb-2 justify-center items-center'>
                  <Grid container columns={{ xs: 12, md: 12 }}>
                    <Grid item xs={12}>
                      <div className='mr-3 ml-3 block middle justify-center'>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_X_API_KEY_CAPTCHA}
                          onChange={(e) => {
                            handleChange(e);
                            RecaptchaEnable();
                          }}
                          hl='en'
                          className='object-contain'
                          badge='bottomright'
                        />
                        {isEnable || errors?.captchaRequired ? null : (
                          <p className='text-center text-red-500 pt-3'>
                            Captcha is required
                          </p>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className='flex'>
                  <Field
                    type='checkbox'
                    name='acceptPrivacy'
                    id='acceptPrivacy'
                    className='mr-3'
                    checked={acceptPrivacy === true}
                    onChange={(e) => {
                      handleChange(e);
                      isAccept();
                    }}
                  />
                  <label htmlFor='privacy'>
                    I Agree to{' '}
                    <a
                      href='https://www.mycommand.net/private-policy/'
                      target='_blank'
                      rel="noopener noreferrer"
                      className='hover:underline text-blue-600'
                    >
                      Privacy policy
                    </a>{' '}
                    and{' '}
                    <a
                      href='https://www.mycommand.net/terms-and-conditions/'
                      target='_blank'
                      rel="noopener noreferrer"
                      className='hover:underline text-blue-600'
                    >
                      Terms and conditions
                    </a>
                  </label>
                </div>
                <ErrorMessage
                  component='div'
                  name='acceptPrivacy'
                  className='text-red-500'
                />
              </div>
              {signupErrorMessage && (
                <p className='text-center text-white mt-4 bg-red-500 md:w-3/4 mx-auto mb-2'>
                  {signupErrorMessage.body}
                </p>
              )}
              <div className='flex items-center'>
                {!signupLoading && (
                  <button
                    disabled={!isEnable}
                    className='bg-[#1A5DDA] hover:bg-blue-700 text-white font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline w-full my-2'
                    type='submit'
                  >
                    Sign Up
                  </button>
                )}
                {signupLoading && (
                  <button
                    disabled={!isEnable}
                    className='bg-[#1A5DDA] hover:bg-blue-700 text-white font-bold py-2 px-10 rounded focus:outline-none focus:shadow-outline w-full my-2'
                    type='submit'
                  >
                    <CircularProgress style={{ color: 'white' }} size={20} />
                  </button>
                )}
              </div>
            </Form>
          </container>
        )}
      </Formik>
    </div>
  );
}

export default SignUpCustomer;
