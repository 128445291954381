import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useStyles } from 'react-styles-hook';
import EventMailField from '../../components/Events/EventMailField';
import Header from '../../components/Header';
import EventRetailer from '../../components/Events/EventRetailer';
import EventCustomer from '../../components/Events/EventCustomer';
import EventCarrier from '../../components/Events/EventCarrier';
import EventBuildingManager from '../../components/Events/EventBuildingManager';
import getStyles from '../Events/style';
import checkCurrentRole from '../../utils/checkCurrentRole';
import { createEventLoading, getBuildingLoading } from '../../service/reducer';
import roleToUppercase from '../../utils/roleToUppercase';
import DropFile from '../../components/Events/DropFile';
import { deleteFileS3 } from '../../utils/awsLib';
import TableEventLoading from '../../components/EventsManager/TableEventLoading';
import { CircularProgress } from '@mui/material';
import DatePicker from '../../components/Events/DatePicker';
import AddTimeSlot from '../../components/Events/AddTimeSlot';

export default function Events() {
  const dispatch = useDispatch();

  const classes = useStyles(getStyles());

  const {
    buildingInfo,
    buildingLoading,
    createEventErrorMessage,
    createEventProgress,
    createEventSuccessMessage,
    uploadInsuranceFileLoading,
    uploadInsuranceFileSuccess,
    uploadInsuranceFileError,
  } = useSelector((state) => ({
    createEventErrorMessage: state.getIn(['app', 'createEventErrorMessage']),
    uploadInsuranceFileLoading: state.getIn([
      'app',
      'uploadInsuranceFileLoading',
    ]),
    uploadInsuranceFileSuccess: state.getIn([
      'app',
      'uploadInsuranceFileSuccess',
    ]),
    uploadInsuranceFileError: state.getIn(['app', 'uploadInsuranceFileError']),
    createEventProgress: state.getIn(['app', 'createEventLoading']),
    createEventSuccessMessage: state.getIn([
      'app',
      'createEventSuccessMessage',
    ]),
    buildingInfo: state.getIn(['app', 'getCurrentBuilding']),
    buildingLoading: state.getIn(['app', 'getBuildingLoading']),
  }));

  const [buildingList, setBuildingList] = useState([
    { buildingId: 0, buildingName: 'All Buildings' },
  ]);
  const [buildingSelected, setBuildingSelected] = useState(buildingList[0]);

  useEffect(() => {
    if (buildingSelected?.buildingId) {
      const role = currentRole;
      const buildingId = buildingSelected?.buildingId;
      const body = { role, buildingId };
      dispatch(getBuildingLoading(body));
    }
  }, [buildingSelected]);

  const [successMessage, setSuccessMessage] = useState();

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setSuccessMessage();
      return;
    }
    if (!createEventProgress && createEventSuccessMessage) {
      setSuccessMessage('Your event was created !');
    }
  }, [createEventProgress, createEventSuccessMessage]);

  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  const [currentRole, setCurrentRole] = useState();
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    if (userDynamo !== null) {
      delete userDynamo.firstLogin;
      setCurrUserInfo(userDynamo);
      setCurrentRole(checkCurrentRole(Object.keys(currUserInfo)[0]));
      setUserInfo(currUserInfo[currentRole]);
    }
  }, [userDynamo, userInfo, currentRole]);

  const [showRetailer, setShowRetailer] = useState(false);
  const toggleRetailer = () => {
    setShowRetailer(!showRetailer);
  };

  const [showCustomer, setShowCustomer] = useState(false);
  const toggleCustomer = () => {
    setShowCustomer(!showCustomer);
  };

  const [showCarrier, setShowCarrier] = useState(false);
  const toggleCarrier = () => {
    setShowCarrier(!showCarrier);
  };

  const [showBuildingManager, setShowBuildingManager] = useState(false);
  const toggleBuildingManager = () => {
    setShowBuildingManager(!showBuildingManager);
  };

  const [formValue, setFormValue] = useState({});

  useEffect(() => {
    if (currentRole === 'carrier') {
      setFormValue({
        carrierCompany: userInfo?.company,
        carrierLastName: userInfo?.lastName,
        carrierFirstName: userInfo?.firstName,
        carrierPhone: userInfo?.phone,
        carrierAddress: userInfo?.address,
        carrierComplement: userInfo?.complement,
        carrierState: userInfo?.addressState,
        carrierCity: userInfo?.city,
        carrierZipcode: userInfo?.zipcode,
      });
    }
    if (currentRole === 'retailer') {
      setFormValue({
        retailerCompany: userInfo?.company,
        retailerLastName: userInfo?.lastName,
        retailerFirstName: userInfo?.firstName,
        retailerPhone: userInfo?.phone,
        retailerAddress: userInfo?.address,
        retailerComplement: userInfo?.complement,
        retailerState: userInfo?.addressState,
        retailerCity: userInfo?.city,
        retailerZipcode: userInfo?.zipcode,
      });
    }
    if (currentRole === 'customer') {
      setFormValue({
        customerFreightDoor: userInfo?.freightDoor || false,
        freightEntrance: userInfo?.freightEntrance || false,
        specificFreightDoor: userInfo?.specificFreightDoor || false,
        sameFreightAddress: userInfo?.sameFreightAddress || false,
        freightElevator: userInfo?.freightElevator || false,
        elevator: userInfo?.elevator || false,
        serviceElevator: userInfo?.serviceElevator || false,
        buildingManager: userInfo?.buildingManager || false,
        typeOfResidence: userInfo?.typeOfResidence || 'building',
        freightDoorOpeningDirection:
          userInfo?.freightDoorOpeningDirection || 'notMentioned',
        entranceDoorOpeningDirection:
          userInfo?.entranceDoorOpeningDirection || 'notMentioned',
        // files
        entranceDoorFileKeyId: userInfo?.entranceDoorFileKeyId,
        entranceDoorFileName: userInfo?.entranceDoorFileName,
        freightDoorFileKeyId: userInfo?.freightDoorFileKeyId,
        freightDoorFileName: userInfo?.freightDoorFileName,
        //
        customerFirstname: userInfo?.firstName,
        customerLastname: userInfo?.lastName,
        customerPhone: userInfo?.phone,
        customerAddress: userInfo?.address,
        customerComplement: userInfo?.complement,
        customerState: userInfo?.addressState,
        customerCity: userInfo?.city,
        customerZipcode: userInfo?.zipcode,
        specificDoorLenght: userInfo?.specificDoorLenght,
        specificDoorHeight: userInfo?.specificDoorHeight,
        freightAddress: userInfo?.freightAddress,
        freightComplement: userInfo?.freightComplement,
        freightState: userInfo?.freightState,
        freightCity: userInfo?.freightCity,
        freightZipcode: userInfo?.freightZipcode,
        freightDoorLenght: userInfo?.freightDoorLenght,
        freightDoorHeight: userInfo?.freightDoorHeight,
        freightElevatorLength: userInfo?.freightElevatorLength,
        freightElevatorHeight: userInfo?.freightElevatorHeight,
        freightElevatorWidth: userInfo?.freightElevatorWidth,
        bmCompany: userInfo?.bmCompany,
        bmName: userInfo?.bmName,
        bmFirstName: userInfo?.bmFirstName,
        bmEmail: userInfo?.bmEmail,
        bmPhone: userInfo?.bmPhone,
        accessCodeFreightEntrance: userInfo?.accessCodeFreightEntrance,
        accessCodeDeliveryAddress: userInfo?.accessCodeDeliveryAddress,
        accessInformationDeliveryAddress:
          userInfo?.accessInformationDeliveryAddress,
        accessInformationFreightEntrance:
          userInfo?.accessInformationFreightEntrance,
        passengerElevatorLength: userInfo?.passengerElevatorLength,
        passengerElevatorHeight: userInfo?.passengerElevatorHeight,
        passengerElevatorWidth: userInfo?.passengerElevatorWidth,
        entranceLengthPassengerElevator:
          userInfo?.entranceLengthPassengerElevator,
        entranceHeightPassengerElevator:
          userInfo?.entranceHeightPassengerElevator,
        entranceHeightFreightElevator: userInfo?.entranceHeightFreightElevator,
        entranceLengthFreightElevator: userInfo?.entranceLengthFreightElevator,
      });
    }
    if (currentRole === 'buildingManager') {
      setFormValue({
        BMfirstName: userInfo?.firstName,
        BMlastName: userInfo?.lastName,
        BMphone: userInfo?.phone,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (buildingInfo) {
      setFormValue((prevState) => ({
        ...prevState,
        BMname: buildingInfo?.buildingName,
        BMaddress: buildingInfo?.address,
        BMcomplement: buildingInfo?.complement,
        BMaddressState: buildingInfo?.addressState,
        BMcity: buildingInfo?.city,
        BMzipcode: buildingInfo?.zipcode,
        BMtypeOfResidence: buildingInfo?.typeOfResidence,
        BMdoorSizeLenght: buildingInfo?.doorSizeLenght,
        BMdoorSizeHeight: buildingInfo?.doorSizeHeight,
        BMelevatorLenght: buildingInfo?.elevatorLenght,
        BMelevatorHeight: buildingInfo?.elevatorHeight,
        BMelevatorWidth: buildingInfo?.elevatorWidth,
        BMspecificFreightDoor: buildingInfo?.specificFreightDoor,
        BMspecificFreightDoorLenght: buildingInfo?.specificFreightDoorLenght,
        BMspecificFreightDoorHeight: buildingInfo?.specificFreightDoorHeight,
        BMsameFreightAddress: buildingInfo?.sameFreightAddress,
        BMfreightAddress: buildingInfo?.freightAddress,
        BMfreightComplement: buildingInfo?.freightComplement,
        BMfreightState: buildingInfo?.freightState,
        BMfreightCity: buildingInfo?.freightCity,
        BMfreightZipcode: buildingInfo?.freightZipcode,
        BMfreightOpeningHourStart: buildingInfo?.freightOpeningHourStart,
        BMfreightOpeningHourEnd: buildingInfo?.freightOpeningHourEnd,
        BMfreightElevator: buildingInfo?.freightElevator,
        BMfreightElevatorLenght: buildingInfo?.freightElevatorLenght,
        BMfreightElevatorHeight: buildingInfo?.freightElevatorHeight,
        BMfreightElevatorWidth: buildingInfo?.freightElevatorWidth,
        BMneedToBookElevator: buildingInfo?.needToBookElevator,
        BMcontactLastName: buildingInfo?.contactLastName,
        BMcontactFirstName: buildingInfo?.contactFirstName,
        BMcontactEmail: buildingInfo?.contactEmail,
        BMcontactPhone: buildingInfo?.contactPhone,
        BMdoorman: buildingInfo?.doorMan,
        BMvirtualDoorman: buildingInfo?.virtualDoorMan,
        BMaccessInformation: buildingInfo?.accessInformation,
        BMCOIRequirement: buildingInfo?.COIRequirement,
      }));
    }
  }, [buildingInfo]);

  const finalForm = {
    deliveryAddress: {
      address: formValue?.customerAddress || null,
      complement: formValue?.customerComplement || null,
      state: formValue?.customerState || null,
      city: formValue?.customerCity || null,
      zipcode: formValue?.customerZipcode || null,
    },
    buildingManager: {
      firstName: formValue?.BMfirstName || null,
      lastName: formValue?.BMlastName || null,
      phone: formValue?.BMphone || null,
      EINNumber: formValue?.BMEINNumber || null,
      internalRef: formValue?.BMInternalRef || null,
    },

    freightEntrance: {
      address: formValue?.BMfreightAddress || null,
      complement: formValue?.BMfreightComplement || null,
      state: formValue?.BMfreightState || null,
      city: formValue?.BMfreightCity || null,
      zipcode: formValue?.BMfreightZipcode || null,
      openingHourStart: formValue?.BMfreightOpeningHourStart || null,
      openingHourEnd: formValue?.BMfreightOpeningHourEnd || null,
      freightElevator: formValue?.BMfreightElevator || false,
      elevatorLenght: formValue?.BMfreightElevatorLenght || null,
      elevatorHeight: formValue?.BMfreightElevatorHeight || null,
      elevatorWidth: formValue?.BMfreightElevatorWidth || null,
      needToBookElevator: formValue?.BMneedToBookElevator || false,
      contactLastName: formValue?.BMcontactLastName || null,
      contactFirstName: formValue?.BMcontactFirstName || null,
      contactEmail: formValue?.BMcontactEmail || null,
      contactPhone: formValue?.BMcontactPhone || null,
    },
    retailer: {
      company: formValue?.retailerCompany || null,
      firstName: formValue?.retailerFirstName || null,
      lastName: formValue?.retailerLastName || null,
      phone: formValue?.retailerPhone || null,
      address: formValue?.retailerAddress || null,
      complement: formValue?.retailerComplement || null,
      state: formValue?.retailerState || null,
      city: formValue?.retailerCity || null,
      zipcode: formValue?.retailerZipcode || null,
      EINNumber: formValue?.retailerEINNumber || null,
      internalRef: formValue?.retailerInternalRef || null,
    },
    product: {
      reference: formValue?.retailerReference || null,
      length: formValue?.retailerLenght || null,
      width: formValue?.retailerWidth || null,
      height: formValue?.retailerHeight || null,
      weight: formValue?.retailerWeight || null,
    },
    carrier: {
      company: formValue?.carrierCompany || null,
      firstName: formValue?.carrierFirstName || null,
      lastName: formValue?.carrierLastName || null,
      phone: formValue?.carrierPhone || null,
      address: formValue?.carrierAddress || null,
      complement: formValue?.carrierComplement || null,
      state: formValue?.carrierState || null,
      city: formValue?.carrierCity || null,
      zipcode: formValue?.carrierZipcode || null,
      EINNumber: formValue?.carrierEINNumber || null,
      internalRef: formValue?.carrierInternalRef || null,
    },
    insuranceFileKey: formValue?.insuranceFileKey || null,
    invoiceFileKey: formValue?.invoiceFileKey || null,
    eventStatus: 'NEW',
    createdBy: roleToUppercase(currentRole),
    // invoiceFileKey: formValue?.retailerInvoiceFileKey || null, // not used anywhere + duplication
    role: currentRole,
  };

  if (formValue?.customerFirstname) {
    finalForm.customer = {
      // specific behaviour for boolean field

      freightDoor: formValue?.customerFreightDoor || false,
      freightEntrance: formValue?.freightEntrance || false,
      sameFreightAddress: formValue?.sameFreightAddress || false,
      elevator: formValue?.elevator || false,
      serviceElevator: formValue?.serviceElevator || false,
      buildingManager: formValue?.buildingManager || false,
      freightElevator: formValue?.freightElevator || false,
      specificFreightDoor: formValue?.specificFreightDoor || false,
      //
      firstName: formValue?.customerFirstname || null,
      lastName: formValue?.customerLastname || null,
      phone: formValue?.customerPhone || null,
      EINNumber: formValue?.customerEINNumber || null,
      internalRef: formValue?.customerInternalRef || null,
      address: formValue?.customerAddress || null,
      complement: formValue?.customerComplement || null,
      addressState: formValue?.customerState || null,
      city: formValue?.customerCity || null,
      zipcode: formValue?.customerZipcode || null,
      typeOfResidence: formValue?.typeOfResidence || null,
      entranceDoorOpeningDirection:
        formValue?.entranceDoorOpeningDirection || null,
      entranceDoorFileKeyId: formValue?.entranceDoorFileKeyId || null,
      entranceDoorFileName: formValue?.entranceDoorFileName || null,
      freightDoorFileKeyId: formValue?.freightDoorFileKeyId || null,
      freightDoorFileName: formValue?.freightDoorFileName || null,
      freightDoorOpeningDirection:
        formValue?.freightDoorOpeningDirection || null,
      specificDoorLenght: formValue?.specificDoorLenght || null,
      specificDoorHeight: formValue?.specificDoorHeight || null,
      freightAddress: formValue?.freightAddress || null,
      freightComplement: formValue?.freightComplement || null,
      freightState: formValue?.freightState || null,
      freightCity: formValue?.freightCity || null,
      freightZipcode: formValue?.freightZipcode || null,
      freightDoorLenght: formValue?.freightDoorLenght || null,
      freightDoorHeight: formValue?.freightDoorHeight || null,
      freightElevatorLength: formValue?.freightElevatorLength || null,
      freightElevatorHeight: formValue?.freightElevatorHeight || null,
      freightElevatorWidth: formValue?.freightElevatorWidth || null,
      bmCompany: formValue?.bmCompany || null,
      bmName: formValue?.bmName || null,
      bmFirstName: formValue?.bmFirstName || null,
      bmEmail: formValue?.bmEmail || null,
      bmPhone: formValue?.bmPhone || null,
      accessCodeFreightEntrance: formValue?.accessCodeFreightEntrance || null,
      accessCodeDeliveryAddress: formValue?.accessCodeDeliveryAddress || null,
      accessInformationDeliveryAddress:
        formValue?.accessInformationDeliveryAddress || null,
      accessInformationFreightEntrance:
        formValue?.accessInformationFreightEntrance || null,
      passengerElevatorLength: formValue?.passengerElevatorLength || null,
      passengerElevatorHeight: formValue?.passengerElevatorHeight || null,
      passengerElevatorWidth: formValue?.passengerElevatorWidth || null,
      entranceLengthPassengerElevator:
        formValue?.entranceLengthPassengerElevator || null,
      entranceHeightPassengerElevator:
        formValue?.entranceHeightPassengerElevator || null,
      entranceHeightFreightElevator:
        formValue?.entranceHeightFreightElevator || null,
      entranceLengthFreightElevator:
        formValue?.entranceLengthFreightElevator || null,
    };
  }

  if (
    formValue?.BMaddress &&
    formValue?.BMaddressState &&
    formValue?.BMzipcode &&
    formValue?.BMname
  ) {
    finalForm.building = {
      name: formValue?.BMname || null,
      address: formValue?.BMaddress || null,
      complement: formValue?.BMcomplement || null,
      state: formValue?.BMaddressState || null,
      city: formValue?.BMcity || null,
      zipcode: formValue?.BMzipcode || null,
      typeOfResidence: formValue?.BMtypeOfResidence || null,
      doorSizeLenght: formValue?.BMdoorSizeLenght || null,
      doorSizeHeight: formValue?.BMdoorSizeHeight || null,
      elevatorLenght: formValue?.BMelevatorLenght || null,
      elevatorHeight: formValue?.BMelevatorHeight || null,
      elevatorWidth: formValue?.BMelevatorWidth || null,
      specificFreightDoor: formValue?.BMspecificFreightDoor || false,
      sameFreightAddress: formValue?.BMsameFreightAddress || false,
      specificFreightDoorLenght: formValue?.BMspecificFreightDoorLenght || null,
      specificFreightDoorHeight: formValue?.BMspecificFreightDoorHeight || null,
      doorman: formValue?.BMdoorman || false,
      virtualDoorman: formValue?.BMvirtualDoorman || false,
      accessInformation: formValue?.BMaccessInformation || null,
      COIRequirement: formValue?.BMCOIRequirement || null,
    };
  }
  if (formValue?.emailBuildingManager || currentRole === 'buildingManager') {
    finalForm.buildingManagerEmail =
      formValue?.emailBuildingManager || userInfo?.email;
  }
  if (formValue?.emailRetailer || currentRole === 'retailer') {
    finalForm.retailerEmail = formValue?.emailRetailer || userInfo?.email;
  }
  if (formValue?.emailCustomer || currentRole === 'customer') {
    finalForm.customerEmail = formValue?.emailCustomer || userInfo?.email;
  }
  if (formValue?.emailCarrier || currentRole === 'carrier') {
    finalForm.carrierEmail = formValue?.emailCarrier || userInfo?.email;
  }

  // Insurance File
  const [fileKeyId, setFileKeyId] = useState();
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [errorFileMessage, setErrorFileMessage] = useState(null);

  // Invoice File
  const [invoiceFileKeyId, setInvoiceFileKeyId] = useState();
  const [invoiceFileName, setInvoiceFileName] = useState();
  const [invoiceFile, setInvoiceFile] = useState();
  const [errorInvoiceFileMessage, setErrorInvoiceFileMessage] = useState(null);
  const [uploadFileSuccessMessage, setUploadFileSuccessMessage] = useState();
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState();

  // customer Entrance Door File
  const [entranceDoorFileKeyId, setEntranceDoorFileKeyId] = useState(
    userInfo?.entranceDoorFileKeyId
  );
  const [entranceDoorFileName, setEntranceDoorFileName] = useState(
    userInfo?.entranceDoorFileName
  );
  const [entranceDoorFile, setEntranceDoorFile] = useState();
  const [errorEntranceFileMessage, setErrorEntranceFileMessage] =
    useState(null);
  const [
    uploadEntranceDoorFileSuccessMessage,
    setUploadEntranceDoorFileSuccessMessage,
  ] = useState();
  const [
    uploadEntranceDoorFileErrorMessage,
    setUploadEntranceDoorFileErrorMessage,
  ] = useState();

  // customer Freight Door File
  const [freightDoorFileKeyId, setFreightDoorFileKeyId] = useState(
    userInfo?.freightDoorFileKeyId
  );
  const [freightDoorFileName, setFreightDoorFileName] = useState(
    userInfo?.freightDoorFileName
  );
  const [freightDoorFile, setFreightDoorFile] = useState();
  const [errorFreightFileMessage, setErrorFreightFileMessage] = useState(null);
  const [
    uploadFreightDoorFileSuccessMessage,
    setUploadFreightDoorFileSuccessMessage,
  ] = useState();
  const [
    uploadFreightDoorFileErrorMessage,
    setUploadFreightDoorFileErrorMessage,
  ] = useState();

  // Building Manager COI File
  const [COIDocumentFileKeyId, setCOIDocumentFileKeyId] = useState(
    userInfo?.COIDocumentFileKeyId
  );
  const [COIDocumentFileName, setCOIDocumentFileName] = useState(
    userInfo?.COIDocumentFileName
  );
  const [COIDocumentFile, setCOIDocumentFile] = useState();
  const [errorCOIDocumentFileMessage, setErrorCOIDocumentFileMessage] =
    useState(null);
  const [
    uploadCOIDocumentFileSuccessMessage,
    setUploadCOIDocumentFileSuccessMessage,
  ] = useState();
  const [
    uploadCOIDocumentFileErrorMessage,
    setUploadCOIDocumentFileErrorMessage,
  ] = useState();

  // Building Manager Building document File
  const [buildingDocumentFileKeyId, setBuildingDocumentFileKeyId] = useState(
    userInfo?.buildingDocumentFileKeyId
  );
  const [buildingDocumentFileName, setBuildingDocumentFileName] = useState(
    userInfo?.buildingDocumentFileName
  );
  const [buildingDocumentFile, setBuildingDocumentFile] = useState();
  const [errorBuildingDocumentFileMessage, setErrorBuildingDocumentFileMessage] = useState(null);
  const [
    uploadBuildingDocumentFileSuccessMessage,
    setUploadBuildingDocumentFileSuccessMessage,
  ] = useState();
  const [
    uploadBuildingDocumentFileErrorMessage,
    setUploadBuildingDocumentFileErrorMessage,
  ] = useState();

  useEffect(() => {
    // permits to initialize value correctly
    setEntranceDoorFileKeyId(userInfo?.entranceDoorFileKeyId);
    setEntranceDoorFileName(userInfo?.entranceDoorFileName);
    setFreightDoorFileKeyId(userInfo?.freightDoorFileKeyId);
    setFreightDoorFileName(userInfo?.freightDoorFileName);
  }, [
    userInfo?.entranceDoorFileKeyId,
    userInfo?.entranceDoorFileName,
    userInfo?.freightDoorFileKeyId,
    userInfo?.freightDoorFileName,
  ]);
  useEffect(() => {
    // permits to initialize value correctly
    setCOIDocumentFileKeyId(buildingInfo?.COIDocumentFileKeyId);
    setCOIDocumentFileName(buildingInfo?.COIDocumentFileName);
    setBuildingDocumentFileKeyId(buildingInfo?.buildingDocumentFileKeyId);
    setBuildingDocumentFileName(buildingInfo?.buildingDocumentFileName);
  }, [
    buildingInfo?.COIDocumentFileKeyId,
    buildingInfo?.COIDocumentFileName,
    buildingInfo?.buildingDocumentFileKeyId,
    buildingInfo?.buildingDocumentFileName,
  ]);

  // deleteFile
  const deleteFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorFileMessage(null);
      setFileKeyId();
      setFile();
      setFileName();
      deleteFileS3(fileKeyId);
    }
  };

  // deleteInvoiceFile
  const deleteInvoiceFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorInvoiceFileMessage(null);
      setInvoiceFileKeyId();
      setInvoiceFile();
      setInvoiceFileName(null);
      deleteFileS3(fileKeyId); // maybe comment it later ?
    }
  };
  // delete Entrance Door File
  const deleteEntranceFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorEntranceFileMessage(null);
      setEntranceDoorFileKeyId();
      setEntranceDoorFile();
      setEntranceDoorFileName(null);
      // deleteFileS3(fileKeyId);  // Permits to not delete the orignal file which is loaded there
    }
  };
  // delete Freight Door File
  const deleteFreightFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorFreightFileMessage(null);
      setFreightDoorFileKeyId();
      setFreightDoorFile();
      setFreightDoorFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };
  // delete BM COI File
  const deleteCOIDocumentFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorCOIDocumentFileMessage(null);
      setCOIDocumentFileKeyId();
      setCOIDocumentFile();
      setCOIDocumentFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };
  // delete BM building DocumentFile
  const deleteBuildingDocumentFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorBuildingDocumentFileMessage(null);
      setBuildingDocumentFileKeyId();
      setBuildingDocumentFile();
      setBuildingDocumentFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };

  // init choosen date per user
  const [customerChoosenDate, setCustomerChoosenDate] = useState([]);
  const [buildingManagerChoosenDate, setBuildingManagerChoosenDate] = useState(
    []
  );
  const [carrierChoosenDate, setCarrierChoosenDate] = useState([]);
  const [retailerChoosenDate, setRetailerChoosenDate] = useState([]);

  // submit function
  const createEvent = () => {
    if (customerChoosenDate.length !== 0) {
      finalForm.customerDate = customerChoosenDate;
    }
    if (retailerChoosenDate.length !== 0) {
      finalForm.retailerDate = retailerChoosenDate;
    }
    if (buildingManagerChoosenDate.length !== 0) {
      finalForm.buildingManagerDate = buildingManagerChoosenDate;
    }
    if (carrierChoosenDate.length !== 0) {
      finalForm.carrierDate = carrierChoosenDate;
    }
    if (fileKeyId) {
      finalForm.insuranceFileKey = fileKeyId;
    }
    if (invoiceFileKeyId) {
      finalForm.invoiceFileKey = invoiceFileKeyId;
    }
    if (entranceDoorFileKeyId) {
      finalForm.entranceDoorFileKeyId = entranceDoorFileKeyId;
    }
    if (freightDoorFileKeyId) {
      finalForm.freightDoorFileKeyId = freightDoorFileKeyId;
    }
    if (COIDocumentFileKeyId) {
      finalForm.COIDocumentFileKeyId = COIDocumentFileKeyId;
    }
    if (buildingDocumentFileKeyId) {
      finalForm.buildingDocumentFileKeyId = buildingDocumentFileKeyId;
    }

    setSuccessMessage(createEventSuccessMessage);
    dispatch(createEventLoading(finalForm));
    setUploadFileSuccessMessage();
    setUploadFileErrorMessage();
  };

  let initialValues;
  if (currentRole) {
    if (currentRole === 'customer') {
      initialValues = {
        emailCustomer: userInfo?.email,
        emailCarrier: '',
        emailRetailer: '',
        emailBuildingManager: '',
        customerFirstname: userInfo?.firstName,
        customerLastname: userInfo?.lastName,
        customerAddress: userInfo?.address,
        customerState: userInfo?.addressState,
        customerCity: userInfo?.city,
        customerZipcode: userInfo?.zipcode,
      };
    }

    if (currentRole === 'carrier') {
      initialValues = {
        emailCustomer: '',
        emailCarrier: userInfo?.email,
        emailRetailer: '',
        emailBuildingManager: '',
        customerFirstname: '',
        customerLastname: '',
        customerAddress: '',
        customerState: '',
        customerCity: '',
        customerZipcode: '',
      };
    } else if (currentRole !== 'carrier' && currentRole !== 'customer') {
      initialValues = {
        emailCustomer: '',
        emailCarrier: '',
        emailRetailer: '',
        emailBuildingManager: '',
        customerFirstname: '',
        customerLastname: '',
        customerAddress: '',
        customerState: '',
        customerCity: '',
        customerZipcode: '',
      };
    }
  }

  const isNumber = new RegExp('^[0-9]*$');

  const [customerDeleteForbidden, setCustomerDeleteForbidden] = useState(false);
  const [buildingManagerDeleteForbidden, setBuildingManagerDeleteForbidden] =
    useState(false);
  const [carrierDeleteForbidden, setCarrierDeleteForbidden] = useState(false);
  const [retailerDeleteForbidden, setRetailerDeleteForbidden] = useState(false);

  return (
    <div>
      <div className='static relative'>
        <Header />
        <h1 className='text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold lg:ml-20 ml-10 md:ml-40 absolute bottom-40'>
          Events - Create an Event
        </h1>
      </div>
      {!userInfo && !currentRole && <TableEventLoading />}
      {userInfo && currentRole && (
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};

            if (values?.emailCustomer === '') {
              errors.emailCustomer = 'Email Customer/Resident is required';
            }
            if (values?.emailCarrier === '') {
              errors.emailCarrier = 'Email Carrier/Contractor is required';
            }
            if (
              values?.customerFirstname === '' ||
              values?.customerLastname === '' ||
              values?.customerAddress === '' ||
              values?.customerState === '' ||
              values?.customerCity === '' ||
              values?.customerZipcode === '' ||
              values?.customerFirstname === undefined ||
              values?.customerLastname === undefined ||
              values?.customerAddress === undefined ||
              values?.customerState === undefined ||
              values?.customerCity === undefined ||
              values?.customerZipcode === undefined
            ) {
              errors.customerField = "Customer's / Resident's field is missing";
            }
            if (
              values?.customerFirstname === '' ||
              values?.customerFirstname === undefined
            ) {
              errors.customerFirstname = 'First name is required';
            }
            if (
              values?.customerLastname === '' ||
              values?.customerLastname === undefined
            ) {
              errors.customerLastname = 'Last name is required';
            }
            if (
              values?.customerAddress === '' ||
              values?.customerAddress === undefined
            ) {
              errors.customerAddress = 'Address is required';
            }
            if (
              values?.customerState === '' ||
              values?.customerState === undefined
            ) {
              errors.customerState = 'State is required';
            }
            if (
              values?.customerCity === '' ||
              values?.customerCity === undefined
            ) {
              errors.customerCity = 'City is required';
            }
            if (
              values?.customerZipcode === '' ||
              values?.customerZipcode === undefined
            ) {
              errors.customerZipcode = 'Zipcode is required';
            }
            if (
              values.BMdoorSizeLenght &&
              values.BMdoorSizeLenght !== '' &&
              !isNumber.test(values?.BMdoorSizeLenght)
            ) {
              errors.BMdoorSizeLenght = 'Number only';
            }

            if (
              values.BMdoorSizeHeight &&
              values.BMdoorSizeHeight !== '' &&
              !isNumber.test(values?.BMdoorSizeHeight)
            ) {
              errors.BMdoorSizeHeight = 'Number only';
            }
            if (
              values.BMelevatorLenght &&
              values.BMelevatorLenght !== '' &&
              !isNumber.test(values?.BMelevatorLenght)
            ) {
              errors.BMelevatorLenght = 'Number only';
            }
            if (
              values.BMelevatorHeight &&
              values.BMelevatorHeight !== '' &&
              !isNumber.test(values?.BMelevatorHeight)
            ) {
              errors.BMelevatorHeight = 'Number only';
            }
            if (
              values.BMelevatorWidth &&
              values.BMelevatorWidth !== '' &&
              !isNumber.test(values?.BMelevatorWidth)
            ) {
              errors.BMelevatorWidth = 'Number only';
            }
            if (
              values.retailerLenght &&
              values.retailerLenght !== '' &&
              !isNumber.test(values?.retailerLenght)
            ) {
              errors.retailerLenght = 'Number only';
            }
            if (
              values.retailerHeight &&
              values.retailerHeight !== '' &&
              !isNumber.test(values?.retailerHeight)
            ) {
              errors.retailerHeight = 'Number only';
            }
            if (
              values.retailerWidth &&
              values.retailerWidth !== '' &&
              !isNumber.test(values?.retailerWidth)
            ) {
              errors.retailerWidth = 'Number only';
            }
            if (
              values.retailerWeight &&
              values.retailerWeight !== '' &&
              !isNumber.test(values?.retailerWeight)
            ) {
              errors.retailerWeight = 'Number only';
            }
            if (
              values.BMspecificFreightDoorLenght &&
              values.BMspecificFreightDoorLenght !== '' &&
              !isNumber.test(values?.BMspecificFreightDoorLenght)
            ) {
              errors.BMspecificFreightDoorLenght = 'Number only';
            }
            if (
              values.BMspecificFreightDoorHeight &&
              values.BMspecificFreightDoorHeight !== '' &&
              !isNumber.test(values?.BMspecificFreightDoorHeight)
            ) {
              errors.BMspecificFreightDoorHeight = 'Number only';
            }
            if (
              values.BMfreightElevatorLenght &&
              values.BMfreightElevatorLenght !== '' &&
              !isNumber.test(values?.BMfreightElevatorLenght)
            ) {
              errors.BMfreightElevatorLenght = 'Number only';
            }
            if (
              values.BMfreightElevatorHeight &&
              values.BMfreightElevatorHeight !== '' &&
              !isNumber.test(values?.BMfreightElevatorHeight)
            ) {
              errors.BMfreightElevatorHeight = 'Number only';
            }
            if (
              values.BMfreightElevatorWidth &&
              values.BMfreightElevatorWidth !== '' &&
              !isNumber.test(values?.BMfreightElevatorWidth)
            ) {
              errors.BMfreightElevatorWidth = 'Number only';
            }
            if (
              (values?.emailCarrier !== '' &&
                [
                  values?.emailCustomer,
                  values?.emailBuildingManager,
                  values?.emailRetailer,
                ].includes(values?.emailCarrier)) ||
              (values?.emailRetailer !== '' &&
                [values?.emailCustomer, values?.emailBuildingManager].includes(
                  values?.emailRetailer
                )) ||
              (values?.emailBuildingManager !== '' &&
                [values?.emailCustomer].includes(values?.emailBuildingManager))
            ) {
              errors.sameMail = "Email can't be the same";
            }

            return errors;
          }}
          onSubmit={() => {
            createEvent();
          }}
        >
          {({ formik, values, handleChange, errors }) => (
            <div className='flex flex-col w-full relative bottom-20 items-center'>
              <Form className='bg-white relative rounded-lg w-full lg:w-4/5 md:w-3/4 max-w-screen-lg'>
                <div className='flex flex-col items-center'>
                  <div className='w-full md:w-5/6 lg:w-3/5 px-4 md:px-6 lg:px-8 pt-6 pb-8'>
                    <h2 className='text-black py-4 text-2xl font-bold text-[#2260DD]'>
                      Your event
                    </h2>
                    <h3 className='text-black py-4 text-xl mt-6'>
                      {' '}
                      + Add Event holders{' '}
                    </h3>
                    <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg bg-amber-100'>
                      {currentRole === 'customer' ? (
                        <EventMailField
                          label='Customer/Resident*'
                          name='emailCustomer'
                          type='email'
                          disabled
                          value={userInfo.email}
                          autoComplete='off'
                          placeholder='customer@example.com'
                        />
                      ) : (
                        <EventMailField
                          label='Customer/Resident*'
                          name='emailCustomer'
                          type='email'
                          autoComplete='off'
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value.toLowerCase(),
                            }));
                          }}
                          placeholder='customer@example.com'
                        />
                      )}
                      <div className='w-full md:w-5/6 mx-auto'>
                        <div className='w-full mx-auto'>
                          <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                            Delivery Calendar
                          </h3>
                          <p className='pb-4 md:pl-2'>
                            Pick some available day for your delivery (max 3)
                          </p>
                          {customerChoosenDate?.length !== 0 &&
                            customerChoosenDate?.map((choosenDate) => (
                              <DatePicker
                                key={choosenDate.id}
                                currentDateId={choosenDate.id}
                                userChoosenDate={customerChoosenDate}
                                setUserChoosenDate={setCustomerChoosenDate}
                              />
                            ))}

                          <AddTimeSlot
                            userChoosenDate={customerChoosenDate}
                            setUserChoosenDate={setCustomerChoosenDate}
                            deleteForbidden={customerDeleteForbidden}
                            setDeleteForbidden={setCustomerDeleteForbidden}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg  bg-sky-100'>
                      {currentRole === 'buildingManager' ? (
                        <EventMailField
                          label='Building Manager'
                          name='emailBuildingManager'
                          type='email'
                          disabled
                          value={userInfo.email}
                          autoComplete='off'
                          placeholder='buildingManager@example.com'
                        />
                      ) : (
                        <EventMailField
                          label='Building Manager'
                          name='emailBuildingManager'
                          type='email'
                          autoComplete='off'
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value.toLowerCase(),
                            }));
                          }}
                          placeholder='buildingManager@example.com'
                        />
                      )}
                      <div className='w-full md:w-5/6 mx-auto'>
                        <div className='w-full mx-auto'>
                          <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                            Delivery Calendar
                          </h3>
                          <p className='pb-4 md:pl-2'>
                            Pick some available day for your delivery (max 3)
                          </p>
                          {buildingManagerChoosenDate?.length !== 0 &&
                            buildingManagerChoosenDate?.map((choosenDate) => (
                              <DatePicker
                                key={choosenDate.id}
                                currentDateId={choosenDate.id}
                                userChoosenDate={buildingManagerChoosenDate}
                                setUserChoosenDate={
                                  setBuildingManagerChoosenDate
                                }
                              />
                            ))}
                          <AddTimeSlot
                            userChoosenDate={buildingManagerChoosenDate}
                            setUserChoosenDate={setBuildingManagerChoosenDate}
                            deleteForbidden={buildingManagerDeleteForbidden}
                            setDeleteForbidden={
                              setBuildingManagerDeleteForbidden
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg bg-indigo-100'>
                      {currentRole === 'carrier' ? (
                        <EventMailField
                          label='Carrier/Contractor*'
                          name='emailCarrier'
                          type='email'
                          disabled
                          value={userInfo.email}
                          autoComplete='off'
                          placeholder='carrier@example.com'
                        />
                      ) : (
                        <EventMailField
                          label='Carrier/Contractor*'
                          name='emailCarrier'
                          type='email'
                          autoComplete='off'
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value.toLowerCase(),
                            }));
                          }}
                          placeholder='carrier@example.com'
                        />
                      )}
                      <div className='w-full md:w-5/6 mx-auto'>
                        <div className='w-full mx-auto'>
                          <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                            Delivery Calendar
                          </h3>
                          <p className='pb-4 md:pl-2'>
                            Pick some available day for your delivery (max 3)
                          </p>
                          {carrierChoosenDate?.length !== 0 &&
                            carrierChoosenDate?.map((choosenDate) => (
                              <DatePicker
                                key={choosenDate.id}
                                currentDateId={choosenDate.id}
                                userChoosenDate={carrierChoosenDate}
                                setUserChoosenDate={setCarrierChoosenDate}
                              />
                            ))}
                          <AddTimeSlot
                            userChoosenDate={carrierChoosenDate}
                            setUserChoosenDate={setCarrierChoosenDate}
                            deleteForbidden={carrierDeleteForbidden}
                            setDeleteForbidden={setCarrierDeleteForbidden}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg bg-pink-100'>
                      {currentRole === 'retailer' ? (
                        <EventMailField
                          label='Retailer'
                          name='emailRetailer'
                          type='email'
                          value={userInfo.email}
                          autoComplete='off'
                          placeholder='retailer@example.com'
                        />
                      ) : (
                        <EventMailField
                          label='Retailer'
                          name='emailRetailer'
                          type='email'
                          autoComplete='off'
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value.toLowerCase(),
                            }));
                          }}
                          placeholder='retailer@example.com'
                        />
                      )}
                      <div className='w-full md:w-5/6 mx-auto'>
                        <div className='w-full mx-auto'>
                          <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                            Delivery Calendar
                          </h3>
                          <p className='pb-4 md:pl-2'>
                            Pick some available day for your delivery (max 3)
                          </p>
                          {retailerChoosenDate?.length !== 0 &&
                            retailerChoosenDate?.map((choosenDate) => (
                              <DatePicker
                                key={choosenDate.id}
                                currentDateId={choosenDate.id}
                                userChoosenDate={retailerChoosenDate}
                                setUserChoosenDate={setRetailerChoosenDate}
                              />
                            ))}
                          <AddTimeSlot
                            userChoosenDate={retailerChoosenDate}
                            setUserChoosenDate={setRetailerChoosenDate}
                            deleteForbidden={retailerDeleteForbidden}
                            setDeleteForbidden={setRetailerDeleteForbidden}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full flex-col'>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      onClick={toggleCustomer}
                      className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg px-16 py-8'
                    >
                      <div className='flex justify-between'>
                        <div className='flex'>
                          <div
                            style={classes.customer}
                            className='self-center'
                          ></div>
                          <p className='px-2'>Customer/Resident</p>
                        </div>
                        <div className='flex'>
                          {showCustomer ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M4.5 15.75l7.5-7.5 7.5 7.5'
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id='eventCustomer'
                    className={`${showCustomer ? 'block' : 'hidden'}`}
                  >
                    <EventCustomer
                      setFormValue={setFormValue}
                      formValue={formValue}
                      handleChange={handleChange}
                      errors={errors}
                      currentRole={currentRole} // Used For uploading
                      // Upload part entranceDoor
                      entranceDoorFileKeyId={entranceDoorFileKeyId}
                      setEntranceDoorFileKeyId={setEntranceDoorFileKeyId}
                      entranceDoorFileName={entranceDoorFileName}
                      setEntranceDoorFileName={setEntranceDoorFileName}
                      entranceDoorFile={entranceDoorFile}
                      setEntranceDoorFile={setEntranceDoorFile}
                      errorEntranceFileMessage={errorEntranceFileMessage}
                      setErrorEntranceFileMessage={setErrorEntranceFileMessage}
                      deleteEntranceFile={deleteEntranceFile}
                      uploadEntranceDoorFileSuccessMessage={
                        uploadEntranceDoorFileSuccessMessage
                      }
                      setUploadEntranceDoorFileSuccessMessage={
                        setUploadEntranceDoorFileSuccessMessage
                      }
                      uploadEntranceDoorFileErrorMessage={
                        uploadEntranceDoorFileErrorMessage
                      }
                      setUploadEntranceDoorFileErrorMessage={
                        setUploadEntranceDoorFileErrorMessage
                      }
                      // Upload part freightDoor
                      freightDoorFileKeyId={freightDoorFileKeyId}
                      setFreightDoorFileKeyId={setFreightDoorFileKeyId}
                      freightDoorFileName={freightDoorFileName}
                      setFreightDoorFileName={setFreightDoorFileName}
                      freightDoorFile={freightDoorFile}
                      setFreightDoorFile={setFreightDoorFile}
                      errorFreightFileMessage={errorFreightFileMessage}
                      setErrorFreightFileMessage={setErrorFreightFileMessage}
                      deleteFreightFile={deleteFreightFile}
                      uploadFreightDoorFileSuccessMessage={
                        uploadFreightDoorFileSuccessMessage
                      }
                      setUploadFreightDoorFileSuccessMessage={
                        setUploadFreightDoorFileSuccessMessage
                      }
                      uploadFreightDoorFileErrorMessage={
                        uploadFreightDoorFileErrorMessage
                      }
                      setUploadFreightDoorFileErrorMessage={
                        setUploadFreightDoorFileErrorMessage
                      }
                    />
                  </div>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      onClick={toggleBuildingManager}
                      className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg my-5 px-16 py-8'
                    >
                      <div className='flex justify-between'>
                        <div className='flex'>
                          <div
                            style={classes.buildingManager}
                            className='self-center'
                          ></div>
                          <p className='px-2'>Building Manager</p>
                        </div>
                        <div className='flex'>
                          {showBuildingManager ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M4.5 15.75l7.5-7.5 7.5 7.5'
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id='eventBuildingManager'
                    className={`${showBuildingManager ? 'block' : 'hidden'}`}
                  >
                    <EventBuildingManager
                      buildingManagerChoosenDate={buildingManagerChoosenDate}
                      setBuildingManagerChoosenDate={
                        setBuildingManagerChoosenDate
                      }
                      setFormValue={setFormValue}
                      formValue={formValue}
                      handleChange={handleChange}
                      userDynamo={userDynamo}
                      currentRole={currentRole}
                      userInfo={userInfo}
                      setBuildingList={setBuildingList}
                      buildingSelected={buildingSelected}
                      setBuildingSelected={setBuildingSelected}
                      buildingList={buildingList}
                      buildingInfo={buildingInfo}
                      // Upload part COIDocumentFile
                      COIDocumentFileKeyId={COIDocumentFileKeyId}
                      setCOIDocumentFileKeyId={setCOIDocumentFileKeyId}
                      COIDocumentFileName={COIDocumentFileName}
                      setCOIDocumentFileName={setCOIDocumentFileName}
                      COIDocumentFile={COIDocumentFile}
                      setCOIDocumentFile={setCOIDocumentFile}
                      errorCOIDocumentFileMessage={errorCOIDocumentFileMessage}
                      setErrorCOIDocumentFileMessage={setErrorCOIDocumentFileMessage}
                      deleteCOIDocumentFile={deleteCOIDocumentFile}
                      uploadCOIDocumentFileSuccessMessage={
                        uploadCOIDocumentFileSuccessMessage
                      }
                      setUploadCOIDocumentFileSuccessMessage={
                        setUploadCOIDocumentFileSuccessMessage
                      }
                      uploadCOIDocumentFileErrorMessage={
                        uploadCOIDocumentFileErrorMessage
                      }
                      setUploadCOIDocumentFileErrorMessage={
                        setUploadCOIDocumentFileErrorMessage
                      }
                      // Upload part Building Document File
                      buildingDocumentFileKeyId={buildingDocumentFileKeyId}
                      setBuildingDocumentFileKeyId={setBuildingDocumentFileKeyId}
                      buildingDocumentFileName={buildingDocumentFileName}
                      setBuildingDocumentFileName={setBuildingDocumentFileName}
                      buildingDocumentFile={buildingDocumentFile}
                      setBuildingDocumentFile={setBuildingDocumentFile}
                      errorBuildingDocumentFileMessage={errorBuildingDocumentFileMessage}
                      setErrorBuildingDocumentFileMessage={setErrorBuildingDocumentFileMessage}
                      deleteBuildingDocumentFile={deleteBuildingDocumentFile}
                      uploadBuildingDocumentFileSuccessMessage={
                        uploadBuildingDocumentFileSuccessMessage
                      }
                      setUploadBuildingDocumentFileSuccessMessage={
                        setUploadBuildingDocumentFileSuccessMessage
                      }
                      uploadBuildingDocumentFileErrorMessage={
                        uploadBuildingDocumentFileErrorMessage
                      }
                      setUploadBuildingDocumentFileErrorMessage={
                        setUploadBuildingDocumentFileErrorMessage
                      }
                    />
                  </div>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      onClick={toggleCarrier}
                      className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg px-16 py-8'
                    >
                      <div className='flex justify-between'>
                        <div className='flex'>
                          <div
                            style={classes.carrier}
                            className='self-center'
                          ></div>
                          <p className='px-2'>Carrier/Contractor</p>
                        </div>
                        <div className='flex'>
                          {showCarrier ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M4.5 15.75l7.5-7.5 7.5 7.5'
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id='eventCarrier'
                    className={`${showCarrier ? 'block' : 'hidden'}`}
                  >
                    <EventCarrier
                      carrierChoosenDate={carrierChoosenDate}
                      setCarrierChoosenDate={setCarrierChoosenDate}
                      setFormValue={setFormValue}
                      formValue={formValue}
                      handleChange={handleChange}
                    />
                  </div>
                  <div className='flex justify-center'>
                    <button
                      type='button'
                      onClick={toggleRetailer}
                      className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg my-5 px-16 py-8'
                    >
                      <div className='flex justify-between'>
                        <div className='flex'>
                          <div
                            style={classes.retailer}
                            className='self-center'
                          ></div>
                          <p className='px-2'>Retailer</p>
                        </div>
                        <div className='flex'>
                          {showRetailer ? (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M4.5 15.75l7.5-7.5 7.5 7.5'
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='3'
                              stroke='currentColor'
                              className='w-6 h-6'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                  <div
                    id='eventRetailer'
                    className={`${showRetailer ? 'block' : 'hidden'}`}
                  >
                    <EventRetailer
                      setFormValue={setFormValue}
                      formValue={formValue}
                      retailerChoosenDate={retailerChoosenDate}
                      setRetailerChoosenDate={setRetailerChoosenDate}
                      handleChange={handleChange}
                      invoiceFileKeyId={invoiceFileKeyId}
                      setInvoiceFileKeyId={setInvoiceFileKeyId}
                      invoiceFileName={invoiceFileName}
                      setInvoiceFileName={setInvoiceFileName}
                      invoiceFile={invoiceFile}
                      setInvoiceFile={setInvoiceFile}
                      errorInvoiceFileMessage={errorInvoiceFileMessage}
                      setErrorInvoiceFileMessage={setErrorInvoiceFileMessage}
                      deleteInvoiceFile={deleteInvoiceFile}
                      uploadFileSuccessMessage={uploadFileSuccessMessage}
                      setUploadFileSuccessMessage={setUploadFileSuccessMessage}
                      uploadFileErrorMessage={uploadFileErrorMessage}
                      setUploadFileErrorMessage={setUploadFileErrorMessage}
                      currentRole={currentRole}
                    />
                  </div>
                  <div className='flex flex-col items-center	'>
                    <h3 className='text-black py-4 text-xl mt-6 font-bold'>
                      Certificate of fiability insurance
                    </h3>
                    <p className='text-s font-medium'>
                      COI checked by BM. If COI is not correct, delete it.
                    </p>
                    <DropFile
                      fileKeyId={fileKeyId}
                      setFileKeyId={setFileKeyId}
                      setFileName={setFileName}
                      file={file}
                      setFile={setFile}
                      // setFile={setFile} // duplication
                      errorFileMessage={errorFileMessage}
                      setErrorFileMessage={setErrorFileMessage}
                      deleteFile={deleteFile}
                      uploadErrorMessage={uploadInsuranceFileError}
                      uploadInProgress={
                        uploadInsuranceFileLoading ? true : false
                      }
                    />
                    {/* // Delete File */}
                    {!uploadInsuranceFileLoading &&
                    fileName &&
                    !errorFileMessage ? (
                      <div className='pt-6 text-neutral-dark font-bold'>
                        <p>Chosen file:</p>
                        <div className='flex flex-row items-center'>
                          <a
                            href={
                              'https://' +
                              process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                              '.s3.' +
                              process.env.REACT_APP_AWS_REGION +
                              '.amazonaws.com/' +
                              fileKeyId
                            }
                            target='_blank'
                            rel='noreferrer'
                            className='underline'
                          >
                            {fileName}
                          </a>
                          <button
                            type='button'
                            onClick={() => deleteFile(fileKeyId)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth={1.5}
                              stroke='currentColor'
                              className='pl-2 w-6 h-6 text-error'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {/* // Loader uploadFile */}
                    {uploadInsuranceFileLoading && (
                      <>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          fill='none'
                          viewBox='0 0 24 24'
                          strokeWidth={1.5}
                          stroke='currentColor'
                          className='animate-bounce w-8 h-8 text-primary'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z'
                          />
                        </svg>
                        <p>upload in progress...</p>
                      </>
                    )}
                  </div>
                  <div className='flex justify-center px-4 w-full'>
                    {createEventProgress && (
                      <button
                        disabled={true}
                        type='submit'
                        className='bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-10 text-white text-lg hover:scale-105 ease-out duration-300'
                      >
                        <CircularProgress
                          style={{ color: 'white' }}
                          size={20}
                        />
                      </button>
                    )}
                    {!createEventProgress && !successMessage && (
                      <button
                        type='submit'
                        className='bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-5 text-white text-lg hover:scale-105 ease-out duration-300'
                        onChange={createEventLoading}
                      >
                        {' '}
                        Save changes{' '}
                      </button>
                    )}
                  </div>
                  {errors.customerField && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {errors.customerField}
                    </p>
                  )}
                  {errors.emailCustomer && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {errors.emailCustomer}
                    </p>
                  )}
                  {errors.sameMail && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {errors.sameMail}
                    </p>
                  )}
                  {errors.emailCarrier && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {errors.emailCarrier}
                    </p>
                  )}
                  {(errors.BMdoorSizeLenght || errors.BMdoorSizeHeight) && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {'Door size : number only'}
                    </p>
                  )}
                  {(errors.BMelevatorLenght ||
                    errors.BMelevatorHeight ||
                    errors.BMelevatorWidth) && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {'Elevator size : number only'}
                    </p>
                  )}
                  {(errors.BMspecificFreightDoorLenght ||
                    errors.BMspecificFreightDoorHeight) && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {'Entrance Door size : number only'}
                    </p>
                  )}
                  {(errors.BMfreightElevatorLenght ||
                    errors.BMfreightElevatorHeight ||
                    errors.BMfreightElevatorWidth) && (
                    <p className='text-red-600 text-center'>
                      {' '}
                      {'Freight Elevator size : number only'}
                    </p>
                  )}
                  {successMessage && (
                    <p className='text-green-700 text-center text-white rounded-lg bg-green-100 mt-4 md:w-3/4 lg:w-2/6 mx-auto'>
                      Your event is being created, please wait...
                    </p>
                  )}
                  {createEventErrorMessage?.statusCode === 403 && (
                    <p className='text-center text-white mt-4 bg-red-500 md:w-3/4 lg:w-2/6 mx-auto'>
                      Event creation limit reached, contact
                      commercial@mycommand.net for more information.
                    </p>
                  )}
                  {createEventErrorMessage?.statusCode !== undefined &&
                    createEventErrorMessage?.statusCode !== 200
                    && createEventErrorMessage?.statusCode !== 403&&(
                    <p className='text-center text-white mt-4 bg-red-500 md:w-3/4 lg:w-2/6 mx-auto'>
                      Error while uploading your information,
                      please be sure to fill the form correctly.
                    </p>
                  )}
                </div>
              </Form>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
}
