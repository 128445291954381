import React from "react";
import { ErrorMessage, useField } from "formik";

function AccountField({ label, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className="mt-10">
      <div className="flex w-full">
        <div className="w-2/5 self-center">
          <label
            className="block text-gray-700 text-sm font-bold"
            htmlFor={field.name}
          >
            {label}
          </label>
        </div>
        <div className="w-full">
          <input
            className={`shadow placeholder:text-slate-300 appearance-none border rounded w-full md:w-4/5 lg:w-3/5 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300${
              meta.touched && meta.error && "border-red-500"
            }`}
            type="text"
            {...field}
            {...props}
          />
          <ErrorMessage
            component="div"
            name={field.name}
            className="text-red-500 mt-1"
          />
        </div>
      </div>
    </div>
  );
}

export default AccountField;
