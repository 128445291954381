import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import 'yup-phone-lite';
import ComplementField from '../ComplementField';
import { useDispatch, useSelector } from 'react-redux';
import checkCurrentRole from '../../../utils/checkCurrentRole';
import { updateUserLaunched } from '../../../service/reducer';
import { CircularProgress } from '@mui/material';
import DropFile from "../../Events/DropFile";
import { deleteFileS3 } from "../../../utils/awsLib";
import { useStyles } from "react-styles-hook";
import getStyles from "./style";

const ComplementCustomer = () => {
  const dispatch = useDispatch();

  const classes = useStyles(getStyles());


  const {
    userDynamo,
    updateUserSuccessMessage,
    updateUserLoading,
    uploadEntranceDoorPhotoFileError,
    uploadFreightDoorPhotoFileError,
    uploadEntranceDoorPhotoFileLoading,
    uploadFreightDoorPhotoFileLoading,
  } =
    useSelector((state) => ({
      userDynamo: state.getIn(['app', 'userDynamo']),
      updateUserSuccessMessage: state.getIn([
        'app',
        'updateUserSuccessMessage',
      ]),
      updateUserLoading: state.getIn(['app', 'updateUserLoading']),
      uploadEntranceDoorPhotoFileError: state.getIn(['app', 'uploadEntranceDoorPhotoFileError']),
      uploadFreightDoorPhotoFileError: state.getIn(['app', 'uploadFreightDoorPhotoFileError']),
      uploadEntranceDoorPhotoFileLoading: state.getIn([
        'app',
        'uploadEntranceDoorPhotoFileLoading',
      ]),
      uploadFreightDoorPhotoFileLoading: state.getIn([
        'app',
        'uploadFreightDoorPhotoFileLoading',
      ]),
    }));

  // success message //
  const [successMessage, setSuccessMessage] = useState();

  // door entrance and freight
  const [entranceDoorFile, setEntranceDoorFile] = useState(null);
  const [freightDoorFile, setFreightDoorFile] = useState(null);
  const [errorEntranceFileMessage, setErrorEntranceFileMessage] = useState(null);
  const [errorFreightFileMessage, setErrorFreightFileMessage] = useState(null);

  // deleteFile
  const deleteEntranceFile = (entranceDoorFileKeyId) => {
    if (entranceDoorFileKeyId) {
      setErrorEntranceFileMessage(null);
      setEntranceDoorFileKeyId(null);
      setEntranceDoorFile(null);
      setEntranceDoorFileName(null);
      deleteFileS3(entranceDoorFileKeyId);
    }
  };
  const deleteFreightFile = (freightDoorFileKeyId) => {
    if (freightDoorFileKeyId) {
      setErrorFreightFileMessage(null);
      setFreightDoorFileKeyId(null);
      setFreightDoorFile(null);
      setFreightDoorFileName(null);
      deleteFileS3(freightDoorFileKeyId);
    }
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!updateUserLoading && updateUserSuccessMessage) {
      setSuccessMessage('Your info was updated !');
    }
  }, [updateUserLoading, updateUserSuccessMessage]);

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);
  let currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  // set customer info //
  const userInfo = currUserInfo[currentRole];
  const [typeOfResidence, setTypeOfResidence] = useState(
    userInfo.typeOfResidence || 'building'
  );
  const [entranceDoorOpeningDirection, setEntranceDoorOpeningDirection] = useState(
    userInfo.entranceDoorOpeningDirection || 'notMentioned'
  );
  const [freightDoorOpeningDirection, setFreightDoorOpeningDirection] = useState(
    userInfo.freightDoorOpeningDirection || 'notMentioned'
  );
  const [showFreightDoorSize, setShowFreightDoorSize] = useState(
    userInfo.specificFreightDoor || false
  );
  const [showFreightAddress, setShowFreightAddress] = useState(
    userInfo?.sameFreightAddress || false
  );
  const [showPassengerElevatorSize, setShowPassengerElevatorSize] = useState(
    userInfo?.serviceElevator || false
  );
  const [showFreightElevatorSize, setShowFreightElevatorSize] = useState(
    userInfo?.freightElevator || false
  );
  const [showElevator, setShowElevator] = useState(
    userInfo?.elevator || false
  );
  const [showBuildingManager, setShowBuildingManager] = useState(
    userInfo?.buildingManager || false
  );

  const [entranceDoorFileKeyId, setEntranceDoorFileKeyId] = useState(userInfo.entranceDoorFileKeyId);
  const [freightDoorFileKeyId, setFreightDoorFileKeyId] = useState(userInfo.freightDoorFileKeyId);
  const [entranceDoorFileName, setEntranceDoorFileName] = useState(userInfo.entranceDoorFileName);
  const [freightDoorFileName, setFreightDoorFileName] = useState(userInfo.freightDoorFileName);

  // update Complement with formik form //
  const updateComplement = (formik) => {
    formik.typeOfResidence = typeOfResidence;
    formik.entranceDoorOpeningDirection = entranceDoorOpeningDirection;
    formik.freightDoorOpeningDirection = freightDoorOpeningDirection;
    formik.specificFreightDoor = showFreightDoorSize;
    formik.sameFreightAddress = showFreightAddress;
    formik.freightElevator = showFreightElevatorSize;
    formik.serviceElevator = showPassengerElevatorSize;
    formik.elevator = showElevator;
    formik.buildingManager = showBuildingManager;
    formik.entranceDoorFileKeyId = entranceDoorFileKeyId;
    formik.entranceDoorFileName = entranceDoorFileName;
    formik.freightDoorFileKeyId = freightDoorFileKeyId;
    formik.freightDoorFileName = freightDoorFileName;
    setSuccessMessage(updateUserSuccessMessage);
    dispatch(updateUserLaunched(formik));
  };

  return (
    <div className="h-screen">
      <Formik
        initialValues={{
          id: userInfo.id || null,
          address: userInfo.address || '',
          complement: userInfo.complement || null,
          addressState: userInfo.addressState || '',
          city: userInfo.city || null,
          zipcode: userInfo.zipcode || '',
          typeOfResidence: userInfo.typeOfResidence || null,
          entranceDoorOpeningDirection: userInfo.entranceDoorOpeningDirection || null,
          freightDoorOpeningDirection: userInfo.freightDoorOpeningDirection || null,
          specificFreightDoor: userInfo.specificFreightDoor || null,
          specificDoorLenght: userInfo.specificDoorLenght || null,
          specificDoorHeight: userInfo.specificDoorHeight || null,
          sameFreightAddress: userInfo.sameFreightAddress || null,
          freightAddress: userInfo.freightAddress || null,
          freightComplement: userInfo.freightComplement || null,
          freightState: userInfo.freightState || null,
          freightCity: userInfo.freightCity || null,
          freightZipcode: userInfo.freightZipcode || null,
          freightDoorLenght: userInfo.freightDoorLenght || null,
          freightDoorHeight: userInfo.freightDoorHeight || null,
          freightElevator: userInfo.freightElevator || null,
          freightElevatorLength: userInfo.freightElevatorLength || null,
          freightElevatorHeight: userInfo.freightElevatorHeight || null,
          freightElevatorWidth: userInfo.freightElevatorWidth || null,
          elevator: userInfo.elevator || null,
          serviceElevator: userInfo.serviceElevator || null,
          buildingManager: userInfo.buildingManager || null,
          bmCompany: userInfo.bmCompany || null,
          bmName: userInfo.bmName || null,
          bmFirstName: userInfo.bmFirstName || null,
          bmEmail: userInfo.bmEmail || null,
          bmPhone: userInfo.bmPhone || null,
          accessCodeFreightEntrance: userInfo.accessCodeFreightEntrance || null,
          // accessCodeFreightElevator: userInfo.accessCodeFreightElevator || null,
          // accessCodePassengerElevator: userInfo.accessCodePassengerElevator || null,
          accessCodeDeliveryAddress: userInfo.accessCodeDeliveryAddress || null,
          accessInformationDeliveryAddress: userInfo.accessInformationDeliveryAddress || null,
          accessInformationFreightEntrance: userInfo.accessInformationFreightEntrance || null,
          passengerElevatorLength: userInfo.passengerElevatorLength || null,
          passengerElevatorHeight: userInfo.passengerElevatorHeight || null,
          passengerElevatorWidth: userInfo.passengerElevatorWidth || null,
          entranceLengthPassengerElevator: userInfo.entranceLengthPassengerElevator || null,
          entranceHeightPassengerElevator: userInfo.entranceHeightPassengerElevator || null,
          entranceHeightFreightElevator: userInfo.entranceHeightFreightElevator || null,
          entranceLengthFreightElevator: userInfo.entranceLengthFreightElevator || null,
          role: currentRole,
          // Those aren't used anymore :
          // elevatorLenght: userInfo.elevatorLenght || null,
          // elevatorHeight: userInfo.elevatorHeight || null,
          // elevatorWidth: userInfo.elevatorWidth || null,
          // entranceLength: userInfo.entranceLength || null,
          // entranceHeight: userInfo.entranceHeight || null,
          // accessCode: userInfo.accessCode || null,
        }} validate={(values) => {
        // set errors message //
        const errors = {};
        if (values.address === '') {
          errors.address = 'Your address is required';
        }
        if (values.addressState === '') {
          errors.addressState = 'State is required';
        }
        if (values.city === '') {
          errors.city = 'Your city is required';
        }
        if (values.zipcode === '') {
          errors.zipcode = 'Your zipcode is required';
        }
        if (showBuildingManager) {
          if (!values.bmCompany) {
            errors.bmCompany = 'Building Manager Company is required';
          } else if (!values.bmFirstName) {
            errors.bmFirstName = 'Building Manager Firstname is required';
          } else if (!values.bmName) {
            errors.bmName = 'Building Manager Lastname is required';
          } else if (!values.bmEmail) {
            errors.bmEmail = 'Building Manager Email is required';
          }
          return errors;
        }
        return errors;
      }}
        onSubmit={(formik) => {
          updateComplement(formik);
        }}
      >
        {({values, handleChange, errors}) => (
          <div className="flex flex-col mt-10 w-full">
            <h1 className="text-white text-4xl font-bold mb-5 ml-10 md:ml-20">
              {' '}
              Complements{' '}
            </h1>
            <div className="relative px-2 md:px-8 w-full">
              <Form className="bg-white shadow-md rounded-lg px-4 lg:px-8 pt-6 pb-8 w-full max-w-screen-2xl mx-auto">
                <div className="flex-col">
                  <div className="w-full md:w-5/6 lg:w-3/5 mx-auto">
                    <div style={classes.deliveryAddress}>
                      {/* Delivery adress */}
                      <h2 className="text-black py-4 text-2xl font-bold text-[#2260DD]">
                        {' '}
                        Informations{' '}
                      </h2>
                      <h3 className="text-black py-4 text-xl">
                        {' '}
                        Delivery address{' '}
                      </h3>
                      <ComplementField
                        label="Address"
                        name="address"
                        type="text"
                        placeholder="123 E Example St"
                      />{' '}
                      <ComplementField
                        label="Complement"
                        name="complement"
                        type="text"
                        placeholder="Unit, Apt, suite..."
                      />
                      <ComplementField
                        label="City"
                        name="city"
                        type="text"
                        placeholder="City Name"
                      />
                      <ComplementField
                        label="State"
                        name="addressState"
                        type="text"
                        placeholder="State Name"
                      />
                      <ComplementField
                        label="Zip Code"
                        name="zipcode"
                        type="text"
                        placeholder="#####"
                      />
                      <div className="flex my-8 w-full ">
                        <p className="self-center w-2/5 block text-gray-700 text-smc">
                          {' '}
                          Type of residence{' '}
                        </p>
                        <ul
                          className="w-full flex-col text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3 ${
                                typeOfResidence === 'building' &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="building"
                                checked={typeOfResidence === 'building'}
                                onChange={(e) => {
                                  setTypeOfResidence(e.target.value);
                                }}
                                name="type"
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="type"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Building{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                typeOfResidence === 'house' &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="house"
                                name="type"
                                checked={typeOfResidence === 'house'}
                                onChange={(e) => {
                                  setTypeOfResidence(e.target.value);
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="type"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                House{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>


                      <ComplementField
                        label="Access information / Parking information"
                        name="accessInformationDeliveryAddress"
                        type="text"
                        placeholder="Any information relevant (road, parking, restrictions, areas...)"
                      />

                      <ComplementField
                        label="Access code, if several, indicate so"
                        name="accessCodeDeliveryAddress"
                        type="text"
                        placeholder="Descriptions & Codes"
                      />
                      <div
                        className={`flex my-8 w-full`}
                      >
                        <p className="self-center w-2/5 block text-gray-700 text-smc">
                          {' '}
                          Entrance door size (<i>optional </i>) {' '}
                        </p>
                        <div className="flex w-full">
                          <div className="lg:w-1/5 w-2/4 mr-6">
                            <Field
                              type="text"
                              name="specificDoorLenght"
                              placeholder="WW"
                              className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <small className="block mt-1 text-xs text-[#6B7280]">
                              {' '}
                              Width (inches){' '}
                            </small>
                          </div>
                          <div className="lg:w-1/5 w-2/4 lg:mr-6">
                            <Field
                              type="text"
                              name="specificDoorHeight"
                              placeholder="HH"
                              className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                            <small className="block mt-1 text-xs text-[#6B7280]">
                              {' '}
                              Height (inches){' '}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`flex my-8 w-full`}
                      >
                        <p className="self-center w-2/5 block text-gray-700 text-smc">
                          {' '}
                          Door opening direction{' '}
                        </p>
                        <ul
                          className="w-full flex-col text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3 ${
                                entranceDoorOpeningDirection === 'notMentioned' &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="notMentioned"
                                checked={entranceDoorOpeningDirection === 'notMentioned'}
                                onChange={(e) => {
                                  setEntranceDoorOpeningDirection(e.target.value);
                                }}
                                name="entranceDoorOpeningDirection"
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="entranceDoorOpeningDirection"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Not mentioned{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                entranceDoorOpeningDirection === 'inward' &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="inward"
                                name="entranceDoorOpeningDirection"
                                checked={entranceDoorOpeningDirection === 'inward'}
                                onChange={(e) => {
                                  setEntranceDoorOpeningDirection(e.target.value);
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="entranceDoorOpeningDirection"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                Inward{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                entranceDoorOpeningDirection === 'outward' &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="outward"
                                name="entranceDoorOpeningDirection"
                                checked={entranceDoorOpeningDirection === 'outward'}
                                onChange={(e) => {
                                  setEntranceDoorOpeningDirection(e.target.value);
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="entranceDoorOpeningDirection"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                Outward{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="flex flex-col items-center	">
                        <h3 className="text-black pb-4 text-xl font-bold">
                          Picture of the entrance door
                        </h3>
                        <p className="text-s font-small">
                          (<i>optional </i>)
                        </p>
                        <DropFile
                          fileKeyId={entranceDoorFileKeyId}
                          setFileKeyId={setEntranceDoorFileKeyId}
                          setFileName={setEntranceDoorFileName}
                          file={entranceDoorFile}
                          setFile={setEntranceDoorFile}
                          errorFileMessage={errorEntranceFileMessage}
                          setErrorFileMessage={setErrorEntranceFileMessage}
                          deleteFile={deleteEntranceFile}
                          uploadErrorMessage={uploadEntranceDoorPhotoFileError}
                          uploadInProgress={
                            uploadEntranceDoorPhotoFileLoading ? true : false
                          }
                        />
                        {/* // Delete File */}
                        {!uploadEntranceDoorPhotoFileLoading &&
                        entranceDoorFileName &&
                        !errorEntranceFileMessage ? (
                          <div className="pt-6 text-neutral-dark font-bold">
                            <p>Chosen entranceDoorFile:</p>
                            <div className="flex flex-row items-center">
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  entranceDoorFileKeyId
                                }
                                target="_blank"
                                rel="noreferrer"
                                className="underline"
                              >
                                {entranceDoorFileName}
                              </a>
                              <button
                                type="button"
                                onClick={() => deleteEntranceFile(entranceDoorFileKeyId)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="pl-2 w-6 h-6 text-error"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ) : null}
                        {/* // Loader uploadFile */}
                        {uploadEntranceDoorPhotoFileLoading && (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="animate-bounce w-8 h-8 text-primary"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                              />
                            </svg>
                            <p>upload in progress...</p>
                          </>
                        )}
                      </div>
                    </div>
                    <div style={classes.freightEntranceTitle}>
                      <div className="flex my-8 w-full">
                        <p className="self-center w-2/5 block text-gray-700 text-smc">
                          {' '}
                          {/*was freight door before*/}
                          Do you have a specific freight entrance ?{' '}
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] ">
                            <div
                              className={`flex items-center pl-3  ${
                                showFreightDoorSize &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="true"
                                name="freightDoor"
                                checked={showFreightDoorSize === true}
                                onChange={(e) => {
                                  setShowFreightDoorSize(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="freightDoor"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showFreightDoorSize === false &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="false"
                                name="freightDoor"
                                checked={showFreightDoorSize === false}
                                onChange={(e) => {
                                  setShowFreightDoorSize(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="freightDoor"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/*Permits to hide freight blocks*/}
                    <div
                      className={`${
                        showFreightDoorSize === false ? 'hidden' : 'visible'
                      }`}
                      style={classes.freightEntrance}
                    >
                      <h3 className="text-black py-4 text-xl mt-14">
                        {' '}
                        Freight entrance{' '}
                      </h3>
                      <div className="flex my-8 ">
                        <p className="self-center w-2/5 text-gray-700 text-smc ">
                          At the same address ?
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showFreightAddress &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="true"
                                name="sameFreightAddress"
                                checked={showFreightAddress === true}
                                onChange={(e) => {
                                  setShowFreightAddress(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="sameFreightAddress"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showFreightAddress === false &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="false"
                                name="sameFreightAddress"
                                checked={showFreightAddress === false}
                                onChange={(e) => {
                                  setShowFreightAddress(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="sameFreightAddress"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`
                     my-8 w-full ${
                          showFreightAddress === true ? 'hidden' : 'visible'
                        }`}
                      >
                        <ComplementField
                          label="Address"
                          name="freightAddress"
                          type="text"
                          placeholder="123 E Example St"
                        />
                        <ComplementField
                          label="Complement"
                          name="freightComplement"
                          type="text"
                          placeholder="Complement address"
                        />

                        <ComplementField
                          label="City"
                          name="freightCity"
                          type="text"
                          placeholder="City Name"
                        />

                        <ComplementField
                          label="State"
                          name="freightState"
                          type="text"
                          placeholder="State Name"
                        />

                        <ComplementField
                          label="Zip Code"
                          name="freightZipcode"
                          type="text"
                          placeholder="#####"
                        />

                        <ComplementField
                          label="Access information / Parking information"
                          name="accessInformationFreightEntrance"
                          type="text"
                          placeholder="Any information relevant (road, parking, restrictions, areas...)"
                        />
                      </div>

                      <div className={`my-8 w-full`}>
                        <div className="flex my-8 w-full">
                          <p className="self-center w-2/5 block text-gray-700 text-smc">
                            {' '}
                            Freight entrance door size (<i>optional </i>){' '}
                          </p>
                          <div className="flex w-full">
                            <div className="lg:w-1/5 w-2/4 mr-6">
                              <Field
                                type="text"
                                name="freightDoorLenght"
                                placeholder="WW"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Width (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-2/4 lg:mr-6">
                              <Field
                                type="text"
                                name="freightDoorHeight"
                                placeholder="HH"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Height (inches){' '}
                              </small>
                            </div>
                          </div>
                        </div>
                        <ComplementField
                          label="Access code of freight entrance, if several, indicate so"
                          name="accessCodeFreightEntrance"
                          type="text"
                          placeholder="Descriptions & Codes"
                        />

                        <div
                          className={`flex my-8 w-full`}
                        >
                          <p className="self-center w-2/5 block text-gray-700 text-smc">
                            {' '}
                            Freight door opening direction{' '}
                          </p>
                          <ul
                            className="w-full flex-col text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                            <li
                              className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                              <div
                                className={`flex items-center pl-3 ${
                                  freightDoorOpeningDirection === 'notMentioned' &&
                                  'bg-primary-lighter rounded-t-lg'
                                }`}
                              >
                                <Field
                                  type="radio"
                                  value="notMentioned"
                                  checked={freightDoorOpeningDirection === 'notMentioned'}
                                  onChange={(e) => {
                                    setFreightDoorOpeningDirection(e.target.value);
                                  }}
                                  name="freightDoorOpeningDirection"
                                  className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                                />
                                <label
                                  htmlFor="freightDoorOpeningDirection"
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                  {' '}
                                  Not mentioned{' '}
                                </label>
                              </div>
                            </li>
                            <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                              <div
                                className={`flex items-center pl-3  ${
                                  freightDoorOpeningDirection === 'inward' &&
                                  'bg-primary-lighter rounded-b-lg'
                                }`}
                              >
                                <Field
                                  type="radio"
                                  value="inward"
                                  name="freightDoorOpeningDirection"
                                  checked={freightDoorOpeningDirection === 'inward'}
                                  onChange={(e) => {
                                    setFreightDoorOpeningDirection(e.target.value);
                                  }}
                                  className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                                />
                                <label
                                  htmlFor="freightDoorOpeningDirection"
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                                >
                                  {' '}
                                  Inward{' '}
                                </label>
                              </div>
                            </li>
                            <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                              <div
                                className={`flex items-center pl-3  ${
                                  freightDoorOpeningDirection === 'outward' &&
                                  'bg-primary-lighter rounded-b-lg'
                                }`}
                              >
                                <Field
                                  type="radio"
                                  value="outward"
                                  name="freightDoorOpeningDirection"
                                  checked={freightDoorOpeningDirection === 'outward'}
                                  onChange={(e) => {
                                    setFreightDoorOpeningDirection(e.target.value);
                                  }}
                                  className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                                />
                                <label
                                  htmlFor="freightDoorOpeningDirection"
                                  className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                                >
                                  {' '}
                                  Outward{' '}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="flex flex-col items-center	">
                          <h3 className="text-black py-4 text-xl mt-6 font-bold">
                            Picture of the freight door
                          </h3>
                          <p className="text-s font-small">
                            (<i>optional </i>)
                          </p>
                          <DropFile
                            fileKeyId={freightDoorFileKeyId}
                            setFileKeyId={setFreightDoorFileKeyId}
                            setFileName={setFreightDoorFileName}
                            file={freightDoorFile}
                            setFile={setFreightDoorFile}
                            errorFileMessage={errorFreightFileMessage}
                            setErrorFileMessage={setErrorFreightFileMessage}
                            deleteFile={deleteFreightFile}
                            uploadErrorMessage={uploadFreightDoorPhotoFileError}
                            uploadInProgress={
                              uploadFreightDoorPhotoFileLoading ? true : false
                            }
                          />
                          {/* // Delete File */}
                          {!uploadFreightDoorPhotoFileLoading &&
                          freightDoorFileName &&
                          !errorFreightFileMessage ? (
                            <div className="pt-6 text-neutral-dark font-bold">
                              <p>Chosen freightDoorFile:</p>
                              <div className="flex flex-row items-center">
                                <a
                                  href={
                                    'https://' +
                                    process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                    '.s3.' +
                                    process.env.REACT_APP_AWS_REGION +
                                    '.amazonaws.com/' +
                                    freightDoorFileKeyId
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                  className="underline"
                                >
                                  {freightDoorFileName}
                                </a>
                                <button
                                  type="button"
                                  onClick={() => deleteFreightFile(freightDoorFileKeyId)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="pl-2 w-6 h-6 text-error"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          ) : null}
                          {/* // Loader uploadFile */}
                          {uploadFreightDoorPhotoFileLoading && (
                            <>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="animate-bounce w-8 h-8 text-primary"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                />
                              </svg>
                              <p>upload in progress...</p>
                            </>
                          )}
                        </div>

                      </div>
                    </div>
                    <div style={classes.elevatorTitle}>
                      <div className="flex my-8 w-full">
                        <p className="self-center w-2/5 block text-gray-700 text-smc">
                          {' '}
                          {/*was freight door before*/}
                          Do you have an elevator ?{' '}
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF] ">
                            <div
                              className={`flex items-center pl-3  ${
                                showElevator === true &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="true"
                                name="elevator"
                                checked={showElevator === true}
                                onChange={(e) => {
                                  setShowElevator(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="elevator"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showElevator === false &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="false"
                                name="elevator"
                                checked={showElevator === false}
                                onChange={(e) => {
                                  setShowElevator(
                                    e.target.value === ' '
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="elevator"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className={` ${
                        showElevator === true ? 'visible' : 'hidden'
                      }`}
                      style={classes.elevator}
                    >

                      <h3 className="text-black py-4 text-xl">
                        {' '}
                        Elevator{' '}
                      </h3>
                      <div className="flex my-8 ">
                        <p className="self-center w-2/5 text-gray-700 text-smc">
                          Passenger elevator ?
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showPassengerElevatorSize &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="true"
                                name="serviceElevator"
                                checked={showPassengerElevatorSize === true}
                                onChange={(e) => {
                                  setShowPassengerElevatorSize(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="serviceElevator"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showPassengerElevatorSize === false &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="false"
                                name="serviceElevator"
                                checked={showPassengerElevatorSize === false}
                                onChange={(e) => {
                                  setShowPassengerElevatorSize(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="serviceElevator"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={` ${
                          showPassengerElevatorSize === true ? 'visible' : 'hidden'
                        }`}
                      >
                        <div className={`flex my-8 w-full`}>
                          <p className="self-center w-2/5 block text-gray-700 text-smc">
                            {' '}
                            Passenger elevator size{' '}
                          </p>
                          <div className="flex w-full">
                            <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                              <Field
                                type="text"
                                name="passengerElevatorLength"
                                placeholder="LL"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Length (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                              <Field
                                type="text"
                                name="passengerElevatorHeight"
                                placeholder="HH"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Height (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-1/3 lg:mr-6">
                              <Field
                                type="text"
                                name="passengerElevatorWidth"
                                placeholder="WW"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Width (inches){' '}
                              </small>
                            </div>
                          </div>
                        </div>


                        <div className={`flex my-8 w-full`}>

                          <p className="self-center w-2/5 block text-gray-700 text-smc">
                            {' '}
                            Passenger door size (<i>optional </i>){' '}
                          </p>
                          <div className="flex w-full">
                            <div className="lg:w-1/5 w-2/4 lg:mr-6 mr-2">
                              <Field
                                type="text"
                                name="entranceLengthPassengerElevator"
                                placeholder="WW"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Width (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-2/4 lg:mr-6">
                              <Field
                                type="text"
                                name="entranceHeightPassengerElevator"
                                placeholder="HH"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Height (inches){' '}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className="flex my-8 ">
                        <p className="self-center w-2/5 text-gray-700 text-smc">
                          Freight elevator ?
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showFreightElevatorSize &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="true"
                                name="freightElevator"
                                checked={showFreightElevatorSize === true}
                                onChange={(e) => {
                                  setShowFreightElevatorSize(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="freightElevator"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showFreightElevatorSize === false &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="false"
                                name="freightElevator"
                                checked={showFreightElevatorSize === false}
                                onChange={(e) => {
                                  setShowFreightElevatorSize(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="freightElevator"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={` ${
                          showFreightElevatorSize === true ? 'visible' : 'hidden'
                        }`}
                      >
                        <div className={`flex my-8 w-full`}>
                          <p className="self-center w-2/5 block text-gray-700 text-smc">
                            {' '}
                            Freight elevator size{' '}
                          </p>
                          <div className="flex w-full">
                            <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                              <Field
                                type="text"
                                name="freightElevatorLength"
                                placeholder="LL"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Length (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                              <Field
                                type="text"
                                name="freightElevatorHeight"
                                placeholder="HH"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Height (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-1/3">
                              <Field
                                type="text"
                                name="freightElevatorWidth"
                                placeholder="WW"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Width (inches){' '}
                              </small>
                            </div>
                          </div>
                        </div>


                        <div className={`flex my-8 w-full`}>
                          <p className="self-center w-2/5 block text-gray-700 text-smc">
                            {' '}
                            Freight door size (<i>optional </i>){' '}
                          </p>
                          <div className="flex w-full">
                            <div className="lg:w-1/5 w-2/4 lg:mr-6 mr-2">
                              <Field
                                type="text"
                                name="entranceLengthFreightElevator"
                                placeholder="WW"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Width (inches){' '}
                              </small>
                            </div>
                            <div className="lg:w-1/5 w-2/4 lg:mr-6">
                              <Field
                                type="text"
                                name="entranceHeightFreightElevator"
                                placeholder="HH"
                                className="shadow appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300"
                              />
                              <small className="block mt-1 text-xs text-[#6B7280]">
                                {' '}
                                Height (inches){' '}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={classes.buildingManagerTitle}>
                      <div className="flex my-8">
                        <p className="self-center w-2/5 text-gray-700 text-smc">
                          Do you have a Building Manager ?
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showBuildingManager &&
                                'bg-primary-lighter rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="true"
                                name="buildingManager"
                                checked={showBuildingManager === true}
                                onChange={(e) => {
                                  setShowBuildingManager(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="buildingManager"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                Yes{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3  ${
                                showBuildingManager === false &&
                                'bg-primary-lighter rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="false"
                                name="buildingManager"
                                checked={showBuildingManager === false}
                                onChange={(e) => {
                                  setShowBuildingManager(
                                    e.target.value === 'true'
                                  );
                                }}
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="buildingManager"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                No{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>

                    </div>

                    <div
                      style={classes.buildingManager}
                      className={`w-full p-8 rounded-lg  ${
                        showBuildingManager === true ? 'visible' : 'hidden'
                      }`}
                    >
                      <h3 className="text-black text-xl"> Building manager </h3>

                      <div className="mt-10">
                        <div className="flex w-full flex-col ">
                          <div className="flex">
                            <div className="w-2/5 ">Company</div>
                            <div className="w-full">
                              <Field
                                id="bmCompany"
                                name="bmCompany"
                                type="text"
                                placeholder="Building Manager Company Name"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                className="shadow appearance-none border placeholder:text-slate-300 rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                              />

                              {errors?.bmCompany && (
                                <p className="text-red-600 text-center md:text-left">
                                  {' '}
                                  {errors.bmCompany}{' '}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-2/5 ">Firstname</div>
                            <div className="w-full">
                              <Field
                                id="bmFirstName"
                                name="bmFirstName"
                                type="text"
                                placeholder="Building Manager First Name"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                              />

                              {errors?.bmFirstName && (
                                <p className="text-red-600 text-center md:text-left">
                                  {' '}
                                  {errors.bmFirstName}{' '}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-2/5 ">Lastname</div>
                            <div className="w-full">
                              <Field
                                id="bmName"
                                name="bmName"
                                type="text"
                                placeholder="Building Manager Last Name"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                              />

                              {errors?.bmName && (
                                <p className="text-red-600 text-center md:text-left">
                                  {' '}
                                  {errors.bmName}{' '}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-2/5 ">Email</div>
                            <div className="w-full">
                              <Field
                                id="bmEmail"
                                name="bmEmail"
                                type="email"
                                placeholder="buildingmanager@example.com"
                                onChange={(e) => {
                                  handleChange(e);
                                }}
                                className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                              />

                              {errors?.bmEmail && (
                                <p className="text-red-600 text-center md:text-left">
                                  {' '}
                                  {errors.bmEmail}{' '}
                                </p>
                              )}
                            </div>
                          </div>
                          <div className="flex mt-4">
                            <div className="w-2/5 ">Phone</div>
                            <div className="w-full">
                              <Field
                                id="bmPhone"
                                name="bmPhone"
                                type="text"
                                placeholder="#####"
                                className="shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  {/* Disabled btn until loading to stop spam request */}
                  {updateUserLoading && (
                    <button
                      disabled={true}
                      type="submit"
                      className="bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-10 text-white text-lg hover:scale-105 ease-out duration-300"
                    >
                      <CircularProgress style={{color: 'white'}} size={20}/>
                    </button>
                  )}
                  {!updateUserLoading && !successMessage && (
                    <button
                      type="submit"
                      className="bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-5 text-white text-lg hover:scale-105 ease-out duration-300"
                      onChange={updateComplement}
                    >
                      {' '}
                      Save changes{' '}
                    </button>
                  )}
                </div>
                {/* success*/}
                {successMessage && (
                  <p
                    className="text-green-700 text-center text-white rounded-lg bg-green-100 mt-4 md:w-3/4 lg:w-2/6 mx-auto">
                    {successMessage}
                  </p>
                )}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ComplementCustomer;
