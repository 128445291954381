const getStyles = () => ({
  deliveryAddress: {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    paddingTop: "20px",
  },
  freightEntranceTitle: {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  freightBlock: {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    paddingTop: "20px",
  },
  elevatorTitle: {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  elevatorBlock: {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    paddingTop: "20px",
  },
  buildingManagerTitle: {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  buildingManagerBlock : {
    background: "#fff3d7",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    paddingTop: "20px",
  }
});

export default getStyles;