import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ComplementField from '../../ComplementField';
import { useDispatch, useSelector } from 'react-redux';
import checkCurrentRole from '../../../../utils/checkCurrentRole';
import { CircularProgress } from '@mui/material';
import {
  createBuildingLauched,
  resetCreateBuilding,
} from '../../../../service/reducer';
import { useStyles } from "react-styles-hook";
import getStyles from "../style";
import DropFile from "../../../Events/DropFile";
import { deleteFileS3 } from "../../../../utils/awsLib";

const CreateBuildingForm = ({buildingFileKeyId}) => {
  const dispatch = useDispatch();
  const classes = useStyles(getStyles());

  // reset success message //
  useEffect(() => {
    dispatch(resetCreateBuilding());
    setConfirmationMessage(null);
  }, []);

  const {
    userDynamo,
    createBuildingProgress,
    createBuildingErrorMessage,
    createBuildingSuccessMessage,
    uploadCOIDocumentFilePhotoFileError,
    uploadBuildingDocumentFilePhotoFileError,
    uploadCOIDocumentFilePhotoFileLoading,
    uploadBuildingDocumentFilePhotoFileLoading,
  } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
    createBuildingProgress: state.getIn(['app', 'createBuildingLoading']),
    createBuildingErrorMessage: state.getIn([
      'app',
      'createBuildingErrorMessage',
    ]),
    createBuildingSuccessMessage: state.getIn([
      'app',
      'createBuildingSuccessMessage',
    ]),
    uploadCOIDocumentFilePhotoFileError: state.getIn(['app', 'uploadCOIDocumentFilePhotoFileError']),
    uploadBuildingDocumentFilePhotoFileError: state.getIn(['app', 'uploadBuildingDocumentFilePhotoFileError']),
    uploadCOIDocumentFilePhotoFileLoading: state.getIn([
      'app',
      'uploadCOIDocumentFilePhotoFileLoading',
    ]),
    uploadBuildingDocumentFilePhotoFileLoading: state.getIn([
      'app',
      'uploadBuildingDocumentFilePhotoFileLoading',
    ]),
  }));

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  const [confirmationMessage, setConfirmationMessage] = useState(null);

  // COIDocument and buildingDocument
  const [COIDocumentFile, setCOIDocumentFile] = useState(null);
  const [buildingDocumentFile, setBuildingDocumentFile] = useState(null);
  const [errorCOIDocumentFileMessage, setErrorCOIDocumentFileMessage] = useState(null);
  const [errorBuildingDocumentFileMessage, setErrorBuildingDocumentFileMessage] = useState(null);

  // deleteFile
  const deleteCOIDocumentFile = (COIDocumentFileKeyId) => {
    if (COIDocumentFileKeyId) {
      setErrorCOIDocumentFileMessage(null);
      setCOIDocumentFileKeyId(null);
      setCOIDocumentFile(null);
      setCOIDocumentFileName(null);
      deleteFileS3(COIDocumentFileKeyId);
    }
  };
  const deleteBuildingDocumentFile = (buildingDocumentFileKeyId) => {
    if (buildingDocumentFileKeyId) {
      setErrorBuildingDocumentFileMessage(null);
      setBuildingDocumentFileKeyId(null);
      setBuildingDocumentFile(null);
      setBuildingDocumentFileName(null);
      deleteFileS3(buildingDocumentFileKeyId);
    }
  };

  // set success messsage //
  useEffect(() => {
    if (!createBuildingProgress && createBuildingSuccessMessage !== null) {
      setConfirmationMessage('Building create with success');
    } else if (!createBuildingProgress && createBuildingErrorMessage !== null) {
      setConfirmationMessage('An error has occurred, please try again later.');
    } else {
      setConfirmationMessage(null);
    }
  }, [createBuildingSuccessMessage, createBuildingErrorMessage]);

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);
  let currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  const userInfo = currUserInfo[currentRole];

  const [typeOfResidence, setTypeOfResidence] = useState('RENTAL');
  const [specificFreightDoor, setSpecificFreightDoor] = useState(false);
  const [sameFreightAddress, setSameFreightAddress] = useState(false);
  const [haveFreightElevator, setHaveFreightElevator] = useState(false);
  const [bookElevator, setBookElevator] = useState(false);
  const [haveDoorman, setHaveDoorman] = useState(false);
  const [haveVirtualDoorman, setHaveVirtualDoorman] = useState(false);

  const [COIDocumentFileKeyId, setCOIDocumentFileKeyId] = useState(); // no UseState Because we are creating for first time
  const [buildingDocumentFileKeyId, setBuildingDocumentFileKeyId] = useState(); // no UseState Because we are creating for first time
  const [COIDocumentFileName, setCOIDocumentFileName] = useState(); // no UseState Because we are creating for first time
  const [buildingDocumentFileName, setBuildingDocumentFileName] = useState(); // no UseState Because we are creating for first time
  const createBuilding = (formik) => {
    formik.freightElevator = haveFreightElevator;
    formik.typeOfResidence = typeOfResidence;
    formik.specificFreightDoor = specificFreightDoor;
    formik.sameFreightAddress = sameFreightAddress;
    formik.needToBookElevator = bookElevator;
    formik.doorMan = haveDoorman;
    formik.virtualDoorMan = haveVirtualDoorman;
    if (buildingFileKeyId) {
      formik.pictureFileKey = buildingFileKeyId;
    }
    if (COIDocumentFileKeyId) {
      formik.COIDocumentFileKeyId = COIDocumentFileKeyId;
    }
    if (buildingDocumentFileKeyId) {
      formik.buildingDocumentFileKeyId = buildingDocumentFileKeyId;
    }
    if (COIDocumentFileName) {
      formik.COIDocumentFileName = COIDocumentFileName;
    }
    if (buildingDocumentFileName) {
      formik.buildingDocumentFileName = buildingDocumentFileName;
    }
    dispatch(createBuildingLauched(formik));
  };

  const isNumber = new RegExp('^[0-9]*$');

  return (
    <div>
      <Formik
        initialValues={{
          buildingName: '',
          address: '',
          complement: '',
          addressState: '',
          city: '',
          zipcode: '',
          EINNumber: '',
          typeOfResidence: '',
          doorSizeLenght: '',
          doorSizeHeight: '',
          elevatorLenght: '',
          elevatorHeight: '',
          elevatorWidth: '',
          specificFreightDoor: '',
          specificFreightDoorLenght: '',
          specificFreightDoorHeight: '',
          sameFreightAddress: '',
          freightAddress: '',
          freightComplement: '',
          freightState: '',
          freightCity: '',
          freightZipcode: '',
          freightOpeningHourStart: '',
          freightOpeningHourEnd: '',
          freightElevator: '',
          freightElevatorLenght: '',
          freightElevatorHeight: '',
          freightElevatorWidth: '',
          needToBookElevator: '',
          contactLastName: '',
          contactFirstName: '',
          contactPhone: '',
          contactEmail: '',
          doorMan: '',
          virtualDoorMan: '',
          accessInformation: '',
          COIRequirement: '',
          pictureFileKey: '',
          COIDocumentFileKeyId: '',
          COIDocumentFileName: '',
          buildingDocumentFileKeyId: '',
          buildingDocumentFileName: '',
          role: currentRole,
        }}
        validate={(values) => {
          /* Set all errors message*/
          const errors = {};
          if (values?.buildingName === '') {
            errors.buildingName = 'Building Name is required';
          }
          if (values?.address === '') {
            errors.address = 'Address is required';
          }
          if (values?.addressState === '') {
            errors.addressState = 'State is required';
          }
          if (values?.city === '') {
            errors.city = 'City is required';
          }
          if (values?.zipcode === '') {
            errors.zipcode = 'Zipcode is required';
          }
          if (!isNumber.test(values?.doorSizeLenght)) {
            errors.doorSizeLenght = 'Number only';
          }
          if (!isNumber.test(values?.doorSizeHeight)) {
            errors.doorSizeHeight = 'Number only';
          }
          if (!isNumber.test(values?.elevatorLenght)) {
            errors.elevatorLenght = 'Number only';
          }
          if (!isNumber.test(values?.elevatorHeight)) {
            errors.elevatorHeight = 'Number only';
          }
          if (!isNumber.test(values?.elevatorWidth)) {
            errors.elevatorWidth = 'Number only';
          }
          if (!isNumber.test(values?.specificFreightDoorLenght)) {
            errors.specificFreightDoorLenght = 'Number only';
          }
          if (!isNumber.test(values?.specificFreightDoorHeight)) {
            errors.specificFreightDoorHeight = 'Number only';
          }
          if (!isNumber.test(values?.freightElevatorLenght)) {
            errors.freightElevatorLenght = 'Number only';
          }
          if (!isNumber.test(values?.freightElevatorHeight)) {
            errors.freightElevatorHeight = 'Number only';
          }
          if (!isNumber.test(values?.freightElevatorWidth)) {
            errors.freightElevatorWidth = 'Number only';
          }
          return errors;
        }}
        onSubmit={(formik) => {
          createBuilding(formik);
        }}
      >
        {({errors}) => (
          <Form>
            <div className="flex-col">
              <div className="w-full">
                <div style={classes.newBuilding}>

                  <h3 className="text-black pt-4 pb-2 text-xl">
                    New Building - General{' '}
                  </h3>
                  <ComplementField
                    label="Building name*"
                    name="buildingName"
                    type="text"
                    placeholder="Building Name"
                  />
                  <ComplementField
                    label="Address*"
                    name="address"
                    type="text"
                    placeholder="123 E Example St"
                  />
                  <ComplementField
                    label="Complement"
                    name="complement"
                    type="text"
                    placeholder="Unit, Apt, Suite..."
                  />
                  <ComplementField
                    label="City*"
                    name="city"
                    type="text"
                    placeholder="City Name"
                  />
                  <ComplementField
                    label="State*"
                    name="addressState"
                    type="text"
                    placeholder="State Name"
                  />
                  <ComplementField
                    label="Zip Code*"
                    name="zipcode"
                    type="text"
                    placeholder="#####"
                  />
                  <ComplementField
                    label="EIN Number"
                    name="EINNumber"
                    type="text"
                    placeholder="12-3456789"
                  />
                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Type of residence{' '}
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            typeOfResidence === 'RENTAL' &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="RENTAL"
                            name="typeOfResidence"
                            checked={typeOfResidence === 'RENTAL'}
                            onChange={(e) => {
                              setTypeOfResidence(e.target.value);
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="typeOfResidence"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Rental{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            typeOfResidence === 'CONDO' && 'bg-primary-lighter '
                          }`}
                        >
                          <Field
                            type="radio"
                            value="CONDO"
                            name="typeOfResidence"
                            checked={typeOfResidence === 'CONDO'}
                            onChange={(e) => {
                              setTypeOfResidence(e.target.value);
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="typeOfResidence"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            Condo{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            typeOfResidence === 'COOP' && 'bg-primary-lighter'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="COOP"
                            name="typeOfResidence"
                            checked={typeOfResidence === 'COOP'}
                            onChange={(e) => {
                              setTypeOfResidence(e.target.value);
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="typeOfResidence"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            Coop{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            typeOfResidence === 'OTHER' &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="OTHER"
                            name="typeOfResidence"
                            checked={typeOfResidence === 'OTHER'}
                            onChange={(e) => {
                              setTypeOfResidence(e.target.value);
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="typeOfResidence"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            Other{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div style={classes.particularity}>

                  <h3 className="text-black py-4 text-xl"> Particularity </h3>
                  <div className="flex flex-col sm:flex-row my-8 w-full">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Door size{' '}
                    </p>
                    <div className="flex w-full space-x-2">
                      <div className="lg:w-2/5 w-2/4">
                        <Field
                          type="text"
                          name="doorSizeLenght"
                          placeholder="WW"
                          className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="doorSizeLenght"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Width (inches){' '}
                        </small>
                      </div>
                      <div className="lg:w-2/5 w-2/4">
                        <Field
                          type="text"
                          name="doorSizeHeight"
                          placeholder="HH"
                          className="shadow  placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="doorSizeHeight"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Height (inches){' '}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row my-8 w-full">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Elevator size{' '}
                    </p>
                    <div className="flex w-full space-x-2">
                      <div className="lg:w-2/5 w-2/4">
                        <Field
                          type="text"
                          name="elevatorLenght"
                          placeholder="LL"
                          className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="elevatorLenght"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Length (inches){' '}
                        </small>
                      </div>
                      <div className="lg:w-2/5 w-2/4">
                        <Field
                          type="text"
                          name="elevatorHeight"
                          placeholder="HH"
                          className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="elevatorHeight"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Height (inches){' '}
                        </small>
                      </div>
                      <div className="lg:w-2/5 w-2/4">
                        <Field
                          type="text"
                          name="elevatorWidth"
                          placeholder="WW"
                          className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="elevatorWidth"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Width (inches){' '}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Do you have a freight entrance ?{' '}
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            specificFreightDoor === true &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="true"
                            name="specificFreightDoor"
                            checked={specificFreightDoor === true}
                            onChange={(e) => {
                              setSpecificFreightDoor(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="specificFreightDoor"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Yes{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            specificFreightDoor === false &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="false"
                            name="specificFreightDoor"
                            checked={specificFreightDoor === false}
                            onChange={(e) => {
                              setSpecificFreightDoor(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="specificFreightDoor"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            No{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={`flex flex-col sm:flex-row my-8 w-full ${
                      specificFreightDoor === true ? 'visible' : 'hidden'
                    }`}
                  >
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Freight entrance size{' '}
                    </p>
                    <div className="flex w-full">
                      <div className="lg:w-1/5 w-2/4 mr-6">
                        <Field
                          type="text"
                          placeholder="WW"
                          name="specificFreightDoorLenght"
                          className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="specificFreightDoorLenght"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Width (inches){' '}
                        </small>
                      </div>
                      <div className="lg:w-1/5 w-2/4 lg:mr-6">
                        <Field
                          type="text"
                          name="specificFreightDoorHeight"
                          placeholder="HH"
                          className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="specificFreightDoorHeight"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Height (inches){' '}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Same address as the main entrance ?{' '}
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            sameFreightAddress === true &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="true"
                            name="sameFreightAddress"
                            checked={sameFreightAddress === true}
                            onChange={(e) => {
                              setSameFreightAddress(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="sameFreightAddress"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Yes{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            sameFreightAddress === false &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="false"
                            name="sameFreightAddress"
                            checked={sameFreightAddress === false}
                            onChange={(e) => {
                              setSameFreightAddress(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="sameFreightAddress"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            No{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={`my-4 w-full ${
                    sameFreightAddress !== true ? 'visible' : 'hidden'
                  }`}
                  style={classes.freightEntranceInformations}
                >
                  <h3 className="text-black py-4 text-xl mt-4">
                    {' '}
                    Freight entrance informations{' '}
                  </h3>
                  <ComplementField
                    label="Address"
                    name="freightAddress"
                    type="text"
                    placeholder="123 E Example St"
                  />
                  <ComplementField
                    label="Complement"
                    name="freightComplement"
                    type="text"
                    placeholder="Unit, Apt, Suite..."
                  />
                  <ComplementField
                    label="City"
                    name="freightCity"
                    type="text"
                    placeholder="City Name"
                  />
                  <ComplementField
                    label="State"
                    name="freightState"
                    type="text"
                    placeholder="State Name"
                  />
                  <ComplementField
                    label="ZipCode"
                    name="freightZipcode"
                    type="text"
                    placeholder="#####"
                  />
                </div>

                <div style={classes.otherInformations}>

                  <h3 className="text-black pt-4 pb-2 text-xl">
                    Other informations{' '}
                  </h3>


                  <div className="flex flex-col sm:flex-row my-8 w-full">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Opening hour{' '}
                    </p>
                    <div className="flex w-full">
                      <div className="flex lg:w-full w-2/4 mx-2">
                        <p className="w-full flex items-center"> From : </p>
                        <Field
                          type="text"
                          name="freightOpeningHourStart"
                          placeholder="hh:mm"
                          className="shadowplaceholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                      <div className="flex lg:w-full w-2/4 mx-2">
                        <p className="w-full flex items-center"> To : </p>
                        <Field
                          type="text"
                          name="freightOpeningHourEnd"
                          placeholder="hh:mm"
                          className="shadow  placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Freight elevator ?{' '}
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            haveFreightElevator === true &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="true"
                            name="freightElevator"
                            checked={haveFreightElevator === true}
                            onChange={(e) => {
                              setHaveFreightElevator(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="freightElevator"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Yes{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            haveFreightElevator === false &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="false"
                            name="freightElevator"
                            checked={haveFreightElevator === false}
                            onChange={(e) => {
                              setHaveFreightElevator(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="freightElevator"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            No{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={`flex flex-col sm:flex-row my-8 w-full ${
                      haveFreightElevator === true ? 'visible' : 'hidden'
                    }`}
                  >
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Freight elevator size{' '}
                    </p>
                    <div className="flex w-full">
                      <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                        <Field
                          type="text"
                          name="freightElevatorLenght"
                          placeholder="LL"
                          className="shadow  placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="freightElevatorLenght"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Length (inches){' '}
                        </small>
                      </div>
                      <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                        <Field
                          type="text"
                          name="freightElevatorHeight"
                          placeholder="HH"
                          className="shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="freightElevatorHeight"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Height (inches){' '}
                        </small>
                      </div>
                      <div className="lg:w-1/5 w-1/3">
                        <Field
                          type="text"
                          name="freightElevatorWidth"
                          placeholder="WW"
                          className="shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                        <ErrorMessage
                          component="div"
                          name="freightElevatorWidth"
                          className="text-red-500"
                        />
                        <small className="block mt-1 text-xs text-[#6B7280]">
                          {' '}
                          Width (inches){' '}
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 text-gray-700 text-smc">
                      Need to book elevator ?
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            bookElevator === true &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="true"
                            name="needToBookElevator"
                            checked={bookElevator === true}
                            onChange={(e) => {
                              setBookElevator(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="bookElevator"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Yes{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            bookElevator === false &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="false"
                            name="bookElevator"
                            checked={bookElevator === false}
                            onChange={(e) => {
                              setBookElevator(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="freightElevator"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            No{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className={` ${bookElevator === true ? 'visible' : 'hidden'}`}
                  >
                    <ComplementField
                      label="Contact First Name"
                      name="contactFirstName"
                      type="text"
                      placeholder="Contact First Name"
                    />
                    <ComplementField
                      label="Contact Last Name"
                      name="contactLastName"
                      type="text"
                      placeholder="Contact Last Name"
                    />
                    <ComplementField
                      label="Contact email"
                      name="contactEmail"
                      type="text"
                      placeholder="johndoe@example.com"
                    />
                    <ComplementField
                      label="Contact phone"
                      name="contactPhone"
                      type="text"
                      placeholder="##########"
                    />
                  </div>
                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Do you have a doorman ?{' '}
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            haveDoorman === true &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="true"
                            name="doorMan"
                            checked={haveDoorman === true}
                            onChange={(e) => {
                              setHaveDoorman(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="Doorman"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Yes{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            haveDoorman === false &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="false"
                            name="Doorman"
                            checked={haveDoorman === false}
                            onChange={(e) => {
                              setHaveDoorman(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="Doorman"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            No{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="flex flex-col sm:flex-row my-8 ">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Do you have a virtual doorman ?{' '}
                    </p>
                    <ul
                      className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            haveVirtualDoorman === true &&
                            'bg-primary-lighter rounded-t-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="true"
                            name="virtualDoorMan"
                            checked={haveVirtualDoorman === true}
                            onChange={(e) => {
                              setHaveVirtualDoorman(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="virtualDoorMan"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                          >
                            {' '}
                            Yes{' '}
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                        <div
                          className={`flex items-center pl-3 ${
                            haveVirtualDoorman === false &&
                            'bg-primary-lighter rounded-b-lg'
                          }`}
                        >
                          <Field
                            type="radio"
                            value="false"
                            name="VirtualDoorman"
                            checked={haveVirtualDoorman === false}
                            onChange={(e) => {
                              setHaveVirtualDoorman(e.target.value === 'true');
                            }}
                            className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                          />
                          <label
                            htmlFor="virtualDoorMan"
                            className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                          >
                            {' '}
                            No{' '}
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>

                  {/* CREATE NEW BUILDING*/}

                  <div className="flex flex-col sm:flex-row my-8 w-full">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      COI requirements{' '}
                    </p>
                    <div className="flex w-full">
                      <Field
                        as="textarea"
                        type="text"
                        name="COIRequirement"
                        rows="7"
                        placeholder="COI requested details"
                        className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></Field>
                    </div>
                  </div>
                  <div className="flex flex-col items-center	">
                    <h3 className="text-black pb-4 text-xl font-bold">
                      COI documents / Access Informations
                    </h3>
                    <p className="text-s font-small">
                      (<i>optional </i>)
                    </p>
                    <DropFile
                      fileKeyId={COIDocumentFileKeyId}
                      setFileKeyId={setCOIDocumentFileKeyId}
                      setFileName={setCOIDocumentFileName}
                      file={COIDocumentFile}
                      setFile={setCOIDocumentFile}
                      errorFileMessage={errorCOIDocumentFileMessage}
                      setErrorFileMessage={setErrorCOIDocumentFileMessage}
                      deleteFile={deleteCOIDocumentFile}
                      uploadErrorMessage={uploadCOIDocumentFilePhotoFileError}
                      uploadInProgress={
                        uploadCOIDocumentFilePhotoFileLoading ? true : false
                      }
                    />
                    {/* // Delete File */}
                    {!uploadCOIDocumentFilePhotoFileLoading &&
                    COIDocumentFileName &&
                    !errorCOIDocumentFileMessage ? (
                      <div className="pt-6 text-neutral-dark font-bold">
                        <p>Chosen COIDocumentFile:</p>
                        <div className="flex flex-row items-center">
                          <a
                            href={
                              'https://' +
                              process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                              '.s3.' +
                              process.env.REACT_APP_AWS_REGION +
                              '.amazonaws.com/' +
                              COIDocumentFileKeyId
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                          >
                            {COIDocumentFileName}
                          </a>
                          <button
                            type="button"
                            onClick={() => deleteCOIDocumentFile(COIDocumentFileKeyId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="pl-2 w-6 h-6 text-error"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {/* // Loader uploadFile */}
                    {uploadCOIDocumentFilePhotoFileLoading && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="animate-bounce w-8 h-8 text-primary"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>
                        <p>upload in progress...</p>
                      </>
                    )}
                  </div>
                  <div className="flex flex-col items-center	">
                    <h3 className="text-black py-4 text-xl mt-6 font-bold">
                      Building document, photo, floor, plan...
                    </h3>
                    <p className="text-s font-small">
                      (<i>optional </i>)
                    </p>
                    <DropFile
                      fileKeyId={buildingDocumentFileKeyId}
                      setFileKeyId={setBuildingDocumentFileKeyId}
                      setFileName={setBuildingDocumentFileName}
                      file={buildingDocumentFile}
                      setFile={setBuildingDocumentFile}
                      errorFileMessage={errorBuildingDocumentFileMessage}
                      setErrorFileMessage={setErrorBuildingDocumentFileMessage}
                      deleteFile={deleteBuildingDocumentFile}
                      uploadErrorMessage={uploadBuildingDocumentFilePhotoFileError}
                      uploadInProgress={
                        uploadBuildingDocumentFilePhotoFileLoading ? true : false
                      }
                    />
                    {/* // Delete File */}
                    {!uploadBuildingDocumentFilePhotoFileLoading &&
                    buildingDocumentFileName &&
                    !errorBuildingDocumentFileMessage ? (
                      <div className="pt-6 text-neutral-dark font-bold">
                        <p>Chosen buildingDocumentFile:</p>
                        <div className="flex flex-row items-center">
                          <a
                            href={
                              'https://' +
                              process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                              '.s3.' +
                              process.env.REACT_APP_AWS_REGION +
                              '.amazonaws.com/' +
                              buildingDocumentFileKeyId
                            }
                            target="_blank"
                            rel="noreferrer"
                            className="underline"
                          >
                            {buildingDocumentFileName}
                          </a>
                          <button
                            type="button"
                            onClick={() => deleteBuildingDocumentFile(buildingDocumentFileKeyId)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="pl-2 w-6 h-6 text-error"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {/* // Loader uploadFile */}
                    {uploadBuildingDocumentFilePhotoFileLoading && (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="animate-bounce w-8 h-8 text-primary"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                          />
                        </svg>
                        <p>upload in progress...</p>
                      </>
                    )}
                  </div>

                  <div className="flex flex-col sm:flex-row my-8 w-full">
                    <p className="self-start sm:self-center w-full sm:w-2/5 block text-gray-700 text-smc">
                      {' '}
                      Access informations / Parking informations{' '}
                    </p>
                    <div className="flex w-full">
                      <Field
                        as="textarea"
                        type="text"
                        name="accessInformation"
                        rows="7"
                        placeholder="Any information relevant (road, parking, restrictions, areas...)"
                        className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      ></Field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center space-y-1">
              {errors.buildingName && (
                <p className="text-red-600 text-center">
                  {' '}
                  {errors.buildingName}
                </p>
              )}
              {errors.address && (
                <p className="text-red-600 text-center"> {errors.address}</p>
              )}
              {errors.addressState && (
                <p className="text-red-600 text-center">
                  {' '}
                  {errors.addressState}
                </p>
              )}
              {errors.city && (
                <p className="text-red-600 text-center"> {errors.city}</p>
              )}
              {errors.zipcode && (
                <p className="text-red-600 text-center"> {errors.zipcode}</p>
              )}
              {(errors.doorSizeLenght || errors.doorSizeHeight) && (
                <p className="text-red-600 text-center">
                  {' '}
                  {'Door size : number only'}
                </p>
              )}
              {(errors.elevatorLenght ||
                errors.elevatorHeight ||
                errors.elevatorWidth) && (
                <p className="text-red-600 text-center">
                  {' '}
                  {'Elevator size : number only'}
                </p>
              )}
              {(errors.specificFreightDoorLenght ||
                errors.specificFreightDoorHeight) && (
                <p className="text-red-600 text-center">
                  {' '}
                  {'Entrance Door size : number only'}
                </p>
              )}
              {(errors.freightElevatorLenght ||
                errors.freightElevatorHeight ||
                errors.freightElevatorWidth) && (
                <p className="text-red-600 text-center">
                  {' '}
                  {'Freight Elevator size : number only'}
                </p>
              )}
              {!createBuildingSuccessMessage && (
                <button
                  type="submit"
                  disabled={
                    createBuildingProgress || Object.keys(errors).length !== 0
                  }
                  className={`${
                    createBuildingProgress || Object.keys(errors).length !== 0
                      ? 'bg-neutral-dark'
                      : 'bg-bg-button'
                  } rounded-lg group text-center mx-auto w-full lg:w-96 px-16 py-4 text-white text-lg hover:scale-105 ease-out duration-300`}
                >
                  {createBuildingProgress ? (
                    <CircularProgress size={20} style={{color: 'white'}}/>
                  ) : null}
                  <p>
                    {createBuildingProgress
                      ? 'In Progress'
                      : 'Create a building'}{' '}
                  </p>
                </button>
              )}

              {confirmationMessage && (
                <p
                  className={`${
                    createBuildingSuccessMessage ? 'text-primary' : 'text-error'
                  } text-center  mt-4 md:w-3/4 lg:w-2/6 mx-auto`}
                >
                  {confirmationMessage}
                </p>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateBuildingForm;
