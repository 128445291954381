import React from "react";
import { useSelector } from "react-redux";
import { Field, Form } from "formik";
import Box from "@mui/material/Box";
import { CustomButton } from "../Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

const SubmitForm = (props) => {
  const {
    submitPasswordErrorMessage,
    submitPasswordSuccessMessage,
    submitPasswordLoading,
  } = useSelector((state) => ({
    submitPasswordErrorMessage: state.getIn([
      "app",
      "submitPasswordErrorMessage",
    ]),
    submitPasswordLoading: state.getIn(["app", "submitPasswordLoading"]),
    submitPasswordSuccessMessage: state.getIn([
      "app",
      "submitPasswordSuccessMessage",
    ]),
  }));
  const { values, errors, onSubmit } = props;
  const { code, password, confirmPassword } = values;

  return (
    <Form className="container flex flex-col justify-center items-center">
      {/* verification code section */}
      <div className="px-4 flex flex-col justify-center items-center">
        <div className="flex items-center mb-4 py-5 justify-center items-center">
          <Grid container columns={{ xs: 4, md: 12 }}>
            <Grid item xs={4}>
              <div className="mr-6 block middle">
                <label className="block text-xxms font-medium text-gray-700 mb-4">
                  {"Code"}
                </label>
                <Field
                  id="code"
                  name="code"
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
                />
                {errors.code ? (
                  <p className="text-red-500 text-center md:text-left">
                    {errors.code}
                  </p>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="mr-6 block middle">
                <label className="block text-xxms font-medium text-gray-700 mb-4">
                  {"Password"}
                </label>
                <Field
                  id="password"
                  name="password"
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
                />
                {errors.password ? (
                  <p className="text-red-500 text-center md:text-left">
                    {errors.password}
                  </p>
                ) : null}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className="mr-6 block middle">
                <label className="block text-xxms font-medium text-gray-700 mb-4">
                  {"Confirm password"}
                </label>
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
                />
                {errors.confirmPassword ? (
                  <p className="text-red-500 text-center md:text-left">
                    {errors.confirmPassword}
                  </p>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {submitPasswordLoading && (
        <CustomButton
          type="submit"
          onclick={() => onSubmit}
          title={"Save password"}
        >
          {" "}
          <CircularProgress
            size={20}
            style={{ color: "white" }}
            className="mx-auto"
          />
        </CustomButton>
      )}
      {!submitPasswordLoading && (
        <Box>
          <CustomButton
            type="submit"
            onclick={() => onSubmit}
            title={"Save password"}
          />
          <div className="mt-3 flex justify-center">
            <Link to="/loginas">
              <button
                type="button"
                className="w-full md:w-52 text-[#1a5dda] px-3 py-1.5 border border-[#1a5dda] rounded-md shadow-sm text-xxms font-medium hover:scale-105 hover:font-bold focus:outline-none focus:ring-0 focus:ring-offset-0"
              >
                Cancel
              </button>
            </Link>
          </div>
        </Box>
      )}
      <p className="pt-4 text-red-500 text-sm text-center">
        {submitPasswordErrorMessage}
      </p>
      <style>
        {`
    middle {
      justify-content: center;
    }
  `}
      </style>
    </Form>
  );
};

export default SubmitForm;
