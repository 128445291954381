import React, { useEffect, useRef, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteIcon from '@mui/icons-material/Delete';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import {
  dateFormatted,
  formatForAPI,
  formatTime,
  formatTimePlus30Minutes,
} from '../../../utils/formatEventDate';
import { useInView } from 'react-intersection-observer';

function CustomDatePicker({
  currentDateId,
  userChoosenDate,
  setUserChoosenDate,
  userChoosenDateToUpdate,
  setUserChoosenDateToUpdate,
  currentChoosenDay,
  currentChoosenHour,
  deleteForbidden,
  setDeleteForbidden,
  previousSelectedDate,
}) {
  const deleteCurrentChosenDate = () => {
    // at create event
    if (userChoosenDate) {
      const newArray = userChoosenDate.filter(
        (date) => date.id !== currentDateId
      );
      setUserChoosenDate(newArray);
    } else if (userChoosenDateToUpdate) {
      // at update event
      if (
        previousSelectedDate !== undefined &&
        previousSelectedDate?.length !== 0 &&
        userChoosenDateToUpdate.length === 1
      ) {
        setDeleteForbidden(true);
      } else if (
        previousSelectedDate === undefined ||
        userChoosenDateToUpdate.length !== 1
      ) {
        setDeleteForbidden(false);
        const newArray = userChoosenDateToUpdate.filter(
          (date) => date.id !== currentDateId
        );
        setUserChoosenDateToUpdate(newArray);
      } else {
        setDeleteForbidden(true);
      }
    }
  };
  // Default date displayed is actual day, so we have to force it in initial values
  const [date, setDate] = useState(() => {
    if (currentChoosenDay) {
      return currentChoosenDay;
    } else {
      return dateFormatted(dayjs());
    }
  });
  const [startTime, setStartTime] = useState(() => {
    if (currentChoosenHour) {
      return formatForAPI(currentChoosenHour);
    } else return '';
  });
  const [endTime, setEndTime] = useState(''); // TODO !!! avec currentchoosendEndHour
  const [dateToUpdate, setDateToUpdate] = useState(() => {
    if (currentChoosenDay) {
      return currentChoosenDay;
    } else return null;
  });

  const [startTimeToUpdate, setStartTimeToUpdate] = useState(() => {
    if (currentChoosenHour) {
      return currentChoosenHour;
    } else return null;
  });

  const info = { startTime, endTime, date };

  // update current chosen date
  useEffect(() => {
    if (dateToUpdate && startTimeToUpdate) {
      // Si les 2 n'ont pas encore été modifiés
      return;
    }
    let choosenDateUpdate;
    if (
      !(dateToUpdate && startTimeToUpdate) &&
      info.date &&
      info.startTime &&
      (info.endTime === '' || info.endTime !== null)
    ) {
      /* && info.endTime */
      choosenDateUpdate = {
        id: currentDateId,
        day: date,
        timeSlot: formatForAPI(startTime),
      };
    }
    if (choosenDateUpdate && userChoosenDate) {
      const newArray = userChoosenDate.filter(
        (date) => date.id !== currentDateId
      );
      newArray.push(choosenDateUpdate);
      setUserChoosenDate(newArray);
    } else if (choosenDateUpdate && userChoosenDateToUpdate) {
      const newArray = userChoosenDateToUpdate.map((obj) => {
        if (obj.id === choosenDateUpdate.id) {
          return {
            ...obj,
            day: choosenDateUpdate.day,
            timeSlot: formatForAPI(choosenDateUpdate.timeSlot),
          };
        }
        return obj;
      });
      setUserChoosenDateToUpdate(newArray);
    }
  }, [date, startTime]);

  const resetForbidden = () => {
    if (deleteForbidden) {
      setDeleteForbidden(false);
    } else return;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 350,
        width: 150,
      },
    },
  };

  // const menuRef = useRef(null);
  const scrollToRef = useRef();
  const { ref, inView } = useInView();
  const [shouldScroll, setShouldScroll] = useState(true);

  useEffect(() => {
    if (inView && shouldScroll) {
      scrollToRef.current.scrollIntoView();
      setShouldScroll(false);
    }
  }, [inView, shouldScroll]);

  function resetScroll() {
    setShouldScroll(true);
  }

  return (
    <>
      <div className='py-5 rounded rounded-lg flex flex-col'>
        <div className='flex flex-row w-full'>
          <div className='w-5/6 sm:w-3/4'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                renderInput={(props) => <TextField {...props} />}
                disablePast={true}
                className='border-primary border-2'
                label='Select a date*'
                value={dateToUpdate ? dateToUpdate : date}
                onChange={(newValue) => {
                  setDate(dateFormatted(newValue));
                  setDateToUpdate(null);
                  resetForbidden();
                }}
              />
            </LocalizationProvider>
          </div>
          <div className='w-1/6 sm:w-1/4'>
            <button
              onClick={deleteCurrentChosenDate}
              disabled={deleteForbidden}
            >
              <DeleteIcon
                className={` ${
                  deleteForbidden ? 'text-neutral-dark' : 'text-red-600'
                }`}
              />
              <p
                className={` ${
                  deleteForbidden ? 'text-neutral-dark' : 'text-red-600'
                } ml-2 mt-2 hidden sm:block`}
              >
                Delete
              </p>
            </button>
          </div>
        </div>
        {deleteForbidden ? (
          <div className='text-error text-s'>
            Forbidden, please update this current date.
          </div>
        ) : null}

        <div className='text-[#1A5DDA] w-full'>
          <div className='mt-5'>
            <div className='flex flex-col sm:flex-row lg:flex-col xl:flex-row w-full gap-y-3 sm:gap-x-2 sm:gap-y-0 lg:gap-x-0 xl:gap-x-2 lg:gap-y-3 xl:gap-y-0'>
              <div className='w-1/2' onClick={resetScroll}>
                <FormControl sx={{ minWidth: 150 }}>
                  <InputLabel id='demo-simple-select-label'>
                    Start hour*
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    required
                    label='Start time'
                    value={
                      startTimeToUpdate
                        ? formatForAPI(startTimeToUpdate)
                        : formatForAPI(startTime)
                    }
                    onChange={(e) => {
                      setStartTime(e.target.value);
                      setStartTimeToUpdate(null);
                      resetForbidden();
                      resetScroll();
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value='' ref={ref}>
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={'0'}>12:00 AM</MenuItem>
                    <MenuItem value={'30'}>12:30 AM</MenuItem>
                    <MenuItem value={'100'}>01:00 AM</MenuItem>
                    <MenuItem value={'130'}>01:30 AM</MenuItem>
                    <MenuItem value={'200'}>02:00 AM</MenuItem>
                    <MenuItem value={'230'}>02:30 AM</MenuItem>
                    <MenuItem value={'300'}>03:00 AM</MenuItem>
                    <MenuItem value={'330'}>03:30 AM</MenuItem>
                    <MenuItem value={'400'}>04:00 AM</MenuItem>
                    <MenuItem value={'430'}>04:30 AM</MenuItem>
                    <MenuItem value={'500'}>05:00 AM</MenuItem>
                    <MenuItem value={'530'}>05:30 AM</MenuItem>
                    <MenuItem value={'600'}>06:00 AM</MenuItem>
                    <MenuItem value={'630'}>06:30 AM</MenuItem>
                    <MenuItem value={'700'}>07:00 AM</MenuItem>
                    <MenuItem value={'730'}>07:30 AM</MenuItem>
                    <MenuItem value={'800'}>08:00 AM</MenuItem>
                    <MenuItem value={'830'}>08:30 AM</MenuItem>
                    <MenuItem value={'900'}>09:00 AM</MenuItem>
                    <MenuItem ref={scrollToRef} value={'930'}>
                      09:30 AM
                    </MenuItem>
                    <MenuItem value={'1000'}>10:00 AM</MenuItem>
                    <MenuItem value={'1030'}>10:30 AM</MenuItem>
                    <MenuItem value={'1100'}>11:00 AM</MenuItem>
                    <MenuItem value={'1130'}>11:30 AM</MenuItem>
                    <MenuItem value={'1200'}>12:00 PM</MenuItem>
                    <MenuItem value={'1230'}>12:30 PM</MenuItem>
                    <MenuItem value={'1300'}>01:00 PM</MenuItem>
                    <MenuItem value={'1330'}>01:30 PM</MenuItem>
                    <MenuItem value={'1400'}>02:00 PM</MenuItem>
                    <MenuItem value={'1430'}>02:30 PM</MenuItem>
                    <MenuItem value={'1500'}>03:00 PM</MenuItem>
                    <MenuItem value={'1530'}>03:30 PM</MenuItem>
                    <MenuItem value={'1600'}>04:00 PM</MenuItem>
                    <MenuItem value={'1630'}>04:30 PM</MenuItem>
                    <MenuItem value={'1700'}>05:00 PM</MenuItem>
                    <MenuItem value={'1730'}>05:30 PM</MenuItem>
                    <MenuItem value={'1800'}>06:00 PM</MenuItem>
                    <MenuItem value={'1830'}>06:30 PM</MenuItem>
                    <MenuItem value={'1900'}>07:00 PM</MenuItem>
                    <MenuItem value={'1930'}>07:30 PM</MenuItem>
                    <MenuItem value={'2000'}>08:00 PM</MenuItem>
                    <MenuItem value={'2030'}>08:30 PM</MenuItem>
                    <MenuItem value={'2100'}>09:00 PM</MenuItem>
                    <MenuItem value={'2130'}>09:30 PM</MenuItem>
                    <MenuItem value={'2200'}>10:00 PM</MenuItem>
                    <MenuItem value={'2230'}>10:30 PM</MenuItem>
                    <MenuItem value={'2300'}>11:00 PM</MenuItem>
                    <MenuItem value={'2330'}>11:30 PM</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className='w-1/2'>
                <FormControl sx={{ minWidth: 150 }} onClick={resetScroll}>
                  <InputLabel id='demo-simple-select-label'>
                    End hour
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='End time'
                    value={endTime}
                    onChange={(e) => {
                      setEndTime(e.target.value);
                      resetScroll();
                      // setValueToUpdate(null);
                      //
                      // resetForbidden();
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value='' ref={ref}>
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={'0'}>12:00 AM</MenuItem>
                    <MenuItem value={'30'}>12:30 AM</MenuItem>
                    <MenuItem value={'100'}>01:00 AM</MenuItem>
                    <MenuItem value={'130'}>01:30 AM</MenuItem>
                    <MenuItem value={'200'}>02:00 AM</MenuItem>
                    <MenuItem value={'230'}>02:30 AM</MenuItem>
                    <MenuItem value={'300'}>03:00 AM</MenuItem>
                    <MenuItem value={'330'}>03:30 AM</MenuItem>
                    <MenuItem value={'400'}>04:00 AM</MenuItem>
                    <MenuItem value={'430'}>04:30 AM</MenuItem>
                    <MenuItem value={'500'}>05:00 AM</MenuItem>
                    <MenuItem value={'530'}>05:30 AM</MenuItem>
                    <MenuItem value={'600'}>06:00 AM</MenuItem>
                    <MenuItem value={'630'}>06:30 AM</MenuItem>
                    <MenuItem value={'700'}>07:00 AM</MenuItem>
                    <MenuItem value={'730'}>07:30 AM</MenuItem>
                    <MenuItem value={'800'}>08:00 AM</MenuItem>
                    <MenuItem value={'830'}>08:30 AM</MenuItem>
                    <MenuItem value={'900'}>09:00 AM</MenuItem>
                    <MenuItem ref={scrollToRef} value={'930'}>
                      09:30 AM
                    </MenuItem>
                    <MenuItem value={'1000'}>10:00 AM</MenuItem>
                    <MenuItem value={'1030'}>10:30 AM</MenuItem>
                    <MenuItem value={'1100'}>11:00 AM</MenuItem>
                    <MenuItem value={'1130'}>11:30 AM</MenuItem>
                    <MenuItem value={'1200'}>12:00 PM</MenuItem>
                    <MenuItem value={'1230'}>12:30 PM</MenuItem>
                    <MenuItem value={'1300'}>01:00 PM</MenuItem>
                    <MenuItem value={'1330'}>01:30 PM</MenuItem>
                    <MenuItem value={'1400'}>02:00 PM</MenuItem>
                    <MenuItem value={'1430'}>02:30 PM</MenuItem>
                    <MenuItem value={'1500'}>03:00 PM</MenuItem>
                    <MenuItem value={'1530'}>03:30 PM</MenuItem>
                    <MenuItem value={'1600'}>04:00 PM</MenuItem>
                    <MenuItem value={'1630'}>04:30 PM</MenuItem>
                    <MenuItem value={'1700'}>05:00 PM</MenuItem>
                    <MenuItem value={'1730'}>05:30 PM</MenuItem>
                    <MenuItem value={'1800'}>06:00 PM</MenuItem>
                    <MenuItem value={'1830'}>06:30 PM</MenuItem>
                    <MenuItem value={'1900'}>07:00 PM</MenuItem>
                    <MenuItem value={'1930'}>07:30 PM</MenuItem>
                    <MenuItem value={'2000'}>08:00 PM</MenuItem>
                    <MenuItem value={'2030'}>08:30 PM</MenuItem>
                    <MenuItem value={'2100'}>09:00 PM</MenuItem>
                    <MenuItem value={'2130'}>09:30 PM</MenuItem>
                    <MenuItem value={'2200'}>10:00 PM</MenuItem>
                    <MenuItem value={'2230'}>10:30 PM</MenuItem>
                    <MenuItem value={'2300'}>11:00 PM</MenuItem>
                    <MenuItem value={'2330'}>11:30 PM</MenuItem>
                  </Select>
                  <FormHelperText>Optional</FormHelperText>
                </FormControl>
              </div>
            </div>
            <p className='text-black text-s'>Selected date :</p>
            <p className='text-black text-s'>
              {currentChoosenDay
                ? dateFormatted(currentChoosenDay)
                : dateFormatted(date)}
            </p>
            <p className='text-black text-s pt-4'>Selected time slot :&nbsp;</p>
            <p className='text-black text-s'>
              {startTimeToUpdate
                ? formatTime(startTimeToUpdate)
                : startTime
                ? formatTime(startTime)
                : 'No start hour selected'}
              {' - '}
              {/* TODO : Rajouter avec endTime venant de BDD endTimeToUpdate */}
              {startTimeToUpdate
                ? formatTimePlus30Minutes(startTimeToUpdate)
                : endTime
                ? formatTime(endTime)
                : startTime
                ? formatTimePlus30Minutes(startTime)
                : 'No end hour selected'}
            </p>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default CustomDatePicker;
