function checkCurrentRole(role) {
  switch (role) {
    case 'retailer':
      return 'retailer'
    case 'customer':
      return 'customer'
    case 'buildingManager':
      return 'buildingManager'
    case 'carrier':
      return 'carrier';
    default:
      return null;
  }
}
export default checkCurrentRole;
