import React from 'react';

export const CustomButton = ({ title, onclick, ...props }) => {

  return (
    <button
      type="submit" className="bg-bg-button w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg hover:scale-105 ease-out duration-300" onClick={onclick}
    >
      {title}
    </button>
  );
};

export default CustomButton;
