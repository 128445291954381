import React from 'react';
import Header from '../../../components/Header';
import ComplementBuildingManager from '../../../components/Complements/ComplementBuildingManager';

export default function BuildingManagerInfo() {
  return (
    <div>
      <div className='static'>
        <Header />
      </div>
      <div className='absolute lg:top-40 top-32 inset-x-0'>
        <ComplementBuildingManager />
      </div>
    </div>
  );
}
