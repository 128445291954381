import React from "react";
import { useSelector } from "react-redux";
import { Field, Form } from "formik";
import { CustomButton } from "../Button";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const RequestForm = (props) => {
  const { requestCodeErrorMessage, requestCodeLoading } = useSelector(
    (state) => ({
      requestCodeErrorMessage: state.getIn(["app", "requestCodeErrorMessage"]),
      requestCodeLoading: state.getIn(["app", "requestCodeLoading"]),
    })
  );

  const { values, errors, onSubmit, handleChange } = props;
  const { email } = values;

  return (
    <Form className="container flex flex-col justify-center items-center w-full">
      <div className="w-full px-0 md:px-4 flex flex-col justify-center items-center">
        <div className="w-full flex flex-col md:flex-row items-center mb-6 middle">
          <div className="w-full sm:w-96 block middle">
            <label className="block text-xxms font-medium text-gray-700 mb-2">
              {"Email"}
            </label>
            <Field
              id="Email"
              name="email"
              type="email"
              onChange={handleChange}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-white focus:outline-none focus:ring-orange-light focus:border-orange-dark sm:text-xxs"
            />
            {errors && errors.email === "emailNotValid" ? (
              <p className="text-red-500">{"Email is invalid"}</p>
            ) : null}
            {errors && errors.email === "emailRequired" ? (
              <p className="text-red-500">{"Email required"}</p>
            ) : null}
            {requestCodeErrorMessage ? (
              <p className="text-red-500">
                {requestCodeErrorMessage}
              </p>
            ) : null}
          </div>
        </div>
      </div>
      {!requestCodeLoading && (
        <CustomButton
          type="submit"
          onclick={() => onSubmit}
          title={"Request Code"}
        />
      )}
      {requestCodeLoading && (
        <CustomButton
          type="submit"
          onclick={() => onSubmit}
          title={"Request Code"}
        >
          <CircularProgress style={{ color: "white" }} size={20} />
        </CustomButton>
      )}
      <div className="mt-3 flex justify-center">
        <Link to="/loginas">
          <button
            type="button"
            className="w-full md:w-52 text-[#1a5dda] px-3 py-1.5 border border-[#1a5dda] rounded-md shadow-sm text-xxms font-medium hover:scale-105 hover:font-bold focus:outline-none focus:ring-0 focus:ring-offset-0"
          >
            Cancel
          </button>
        </Link>
      </div>
      <style>
        {`
    .middle {
      justify-content: center;
    }
  `}
      </style>
    </Form>
  );
};

export default RequestForm;
