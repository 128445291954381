import React, { useEffect, useRef, useState } from 'react';
import ComplementField from '../../Complements/ComplementField';
import { Field, ErrorMessage } from 'formik';
import DropdownSelector from '../../EventsManager/Dropdown';
import { useStyles } from 'react-styles-hook';
import getStyles from './style';
import { useDispatch, useSelector } from "react-redux";
import { uploadCOIDocumentFilePhotoFileLaunched, uploadBuildingDocumentFilePhotoFileLaunched } from "../../../service/reducer";
import { deleteFileS3 } from "../../../utils/awsLib";
import { nanoid } from "nanoid";

const EventBuildingManager = ({
                                setBuildingList,
                                buildingSelected,
                                setBuildingSelected,
                                buildingList,
                                setFormValue,
                                handleChange,
                                formValue,
                                userDynamo,
                                userInfo,
                                currentRole,
                                buildingInfo, // !!!!!!
                                // COI DOCUMENT FILE
                                COIDocumentFileKeyId,
                                setCOIDocumentFileKeyId,
                                COIDocumentFileName,
                                setCOIDocumentFileName,
                                COIDocumentFile,
                                setCOIDocumentFile,
                                errorCOIDocumentFileMessage,
                                setErrorCOIDocumentFileMessage,
                                deleteCOIDocumentFile,
                                uploadCOIDocumentFileSuccessMessage,
                                setUploadCOIDocumentFileSuccessMessage,
                                uploadCOIDocumentFileErrorMessage,
                                setUploadCOIDocumentFileErrorMessage,
                                // Building Document File
                                buildingDocumentFileKeyId,
                                setBuildingDocumentFileKeyId,
                                buildingDocumentFileName,
                                setBuildingDocumentFileName,
                                buildingDocumentFile,
                                setBuildingDocumentFile,
                                errorBuildingDocumentFileMessage,
                                setErrorBuildingDocumentFileMessage,
                                deleteBuildingDocumentFile,
                                uploadBuildingDocumentFileSuccessMessage,
                                setUploadBuildingDocumentFileSuccessMessage,
                                uploadBuildingDocumentFileErrorMessage,
                                setUploadBuildingDocumentFileErrorMessage,

                              }) => {
  /* set building manager value to event form*/
  const [typeOfResidence, setTypeOfResidence] = useState('RENTAL');
  const [specificFreightDoor, setSpecificFreightDoor] = useState(false);
  const [sameFreightAddress, setSameFreightAddress] = useState(false);
  const [haveFreightElevator, setHaveFreightElevator] = useState(false);
  const [bookElevator, setBookElevator] = useState(false);
  const [haveDoorman, setHaveDoorman] = useState(false);
  const [haveVirtualDoorman, setHaveVirtualDoorman] = useState(false);

  // File upload
  const dispatch = useDispatch();
  const {
    uploadCOIDocumentFilePhotoFileLoading,
    uploadCOIDocumentFilePhotoFileError,
    uploadCOIDocumentFilePhotoFileSuccess,
    uploadBuildingDocumentFilePhotoFileLoading,
    uploadBuildingDocumentFilePhotoFileError,
    uploadBuildingDocumentFilePhotoFileSuccess,
  } = useSelector((state) => ({
    uploadCOIDocumentFilePhotoFileLoading: state.getIn([
      'app',
      'uploadCOIDocumentFilePhotoFileLoading',
    ]),
    uploadCOIDocumentFilePhotoFileError: state.getIn([
      'app',
      'uploadCOIDocumentFilePhotoFileError',
    ]),
    uploadCOIDocumentFilePhotoFileSuccess: state.getIn([
      'app',
      'uploadCOIDocumentFilePhotoFileSuccess',
    ]),
    uploadBuildingDocumentFilePhotoFileLoading: state.getIn([
      'app',
      'uploadBuildingDocumentFilePhotoFileLoading',
    ]),
    uploadBuildingDocumentFilePhotoFileError: state.getIn([
      'app',
      'uploadBuildingDocumentFilePhotoFileError',
    ]),
    uploadBuildingDocumentFilePhotoFileSuccess: state.getIn([
      'app',
      'uploadBuildingDocumentFilePhotoFileSuccess',
    ]),
  }));

  const uploadCOIDocumentFile = (file, fileKeyId) => {
    dispatch(uploadCOIDocumentFilePhotoFileLaunched({ file, fileKeyId }));
  };
  const uploadBuildingDocumentFile = (file, fileKeyId) => {
    dispatch(uploadBuildingDocumentFilePhotoFileLaunched({ file, fileKeyId }));
  };

  useEffect(() => {
    if (COIDocumentFile && COIDocumentFileKeyId) {
      uploadCOIDocumentFile(COIDocumentFile, COIDocumentFileKeyId);
    }
  }, [COIDocumentFile, COIDocumentFileKeyId]);

  useEffect(() => {
    if (buildingDocumentFile && buildingDocumentFileKeyId) {
      uploadBuildingDocumentFile(buildingDocumentFile, buildingDocumentFileKeyId);
    }
  }, [buildingDocumentFile, buildingDocumentFileKeyId]);

  const onCOIDocumentFileChange = (file) => {
    // delete previous file
    if (COIDocumentFileKeyId) {
      deleteFileS3(COIDocumentFileKeyId);
    }

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'application/pdf'
    ) {
      setErrorCOIDocumentFileMessage(
        'Forbidden type file ! PNG , JPG and PDF only accepted.'
      );
    } else if (file.size < 32000 || file.size > 5000000) {
      setErrorCOIDocumentFileMessage(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 5mb)`
      );
    } else {
      // reset previous File value
      setCOIDocumentFileKeyId();
      setCOIDocumentFile();
      setCOIDocumentFileName();
      setErrorCOIDocumentFileMessage();
      setUploadCOIDocumentFileErrorMessage();
      setUploadCOIDocumentFileSuccessMessage();

      const id = nanoid();
      setCOIDocumentFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setCOIDocumentFile(file);
      setCOIDocumentFileName(file.name);
    }
  };

  const onBuildingDocumentFileChange = (file) => {
    // delete previous file
    if (buildingDocumentFileKeyId) {
      deleteFileS3(buildingDocumentFileKeyId);
    }

    if (
      file.type !== 'image/png' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'application/pdf'
    ) {
      setErrorBuildingDocumentFileMessage(
        'Forbidden type file ! PNG , JPG and PDF only accepted.'
      );
    } else if (file.size < 32000 || file.size > 5000000) {
      setErrorBuildingDocumentFileMessage(
        `Invalid file size : ${(file.size / 1000000).toFixed(
          2
        )} mb (min 0.03mb, max 5mb)`
      );
    } else {
      // reset previous File value
      setBuildingDocumentFileKeyId();
      setBuildingDocumentFile();
      setBuildingDocumentFileName();
      setErrorBuildingDocumentFileMessage();
      setUploadBuildingDocumentFileErrorMessage();
      setUploadBuildingDocumentFileSuccessMessage();

      const id = nanoid();
      setBuildingDocumentFileKeyId(
        id + '.' + file.type.slice(file.type.indexOf('/') + 1, file.type.length)
      );
      setBuildingDocumentFile(file);
      setBuildingDocumentFileName(file.name);
    }
  };

  // set error or success message for COI
  const isInitialMountCOIDocumentFile = useRef(true);
  useEffect(() => {
    if (isInitialMountCOIDocumentFile.current) {
      isInitialMountCOIDocumentFile.current = false;
      return;
    }
    if (uploadCOIDocumentFilePhotoFileError) {
      setUploadCOIDocumentFileErrorMessage(uploadCOIDocumentFilePhotoFileError);
    }
    if (uploadCOIDocumentFilePhotoFileSuccess) {
      setUploadCOIDocumentFileSuccessMessage(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadCOIDocumentFilePhotoFileError, uploadCOIDocumentFilePhotoFileSuccess]);

  // set error or success message for building document
  const isInitialMountBuildingDocumentFile = useRef(true);
  useEffect(() => {
    if (isInitialMountBuildingDocumentFile.current) {
      isInitialMountBuildingDocumentFile.current = false;
      return;
    }
    if (uploadBuildingDocumentFilePhotoFileError) {
      setUploadBuildingDocumentFileErrorMessage(uploadBuildingDocumentFilePhotoFileError);
    }
    if (uploadBuildingDocumentFilePhotoFileSuccess) {
      setUploadBuildingDocumentFileSuccessMessage(
        'Uploading with success. Dont forget to save your changes.'
      );
    }
  }, [uploadBuildingDocumentFilePhotoFileError, uploadBuildingDocumentFilePhotoFileSuccess]);


  useEffect(() => {
    if (userDynamo) {
      if (userDynamo.buildingManager?.buildings) {
        setBuildingList((buildingList) => [
          ...buildingList,
          ...userDynamo.buildingManager?.buildings,
        ]);
      }
    }
  }, [userDynamo]);

  useEffect(() => {
    if (formValue) {
      setTypeOfResidence(formValue?.BMtypeOfResidence);
      setSpecificFreightDoor(formValue?.BMspecificFreightDoor);
      setSameFreightAddress(formValue?.BMsameFreightAddress);
      setHaveFreightElevator(formValue?.BMfreightElevator);
      setBookElevator(formValue?.BMneedToBookElevator);
      setHaveDoorman(formValue?.BMdoorman);
      setHaveVirtualDoorman(formValue?.BMvirtualDoorman);
    }
  }, [formValue]);

  const [deleteForbidden, setDeleteForbidden] = useState(false);
  const classes = useStyles(getStyles());

  return (
    <div>
      <div className="flex flex-col mt-10 w-full bg-white relative px-2 md:px-8 pt-6 pb-8 mb-4">
        <div className="flex-col">
          <div className="w-full md:w-5/6 lg:w-3/5 mx-auto">
            <div style={classes.newBuilding}>
              <h3 className="text-black text-xl font-bold">
                Your informations
              </h3>
              <ComplementField
                label="First Name"
                name="BMfirstName"
                type="text"
                placeholder="My First Name"
                value={formValue?.BMfirstName}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Last Name"
                name="BMlastName"
                type="text"
                placeholder="My Last Name"
                value={formValue?.BMlastName}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Phone"
                name="BMphone"
                type="text"
                placeholder="##########"
                value={formValue?.BMphone}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Internal Ref"
                name="BMInternalRef"
                type="text"
                placeholder="Delivery/Resident Indentification"
                value={formValue?.BMInternalRef}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              {/*<ComplementField*/}
              {/*  label="EIN number"*/}
              {/*  name="BMEINNumber"*/}
              {/*  type="text"*/}
              {/*  placeholder="12-3456789"*/}
              {/*  value={formValue?.BMEINNumber}*/}
              {/*  onChange={(e) => {*/}
              {/*    handleChange(e);*/}
              {/*    setFormValue((prevState) => ({*/}
              {/*      ...prevState,*/}
              {/*      [e.target.name]: e.target.value,*/}
              {/*    }));*/}
              {/*  }}*/}
              {/*/>*/}
            </div>
            <div style={classes.newBuilding}>
              <h3 className="text-black text-xl  mt-2 mb-8">Building Info</h3>
              {/* Show dropdown if building manager and set building value*/}
              {currentRole === 'buildingManager' &&
                userInfo?.buildings?.length >= 1 && (
                  <DropdownSelector
                    selected={buildingSelected}
                    setSelected={setBuildingSelected}
                    buildings={buildingList}
                  />
                )}
              <ComplementField
                label="Building Name"
                name="BMname"
                type="text"
                placeholder="Name of the building"
                value={formValue?.BMname}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Address*"
                name="BMaddress"
                type="text"
                placeholder="123 E Example St"
                value={formValue?.BMaddress}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Complement"
                name="BMcomplement"
                type="text"
                placeholder="Unit, Apt, Suite..."
                value={formValue?.BMcomplement}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="City*"
                name="BMcity"
                type="text"
                value={formValue?.BMcity}
                placeholder="City Name"
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="State*"
                name="BMaddressState"
                type="text"
                value={formValue?.BMaddressState}
                placeholder="State Name"
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Zip Code*"
                name="BMzipcode"
                type="text"
                value={formValue?.BMzipcode}
                placeholder="#####"
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />

              <div className="flex my-8 ">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Type of residence{' '}
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        typeOfResidence === 'RENTAL' &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="RENTAL"
                        name="BMtypeOfResidence"
                        checked={typeOfResidence === 'RENTAL'}
                        onChange={(e) => {
                          setTypeOfResidence(e.target.value);
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="typeOfResidence"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Rental{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        typeOfResidence === 'CONDO' && 'bg-primary-lighter'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="CONDO"
                        name="BMtypeOfResidence"
                        checked={typeOfResidence === 'CONDO'}
                        onChange={(e) => {
                          setTypeOfResidence(e.target.value);
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="typeOfResidence"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        Condo{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        typeOfResidence === 'COOP' && 'bg-primary-lighter '
                      }`}
                    >
                      <Field
                        type="radio"
                        value="COOP"
                        name="BMtypeOfResidence"
                        checked={typeOfResidence === 'COOP'}
                        onChange={(e) => {
                          setTypeOfResidence(e.target.value);
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="typeOfResidence"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        Coop{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        typeOfResidence === 'OTHER' &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="OTHER"
                        name="BMtypeOfResidence"
                        checked={typeOfResidence === 'OTHER'}
                        onChange={(e) => {
                          setTypeOfResidence(e.target.value);
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="typeOfResidence"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        Other{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div style={classes.newBuilding}>
              <h3 className="text-black py-4 text-xl"> Particularity </h3>
              <div className="flex my-8 w-full">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Door size{' '}
                </p>
                <div className="flex w-full">
                  <div className="lg:w-2/5 w-2/4 mx-4">
                    <Field
                      type="text"
                      name="BMdoorSizeLenght"
                      placeholder="WW"
                      value={formValue?.BMdoorSizeLenght}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Width (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMdoorSizeLenght"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-2/5 w-2/4 mx-4">
                    <Field
                      type="text"
                      name="BMdoorSizeHeight"
                      placeholder="HH"
                      value={formValue?.BMdoorSizeHeight}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Height (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMdoorSizeHeight"
                      className="text-red-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex my-8 w-full">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Elevator size{' '}
                </p>
                <div className="flex w-full">
                  <div className="lg:w-2/5 w-2/4 mx-2">
                    <Field
                      type="text"
                      name="BMelevatorLenght"
                      placeholder="LL"
                      value={formValue?.BMelevatorLenght}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Length (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMelevatorLenght"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-2/5 w-2/4 mx-2">
                    <Field
                      type="text"
                      name="BMelevatorHeight"
                      placeholder="HH"
                      value={formValue?.BMelevatorHeight}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Height (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMelevatorHeight"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-2/5 w-2/4 mx-2">
                    <Field
                      type="text"
                      name="BMelevatorWidth"
                      placeholder="WW"
                      value={formValue?.BMelevatorWidth}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Width (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMelevatorWidth"
                      className="text-red-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex my-8 ">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Do you have a freight entrance ?{' '}
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        specificFreightDoor && 'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="true"
                        name="BMspecificFreightDoor"
                        checked={specificFreightDoor === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setSpecificFreightDoor(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="specificFreightDoor"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        specificFreightDoor === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="false"
                        name="BMspecificFreightDoor"
                        checked={specificFreightDoor === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setSpecificFreightDoor(e.target.value === 'true'); // potential error ?
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="specificFreightDoor"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={`flex my-8 w-full ${
                  specificFreightDoor === true ? 'visible' : 'hidden'
                }`}
              >
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Freight entrance size{' '}
                </p>
                <div className="flex w-full ">
                  <div className="lg:w-1/5 w-2/4 mr-6">
                    <Field
                      type="text"
                      name="BMspecificFreightDoorLenght"
                      placeholder="WW"
                      value={formValue?.BMspecificFreightDoorLenght}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Width (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMspecificFreightDoorLenght"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-1/5 w-2/4 lg:mr-6">
                    <Field
                      type="text"
                      name="BMspecificFreightDoorHeight"
                      placeholder="HH"
                      value={formValue?.BMspecificFreightDoorHeight}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Height (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMspecificFreightDoorHeight"
                      className="text-red-500"
                    />
                  </div>
                </div>
              </div>
              <div className="flex my-8 ">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Same address as the main entrance ?{' '}
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        sameFreightAddress === true &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="true"
                        name="BMsameFreightAddress"
                        checked={sameFreightAddress === true}
                        onChange={(e) => {
                          setSameFreightAddress(e.target.value === 'true');
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="sameFreightAddress"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        sameFreightAddress === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="false"
                        name="BMsameFreightAddress"
                        checked={sameFreightAddress === false}
                        onChange={(e) => {
                          setSameFreightAddress(e.target.value === 'true');
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="sameFreightAddress"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={` ${
                sameFreightAddress !== true ? 'visible' : 'hidden'
              }`}
              style={classes.newBuilding}
            >
              <h3 className="text-black py-4 text-xl">
                {' '}
                Freight entrance informations{' '}
              </h3>

              <ComplementField
                label="Address"
                name="BMfreightAddress"
                type="text"
                placeholder="123 E Example St"
                value={formValue?.BMfreightAddress}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="Complement"
                name="BMfreightComplement"
                type="text"
                placeholder="Unit, Apt, Suite..."
                value={formValue?.BMfreightComplement}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="City"
                name="BMfreightCity"
                type="text"
                value={formValue?.BMfreightCity}
                placeholder="City Name"
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="State"
                name="BMfreightState"
                type="text"
                value={formValue?.BMfreightState}
                placeholder="State Name"
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label="ZipCode"
                name="BMfreightZipcode"
                type="text"
                placeholder="#####"
                value={formValue?.BMfreightZipcode}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <div className="flex my-8 w-full">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Opening hour{' '}
                </p>
                <div className="flex w-full">
                  <div className="flex lg:w-full w-2/4 mx-2">
                    <p className="w-full flex items-center"> From : </p>
                    <Field
                      type="text"
                      name="BMfreightOpeningHourStart"
                      placeholder="hh:mm"
                      value={formValue?.BMfreightOpeningHourStart}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                  <div className="flex lg:w-full w-2/4 mx-2">
                    <p className="w-full flex items-center"> To : </p>
                    <Field
                      type="text"
                      name="BMfreightOpeningHourEnd"
                      value={formValue?.BMfreightOpeningHourEnd}
                      placeholder="hh:mm"
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                  </div>
                </div>
              </div>
              <div className="flex my-8 ">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Freight elevator ?{' '}
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        haveFreightElevator === true &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="true"
                        name="BMfreightElevator"
                        checked={haveFreightElevator === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setHaveFreightElevator(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="freightElevator"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        haveFreightElevator === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="false"
                        name="BMfreightElevator"
                        checked={haveFreightElevator === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setHaveFreightElevator(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="freightElevator"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={`flex my-8 w-full ${
                  haveFreightElevator === true ? 'visible' : 'hidden'
                }`}
              >
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Freight elevator size{' '}
                </p>
                <div className="flex w-full">
                  <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                    <Field
                      type="text"
                      name="BMfreightElevatorLenght"
                      placeholder="LL"
                      value={formValue?.BMfreightElevatorLenght}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Length (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMfreightElevatorLenght"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-1/5 w-1/3 lg:mr-6 mr-2">
                    <Field
                      type="text"
                      name="BMfreightElevatorHeight"
                      placeholder="HH"
                      value={formValue?.BMfreightElevatorHeight}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Height (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMfreightElevatorHeight"
                      className="text-red-500"
                    />
                  </div>
                  <div className="lg:w-1/5 w-1/3">
                    <Field
                      type="text"
                      name="BMfreightElevatorWidth"
                      placeholder="WW"
                      value={formValue?.BMfreightElevatorWidth}
                      onChange={(e) => {
                        handleChange(e);
                        setFormValue((prevState) => ({
                          ...prevState,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                      className="shadow placeholder:text-slate-300 appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                    <small className="block mt-1 text-xs text-[#6B7280]">
                      {' '}
                      Width (inches){' '}
                    </small>
                    <ErrorMessage
                      component="div"
                      name="BMfreightElevatorWidth"
                      className="text-red-500"
                    />
                  </div>
                </div>
              </div>

              <div className="flex my-8 ">
                <p className="self-center w-2/5 text-gray-700 text-sm">
                  Need to book elevator ?
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        bookElevator === true &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="true"
                        name="BMneedToBookElevator"
                        checked={bookElevator === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setBookElevator(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="bookElevator"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        bookElevator === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="false"
                        name="BMneedToBookElevator"
                        checked={bookElevator === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setBookElevator(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="freightElevator"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div
                className={` my-8 w-full ${
                  bookElevator === true ? 'visible' : 'hidden'
                }`}
              >
                <ComplementField
                  label="Contact First Name"
                  name="BMcontactFirstName"
                  value={formValue?.BMcontactFirstName}
                  type="text"
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  placeholder="Contact First Name"
                />
                <ComplementField
                  label="Contact Last Name"
                  name="BMcontactLastName"
                  type="text"
                  placeholder="Contact Last Name"
                  value={formValue?.BMcontactLastName}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                />
                <ComplementField
                  label="Contact email"
                  name="BMcontacBMcontactEmailtEmail"
                  type="email"
                  value={formValue?.BMcontactEmail}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  placeholder="johndoe@example.com"
                />
                <ComplementField
                  label="Contact phone"
                  name="BMcontactPhone"
                  type="text"
                  value={formValue?.BMcontactPhone}
                  onChange={(e) => {
                    handleChange(e);
                    setFormValue((prevState) => ({
                      ...prevState,
                      [e.target.name]: e.target.value,
                    }));
                  }}
                  placeholder="##########"
                />
              </div>
            </div>

            <div style={classes.newBuilding}>
              <h3 className="text-black py-4 text-xl "> Other informations </h3>
              <div className="flex my-8 ">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Do you have a doorman ?{' '}
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        haveDoorman === true &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="true"
                        name="BMdoorman"
                        checked={haveDoorman === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setHaveDoorman(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="haveDoorman"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        haveDoorman === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="false"
                        name="BMdoorman"
                        checked={haveDoorman === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setHaveDoorman(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="haveDoorman"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="flex my-8 ">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Do you have a virtual doorman ?{' '}
                </p>
                <ul
                  className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                  <li className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        haveVirtualDoorman === true &&
                        'bg-primary-lighter rounded-t-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="true"
                        name="BMvirtualDoorman"
                        checked={haveVirtualDoorman === true}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setHaveVirtualDoorman(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="virtualDoorMan"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        {' '}
                        Yes{' '}
                      </label>
                    </div>
                  </li>
                  <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                    <div
                      className={`flex items-center pl-3 ${
                        haveVirtualDoorman === false &&
                        'bg-primary-lighter rounded-b-lg'
                      }`}
                    >
                      <Field
                        type="radio"
                        value="false"
                        name="BMvirtualDoorman"
                        checked={haveVirtualDoorman === false}
                        onChange={(e) => {
                          handleChange(e);
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value === 'true',
                          }));
                          setHaveVirtualDoorman(e.target.value === 'true');
                        }}
                        className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                      />
                      <label
                        htmlFor="virtualDoorMan"
                        className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                      >
                        {' '}
                        No{' '}
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
              {/* BUILDING IN EVENT */}
              <div className="flex my-8 w-full">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  COI requirements{' '}
                </p>
                <div className="flex lg:w-full w-2/4 mx-2">
                  <Field
                    as="textarea"
                    type="text"
                    name="BMCOIRequirement"
                    rows="7"
                    placeholder="COI requested details"
                    value={formValue?.BMCOIRequirement}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                    className="shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></Field>
                </div>
              </div>
              <div className='mt-8'>
                <div className='flex flex-col sm:flex-row w-full'>
                  <div className='w-full sm:w-2/5 self-center sm:self-start'>
                    <p className='block text-gray-700 text-smc'>
                      COI documents / Access Informations
                    </p>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div
                      className={`w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    >
                      {/* todo adapter affichage fileName ou formFileKey */}
                      {!uploadCOIDocumentFilePhotoFileLoading &&
                        COIDocumentFileKeyId &&
                        COIDocumentFileName && (
                          <div className='text-neutral-dark'>
                            <p>Chosen file:</p>
                            <div className='flex flex-row items-center'>
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  (COIDocumentFileKeyId ===
                                  COIDocumentFileName // TODO ?
                                    ? COIDocumentFileName
                                    : COIDocumentFileKeyId)
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                {COIDocumentFileName}
                              </a>
                              {currentRole === 'buildingManager' && (
                                <button
                                  type='button'
                                  onClick={() =>
                                    deleteCOIDocumentFile(
                                      COIDocumentFileKeyId ===
                                      COIDocumentFileName
                                        ? COIDocumentFileName
                                        : COIDocumentFileKeyId
                                    )
                                  }
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='pl-2 w-6 h-6 text-error'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      {!COIDocumentFileKeyId &&
                        COIDocumentFileName &&
                        COIDocumentFileName}
                      {/* . . . . . . . what am i doing . . . . . . */}

                      {!COIDocumentFileName && 'No COI document file'}
                      {/* {(formValue.COIDocumentFileKeyId && !COIDocumentFileName) && "No COI file"} */}

                      {/* todo add eye pour voir le fichier */}
                    </div>
                    {/* // upload in progress */}
                    {uploadCOIDocumentFilePhotoFileLoading && (
                      <div className='flex justify-center w-full h-full text-sm'>
                        <p>Upload in progress...</p>
                      </div>
                    )}
                    {/* error */}
                    {(errorCOIDocumentFileMessage ||
                      uploadCOIDocumentFileErrorMessage) && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-error'>
                          {errorCOIDocumentFileMessage
                            ? errorCOIDocumentFileMessage
                            : uploadCOIDocumentFileErrorMessage
                              ? uploadCOIDocumentFileErrorMessage
                              : null}{' '}
                        </p>
                      </div>
                    )}
                    {/* success */}
                    {uploadCOIDocumentFileSuccessMessage && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-primary'>
                          {uploadCOIDocumentFileSuccessMessage}{' '}
                        </p>
                      </div>
                    )}
                    {currentRole === 'buildingManager' && (
                      <label
                        className='cursor-pointer'
                        htmlFor='COIDocumentFile'
                      >
                        <input
                          id='COIDocumentFile'
                          className='hidden'
                          accept='image/*,.pdf'
                          name='COIDocumentFile'
                          type='file'
                          onInput={(e) => {
                            if (e.target.files[0]) {
                              onCOIDocumentFileChange(e.target.files[0]);
                            }
                          }}
                        />

                        <p className='mt-4 text-center bg-bg-button hover:scale-105 ease-out duration-300  w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg'>
                          {COIDocumentFileName ? 'Change File' : 'Upload file'}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className='mt-8'>
                <div className='flex flex-col sm:flex-row w-full'>
                  <div className='w-full sm:w-2/5 self-center sm:self-start'>
                    <p className='block text-gray-700 text-smc'>
                      Building document, photo, floor, plan...
                    </p>
                  </div>
                  <div className='flex flex-col w-full'>
                    <div
                      className={`w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                    >
                      {/* todo adapter affichage fileName ou formFileKey */}
                      {!uploadBuildingDocumentFilePhotoFileLoading &&
                        buildingDocumentFileKeyId &&
                        buildingDocumentFileName && (
                          <div className='text-neutral-dark'>
                            <p>Chosen file:</p>
                            <div className='flex flex-row items-center'>
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  (buildingDocumentFileKeyId === buildingDocumentFileName // TODO ?
                                    ? buildingDocumentFileName
                                    : buildingDocumentFileKeyId)
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                {buildingDocumentFileName}
                              </a>
                              {currentRole === 'buildingManager' && (
                                <button
                                  type='button'
                                  onClick={() =>
                                    deleteBuildingDocumentFile(
                                      buildingDocumentFileKeyId ===
                                      buildingDocumentFileName
                                        ? buildingDocumentFileName
                                        : buildingDocumentFileKeyId
                                    )
                                  }
                                >
                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    fill='none'
                                    viewBox='0 0 24 24'
                                    strokeWidth={1.5}
                                    stroke='currentColor'
                                    className='pl-2 w-6 h-6 text-error'
                                  >
                                    <path
                                      strokeLinecap='round'
                                      strokeLinejoin='round'
                                      d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      {!buildingDocumentFileKeyId &&
                        buildingDocumentFileName &&
                        buildingDocumentFileName}
                      {/* . . . . . . . what am i doing . . . . . . */}

                      {!buildingDocumentFileName && 'No building document file'}
                      {/* {(formValue.buildingDocumentFileKeyId && !buildingDocumentFileName) && "No building document file"} */}

                      {/* todo add eye pour voir le fichier */}
                    </div>
                    {/* // upload in progress */}
                    {uploadBuildingDocumentFilePhotoFileLoading && (
                      <div className='flex justify-center w-full h-full text-sm'>
                        <p>Upload in progress...</p>
                      </div>
                    )}
                    {/* error */}
                    {(errorBuildingDocumentFileMessage ||
                      uploadBuildingDocumentFileErrorMessage) && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-error'>
                          {errorBuildingDocumentFileMessage
                            ? errorBuildingDocumentFileMessage
                            : uploadBuildingDocumentFileErrorMessage
                              ? uploadBuildingDocumentFileErrorMessage
                              : null}{' '}
                        </p>
                      </div>
                    )}
                    {/* success */}
                    {uploadBuildingDocumentFileSuccessMessage && (
                      <div className='pt-1 flex justify-center w-full h-full'>
                        <p className='text-sm text-primary'>
                          {uploadBuildingDocumentFileSuccessMessage}{' '}
                        </p>
                      </div>
                    )}
                    {currentRole === 'buildingManager' && (
                      <label
                        className='cursor-pointer'
                        htmlFor='buildingDocumentFile'
                      >
                        <input
                          id='buildingDocumentFile'
                          className='hidden'
                          accept='image/*,.pdf'
                          name='buildingDocumentFile'
                          type='file'
                          onInput={(e) => {
                            if (e.target.files[0]) {
                              onBuildingDocumentFileChange(e.target.files[0]);
                            }
                          }}
                        />

                        <p className='mt-4 text-center bg-bg-button hover:scale-105 ease-out duration-300  w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg'>
                          {buildingDocumentFileName ? 'Change File' : 'Upload file'}
                        </p>
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex my-8 w-full">
                <p className="self-center w-2/5 block text-gray-700 text-sm">
                  {' '}
                  Access informations / Parking informations{' '}
                </p>
                <div className="flex lg:w-full w-2/4 mx-2">
                  <Field
                    as="textarea"
                    type="text"
                    name="BMaccessInformation"
                    rows="7"
                    placeholder="Any information relevant (road, parking, restrictions, areas...)"
                    value={formValue?.BMaccessInformation}
                    onChange={(e) => {
                      handleChange(e);
                      setFormValue((prevState) => ({
                        ...prevState,
                        [e.target.name]: e.target.value,
                      }));
                    }}
                    className="shadow placeholder:text-slate-300  appearance-none border rounded w-full  py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  ></Field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style>
        {`
    input {
      vertical-align: middle;
    }
    hr {
      margin-bottom: 0.5rem;
    }
  `}
      </style>
    </div>
  );
};

export default EventBuildingManager;
