function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('/');
}

/**
 *
 *
 * @param {Object} commonDate {day: "yyyy/mm/jj", timeSlot: "900"}
 * @returns {Object} {date: "mm/jj/yyyy", timeStart: "9:00am", timeEnd: "9:30pm"}
 */
export function commonDateFormat(commonDate) {
  const date = new Date(commonDate.day)
  let formatDay = formatDate(date);
  let result;
  let timeStart;
  let timeEnd;
  if (Number(commonDate.timeSlot) === 0) {
    timeStart = '12:00am';
    timeEnd = '12:30am'
  }
  else if (Number(commonDate.timeSlot) < 1000) {
    timeStart = commonDate.timeSlot.slice(0, 1) + ':' + commonDate.timeSlot.slice(1) + 'am';
    if ((commonDate.timeSlot).slice(1) === "00") {
      timeEnd = commonDate.timeSlot.slice(0, 1) + ':30am'
    } else {
      timeEnd = Number(commonDate.timeSlot.slice(0, 1)) + 1 + ':00am'
    }
  } else if (Number(commonDate.timeSlot) >= 1000 && Number(commonDate.timeSlot) < 1130) {
    timeStart = commonDate.timeSlot.slice(0, 2) + ':' + commonDate.timeSlot.slice(2) + 'am';
    if ((commonDate.timeSlot).slice(2) === "00") {
      timeEnd = commonDate.timeSlot.slice(0, 2) + ':30am'
    } else {
      timeEnd = Number(commonDate.timeSlot.slice(0, 2)) + 1 + ':00am'
    }
  } else if (Number(commonDate.timeSlot) === 1130) {
    timeStart = commonDate.timeSlot.slice(0, 2) + ':' + commonDate.timeSlot.slice(2) + 'am';
    timeEnd = Number(commonDate.timeSlot.slice(0, 2)) + 1 + ':00pm'
  } else if (Number(commonDate.timeSlot) === 1200 || Number(commonDate.timeSlot) === 1230) {
    timeStart = commonDate.timeSlot.slice(0, 2) + ':' + commonDate.timeSlot.slice(2) + 'pm';
    if ((commonDate.timeSlot).slice(2) === "00") {
      timeEnd = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':30pm'
    } else {
      timeEnd = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':00pm'
    }
  } else if (Number(commonDate.timeSlot) >= 1300 && Number(commonDate.timeSlot) < 2330) {
    timeStart = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':' + commonDate.timeSlot.slice(2) + 'pm';
    if ((commonDate.timeSlot).slice(2) === "00") {
      timeEnd = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':30pm'
    } else {
      timeEnd = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':00pm'
    }
  } else if (Number(commonDate.timeSlot) === 2330) {
    timeStart = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':' + commonDate.timeSlot.slice(2) + 'pm';
    timeEnd = (Number(commonDate.timeSlot.slice(0, 2)) - 12).toString() + ':00am'
  }
  result = {
    date: formatDay,
    timeStart: timeStart,
    timeEnd: timeEnd,
  }
  return result;
} 