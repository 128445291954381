import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { useStyles } from 'react-styles-hook';
import EventMailField from '../../components/Events/EventMailField';
import Header from '../../components/Header';
import EventRetailer from '../../components/Events/EventRetailer';
import EventCustomer from '../../components/Events/EventCustomer';
import EventCarrier from '../../components/Events/EventCarrier';
import EventBuildingManager from '../../components/Events/EventBuildingManager';
import getStyles from '../Events/style';
import checkCurrentRole from '../../utils/checkCurrentRole';
import roleToUppercase from '../../utils/roleToUppercase';
import { useParams } from 'react-router-dom';
import {
  getDeliveryEventsLaunched,
  updateDeliveryEventLaunched,
} from './reducer';
import DropFile from '../../components/Events/DropFile';
import { deleteFileS3 } from '../../utils/awsLib';
import TableEventLoading from '../../components/EventsManager/TableEventLoading';
import CircularProgress from '@mui/material/CircularProgress';
import { removeNonBooleans, sortByCreatedDayDesc } from '../../utils/format';
import { getBuildingLoading } from '../../service/reducer';
import DatePicker from '../../components/Events/DatePicker';
import AddTimeSlot from '../../components/Events/AddTimeSlot';
import BookingUser from '../../components/EventsManager/BookingUser';
export default function UpdateEvents() {
  const dispatch = useDispatch();

  const {
    buildingInfo,
    buildingLoading,
    uploadInsuranceFileLoading,
    uploadInsuranceFileSuccess,
    uploadInsuranceFileError,
    updateDeliveryEventLoading,
    updateDeliveryEventSuccessMessage,
    updateDeliveryEventErrorMessage,
  } = useSelector((state) => ({
    uploadInsuranceFileLoading: state.getIn([
      'app',
      'uploadInsuranceFileLoading',
    ]),
    uploadInsuranceFileSuccess: state.getIn([
      'app',
      'uploadInsuranceFileSuccess',
    ]),
    uploadInsuranceFileError: state.getIn(['app', 'uploadInsuranceFileError']),
    updateDeliveryEventLoading: state.getIn([
      'getEventStore',
      'updateDeliveryEventLoading',
    ]),
    updateDeliveryEventSuccessMessage: state.getIn([
      'getEventStore',
      'updateDeliveryEventSuccessMessage',
    ]),
    updateDeliveryEventErrorMessage: state.getIn([
      'getEventStore',
      'updateDeliveryEventErrorMessage',
    ]),
    buildingInfo: state.getIn(['app', 'getCurrentBuilding']),
    buildingLoading: state.getIn(['app', 'getBuildingLoading']),
  }));

  const [buildingList, setBuildingList] = useState([
    { buildingId: 0, buildingName: 'All Buildings' },
  ]);
  const [buildingSelected, setBuildingSelected] = useState(buildingList[0]);

  useEffect(() => {
    if (buildingSelected?.buildingId) {
      const role = currentRole;
      const buildingId = buildingSelected?.buildingId;
      const body = { role, buildingId };
      // reset previous File value
      setErrorCOIDocumentFileMessage(null);
      setCOIDocumentFileKeyId();
      setCOIDocumentFile();
      setCOIDocumentFileName();
      setUploadCOIDocumentFileErrorMessage();
      setUploadCOIDocumentFileSuccessMessage();

      setErrorBuildingDocumentFileMessage(null);
      setBuildingDocumentFileKeyId();
      setBuildingDocumentFile();
      setBuildingDocumentFileName(null);
      setUploadBuildingDocumentFileErrorMessage();
      setUploadBuildingDocumentFileSuccessMessage();
      dispatch(getBuildingLoading(body));
    }
  }, [buildingSelected]);

  const [successMessage, setSuccessMessage] = useState();

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      setSuccessMessage();
      return;
    }
    if (!updateDeliveryEventLoading && updateDeliveryEventSuccessMessage) {
      setSuccessMessage('Your event was updated !');
    }
  }, [updateDeliveryEventLoading, updateDeliveryEventSuccessMessage]);

  const classes = useStyles(getStyles());

  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  const [currentRole, setCurrentRole] = useState();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (userDynamo !== null) {
      delete userDynamo.firstLogin;
      setCurrUserInfo(userDynamo);
      setCurrentRole(checkCurrentRole(Object.keys(currUserInfo)[0]));
      setUserInfo(currUserInfo[currentRole]);
    }
  }, [userDynamo, userInfo, currentRole]);

  const [showRetailer, setShowRetailer] = useState(false);
  const toggleRetailer = () => {
    setShowRetailer(!showRetailer);
  };

  const [showCustomer, setShowCustomer] = useState(false);
  const toggleCustomer = () => {
    setShowCustomer(!showCustomer);
  };

  const [showCarrier, setShowCarrier] = useState(false);
  const toggleCarrier = () => {
    setShowCarrier(!showCarrier);
  };

  const [showBuildingManager, setShowBuildingManager] = useState(false);
  const toggleBuildingManager = () => {
    setShowBuildingManager(!showBuildingManager);
  };

  // init choosen date per user
  const [customerChoosenDateToUpdate, setCustomerChoosenDateToUpdate] =
    useState([]);

  const [
    buildingManagerChoosenDateToUpdate,
    setBuildingManagerChoosenDateToUpdate,
  ] = useState([]);
  const [carrierChoosenDateToUpdate, setCarrierChoosenDateToUpdate] = useState(
    []
  );
  const [retailerChoosenDateToUpdate, setRetailerChoosenDateToUpdate] =
    useState([]);

  // Get Event ID with use params
  const { deliveryEventId } = useParams();

  // Launch the call API until we get the current role and event ID
  useEffect(() => {
    if (currentRole && deliveryEventId) {
      getDeliveryEvents();
    }
  }, [currentRole, deliveryEventId]);

  // Call API to get all Info of the event
  const getDeliveryEvents = () => {
    dispatch(getDeliveryEventsLaunched({ deliveryEventId, role: currentRole }));
  };

  // Get all Info of the event into 'deliveryEvents'
  const { deliveryEvents, getDeliveryEventsLoading } = useSelector((state) => ({
    deliveryEvents: state.getIn(['getEventStore', 'deliveryEvents']),
    getDeliveryEventsLoading: state.getIn([
      'getEventStore',
      'getDeliveryEventsLoading',
    ]),
  }));

  //Set all event info in a useState 'formData'
  const [formData, setFormData] = useState(deliveryEvents);
  const [isEventCustomerVisible, setIsEventCustomerVisible] = useState(false);

  useEffect(() => {
    if (
      deliveryEvents !== null &&
      getDeliveryEventsLoading === false &&
      currentRole
    ) {
      deliveryEvents['currentRole'] = currentRole;
      setIsEventCustomerVisible(false);
      setFormData(deliveryEvents);

      if (deliveryEvents.customerDate) {
        const sortDate = sortByCreatedDayDesc(deliveryEvents.customerDate);
        setCustomerChoosenDateToUpdate(sortDate);
      }

      if (deliveryEvents.buildingManagerDate) {
        setBuildingManagerChoosenDateToUpdate(
          deliveryEvents.buildingManagerDate
        );
      }
      if (deliveryEvents.carrierDate) {
        setCarrierChoosenDateToUpdate(deliveryEvents.carrierDate);
      }
      if (deliveryEvents.retailerDate) {
        setRetailerChoosenDateToUpdate(deliveryEvents.retailerDate);
      }
      // at update invoiceFileKey = null if invoiceFile is missing
      if (
        deliveryEvents.invoiceFileKey ||
        deliveryEvents.invoiceFileKey === null
      ) {
        setInvoiceFileName(deliveryEvents.invoiceFileKey);
      }
      // at update entranceDoorFileKeyId = null if invoiceFile is missing
      if (
        deliveryEvents.entranceDoorFileKeyId ||
        deliveryEvents.entranceDoorFileKeyId === null
      ) {
        setEntranceDoorFileName(deliveryEvents.entranceDoorFileKeyId);
      }
      // at update freightDoorFileKeyId = null if invoiceFile is missing
      if (
        deliveryEvents.freightDoorFileKeyId ||
        deliveryEvents.freightDoorFileKeyId === null
      ) {
        setFreightDoorFileName(deliveryEvents.freightDoorFileKeyId);
      }
      // at update COIDocumentFileKeyId = null if invoiceFile is missing
      if (
        deliveryEvents.COIDocumentFileKeyId ||
        deliveryEvents.COIDocumentFileKeyId === null
      ) {
        setCOIDocumentFileName(deliveryEvents.COIDocumentFileKeyId);
      }
      // at update buildingDocumentFileKeyId = null if invoiceFile is missing
      if (
        deliveryEvents.buildingDocumentFileKeyId ||
        deliveryEvents.buildingDocumentFileKeyId === null
      ) {
        setBuildingDocumentFileName(deliveryEvents.buildingDocumentFileKeyId);
      }
    }
  }, [deliveryEvents, getDeliveryEventsLoading, currentRole]);

  let initialValues;
  if (formData) {
    initialValues = {
      id: formData?.id,
      eventNumber: formData?.eventNumber,
      emailCustomer: formData?.customerEmail || '',
      emailBuildingManager: formData?.emailBuildingManager || '',
      emailCarrier: formData?.carrierEmail || '',
      emailRetailer: formData?.retailerEmail || '',
      customerFirstname: formData?.customer?.firstName,
      customerLastname: formData?.customer?.lastName,
      customerPhone: formData?.customer?.phone,
      customerEINNumber: formData?.customer?.EINNumber,
      customerInternalRef: formData?.customer?.internalRef,
      // specific behaviour for boolean field
      customerSpecificFreightDoor:
        formData?.customer?.specificFreightDoor || false,
      customerElevator: formData?.customer?.elevator || false,
      customerSameFreightAddress:
        formData?.customer?.sameFreightAddress || false,
      customerFreightEntrance: formData?.customer?.freightEntrance || false,
      customerFreightElevator: formData?.customer?.freightElevator || false,
      customerServiceElevator: formData?.customer?.serviceElevator || false,
      customerBuildingManager: formData?.customer?.buildingManager || false,
      customerFreightDoor: formData?.customer?.freightDoor || false,
      //
      customerAddressState: formData?.customer?.customerState || null,
      customerTypeOfResidence: formData?.customer?.typeOfResidence || null,
      customerEntranceDoorOpeningDirection:
        formData?.customer?.entranceDoorOpeningDirection || null,
      customerFreightDoorOpeningDirection:
        formData?.customer?.freightDoorOpeningDirection || null,
      customerSpecificDoorLenght:
        formData?.customer?.specificDoorLenght || null,
      customerSpecificDoorHeight:
        formData?.customer?.specificDoorHeight || null,
      customerFreightAddress: formData?.customer?.freightAddress || null,
      customerFreightComplement: formData?.customer?.freightComplement || null,
      customerFreightState: formData?.customer?.freightState || null,
      customerFreightCity: formData?.customer?.freightCity || null,
      customerFreightZipcode: formData?.customer?.freightZipcode || null,
      customerFreightDoorLenght: formData?.customer?.freightDoorLenght || null,
      customerFreightDoorHeight: formData?.customer?.freightDoorHeight || null,
      customerFreightElevatorLength:
        formData?.customer?.freightElevatorLength || null,
      customerFreightElevatorHeight:
        formData?.customer?.freightElevatorHeight || null,
      customerFreightElevatorWidth:
        formData?.customer?.freightElevatorWidth || null,
      customerBmCompany: formData?.customer?.bmCompany || null,
      customerBmName: formData?.customer?.bmName || null,
      customerBmFirstName: formData?.customer?.bmFirstName || null,
      customerBmEmail: formData?.customer?.bmEmail || null,
      customerBmPhone: formData?.customer?.bmPhone || null,
      customerAccessCodeFreightEntrance:
        formData?.customer?.accessCodeFreightEntrance || null,
      customerAccessCodeDeliveryAddress:
        formData?.customer?.accessCodeDeliveryAddress || null,
      customerAccessInformationDeliveryAddress:
        formData?.customer?.accessInformationDeliveryAddress || null,
      customerAccessInformationFreightEntrance:
        formData?.customer?.accessInformationFreightEntrance || null,
      customerPassengerElevatorLength:
        formData?.customer?.passengerElevatorLength || null,
      customerPassengerElevatorHeight:
        formData?.customer?.passengerElevatorHeight || null,
      customerPassengerElevatorWidth:
        formData?.customer?.passengerElevatorWidth || null,
      customerEntranceLengthPassengerElevator:
        formData?.customer?.entranceLengthPassengerElevator || null,
      customerEntranceHeightPassengerElevator:
        formData?.customer?.entranceHeightPassengerElevator || null,
      customerEntranceHeightFreightElevator:
        formData?.customer?.entranceHeightFreightElevator || null,
      customerEntranceLengthFreightElevator:
        formData?.customer?.entranceLengthFreightElevator || null,

      customerAddress: formData?.deliveryAddress?.address,
      customerComplement: formData?.deliveryAddress?.complement,
      customerState: formData?.deliveryAddress?.state,
      customerCity: formData?.deliveryAddress?.city,
      customerZipcode: formData?.deliveryAddress?.zipcode,
      BMfirstName: formData?.buildingManager?.firstName,
      BMlastName: formData?.buildingManager?.lastName,
      BMphone: formData?.buildingManager?.phone,
      BMEINNumber: formData?.buildingManager?.EINNumber,
      BMInternalRef: formData?.buildingManager?.internalRef,
      BMfreightAddress: formData?.freightEntrance?.address,
      BMfreightComplement: formData?.freightEntrance?.complement,
      BMfreightState: formData?.freightEntrance?.state,
      BMfreightCity: formData?.freightEntrance?.city,
      BMfreightZipcode: formData?.freightEntrance?.zipcode,
      BMfreightOpeningHourStart: formData?.freightEntrance?.openingHourStart,
      BMfreightOpeningHourEnd: formData?.freightEntrance?.openingHourEnd,
      BMfreightElevator: formData?.freightEntrance?.freightElevator || false,
      BMfreightElevatorLenght: formData?.freightEntrance?.elevatorLenght,
      BMfreightElevatorHeight: formData?.freightEntrance?.elevatorHeight,
      BMfreightElevatorWidth: formData?.freightEntrance?.elevatorWidth,
      BMneedToBookElevator:
        formData?.freightEntrance?.needToBookElevator || false,
      BMcontactLastName: formData?.freightEntrance?.contactLastName,
      BMcontactFirstName: formData?.freightEntrance?.contactFirstName,
      BMcontactEmail: formData?.freightEntrance?.contactEmail,
      BMcontactPhone: formData?.freightEntrance?.contactPhone,

      BMname: formData?.building?.name,
      BMaddress: formData?.building?.address,
      BMcomplement: formData?.building?.complement,
      BMaddressState: formData?.building?.state,
      BMcity: formData?.building?.city,
      BMzipcode: formData?.building?.zipcode,
      BMtypeOfResidence: formData?.building?.typeOfResidence,
      BMdoorSizeLenght: formData?.building?.doorSizeLenght,
      BMdoorSizeHeight: formData?.building?.doorSizeHeight,
      BMelevatorLenght: formData?.building?.elevatorLenght,
      BMelevatorHeight: formData?.building?.elevatorHeight,
      BMelevatorWidth: formData?.building?.elevatorWidth,
      BMspecificFreightDoor: formData?.building?.specificFreightDoor || false,
      BMsameFreightAddress: formData?.building?.sameFreightAddress || true,
      BMspecificFreightDoorLenght:
        formData?.building?.specificFreightDoorLenght,
      BMspecificFreightDoorHeight:
        formData?.building?.specificFreightDoorHeight,
      BMdoorman: formData?.building?.doorman || false,
      BMvirtualDoorman: formData?.building?.virtualDoorman || false,
      BMaccessInformation: formData?.building?.accessInformation,
      BMCOIRequirement: formData?.building?.COIRequirement,

      retailerCompany: formData?.retailer?.company,
      retailerFirstName: formData?.retailer?.firstName,
      retailerLastName: formData?.retailer?.lastName,
      retailerPhone: formData?.retailer?.phone,
      retailerAddress: formData?.retailer?.address,
      retailerComplement: formData?.retailer?.complement,
      retailerState: formData?.retailer?.state,
      retailerCity: formData?.retailer?.city,
      retailerZipcode: formData?.retailer?.zipcode,
      retailerEINNumber: formData?.retailer?.EINNumber,
      retailerInternalRef: formData?.retailer?.internalRef,
      retailerReference: formData?.product?.reference,
      retailerLenght: formData?.product?.length,
      retailerWidth: formData?.product?.width,
      retailerHeight: formData?.product?.height,
      retailerWeight: formData?.product?.weight,

      carrierCompany: formData?.carrier?.company,
      carrierFirstName: formData?.carrier?.firstName,
      carrierLastName: formData?.carrier?.lastName,
      carrierPhone: formData?.carrier?.phone,
      carrierAddress: formData?.carrier?.address,
      carrierComplement: formData?.carrier?.complement,
      carrierState: formData?.carrier?.state,
      carrierCity: formData?.carrier?.city,
      carrierZipcode: formData?.carrier?.zipcode,
      carrierEINNumber: formData?.carrier?.EINNumber,
      carrierInternalRef: formData?.carrier?.internalRef,
      insuranceFileKey: formData?.insuranceFileKey,
      invoiceFileKey: formData?.invoiceFileKey,
      entranceDoorFileKeyId: formData?.entranceDoorFileKeyId,
      freightDoorFileKeyId: formData?.freightDoorFileKeyId,
      COIDocumentFileKeyId: formData?.COIDocumentFileKeyId,
      buildingDocumentFileKeyId: formData?.buildingDocumentFileKeyId,

      currentRole: formData?.currentRole,
    };
  } else initialValues = null;

  const [formValue, setFormValue] = useState({
    id: initialValues?.id,
    eventNumber: initialValues?.eventNumber,
    emailCustomer: initialValues?.customerEmail,
    emailBuildingManager: initialValues?.emailBuildingManager,
    emailCarrier: initialValues?.carrierEmail,
    customerFirstname: initialValues?.customerFirstname,
    customerLastname: initialValues?.customerLastname,
    customerPhone: initialValues?.customerPhone,
    customerEINNumber: initialValues?.customerEINNumber,
    customerInternalRef: initialValues?.customerInternalRef,
    customerAddress: initialValues?.customerAddress,
    customerComplement: initialValues?.customerComplement,
    customerState: initialValues?.customerState,
    customerCity: initialValues?.customerCity,
    customerZipcode: initialValues?.customerZipcode,
    // specific behaviour for boolean field
    customerSpecificFreightDoor:
      initialValues?.customerSpecificFreightDoor || null,
    customerElevator: initialValues?.customerElevator || null,
    customerSameFreightAddress:
      initialValues?.customerSameFreightAddress || null,
    customerFreightEntrance: initialValues?.customerFreightEntrance || null,
    customerFreightElevator: initialValues?.customerFreightElevator || null,
    customerServiceElevator: initialValues?.customerServiceElevator || null,
    customerBuildingManager: initialValues?.customerBuildingManager || null,
    //
    customerAddressState: initialValues?.customerAddressState || null,
    customerTypeOfResidence: initialValues?.customerTypeOfResidence || null,
    customerEntranceDoorOpeningDirection:
      initialValues?.customerEntranceDoorOpeningDirection || null,
    customerFreightDoorOpeningDirection:
      initialValues?.customerFreightDoorOpeningDirection || null,
    customerSpecificDoorLenght:
      initialValues?.customerSpecificDoorLenght || null,
    customerSpecificDoorHeight:
      initialValues?.customerSpecificDoorHeight || null,
    customerFreightAddress: initialValues?.customerFreightAddress || null,
    customerFreightComplement: initialValues?.customerFreightComplement || null,
    customerFreightState: initialValues?.customerFreightState || null,
    customerFreightCity: initialValues?.customerFreightCity || null,
    customerFreightZipcode: initialValues?.customerFreightZipcode || null,
    customerFreightDoorLenght: initialValues?.customerFreightDoorLenght || null,
    customerFreightDoorHeight: initialValues?.customerFreightDoorHeight || null,
    customerFreightElevatorLength:
      initialValues?.customerFreightElevatorLength || null,
    customerFreightElevatorHeight:
      initialValues?.customerFreightElevatorHeight || null,
    customerFreightElevatorWidth:
      initialValues?.customerFreightElevatorWidth || null,
    customerBmCompany: initialValues?.customerBmCompany || null,
    customerBmName: initialValues?.customerBmName || null,
    customerBmFirstName: initialValues?.customerBmFirstName || null,
    customerBmEmail: initialValues?.customerBmEmail || null,
    customerBmPhone: initialValues?.customerBmPhone || null,
    customerAccessCodeFreightEntrance:
      initialValues?.customerAccessCodeFreightEntrance || null,
    customerAccessCodeDeliveryAddress:
      initialValues?.customerAccessCodeDeliveryAddress || null,
    customerAccessInformationDeliveryAddress:
      initialValues?.customerAccessInformationDeliveryAddress || null,
    customerAccessInformationFreightEntrance:
      initialValues?.customerAccessInformationFreightEntrance || null,
    customerPassengerElevatorLength:
      initialValues?.customerPassengerElevatorLength || null,
    customerPassengerElevatorHeight:
      initialValues?.customerPassengerElevatorHeight || null,
    customerPassengerElevatorWidth:
      initialValues?.customerPassengerElevatorWidth || null,
    customerEntranceLengthPassengerElevator:
      initialValues?.customerEntranceLengthPassengerElevator || null,
    customerEntranceHeightPassengerElevator:
      initialValues?.customerEntranceHeightPassengerElevator || null,
    customerEntranceHeightFreightElevator:
      initialValues?.customerEntranceHeightFreightElevator || null,
    customerEntranceLengthFreightElevator:
      initialValues?.customerEntranceLengthFreightElevator || null,

    BMfirstName: initialValues?.BMfirstName,
    BMlastName: initialValues?.BMlastName,
    BMphone: initialValues?.BMphone,
    BMEINNumber: initialValues?.BMEINNumber,
    BMInternalRef: initialValues?.BMInternalRef,

    BMfreightAddress: initialValues?.BMfreightAddress,
    BMfreightComplement: initialValues?.BMfreightComplement,
    BMfreightState: initialValues?.BMfreightState,
    BMfreightCity: initialValues?.BMfreightCity,
    BMfreightZipcode: initialValues?.BMfreightZipcode,
    BMfreightOpeningHourStart: initialValues?.BMfreightOpeningHourStart,
    BMfreightOpeningHourEnd: initialValues?.BMfreightOpeningHourEnd,
    BMfreightElevator: initialValues?.BMfreightElevator || false,
    BMfreightElevatorLenght: initialValues?.BMfreightElevatorLenght,
    BMfreightElevatorHeight: initialValues?.BMfreightElevatorHeight,
    BMfreightElevatorWidth: initialValues?.BMfreightElevatorWidth,
    BMneedToBookElevator: initialValues?.BMneedToBookElevator || false,
    BMcontactLastName: initialValues?.BMcontactLastName,
    BMcontactFirstName: initialValues?.BMcontactFirstName,
    BMcontactEmail: initialValues?.BMcontactEmail,
    BMcontactPhone: initialValues?.BMcontactPhone,

    retailerCompany: initialValues?.retailerCompany,
    retailerFirstName: initialValues?.retailerFirstName,
    retailerLastName: initialValues?.retailerLastName,
    retailerPhone: initialValues?.retailerPhone,
    retailerAddress: initialValues?.retailerAddress,
    retailerComplement: initialValues?.retailerComplement,
    retailerState: initialValues?.retailerState,
    retailerCity: initialValues?.retailerCity,
    retailerZipcode: initialValues?.retailerZipcode,
    retailerEINNumber: initialValues?.retailerEINNumber,
    retailerInternalRef: initialValues?.retailerInternalRef,

    retailerReference: initialValues?.retailerReference,
    retailerLenght: initialValues?.retailerLenght,
    retailerWidth: initialValues?.retailerWidth,
    retailerHeight: initialValues?.retailerHeight,
    retailerWeight: initialValues?.retailerWeight,

    carrierCompany: initialValues?.carrierCompany,
    carrierFirstName: initialValues?.carrierFirstName,
    carrierLastName: initialValues?.carrierLastName,
    carrierPhone: initialValues?.carrierPhone,
    carrierAddress: initialValues?.carrierAddress,
    carrierComplement: initialValues?.carrierComplement,
    carrierState: initialValues?.carrierState,
    carrierCity: initialValues?.carrierCity,
    carrierZipcode: initialValues?.carrierZipcode,
    carrierEINNumber: initialValues?.carrierEINNumber,
    carrierInternalRef: initialValues?.carrierInternalRef,

    BMname: initialValues?.BMname,
    BMaddress: initialValues?.BMaddress,
    BMcomplement: initialValues?.BMcomplement,
    BMaddressState: initialValues?.BMaddressState,
    BMcity: initialValues?.BMcity,
    BMzipcode: initialValues?.BMzipcode,
    BMtypeOfResidence: initialValues?.BMtypeOfResidence,
    BMdoorSizeLenght: initialValues?.BMdoorSizeLenght,
    BMdoorSizeHeight: initialValues?.BMdoorSizeHeight,
    BMelevatorLenght: initialValues?.BMelevatorLenght,
    BMelevatorHeight: initialValues?.BMelevatorHeight,
    BMelevatorWidth: initialValues?.BMelevatorWidth,
    BMspecificFreightDoor: initialValues?.BMspecificFreightDoor || false,
    BMsameFreightAddress: initialValues?.BMsameFreightAddress || false,
    BMspecificFreightDoorLenght: initialValues?.BMspecificFreightDoorLenght,
    BMspecificFreightDoorHeight: initialValues?.BMspecificFreightDoorHeight,
    BMdoorman: initialValues?.BMdoorman || false,
    BMvirtualDoorman: initialValues?.BMvirtualDoorman || false,
    BMaccessInformation: initialValues?.BMaccessInformation,
    BMCOIRequirement: initialValues?.BMCOIRequirement,
    emailRetailer: initialValues?.retailerEmail,
    invoiceFileKey: initialValues?.invoiceFileKey,
    entranceDoorFileKeyId: initialValues?.entranceDoorFileKeyId,
    freightDoorFileKeyId: initialValues?.freightDoorFileKeyId,
    COIDocumentFileKeyId: initialValues?.COIDocumentFileKeyId,
    buildingDocumentFileKeyId: initialValues?.buildingDocumentFileKeyId,
    insuranceFileKey: initialValues?.insuranceFileKey,
    currentRole: initialValues?.currentRole,
  });

  const finalForm = {
    id: formValue.id || null,
    carrierEmail: formValue.emailCarrier || null,
    customerEmail: formValue.emailCustomer || null,
    customer: {
      // specific behaviour for boolean field
      specificFreightDoor: formValue?.specificFreightDoor || false,
      elevator: formValue?.elevator || false,
      sameFreightAddress: formValue?.sameFreightAddress || false,
      freightEntrance: formValue?.freightEntrance || false,
      freightElevator: formValue?.freightElevator || false,
      serviceElevator: formValue?.serviceElevator || false,
      buildingManager: formValue?.buildingManager || false,
      freightDoor: formValue?.freightDoor || false,
      //
      firstName: formValue.customerFirstname || null,
      lastName: formValue.customerLastname || null,
      phone: formValue.customerPhone || null,
      EINNumber: formValue.customerEINNumber || null,
      internalRef: formValue.customerInternalRef || null,
      address: formValue?.customerAddress || null,
      complement: formValue?.customerComplement || null,
      addressState: formValue?.customerState || null,
      city: formValue?.customerCity || null,
      zipcode: formValue?.customerZipcode || null,
      typeOfResidence: formValue?.typeOfResidence || null,
      entranceDoorOpeningDirection:
        formValue?.entranceDoorOpeningDirection || null,
      freightDoorOpeningDirection:
        formValue?.freightDoorOpeningDirection || null,
      specificDoorLenght: formValue?.specificDoorLenght || null,
      specificDoorHeight: formValue?.specificDoorHeight || null,
      freightAddress: formValue?.freightAddress || null,
      freightComplement: formValue?.freightComplement || null,
      freightState: formValue?.freightState || null,
      freightCity: formValue?.freightCity || null,
      freightZipcode: formValue?.freightZipcode || null,
      freightDoorLenght: formValue?.freightDoorLenght || null,
      freightDoorHeight: formValue?.freightDoorHeight || null,
      freightElevatorLength: formValue?.freightElevatorLength || null,
      freightElevatorHeight: formValue?.freightElevatorHeight || null,
      freightElevatorWidth: formValue?.freightElevatorWidth || null,
      bmCompany: formValue?.bmCompany || null,
      bmName: formValue?.bmName || null,
      bmFirstName: formValue?.bmFirstName || null,
      bmEmail: formValue?.bmEmail || null,
      bmPhone: formValue?.bmPhone || null,
      accessCodeFreightEntrance: formValue?.accessCodeFreightEntrance || null,
      accessCodeDeliveryAddress: formValue?.accessCodeDeliveryAddress || null,
      accessInformationDeliveryAddress:
        formValue?.accessInformationDeliveryAddress || null,
      accessInformationFreightEntrance:
        formValue?.accessInformationFreightEntrance || null,
      passengerElevatorLength: formValue?.passengerElevatorLength || null,
      passengerElevatorHeight: formValue?.passengerElevatorHeight || null,
      passengerElevatorWidth: formValue?.passengerElevatorWidth || null,
      entranceLengthPassengerElevator:
        formValue?.entranceLengthPassengerElevator || null,
      entranceHeightPassengerElevator:
        formValue?.entranceHeightPassengerElevator || null,
      entranceHeightFreightElevator:
        formValue?.entranceHeightFreightElevator || null,
      entranceLengthFreightElevator:
        formValue?.entranceLengthFreightElevator || null,
    },
    deliveryAddress: {
      address: formValue.customerAddress || null,
      complement: formValue.customerComplement || null,
      state: formValue.customerState || null,
      city: formValue.customerCity || null,
      zipcode: formValue.customerZipcode || null,
    },
    buildingManager: {
      firstName: formValue.BMfirstName || null,
      lastName: formValue.BMlastName || null,
      phone: formValue.BMphone || null,
      EINNumber: formValue.BMEINNumber || null,
      internalRef: formValue.BMInternalRef || null,
    },

    freightEntrance: {
      address: formValue.BMfreightAddress || null,
      complement: formValue.BMfreightComplement || null,
      state: formValue.BMfreightState || null,
      city: formValue.BMfreightCity || null,
      zipcode: formValue.BMfreightZipcode || null,
      openingHourStart: formValue.BMfreightOpeningHourStart || null,
      openingHourEnd: formValue.BMfreightOpeningHourEnd || null,
      freightElevator: formValue.BMfreightElevator || false,
      elevatorLenght: formValue.BMfreightElevatorLenght || null,
      elevatorHeight: formValue.BMfreightElevatorHeight || null,
      elevatorWidth: formValue.BMfreightElevatorWidth || null,
      needToBookElevator: formValue.BMneedToBookElevator || false,
      contactLastName: formValue.BMcontactLastName || null,
      contactFirstName: formValue.BMcontactFirstName || null,
      contactEmail: formValue.BMcontactEmail || null,
      contactPhone: formValue.BMcontactPhone || null,
    },
    retailer: {
      company: formValue.retailerCompany || null,
      firstName: formValue.retailerFirstName || null,
      lastName: formValue.retailerLastName || null,
      phone: formValue.retailerPhone || null,
      address: formValue.retailerAddress || null,
      complement: formValue.retailerComplement || null,
      state: formValue.retailerState || null,
      city: formValue.retailerCity || null,
      zipcode: formValue.retailerZipcode || null,
      EINNumber: formValue.retailerEINNumber || null,
      internalRef: formValue.retailerInternalRef || null,
    },
    product: {
      reference: formValue.retailerReference || null,
      length: formValue.retailerLenght || null,
      width: formValue.retailerWidth || null,
      height: formValue.retailerHeight || null,
      weight: formValue.retailerWeight || null,
    },
    carrier: {
      company: formValue.carrierCompany || null,
      firstName: formValue.carrierFirstName || null,
      lastName: formValue.carrierLastName || null,
      phone: formValue.carrierPhone || null,
      address: formValue.carrierAddress || null,
      complement: formValue.carrierComplement || null,
      state: formValue.carrierState || null,
      city: formValue.carrierCity || null,
      zipcode: formValue.carrierZipcode || null,
      EINNumber: formValue.carrierEINNumber || null,
      internalRef: formValue.carrierInternalRef || null,
    },
    insuranceFileKey: formValue.insuranceFileKey || null,
    invoiceFileKey: formValue.invoiceFileKey || null,
    entranceDoorFileKeyId: formValue.entranceDoorFileKeyId || null,
    freightDoorFileKeyId: formValue.freightDoorFileKeyId || null,
    COIDocumentFileKeyId: formValue?.COIDocumentFileKeyId || null,
    buildingDocumentFileKeyId: formValue?.buildingDocumentFileKeyId || null,
    updatedBy: roleToUppercase(formValue.currentRole) || null,
    role: formValue.currentRole || null,
  };

  if (
    formValue.BMaddress &&
    formValue.BMaddressState &&
    formValue.BMzipcode &&
    formValue.BMname
  ) {
    finalForm.building = {
      name: formValue.BMname || null,
      address: formValue.BMaddress || null,
      complement: formValue.BMcomplement || null,
      state: formValue.BMaddressState || null,
      city: formValue.BMcity || null,
      zipcode: formValue.BMzipcode || null,
      typeOfResidence: formValue.BMtypeOfResidence || null,
      doorSizeLenght: formValue.BMdoorSizeLenght || null,
      doorSizeHeight: formValue.BMdoorSizeHeight || null,
      elevatorLenght: formValue.BMelevatorLenght || null,
      elevatorHeight: formValue.BMelevatorHeight || null,
      elevatorWidth: formValue.BMelevatorWidth || null,
      specificFreightDoor: formValue.BMspecificFreightDoor || false,
      sameFreightAddress: formValue.BMsameFreightAddress || false,
      specificFreightDoorLenght: formValue.BMspecificFreightDoorLenght || null,
      specificFreightDoorHeight: formValue.BMspecificFreightDoorHeight || null,
      doorman: formValue.BMdoorman || false,
      virtualDoorman: formValue.BMvirtualDoorman || false,
      accessInformation: formValue.BMaccessInformation || null,
      COIRequirement: formValue.BMCOIRequirement || null,
    };
  }
  if (formValue.emailBuildingManager || currentRole === 'buildingManager') {
    finalForm.buildingManagerEmail =
      formValue.emailBuildingManager || userInfo?.email;
  }
  if (formValue.emailRetailer || currentRole === 'retailer') {
    finalForm.retailerEmail = formValue.emailRetailer || userInfo?.email;
  }

  // submit function
  const doUpdateEvent = () => {
    if (customerChoosenDateToUpdate.length !== 0) {
      finalForm.customerDate = customerChoosenDateToUpdate;
    }
    if (retailerChoosenDateToUpdate.length !== 0) {
      finalForm.retailerDate = retailerChoosenDateToUpdate;
    }
    if (buildingManagerChoosenDateToUpdate.length !== 0) {
      finalForm.buildingManagerDate = buildingManagerChoosenDateToUpdate;
    }
    if (carrierChoosenDateToUpdate.length !== 0) {
      finalForm.carrierDate = carrierChoosenDateToUpdate;
    }
    if (fileKeyId) {
      finalForm.insuranceFileKey = fileKeyId;
    }
    if (!fileName) {
      finalForm.insuranceFileKey = null;
    }
    if (invoiceFileKeyId) {
      finalForm.invoiceFileKey = invoiceFileKeyId;
    }
    if (!invoiceFileName) {
      finalForm.invoiceFileKey = null;
    }
    // entrance
    if (entranceDoorFileKeyId) {
      finalForm.entranceDoorFileKeyId = entranceDoorFileKeyId;
    }
    if (!entranceDoorFileName) {
      finalForm.entranceDoorFileKeyId = null;
    }
    // freight
    if (freightDoorFileKeyId) {
      finalForm.freightDoorFileKeyId = freightDoorFileKeyId;
    }
    if (!freightDoorFileName) {
      finalForm.freightDoorFileKeyId = null;
    }
    // entrance
    if (COIDocumentFileKeyId) {
      finalForm.COIDocumentFileKeyId = COIDocumentFileKeyId;
    }
    if (!COIDocumentFileName) {
      finalForm.COIDocumentFileKeyId = null;
    }
    // freight
    if (buildingDocumentFileKeyId) {
      finalForm.buildingDocumentFileKeyId = buildingDocumentFileKeyId;
    }
    if (!buildingDocumentFileName) {
      finalForm.buildingDocumentFileKeyId = null;
    }
    setSuccessMessage(updateDeliveryEventSuccessMessage);
    dispatch(updateDeliveryEventLaunched(finalForm));
  };

  // Insurance File
  const [fileKeyId, setFileKeyId] = useState();
  const [fileName, setFileName] = useState();
  const [file, setFile] = useState();
  const [errorFileMessage, setErrorFileMessage] = useState(null);

  // Invoice File
  const [invoiceFileKeyId, setInvoiceFileKeyId] = useState();
  const [invoiceFileName, setInvoiceFileName] = useState();
  const [invoiceFile, setInvoiceFile] = useState();
  const [errorInvoiceFileMessage, setErrorInvoiceFileMessage] = useState(null);
  const [uploadFileSuccessMessage, setUploadFileSuccessMessage] = useState();
  const [uploadFileErrorMessage, setUploadFileErrorMessage] = useState();

  // customer Entrance Door File
  const [entranceDoorFileKeyId, setEntranceDoorFileKeyId] = useState();
  const [entranceDoorFileName, setEntranceDoorFileName] = useState();
  const [entranceDoorFile, setEntranceDoorFile] = useState();
  const [errorEntranceFileMessage, setErrorEntranceFileMessage] =
    useState(null);
  const [
    uploadEntranceDoorFileSuccessMessage,
    setUploadEntranceDoorFileSuccessMessage,
  ] = useState();
  const [
    uploadEntranceDoorFileErrorMessage,
    setUploadEntranceDoorFileErrorMessage,
  ] = useState();

  // customer Freight Door File
  const [freightDoorFileKeyId, setFreightDoorFileKeyId] = useState();
  const [freightDoorFileName, setFreightDoorFileName] = useState();
  const [freightDoorFile, setFreightDoorFile] = useState();
  const [errorFreightFileMessage, setErrorFreightFileMessage] = useState(null);
  const [
    uploadFreightDoorFileSuccessMessage,
    setUploadFreightDoorFileSuccessMessage,
  ] = useState();
  const [
    uploadFreightDoorFileErrorMessage,
    setUploadFreightDoorFileErrorMessage,
  ] = useState();

  // COI Document BM File
  const [COIDocumentFileKeyId, setCOIDocumentFileKeyId] = useState();
  const [COIDocumentFileName, setCOIDocumentFileName] = useState();
  const [COIDocumentFile, setCOIDocumentFile] = useState();
  const [errorCOIDocumentFileMessage, setErrorCOIDocumentFileMessage] =
    useState(null);
  const [
    uploadCOIDocumentFileSuccessMessage,
    setUploadCOIDocumentFileSuccessMessage,
  ] = useState();
  const [
    uploadCOIDocumentFileErrorMessage,
    setUploadCOIDocumentFileErrorMessage,
  ] = useState();

  //  Document building BM File
  const [buildingDocumentFileKeyId, setBuildingDocumentFileKeyId] = useState();
  const [buildingDocumentFileName, setBuildingDocumentFileName] = useState();
  const [buildingDocumentFile, setBuildingDocumentFile] = useState();
  const [errorBuildingDocumentFileMessage, setErrorBuildingDocumentFileMessage] = useState(null);
  const [
    uploadBuildingDocumentFileSuccessMessage,
    setUploadBuildingDocumentFileSuccessMessage,
  ] = useState();
  const [
    uploadBuildingDocumentFileErrorMessage,
    setUploadBuildingDocumentFileErrorMessage,
  ] = useState();

  const deleteFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorFileMessage(null);
      setFileKeyId();
      setFile();
      setFileName();
      deleteFileS3(fileKeyId);
    }
  };
  // deleteInvoiceFile
  const deleteInvoiceFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorInvoiceFileMessage(null);
      setInvoiceFileKeyId();
      setInvoiceFile();
      setInvoiceFileName();
      deleteFileS3(fileKeyId);
    }
  };
  const deleteEntranceFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorEntranceFileMessage(null);
      setEntranceDoorFileKeyId();
      setEntranceDoorFile();
      setEntranceDoorFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };

  const deleteFreightFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorFreightFileMessage(null);
      setFreightDoorFileKeyId();
      setFreightDoorFile();
      setFreightDoorFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };
  const deleteCOIDocumentFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorCOIDocumentFileMessage(null);
      setCOIDocumentFileKeyId();
      setCOIDocumentFile();
      setCOIDocumentFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };
  const deleteBuildingDocumentFile = (fileKeyId) => {
    if (fileKeyId) {
      setErrorBuildingDocumentFileMessage(null);
      setBuildingDocumentFileKeyId();
      setBuildingDocumentFile();
      setBuildingDocumentFileName(null);
      // deleteFileS3(fileKeyId); // Permits to not delete the orignal file which is loaded there
    }
  };
  useEffect(() => {
    setFormValue({
      id: deliveryEvents?.id,
      eventNumber: deliveryEvents?.eventNumber,
      emailCustomer: deliveryEvents?.customerEmail,
      emailBuildingManager: deliveryEvents?.buildingManagerEmail,
      emailCarrier: deliveryEvents?.carrierEmail,

      customerFirstname:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.firstName === null
          ? userInfo?.firstName
          : deliveryEvents?.customer?.firstName,

      customerLastname:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.lastName === null
          ? userInfo?.lastName
          : deliveryEvents?.customer?.lastName,
      customerPhone:
        currentRole === 'customer' && deliveryEvents?.customer?.phone === null
          ? userInfo?.phone
          : deliveryEvents?.customer?.phone,
      customerEINNumber: deliveryEvents?.customer?.EINNumber,
      customerInternalRef: deliveryEvents?.customer?.internalRef,

      // specific behaviour for boolean field

      specificFreightDoor:
        buildingInfo && !deliveryEvents?.customer?.specificFreightDoor
          ? userInfo?.specificFreightDoor
          : deliveryEvents?.customer?.specificFreightDoor,

      elevator:
        buildingInfo && !deliveryEvents?.customer?.elevator
          ? userInfo?.elevator
          : deliveryEvents?.customer?.elevator,

      sameFreightAddress:
        buildingInfo && !deliveryEvents?.customer?.sameFreightAddress
          ? userInfo?.sameFreightAddress
          : deliveryEvents?.customer?.sameFreightAddress,

      freightEntrance:
        buildingInfo && !deliveryEvents?.customer?.freightEntrance
          ? userInfo?.freightEntrance
          : deliveryEvents?.customer?.freightEntrance,

      freightElevator:
        buildingInfo && !deliveryEvents?.customer?.freightElevator
          ? userInfo?.freightElevator
          : deliveryEvents?.customer?.freightElevator,

      serviceElevator:
        buildingInfo && !deliveryEvents?.customer?.serviceElevator
          ? userInfo?.serviceElevator
          : deliveryEvents?.customer?.serviceElevator,

      buildingManager:
        buildingInfo && !deliveryEvents?.customer?.buildingManager
          ? userInfo?.buildingManager
          : deliveryEvents?.customer?.buildingManager,

      freightDoor:
        buildingInfo && !deliveryEvents?.customer?.freightDoor
          ? userInfo?.freightDoor
          : deliveryEvents?.customer?.freightDoor,

      //

      typeOfResidence:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.typeOfResidence === null
          ? userInfo?.typeOfResidence
          : deliveryEvents?.customer?.typeOfResidence,

      entranceDoorOpeningDirection:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.entranceDoorOpeningDirection === null
          ? userInfo?.entranceDoorOpeningDirection
          : deliveryEvents?.customer?.entranceDoorOpeningDirection,

      freightDoorOpeningDirection:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightDoorOpeningDirection === null
          ? userInfo?.freightDoorOpeningDirection
          : deliveryEvents?.customer?.freightDoorOpeningDirection,

      specificDoorLenght:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.specificDoorLenght === null
          ? userInfo?.specificDoorLenght
          : deliveryEvents?.customer?.specificDoorLenght,

      specificDoorHeight:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.specificDoorHeight === null
          ? userInfo?.specificDoorHeight
          : deliveryEvents?.customer?.specificDoorHeight,

      freightAddress:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightAddress === null
          ? userInfo?.freightAddress
          : deliveryEvents?.customer?.freightAddress,

      freightComplement:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightComplement === null
          ? userInfo?.freightComplement
          : deliveryEvents?.customer?.freightComplement,

      freightState:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightState === null
          ? userInfo?.freightState
          : deliveryEvents?.customer?.freightState,

      freightCity:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightCity === null
          ? userInfo?.freightCity
          : deliveryEvents?.customer?.freightCity,

      freightZipcode:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightZipcode === null
          ? userInfo?.freightZipcode
          : deliveryEvents?.customer?.freightZipcode,

      freightDoorLenght:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightDoorLenght === null
          ? userInfo?.freightDoorLenght
          : deliveryEvents?.customer?.freightDoorLenght,

      freightDoorHeight:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightDoorHeight === null
          ? userInfo?.freightDoorHeight
          : deliveryEvents?.customer?.freightDoorHeight,

      freightElevatorLength:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightElevatorLength === null
          ? userInfo?.freightElevatorLength
          : deliveryEvents?.customer?.freightElevatorLength,

      freightElevatorHeight:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightElevatorHeight === null
          ? userInfo?.freightElevatorHeight
          : deliveryEvents?.customer?.freightElevatorHeight,

      freightElevatorWidth:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.freightElevatorWidth === null
          ? userInfo?.freightElevatorWidth
          : deliveryEvents?.customer?.freightElevatorWidth,

      elevatorWidth:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.elevatorWidth === null
          ? userInfo?.elevatorWidth
          : deliveryEvents?.customer?.elevatorWidth, // must not be used now

      bmCompany:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.bmCompany === null
          ? userInfo?.bmCompany
          : deliveryEvents?.customer?.bmCompany,

      bmName:
        currentRole === 'customer' && deliveryEvents?.customer?.bmName === null
          ? userInfo?.bmName
          : deliveryEvents?.customer?.bmName,

      bmFirstName:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.bmFirstName === null
          ? userInfo?.bmFirstName
          : deliveryEvents?.customer?.bmFirstName,

      bmEmail:
        currentRole === 'customer' && deliveryEvents?.customer?.bmEmail === null
          ? userInfo?.bmEmail
          : deliveryEvents?.customer?.bmEmail,

      bmPhone:
        currentRole === 'customer' && deliveryEvents?.customer?.bmPhone === null
          ? userInfo?.bmPhone
          : deliveryEvents?.customer?.bmPhone,

      accessCodeFreightEntrance:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.accessCodeFreightEntrance === null
          ? userInfo?.accessCodeFreightEntrance
          : deliveryEvents?.customer?.accessCodeFreightEntrance,

      accessCodeDeliveryAddress:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.accessCodeDeliveryAddress === null
          ? userInfo?.accessCodeDeliveryAddress
          : deliveryEvents?.customer?.accessCodeDeliveryAddress,

      accessInformationDeliveryAddress:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.accessInformationDeliveryAddress === null
          ? userInfo?.accessInformationDeliveryAddress
          : deliveryEvents?.customer?.accessInformationDeliveryAddress,

      accessInformationFreightEntrance:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.accessInformationFreightEntrance === null
          ? userInfo?.accessInformationFreightEntrance
          : deliveryEvents?.customer?.accessInformationFreightEntrance,

      passengerElevatorLength:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.passengerElevatorLength === null
          ? userInfo?.passengerElevatorLength
          : deliveryEvents?.customer?.passengerElevatorLength,

      passengerElevatorHeight:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.passengerElevatorHeight === null
          ? userInfo?.passengerElevatorHeight
          : deliveryEvents?.customer?.passengerElevatorHeight,

      passengerElevatorWidth:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.passengerElevatorWidth === null
          ? userInfo?.passengerElevatorWidth
          : deliveryEvents?.customer?.passengerElevatorWidth,

      entranceLengthPassengerElevator:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.entranceLengthPassengerElevator === null
          ? userInfo?.entranceLengthPassengerElevator
          : deliveryEvents?.customer?.entranceLengthPassengerElevator,

      entranceHeightPassengerElevator:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.entranceHeightPassengerElevator === null
          ? userInfo?.entranceHeightPassengerElevator
          : deliveryEvents?.customer?.entranceHeightPassengerElevator,

      entranceHeightFreightElevator:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.entranceHeightFreightElevator === null
          ? userInfo?.entranceHeightFreightElevator
          : deliveryEvents?.customer?.entranceHeightFreightElevator,

      entranceLengthFreightElevator:
        currentRole === 'customer' &&
        deliveryEvents?.customer?.entranceLengthFreightElevator === null
          ? userInfo?.entranceLengthFreightElevator
          : deliveryEvents?.customer?.entranceLengthFreightElevator,

      customerAddress:
        currentRole === 'customer' &&
        deliveryEvents?.deliveryAddress?.address === null
          ? userInfo?.address
          : deliveryEvents?.deliveryAddress?.address,
      customerComplement:
        currentRole === 'customer' &&
        deliveryEvents?.deliveryAddress?.complement === null
          ? userInfo?.complement
          : deliveryEvents?.deliveryAddress?.complement,
      customerState:
        currentRole === 'customer' &&
        deliveryEvents?.deliveryAddress?.state === null
          ? userInfo?.addressState
          : deliveryEvents?.deliveryAddress?.state,
      customerCity:
        currentRole === 'customer' &&
        deliveryEvents?.deliveryAddress?.city === null
          ? userInfo?.city
          : deliveryEvents?.deliveryAddress?.city,
      customerZipcode:
        currentRole === 'customer' &&
        deliveryEvents?.deliveryAddress?.zipcode === null
          ? userInfo?.zipcode
          : deliveryEvents?.deliveryAddress?.zipcode,

      BMfirstName:
        currentRole === 'buildingManager' &&
        deliveryEvents?.buildingManager?.firstName === null
          ? userInfo?.firstName
          : deliveryEvents?.buildingManager?.firstName,
      BMlastName:
        currentRole === 'buildingManager' &&
        deliveryEvents?.buildingManager?.lastName === null
          ? userInfo?.lastName
          : deliveryEvents?.buildingManager?.lastName,
      BMphone:
        currentRole === 'buildingManager' &&
        deliveryEvents?.buildingManager?.phone === null
          ? userInfo?.phone
          : deliveryEvents?.buildingManager?.phone,
      BMEINNumber: deliveryEvents?.buildingManager?.EINNumber,
      BMInternalRef: deliveryEvents?.buildingManager?.internalRef,

      BMfreightAddress: deliveryEvents?.freightEntrance?.address,
      BMfreightComplement: deliveryEvents?.freightEntrance?.complement,
      BMfreightState: deliveryEvents?.freightEntrance?.state,
      BMfreightCity: deliveryEvents?.freightEntrance?.city,
      BMfreightZipcode: deliveryEvents?.freightEntrance?.zipcode,
      BMfreightOpeningHourStart:
        deliveryEvents?.freightEntrance?.openingHourStart,
      BMfreightOpeningHourEnd: deliveryEvents?.freightEntrance?.openingHourEnd,
      BMfreightElevator:
        deliveryEvents?.freightEntrance?.freightElevator || false,
      BMfreightElevatorLenght: deliveryEvents?.freightEntrance?.elevatorLenght,
      BMfreightElevatorHeight: deliveryEvents?.freightEntrance?.elevatorHeight,
      BMfreightElevatorWidth: deliveryEvents?.freightEntrance?.elevatorWidth,
      BMneedToBookElevator:
        deliveryEvents?.freightEntrance?.needToBookElevator || false,
      BMcontactLastName: deliveryEvents?.freightEntrance?.contactLastName,
      BMcontactFirstName: deliveryEvents?.freightEntrance?.contactFirstName,
      BMcontactEmail: deliveryEvents?.freightEntrance?.contactEmail,
      BMcontactPhone: deliveryEvents?.freightEntrance?.contactPhone,

      retailerCompany:
        currentRole === 'retailer' && deliveryEvents?.retailer?.company === null
          ? userInfo?.company
          : deliveryEvents?.retailer?.company,
      retailerFirstName:
        currentRole === 'retailer' &&
        deliveryEvents?.retailer?.firstName === null
          ? userInfo?.firstName
          : deliveryEvents?.retailer?.firstName,
      retailerLastName:
        currentRole === 'retailer' &&
        deliveryEvents?.retailer?.lastName === null
          ? userInfo?.lastName
          : deliveryEvents?.retailer?.lastName,
      retailerPhone:
        currentRole === 'retailer' && deliveryEvents?.retailer?.phone === null
          ? userInfo?.phone
          : deliveryEvents?.retailer?.phone,
      retailerAddress:
        currentRole === 'retailer' && deliveryEvents?.retailer?.address === null
          ? userInfo?.address
          : deliveryEvents?.retailer?.address,
      retailerComplement:
        currentRole === 'retailer' &&
        deliveryEvents?.retailer?.complement === null
          ? userInfo?.complement
          : deliveryEvents?.retailer?.complement,
      retailerState:
        currentRole === 'retailer' && deliveryEvents?.retailer?.state === null
          ? userInfo?.addressState
          : deliveryEvents?.retailer?.state,
      retailerCity:
        currentRole === 'retailer' && deliveryEvents?.retailer?.city === null
          ? userInfo?.city
          : deliveryEvents?.retailer?.city,
      retailerZipcode:
        currentRole === 'retailer' && deliveryEvents?.retailer?.zipcode === null
          ? userInfo?.zipcode
          : deliveryEvents?.retailer?.zipcode,
      retailerEINNumber: deliveryEvents?.retailer?.EINNumber,
      retailerInternalRef: deliveryEvents?.retailer?.internalRef,

      retailerReference: deliveryEvents?.product?.reference,
      retailerLenght: deliveryEvents?.product?.length,
      retailerHeight: deliveryEvents?.product?.height,
      retailerWidth: deliveryEvents?.product?.width,
      retailerWeight: deliveryEvents?.product?.weight,

      carrierCompany:
        currentRole === 'carrier' && deliveryEvents?.carrier?.company === null
          ? userInfo?.company
          : deliveryEvents?.carrier?.company,

      carrierFirstName:
        currentRole === 'carrier' && deliveryEvents?.carrier?.firstName === null
          ? userInfo?.firstName
          : deliveryEvents?.carrier?.firstName,

      carrierLastName:
        currentRole === 'carrier' && deliveryEvents?.carrier?.lastName === null
          ? userInfo?.lastName
          : deliveryEvents?.carrier?.lastName,
      carrierPhone:
        currentRole === 'carrier' && deliveryEvents?.carrier?.phone === null
          ? userInfo?.phone
          : deliveryEvents?.carrier?.phone,
      carrierAddress:
        currentRole === 'carrier' && deliveryEvents?.carrier?.address === null
          ? userInfo?.address
          : deliveryEvents?.carrier?.address,
      carrierComplement:
        currentRole === 'carrier' &&
        deliveryEvents?.carrier?.complement === null
          ? userInfo?.complement
          : deliveryEvents?.carrier?.complement,
      carrierState:
        currentRole === 'carrier' && deliveryEvents?.carrier?.state === null
          ? userInfo?.addressState
          : deliveryEvents?.carrier?.state,
      carrierCity:
        currentRole === 'carrier' && deliveryEvents?.carrier?.city === null
          ? userInfo?.city
          : deliveryEvents?.carrier?.city,
      carrierZipcode:
        currentRole === 'carrier' && deliveryEvents?.carrier?.zipcode === null
          ? userInfo?.zipcode
          : deliveryEvents?.carrier?.zipcode,
      carrierEINNumber: deliveryEvents?.carrier?.EINNumber,
      carrierInternalRef: deliveryEvents?.carrier?.internalRef,

      BMname:
        buildingInfo && !deliveryEvents?.building?.name
          ? buildingInfo?.buildingName
          : deliveryEvents?.building?.name,
      BMaddress:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.address
          : deliveryEvents?.building?.address,
      BMcomplement:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.complement
          : deliveryEvents?.building?.complement,
      BMaddressState:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.addressState
          : deliveryEvents?.building?.state,
      BMcity:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.city
          : deliveryEvents?.building?.city,
      BMzipcode:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.zipcode
          : deliveryEvents?.building?.zipcode,
      BMtypeOfResidence:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.typeOfResidence
          : deliveryEvents?.building?.typeOfResidence,
      BMdoorSizeLenght:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.doorSizeLenght
          : deliveryEvents?.building?.doorSizeLenght,
      BMdoorSizeHeight:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.doorSizeHeight
          : deliveryEvents?.building?.doorSizeHeight,
      BMelevatorLenght:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.elevatorLenght
          : deliveryEvents?.building?.elevatorLenght,
      BMelevatorHeight:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.elevatorHeight
          : deliveryEvents?.building?.elevatorHeight,
      BMelevatorWidth:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.elevatorWidth
          : deliveryEvents?.building?.elevatorWidth,
      BMspecificFreightDoor:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.specificFreightDoor
          : deliveryEvents?.building?.specificFreightDoor,
      BMsameFreightAddress:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.sameFreightAddress
          : deliveryEvents?.building?.sameFreightAddress,
      BMspecificFreightDoorLenght:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.specificFreightDoorLenght
          : deliveryEvents?.building?.specificFreightDoorLenght,
      BMspecificFreightDoorHeight:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.specificFreightDoorHeight
          : deliveryEvents?.building?.specificFreightDoorHeight,
      BMdoorman:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.doorMan
          : deliveryEvents?.building?.doorman,
      BMvirtualDoorman:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.virtualDoorMan
          : deliveryEvents?.building?.virtualDoorman,
      BMaccessInformation:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.accessInformation
          : deliveryEvents?.building?.accessInformation,
      BMCOIRequirement:
        buildingInfo && !deliveryEvents?.building?.address
          ? buildingInfo?.COIRequirement
          : deliveryEvents?.building?.COIRequirement,

      emailRetailer: deliveryEvents?.retailerEmail,
      insuranceFileKey: deliveryEvents?.insuranceFileKey,
      invoiceFileKey: deliveryEvents?.invoiceFileKey,
      entranceDoorFileKeyId: deliveryEvents?.entranceDoorFileKeyId,
      freightDoorFileKeyId: deliveryEvents?.freightDoorFileKeyId,
      COIDocumentFileKeyId: deliveryEvents?.COIDocumentFileKeyId,
      buildingDocumentFileKeyId: deliveryEvents?.buildingDocumentFileKeyId,
      currentRole: deliveryEvents?.currentRole,
    });
  }, [deliveryEvents, userInfo, buildingInfo]);

  useEffect(() => {
    if (buildingInfo) {
      setFormValue((prevState) => ({
        ...prevState,
        // Mandatory in order to refresh document when changing selected building
        COIDocumentFileKeyId: buildingInfo?.COIDocumentFileKeyId,
        COIDocumentFileName: buildingInfo?.COIDocumentFileName,
        buildingDocumentFileKeyId: buildingInfo?.buildingDocumentFileKeyId,
        buildingDocumentFileName: buildingInfo?.buildingDocumentFileName,
        //
        BMname: buildingInfo?.buildingName,
        BMaddress: buildingInfo?.address,
        BMcomplement: buildingInfo?.complement,
        BMaddressState: buildingInfo?.addressState,
        BMcity: buildingInfo?.city,
        BMzipcode: buildingInfo?.zipcode,
        BMtypeOfResidence: buildingInfo?.typeOfResidence,
        BMdoorSizeLenght: buildingInfo?.doorSizeLenght,
        BMdoorSizeHeight: buildingInfo?.doorSizeHeight,
        BMelevatorLenght: buildingInfo?.elevatorLenght,
        BMelevatorHeight: buildingInfo?.elevatorHeight,
        BMelevatorWidth: buildingInfo?.elevatorWidth,
        BMspecificFreightDoor: buildingInfo?.specificFreightDoor,
        BMsameFreightAddress: buildingInfo?.sameFreightAddress,
        BMspecificFreightDoorLenght: buildingInfo?.specificFreightDoorLenght,
        BMspecificFreightDoorHeight: buildingInfo?.specificFreightDoorHeight,
        BMdoorman: buildingInfo?.doorMan,
        BMvirtualDoorman: buildingInfo?.virtualDoorMan,
        BMaccessInformation: buildingInfo?.accessInformation,
        BMCOIRequirement: buildingInfo?.COIRequirement,
        BMfreightAddress: buildingInfo?.freightAddress,
        BMfreightComplement: buildingInfo?.freightComplement,
        BMfreightState: buildingInfo?.freightState,
        BMfreightCity: buildingInfo?.freightCity,
        BMfreightZipcode: buildingInfo?.freightZipcode,
        BMfreightOpeningHourStart: buildingInfo?.freightOpeningHourStart,
        BMfreightOpeningHourEnd: buildingInfo?.freightOpeningHourEnd,
        BMfreightElevator: buildingInfo?.freightElevator || false,
        BMfreightElevatorLenght: buildingInfo?.freightElevatorLenght,
        BMfreightElevatorHeight: buildingInfo?.freightElevatorHeight,
        BMfreightElevatorWidth: buildingInfo?.freightElevatorWidth,
        BMneedToBookElevator: buildingInfo?.needToBookElevator || false,
        BMcontactLastName: buildingInfo?.contactLastName,
        BMcontactFirstName: buildingInfo?.contactFirstName,
        BMcontactEmail: buildingInfo?.contactEmail,
        BMcontactPhone: buildingInfo?.contactPhone,
      }));
    }
  }, [buildingInfo]);

  // permits to refresh formValue # DEBUG
  useEffect(() => {
    // Insurance
    if (formValue?.insuranceFileKey) setFileName(formValue?.insuranceFileKey);
    else setFileName(null);

    // Entrance
    if (formValue?.entranceDoorFileKeyId)
      setEntranceDoorFileName(formValue?.entranceDoorFileKeyId);
    else setEntranceDoorFileName(null);

    if (formValue?.entranceDoorFileKeyId)
      setEntranceDoorFileKeyId(formValue?.entranceDoorFileKeyId);
    else setEntranceDoorFileKeyId(null);

    // Freight
    if (formValue?.freightDoorFileKeyId)
      setFreightDoorFileName(formValue?.freightDoorFileKeyId);
    else setFreightDoorFileName(null);

    if (formValue?.freightDoorFileKeyId)
      setFreightDoorFileKeyId(formValue?.freightDoorFileKeyId);
    else setFreightDoorFileKeyId(null);

    // BM COI
    if (formValue?.COIDocumentFileKeyId)
      setCOIDocumentFileName(formValue?.COIDocumentFileKeyId);
    else setCOIDocumentFileName(null);

    if (formValue?.COIDocumentFileKeyId)
      setCOIDocumentFileKeyId(formValue?.COIDocumentFileKeyId);
    else setCOIDocumentFileKeyId(null);

    // BM building file
    if (formValue?.buildingDocumentFileKeyId)
      setBuildingDocumentFileName(formValue?.buildingDocumentFileKeyId);
    else setBuildingDocumentFileName(null);

    if (formValue?.buildingDocumentFileKeyId)
      setBuildingDocumentFileKeyId(formValue?.buildingDocumentFileKeyId);
    else setBuildingDocumentFileKeyId(null);

    if (
      formValue.buildingManager !== null &&
      formValue.specificFreightDoor !== null &&
      formValue.sameFreightAddress !== null &&
      formValue.serviceElevator !== null &&
      formValue.freightElevator !== null &&
      formValue.elevator !== null
    ) {
      if (formValue.id) {
        setIsEventCustomerVisible(true);
      }
    }
  }, [formValue]);

  const isNumber = new RegExp('^[0-9]*$');

  const [customerDeleteForbidden, setCustomerDeleteForbidden] = useState(false);
  const [buildingManagerDeleteForbidden, setBuildingManagerDeleteForbidden] =
    useState(false);
  const [carrierDeleteForbidden, setCarrierDeleteForbidden] = useState(false);
  const [retailerDeleteForbidden, setRetailerDeleteForbidden] = useState(false);

  return (
    <div>
      <div className='static relative'>
        <Header />
        <h1 className='text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold lg:ml-20 ml-10 md:ml-40 absolute bottom-40'>
          {deliveryEvents?.eventStatus === 'FINISHED'
            ? 'Events - Event Details'
            : 'Events - Update an Event'}
        </h1>
      </div>
      {getDeliveryEventsLoading && <TableEventLoading />}
      {!getDeliveryEventsLoading &&
        userInfo?.email &&
        formData &&
        currentRole && (
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors = {};
              if (values?.emailCustomer === '') {
                errors.emailCustomer = 'Email Customer/Resident is required';
              }
              if (values?.emailCarrier === '') {
                errors.emailCarrier = 'Email Carrier/Contractor is required';
              }
              if (
                values?.customerFirstname === '' ||
                values?.customerLastname === '' ||
                values?.customerAddress === '' ||
                values?.customerState === '' ||
                values?.customerCity === '' ||
                values?.customerZipcode === ''
              ) {
                errors.customerField =
                  "Customer's / Resident's field is missing";
              }
              if (values?.customerFirstname === '') {
                errors.customerFirstname = 'First name is required';
              }
              if (values?.customerLastname === '') {
                errors.customerLastname = 'Last name is required';
              }
              if (values?.customerAddress === '') {
                errors.customerAddress = 'Address is required';
              }
              if (values?.customerState === '') {
                errors.customerState = 'State is required';
              }
              if (values?.customerCity === '') {
                errors.customerCity = 'City is required';
              }
              if (values?.customerZipcode === '') {
                errors.customerZipcode = 'Zipcode is required';
              }
              if (
                values.BMdoorSizeLenght &&
                values.BMdoorSizeLenght !== '' &&
                !isNumber.test(values?.BMdoorSizeLenght)
              ) {
                errors.BMdoorSizeLenght = 'Number only';
              }

              if (
                values.BMdoorSizeHeight &&
                values.BMdoorSizeHeight !== '' &&
                !isNumber.test(values?.BMdoorSizeHeight)
              ) {
                errors.BMdoorSizeHeight = 'Number only';
              }
              if (
                values.BMelevatorLenght &&
                values.BMelevatorLenght !== '' &&
                !isNumber.test(values?.BMelevatorLenght)
              ) {
                errors.BMelevatorLenght = 'Number only';
              }
              if (
                values.BMelevatorHeight &&
                values.BMelevatorHeight !== '' &&
                !isNumber.test(values?.BMelevatorHeight)
              ) {
                errors.BMelevatorHeight = 'Number only';
              }
              if (
                values.BMelevatorWidth &&
                values.BMelevatorWidth !== '' &&
                !isNumber.test(values?.BMelevatorWidth)
              ) {
                errors.BMelevatorWidth = 'Number only';
              }
              if (
                values.retailerLenght &&
                values.retailerLenght !== '' &&
                !isNumber.test(values?.retailerLenght)
              ) {
                errors.retailerLenght = 'Number only';
              }
              if (
                values.retailerHeight &&
                values.retailerHeight !== '' &&
                !isNumber.test(values?.retailerHeight)
              ) {
                errors.retailerHeight = 'Number only';
              }
              if (
                values.retailerWidth &&
                values.retailerWidth !== '' &&
                !isNumber.test(values?.retailerWidth)
              ) {
                errors.retailerWidth = 'Number only';
              }
              if (
                values.retailerWeight &&
                values.retailerWeight !== '' &&
                !isNumber.test(values?.retailerWeight)
              ) {
                errors.retailerWeight = 'Number only';
              }
              if (
                values.BMspecificFreightDoorLenght &&
                values.BMspecificFreightDoorLenght !== '' &&
                !isNumber.test(values?.BMspecificFreightDoorLenght)
              ) {
                errors.BMspecificFreightDoorLenght = 'Number only';
              }
              if (
                values.BMspecificFreightDoorHeight &&
                values.BMspecificFreightDoorHeight !== '' &&
                !isNumber.test(values?.BMspecificFreightDoorHeight)
              ) {
                errors.BMspecificFreightDoorHeight = 'Number only';
              }
              if (
                values.BMfreightElevatorLenght &&
                values.BMfreightElevatorLenght !== '' &&
                !isNumber.test(values?.BMfreightElevatorLenght)
              ) {
                errors.BMfreightElevatorLenght = 'Number only';
              }
              if (
                values.BMfreightElevatorHeight &&
                values.BMfreightElevatorHeight !== '' &&
                !isNumber.test(values?.BMfreightElevatorHeight)
              ) {
                errors.BMfreightElevatorHeight = 'Number only';
              }
              if (
                values.BMfreightElevatorWidth &&
                values.BMfreightElevatorWidth !== '' &&
                !isNumber.test(values?.BMfreightElevatorWidth)
              ) {
                errors.BMfreightElevatorWidth = 'Number only';
              }
              if (
                (formValue?.emailCarrier !== '' &&
                  [
                    formValue?.emailCustomer,
                    formValue?.emailBuildingManager,
                    formValue?.emailRetailer,
                  ].includes(formValue?.emailCarrier)) ||
                (formValue?.emailRetailer !== '' &&
                  formValue?.emailRetailer !== undefined &&
                  [
                    formValue?.emailCustomer,
                    formValue?.emailBuildingManager,
                  ].includes(formValue?.emailRetailer)) ||
                (formValue?.emailBuildingManager !== '' &&
                  formValue?.emailBuildingManager !== undefined &&
                  [formValue?.emailCustomer].includes(
                    formValue?.emailBuildingManager
                  ))
              ) {
                errors.sameMail = "Email can't be the same";
              }

              return errors;
            }}
            onSubmit={() => {
              doUpdateEvent();
            }}
          >
            {({ handleChange, errors, formik, values }) => (
              <div className='flex flex-col w-full relative bottom-20 items-center'>
                <Form className='bg-white relative rounded-lg w-full lg:w-4/5 md:w-3/4 max-w-screen-lg'>
                  <div className='flex flex-col items-center'>
                    <div className='w-full md:w-5/6 lg:w-3/5 px-4 md:px-6 lg:px-8 pt-6 pb-8'>
                      <h2 className='text-black py-4 text-2xl font-bold text-[#2260DD]'>
                        Your event
                      </h2>
                      <p className='font-semibold'> Event ID </p>
                      <EventMailField
                        label='ID'
                        name='eventNumber'
                        value={formValue.eventNumber}
                        disabled
                        type='text'
                        onChange={(e) => {
                          setFormValue((prevState) => ({
                            ...prevState,
                            [e.target.name]: e.target.value,
                          }));
                        }}
                        placeholder='my-command-event-1234'
                      />
                      {deliveryEvents && (
                        <>
                          <p className='mt-6 font-semibold text-gray-700 text-smc'>
                            Previously selected dates :
                          </p>
                          <BookingUser event={deliveryEvents} />
                        </>
                      )}
                      <h3 className='text-black py-4 text-xl mt-6'>
                        {' '}
                        + Add Event holders{' '}
                      </h3>
                      <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg bg-amber-100'>
                        {currentRole === 'customer' ? (
                          <EventMailField
                            label='Customer/Resident*'
                            name='emailCustomer'
                            type='email'
                            value={userInfo.email}
                            onChange={(e) => {
                              setFormValue((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value.toLowerCase(),
                              }));
                            }}
                            placeholder='customer@example.com'
                          />
                        ) : (
                          <EventMailField
                            label='Customer/Resident*'
                            name='emailCustomer'
                            type='email'
                            value={formValue.emailCustomer}
                            onChange={(e) => {
                              setFormValue((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value.toLowerCase(),
                              }));
                            }}
                            placeholder='customer@example.com'
                          />
                        )}
                        {deliveryEvents?.eventStatus !== 'FINISHED' ? (
                          <div className='w-full md:w-5/6 mx-auto'>
                            <div className='w-full mx-auto'>
                              <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                                Delivery Calendar
                              </h3>
                              <p className='pb-4 md:pl-2'>
                                Pick some available day for your delivery (max
                                3)
                              </p>
                              {deliveryEvents?.commonDate.length === 0 &&
                                customerChoosenDateToUpdate?.length !== 0 &&
                                customerChoosenDateToUpdate?.map(
                                  (choosenDate) => (
                                    <DatePicker
                                      key={choosenDate.id}
                                      currentDateId={choosenDate.id}
                                      userChoosenDateToUpdate={
                                        customerChoosenDateToUpdate
                                      }
                                      setUserChoosenDateToUpdate={
                                        setCustomerChoosenDateToUpdate
                                      }
                                      currentChoosenDay={choosenDate.day}
                                      currentChoosenHour={choosenDate.timeSlot}
                                      deleteForbidden={customerDeleteForbidden}
                                      previousSelectedDate={
                                        deliveryEvents?.customerDate
                                      }
                                      setDeleteForbidden={
                                        setCustomerDeleteForbidden
                                      }
                                    />
                                  )
                                )}
                              {deliveryEvents?.commonDate &&
                                deliveryEvents?.commonDate?.length !== 0 && (
                                  <p className='pb-8 md:pl-10 text-primary font-medium'>
                                    There is already a common date
                                  </p>
                                )}
                              {(!deliveryEvents?.commonDate ||
                                deliveryEvents?.commonDate?.length === 0) && (
                                <AddTimeSlot
                                  userChoosenDate={customerChoosenDateToUpdate}
                                  setUserChoosenDate={
                                    setCustomerChoosenDateToUpdate
                                  }
                                  deleteForbidden={customerDeleteForbidden}
                                  setDeleteForbidden={
                                    setCustomerDeleteForbidden
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg  bg-sky-100'>
                        {currentRole === 'buildingManager' ? (
                          <EventMailField
                            label='Building Manager'
                            name='emailBuildingManager'
                            type='email'
                            value={userInfo?.email}
                            placeholder='buildingManager@example.com'
                          />
                        ) : (
                          <EventMailField
                            label='Building Manager'
                            name='emailBuildingManager'
                            type='email'
                            value={formValue.emailBuildingManager}
                            onChange={(e) => {
                              setFormValue((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value.toLowerCase(),
                              }));
                            }}
                            placeholder='buildingManager@example.com'
                          />
                        )}
                        {deliveryEvents?.eventStatus !== 'FINISHED' ? (
                          <div className='w-full md:w-5/6 mx-auto'>
                            <div className='w-full mx-auto'>
                              <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                                Delivery Calendar
                              </h3>
                              <p className='pb-4 md:pl-2'>
                                Pick some available day for your delivery (max
                                3)
                              </p>

                              {deliveryEvents?.commonDate.length === 0 &&
                                buildingManagerChoosenDateToUpdate?.length !==
                                  0 &&
                                buildingManagerChoosenDateToUpdate?.map(
                                  (choosenDate) => (
                                    <DatePicker
                                      key={choosenDate.id}
                                      currentDateId={choosenDate.id}
                                      userChoosenDateToUpdate={
                                        buildingManagerChoosenDateToUpdate
                                      }
                                      setUserChoosenDateToUpdate={
                                        setBuildingManagerChoosenDateToUpdate
                                      }
                                      currentChoosenDay={choosenDate.day}
                                      currentChoosenHour={choosenDate.timeSlot}
                                      deleteForbidden={
                                        buildingManagerDeleteForbidden
                                      }
                                      previousSelectedDate={
                                        deliveryEvents?.buildingManagerDate
                                      }
                                      setDeleteForbidden={
                                        setBuildingManagerDeleteForbidden
                                      }
                                    />
                                  )
                                )}
                              {deliveryEvents?.commonDate &&
                                deliveryEvents?.commonDate?.length !== 0 && (
                                  <p className='pb-8 md:pl-10 text-primary font-medium'>
                                    There is already a common date
                                  </p>
                                )}
                              {(!deliveryEvents?.commonDate ||
                                deliveryEvents?.commonDate?.length === 0) && (
                                <AddTimeSlot
                                  userChoosenDate={
                                    buildingManagerChoosenDateToUpdate
                                  }
                                  setUserChoosenDate={
                                    setBuildingManagerChoosenDateToUpdate
                                  }
                                  deleteForbidden={
                                    buildingManagerDeleteForbidden
                                  }
                                  setDeleteForbidden={
                                    setBuildingManagerDeleteForbidden
                                  }
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg bg-indigo-100'>
                        {currentRole === 'carrier' ? (
                          <EventMailField
                            label='Carrier/Contractor*'
                            name='emailCarrier'
                            type='text'
                            value={userInfo?.email}
                            onChange={(e) => {
                              setFormValue((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value.toLowerCase(),
                              }));
                            }}
                            placeholder='carrier@example.com'
                          />
                        ) : (
                          <EventMailField
                            label='Carrier/Contractor*'
                            name='emailCarrier'
                            type='text'
                            value={formValue.emailCarrier}
                            onChange={(e) => {
                              setFormValue((prevState) => ({
                                ...prevState,
                                [e.target.name]: e.target.value.toLowerCase(),
                              }));
                            }}
                            placeholder='carrier@example.com'
                          />
                        )}
                        {deliveryEvents?.eventStatus !== 'FINISHED' ? (
                          <div className='w-full md:w-5/6 mx-auto'>
                            <div className='w-full mx-auto'>
                              <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                                Delivery Calendar
                              </h3>
                              <p className='pb-4 md:pl-2'>
                                Pick some available day for your delivery (max
                                3)
                              </p>

                              {deliveryEvents?.commonDate.length === 0 &&
                                carrierChoosenDateToUpdate?.length !== 0 &&
                                carrierChoosenDateToUpdate?.map(
                                  (choosenDate) => (
                                    <DatePicker
                                      key={choosenDate.id}
                                      currentDateId={choosenDate.id}
                                      userChoosenDateToUpdate={
                                        carrierChoosenDateToUpdate
                                      }
                                      setUserChoosenDateToUpdate={
                                        setCarrierChoosenDateToUpdate
                                      }
                                      currentChoosenDay={choosenDate.day}
                                      currentChoosenHour={choosenDate.timeSlot}
                                      deleteForbidden={carrierDeleteForbidden}
                                      previousSelectedDate={
                                        deliveryEvents?.carrierDate
                                      }
                                      setDeleteForbidden={
                                        setCarrierDeleteForbidden
                                      }
                                    />
                                  )
                                )}
                              {deliveryEvents?.commonDate &&
                                deliveryEvents?.commonDate?.length !== 0 && (
                                  <p className='pb-8 md:pl-10 text-primary font-medium'>
                                    There is already a common date
                                  </p>
                                )}
                              {(!deliveryEvents?.commonDate ||
                                deliveryEvents?.commonDate?.length === 0) && (
                                <AddTimeSlot
                                  userChoosenDate={carrierChoosenDateToUpdate}
                                  setUserChoosenDate={
                                    setCarrierChoosenDateToUpdate
                                  }
                                  deleteForbidden={carrierDeleteForbidden}
                                  setDeleteForbidden={setCarrierDeleteForbidden}
                                />
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                      {deliveryEvents?.eventStatus !== 'FINISHED' ? (
                        <div className='py-5 px-2 sm:px-4 md:px-8 my-4 rounded rounded-lg bg-pink-100'>
                          {currentRole === 'retailer' ? (
                            <EventMailField
                              label='Retailer'
                              name='emailRetailer'
                              type='text'
                              value={userInfo?.email}
                              onChange={(e) => {
                                setFormValue((prevState) => ({
                                  ...prevState,
                                  [e.target.name]: e.target.value.toLowerCase(),
                                }));
                              }}
                              placeholder='retailer@example.com'
                            />
                          ) : (
                            <EventMailField
                              label='Retailer'
                              name='emailRetailer'
                              type='text'
                              value={formValue.emailRetailer}
                              onChange={(e) => {
                                setFormValue((prevState) => ({
                                  ...prevState,
                                  [e.target.name]: e.target.value.toLowerCase(),
                                }));
                              }}
                              placeholder='retailer@example.com'
                            />
                          )}
                          <div className='w-full md:w-5/6 mx-auto'>
                            <div className='w-full mx-auto'>
                              <h3 className='text-black py-4 text-xl mt-2 font-bold'>
                                Delivery Calendar
                              </h3>
                              <p className='pb-4 md:pl-2'>
                                Pick some available day for your delivery (max
                                3)
                              </p>

                              {deliveryEvents?.eventStatus !== 'FINISHED' &&
                                deliveryEvents?.commonDate.length === 0 &&
                                retailerChoosenDateToUpdate?.length !== 0 &&
                                retailerChoosenDateToUpdate?.map(
                                  (choosenDate) => (
                                    <DatePicker
                                      key={choosenDate.id}
                                      currentDateId={choosenDate.id}
                                      userChoosenDateToUpdate={
                                        retailerChoosenDateToUpdate
                                      }
                                      setUserChoosenDateToUpdate={
                                        setRetailerChoosenDateToUpdate
                                      }
                                      currentChoosenDay={choosenDate.day}
                                      currentChoosenHour={choosenDate.timeSlot}
                                      deleteForbidden={retailerDeleteForbidden}
                                      previousSelectedDate={
                                        deliveryEvents?.retailerDate
                                      }
                                      setDeleteForbidden={
                                        setRetailerDeleteForbidden
                                      }
                                    />
                                  )
                                )}
                              {deliveryEvents?.commonDate &&
                                deliveryEvents?.commonDate?.length !== 0 && (
                                  <p className='pb-8 md:pl-10 text-primary font-medium'>
                                    There is already a common date
                                  </p>
                                )}
                              {(!deliveryEvents?.commonDate ||
                                deliveryEvents?.commonDate?.length === 0) && (
                                <AddTimeSlot
                                  userChoosenDate={retailerChoosenDateToUpdate}
                                  setUserChoosenDate={
                                    setRetailerChoosenDateToUpdate
                                  }
                                  deleteForbidden={retailerDeleteForbidden}
                                  setDeleteForbidden={
                                    setRetailerDeleteForbidden
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className='w-full flex-col'>
                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={toggleCustomer}
                        className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg px-16 py-8'
                      >
                        <div className='flex justify-between'>
                          <div className='flex'>
                            <div
                              style={classes.customer}
                              className='self-center'
                            ></div>
                            <p className='px-2'>Customer/Resident</p>
                          </div>
                          <div className='flex'>
                            {showCustomer ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M4.5 15.75l7.5-7.5 7.5 7.5'
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>

                    <div>
                      {isEventCustomerVisible && (
                        <div
                          id='eventCustomer'
                          className={`${showCustomer ? 'block' : 'hidden'}`}
                        >
                          <EventCustomer
                            customerChoosenDateToUpdate={
                              customerChoosenDateToUpdate
                            }
                            setCustomerChoosenDateToUpdate={
                              setCustomerChoosenDateToUpdate
                            }
                            setFormValue={setFormValue}
                            formValue={formValue}
                            event={deliveryEvents}
                            handleChange={handleChange}
                            // Upload part
                            currentRole={currentRole}
                            // entrance
                            entranceDoorFileKeyId={entranceDoorFileKeyId}
                            setEntranceDoorFileKeyId={setEntranceDoorFileKeyId}
                            entranceDoorFileName={entranceDoorFileName}
                            setEntranceDoorFileName={setEntranceDoorFileName}
                            entranceDoorFile={entranceDoorFile}
                            setEntranceDoorFile={setEntranceDoorFile}
                            errorEntranceFileMessage={errorEntranceFileMessage}
                            setErrorEntranceFileMessage={
                              setErrorEntranceFileMessage
                            }
                            deleteEntranceFile={deleteEntranceFile}
                            uploadEntranceDoorFileSuccessMessage={
                              uploadEntranceDoorFileSuccessMessage
                            }
                            setUploadEntranceDoorFileSuccessMessage={
                              setUploadEntranceDoorFileSuccessMessage
                            }
                            uploadEntranceDoorFileErrorMessage={
                              uploadEntranceDoorFileErrorMessage
                            }
                            setUploadEntranceDoorFileErrorMessage={
                              setUploadEntranceDoorFileErrorMessage
                            }
                            // freight
                            freightDoorFileKeyId={freightDoorFileKeyId}
                            setFreightDoorFileKeyId={setFreightDoorFileKeyId}
                            freightDoorFileName={freightDoorFileName}
                            setFreightDoorFileName={setFreightDoorFileName}
                            freightDoorFile={freightDoorFile}
                            setFreightDoorFile={setFreightDoorFile}
                            errorFreightFileMessage={errorFreightFileMessage}
                            setErrorFreightFileMessage={
                              setErrorFreightFileMessage
                            }
                            deleteFreightFile={deleteFreightFile}
                            uploadFreightDoorFileSuccessMessage={
                              uploadFreightDoorFileSuccessMessage
                            }
                            setUploadFreightDoorFileSuccessMessage={
                              setUploadFreightDoorFileSuccessMessage
                            }
                            uploadFreightDoorFileErrorMessage={
                              uploadFreightDoorFileErrorMessage
                            }
                            setUploadFreightDoorFileErrorMessage={
                              setUploadFreightDoorFileErrorMessage
                            }
                          />
                        </div>
                      )}
                    </div>

                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={toggleBuildingManager}
                        className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg my-5 px-16 py-8'
                      >
                        <div className='flex justify-between'>
                          <div className='flex'>
                            <div
                              style={classes.buildingManager}
                              className='self-center'
                            ></div>
                            <p className='px-2'>Building Manager</p>
                          </div>
                          <div className='flex'>
                            {showBuildingManager ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M4.5 15.75l7.5-7.5 7.5 7.5'
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      id='eventBuildingManager'
                      className={`${showBuildingManager ? 'block' : 'hidden'}`}
                    >
                      <EventBuildingManager
                        buildingManagerChoosenDateToUpdate={
                          buildingManagerChoosenDateToUpdate
                        }
                        setBuildingManagerChoosenDateToUpdate={
                          setBuildingManagerChoosenDateToUpdate
                        }
                        setFormValue={setFormValue}
                        formValue={formValue}
                        event={deliveryEvents}
                        handleChange={handleChange}
                        userDynamo={userDynamo}
                        currentRole={currentRole}
                        userInfo={userInfo}
                        setBuildingList={setBuildingList}
                        buildingSelected={buildingSelected}
                        setBuildingSelected={setBuildingSelected}
                        buildingList={buildingList}
                        buildingInfo={buildingInfo}

                        // Upload part COIDocumentFile
                        COIDocumentFileKeyId={COIDocumentFileKeyId}
                        setCOIDocumentFileKeyId={setCOIDocumentFileKeyId}
                        COIDocumentFileName={COIDocumentFileName}
                        setCOIDocumentFileName={setCOIDocumentFileName}
                        COIDocumentFile={COIDocumentFile}
                        setCOIDocumentFile={setCOIDocumentFile}
                        errorCOIDocumentFileMessage={errorCOIDocumentFileMessage}
                        setErrorCOIDocumentFileMessage={setErrorCOIDocumentFileMessage}
                        deleteCOIDocumentFile={deleteCOIDocumentFile}
                        uploadCOIDocumentFileSuccessMessage={
                          uploadCOIDocumentFileSuccessMessage
                        }
                        setUploadCOIDocumentFileSuccessMessage={
                          setUploadCOIDocumentFileSuccessMessage
                        }
                        uploadCOIDocumentFileErrorMessage={
                          uploadCOIDocumentFileErrorMessage
                        }
                        setUploadCOIDocumentFileErrorMessage={
                          setUploadCOIDocumentFileErrorMessage
                        }
                        // Upload part Building Document File
                        buildingDocumentFileKeyId={buildingDocumentFileKeyId}
                        setBuildingDocumentFileKeyId={setBuildingDocumentFileKeyId}
                        buildingDocumentFileName={buildingDocumentFileName}
                        setBuildingDocumentFileName={setBuildingDocumentFileName}
                        buildingDocumentFile={buildingDocumentFile}
                        setBuildingDocumentFile={setBuildingDocumentFile}
                        errorBuildingDocumentFileMessage={errorBuildingDocumentFileMessage}
                        setErrorBuildingDocumentFileMessage={setErrorBuildingDocumentFileMessage}
                        deleteBuildingDocumentFile={deleteBuildingDocumentFile}
                        uploadBuildingDocumentFileSuccessMessage={
                          uploadBuildingDocumentFileSuccessMessage
                        }
                        setUploadBuildingDocumentFileSuccessMessage={
                          setUploadBuildingDocumentFileSuccessMessage
                        }
                        uploadBuildingDocumentFileErrorMessage={
                          uploadBuildingDocumentFileErrorMessage
                        }
                        setUploadBuildingDocumentFileErrorMessage={
                          setUploadBuildingDocumentFileErrorMessage
                        }
                      />
                    </div>
                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={toggleCarrier}
                        className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg px-16 py-8'
                      >
                        <div className='flex justify-between'>
                          <div className='flex'>
                            <div
                              style={classes.carrier}
                              className='self-center'
                            ></div>
                            <p className='px-2'>Carrier/Contractor</p>
                          </div>
                          <div className='flex'>
                            {showCarrier ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M4.5 15.75l7.5-7.5 7.5 7.5'
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      id='eventCarrier'
                      className={`${showCarrier ? 'block' : 'hidden'}`}
                    >
                      <EventCarrier
                        carrierChoosenDateToUpdate={carrierChoosenDateToUpdate}
                        setCarrierChoosenDateToUpdate={
                          setCarrierChoosenDateToUpdate
                        }
                        setFormValue={setFormValue}
                        formValue={formValue}
                        event={deliveryEvents}
                        handleChange={handleChange}
                      />
                    </div>

                    <div className='flex justify-center'>
                      <button
                        type='button'
                        onClick={toggleRetailer}
                        className='bg-bg-button rounded-lg lg:w-3/5 w-screen text-white text-lg my-5 px-16 py-8'
                      >
                        <div className='flex justify-between'>
                          <div className='flex'>
                            <div
                              style={classes.retailer}
                              className='self-center'
                            ></div>
                            <p className='px-2'>Retailer</p>
                          </div>
                          <div className='flex'>
                            {showRetailer ? (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M4.5 15.75l7.5-7.5 7.5 7.5'
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth='3'
                                stroke='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                  d='M19.5 8.25l-7.5 7.5-7.5-7.5'
                                />
                              </svg>
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      id='eventRetailer'
                      className={`${showRetailer ? 'block' : 'hidden'}`}
                    >
                      <EventRetailer
                        setFormValue={setFormValue}
                        formValue={formValue}
                        retailerChoosenDateToUpdate={
                          retailerChoosenDateToUpdate
                        }
                        setRetailerChoosenDateToUpdate={
                          setRetailerChoosenDateToUpdate
                        }
                        handleChange={handleChange}
                        event={deliveryEvents}
                        deleteInvoiceFile={deleteInvoiceFile}
                        invoiceFileKeyId={invoiceFileKeyId}
                        setInvoiceFileKeyId={setInvoiceFileKeyId}
                        invoiceFileName={invoiceFileName}
                        setInvoiceFileName={setInvoiceFileName}
                        invoiceFile={invoiceFile}
                        setInvoiceFile={setInvoiceFile}
                        errorInvoiceFileMessage={errorInvoiceFileMessage}
                        setErrorInvoiceFileMessage={setErrorInvoiceFileMessage}
                        uploadFileSuccessMessage={uploadFileSuccessMessage}
                        setUploadFileSuccessMessage={
                          setUploadFileSuccessMessage
                        }
                        uploadFileErrorMessage={uploadFileErrorMessage}
                        setUploadFileErrorMessage={setUploadFileErrorMessage}
                        currentRole={currentRole}
                      />
                    </div>

                    <div className='flex flex-col items-center	'>
                      <h3 className='text-black py-4 text-xl mt-6 font-bold'>
                        Certificate of fiability insurance
                      </h3>
                      <DropFile
                        fileKeyId={fileKeyId}
                        setFileKeyId={setFileKeyId}
                        setFileName={setFileName}
                        file={file}
                        setFile={setFile}
                        errorFileMessage={errorFileMessage}
                        setErrorFileMessage={setErrorFileMessage}
                        deleteFile={deleteFile}
                        uploadErrorMessage={uploadInsuranceFileError}
                        uploadInProgress={
                          uploadInsuranceFileLoading ? true : false
                        }
                      />
                      {/* // Delete File */}
                      {!uploadInsuranceFileLoading &&
                        fileName &&
                        !errorFileMessage && (
                          <div className='pt-6 text-neutral-dark font-bold'>
                            <p>Chosen file:</p>
                            <div className='flex flex-row items-center'>
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  formValue?.insuranceFileKey
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                Insurance certificate file
                              </a>
                              <button
                                type='button'
                                // on Update FileKeyId = fileName
                                onClick={() => deleteFile(fileName)}
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 24 24'
                                  strokeWidth={1.5}
                                  stroke='currentColor'
                                  className='pl-2 w-6 h-6 text-error'
                                >
                                  <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        )}
                      {/* // Loader uploadFile */}
                      {uploadInsuranceFileLoading && (
                        <>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='animate-bounce w-8 h-8 text-primary'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z'
                            />
                          </svg>
                          <p>upload in progress...</p>
                        </>
                      )}
                    </div>
                    <div className='flex items-center'>
                      {updateDeliveryEventLoading && (
                        <button
                          disabled={true}
                          type='submit'
                          className='bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-10 text-white text-lg hover:scale-105 ease-out duration-300'
                        >
                          <CircularProgress
                            style={{ color: 'white' }}
                            size={20}
                          />
                        </button>
                      )}
                      {!updateDeliveryEventLoading && !successMessage && (
                        <button
                          type='submit'
                          className='bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-5 text-white text-lg hover:scale-105 ease-out duration-300'
                          onChange={doUpdateEvent}
                        >
                          {' '}
                          Save changes{' '}
                        </button>
                      )}
                    </div>
                    {errors.customerField && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {errors.customerField}
                      </p>
                    )}
                    {errors.emailCustomer && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {errors.emailCustomer}
                      </p>
                    )}
                    {errors.sameMail && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {errors.sameMail}
                      </p>
                    )}
                    {errors.emailCarrier && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {errors.emailCarrier}
                      </p>
                    )}
                    {(errors.BMdoorSizeLenght || errors.BMdoorSizeHeight) && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {'Door size : number only'}
                      </p>
                    )}
                    {(errors.BMelevatorLenght ||
                      errors.BMelevatorHeight ||
                      errors.BMelevatorWidth) && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {'Elevator size : number only'}
                      </p>
                    )}
                    {(errors.BMspecificFreightDoorLenght ||
                      errors.BMspecificFreightDoorHeight) && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {'Entrance Door size : number only'}
                      </p>
                    )}
                    {(errors.BMfreightElevatorLenght ||
                      errors.BMfreightElevatorHeight ||
                      errors.BMfreightElevatorWidth) && (
                      <p className='text-red-600 text-center'>
                        {' '}
                        {'Freight Elevator size : number only'}
                      </p>
                    )}
                    {updateDeliveryEventSuccessMessage && (
                      <p className='text-center text-white mt-4 bg-green-500 md:w-3/4 lg:w-2/6 mx-auto'>
                        {successMessage}
                      </p>
                    )}
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        )}
    </div>
  );
}
