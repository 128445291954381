import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand
} from '@aws-sdk/client-s3';
import { config } from '../config/amplify';

const credentials = {
  accessKeyId: config.s3.BUCKET_ACCESS_KEY,
  secretAccessKey: config.s3.BUCKET_SECRET_KEY,
};
const s3config = {
  region: config.s3.BUCKET_FILE_ONBOARDING_REGION,
  credentials,
};

export const addFile = async (fileToUpload, fileKeyId) => {
  if (fileToUpload.size > 32000 && fileToUpload.size < 5000000) {
    try {
      const s3Client = new S3Client(s3config);

      const file = fileToUpload;
      const fileKey = fileKeyId
      const uploadParams = {
        Bucket: config.s3.BUCKET_FILE_ONBOARDING,
        Key: fileKey,
        Body: file,
      };

      const result = await s3Client.send(new PutObjectCommand(uploadParams));
      return result;
    } catch (err) {
      return err;
    }
  } else {
    return null;
  }
};

export const deleteFileS3 = async (fileKey) => {
  try {
    const s3Client = new S3Client(s3config);
    const deleteParams = {
      Bucket: config.s3.BUCKET_FILE_ONBOARDING,
      Key: fileKey,
    };
    const command = new DeleteObjectCommand(deleteParams);
    const response = await s3Client.send(command);
    return response;
  } catch (err) {
    console.log(err.message);
    return err.message;
  }
};
