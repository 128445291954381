import { all, put, takeLatest, select } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { config } from '../config/amplify';
// import usersMock from '../mocks/users.json';

import {
  getUserSuccess,
  getUsersFailure,
  getBuildingManagerSuccess,
  getBuildingManagerFailure,
  getRetailerSuccess,
  getRetailerFailure,
  getCarrierSuccess,
  getCarrierFailure,
  getCustomerSuccess,
  getCustomerFailure,
  carrierInfoSuccess,
  carrierInfoFailure,
  retailerInfoSuccess,
  retailerInfoFailure,
  buildingManagerInfoSuccess,
  buildingManagerInfoFailure,
  customerInfoSuccess,
  customerInfoFailure,
} from './reducer';

import { getCurrentSessionLaunched } from '../service/reducer';

// CONFIG
const customHeader = {
  'x-api-key': config.apiKey,
};

function* getUser() {
  try {
    // COMMENT TO USE MOCK
    const user = yield API.get(config.apiGateway.NAME, `/`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // const user = usersMock;
    yield put(getUserSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getUsersFailure());
  }
}

function* getBuildingManager() {
  try {
    // COMMENT TO USE MOCK
    const user = yield API.get(config.apiGateway.NAME, `/`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // const user = usersMock;
    yield put(getBuildingManagerSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getBuildingManagerFailure());
  }
}

function* getCustomer() {
  try {
    // COMMENT TO USE MOCK
    const user = yield API.get(config.apiGateway.NAME, `/`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // const user = usersMock;
    yield put(getCustomerSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getCustomerFailure());
  }
}

function* getRetailer() {
  try {
    // COMMENT TO USE MOCK
    const user = yield API.get(config.apiGateway.NAME, `/`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // const user = usersMock;
    yield put(getRetailerSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getRetailerFailure());
  }
}

function* getCarrier() {
  try {
    // COMMENT TO USE MOCK
    const user = yield API.get(config.apiGateway.NAME, `/`, {
      headers: {
        'x-api-key': config.apiKey,
      },
    });
    // const user = usersMock;
    yield put(getCarrierSuccess(user));
  } catch (error) {
    console.log(error);
    yield put(getCarrierFailure());
  }
}

function* carrierInfo(action) {
  const { carrier } = yield select((state) =>
    state.getIn(['app', 'userDynamo'])
  );
  const carrierId = carrier.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.put(
      config.apiGateway.NAME,
      `/carrier/${carrierId}`,
      {
        headers: customHeader,
        body,
      }
    );
    yield put(carrierInfoSuccess(contents));
    yield put(getCurrentSessionLaunched('/account'));
  } catch (error) {
    console.log(error);
    yield put(carrierInfoFailure());
  }
}

function* retailerInfo(action) {
  const { retailer } = yield select((state) =>
    state.getIn(['app', 'userDynamo'])
  );
  const retailerId = retailer.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.put(
      config.apiGateway.NAME,
      `/retailer/${retailerId}`,
      {
        headers: customHeader,
        body,
      }
    );
    yield put(retailerInfoSuccess(contents));
    yield put(getCurrentSessionLaunched('/account'));
  } catch (error) {
    console.log(error);
    yield put(retailerInfoFailure());
  }
}

function* buildingManagerInfo(action) {
  const { buildingManager } = yield select((state) =>
    state.getIn(['app', 'userDynamo'])
  );
  const buildingManagerId = buildingManager.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.put(
      config.apiGateway.NAME,
      `/buildingManager/${buildingManagerId}`,
      {
        headers: customHeader,
        body,
      }
    );
    yield put(buildingManagerInfoSuccess(contents));
    yield put(getCurrentSessionLaunched('/account'));
  } catch (error) {
    console.log(error);
    yield put(buildingManagerInfoFailure());
  }
}

function* customerInfo(action) {
  const { customer } = yield select((state) =>
    state.getIn(['app', 'userDynamo'])
  );
  const customerId = customer.id;
  const body = action.payload.formValue;
  try {
    const contents = yield API.put(
      config.apiGateway.NAME,
      `/customer/${customerId}`,
      {
        headers: customHeader,
        body,
      }
    );
    yield put(customerInfoSuccess(contents));
    yield put(getCurrentSessionLaunched('/account'));
  } catch (error) {
    console.log(error);
    yield put(customerInfoFailure());
  }
}

export default function* saga() {
  yield all([
    takeLatest('store/getUserLaunched', getUser),
    takeLatest('store/getBuildingManagerLaunched', getBuildingManager),
    takeLatest('store/getCarrierLaunched', getCarrier),
    takeLatest('store/getRetailerLaunched', getRetailer),
    takeLatest('store/getCustomerLaunched', getCustomer),
    takeLatest('store/carrierInfoLaunched', carrierInfo),
    takeLatest('store/retailerInfoLaunched', retailerInfo),
    takeLatest('store/buildingManagerInfoLaunched', buildingManagerInfo),
    takeLatest('store/customerInfoLaunched', customerInfo),
  ]);
}
