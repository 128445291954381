import React from 'react';
import ComplementField from '../../Complements/ComplementField';
import { useStyles } from 'react-styles-hook';
import getStyles from './style';

const EventCarrier = ({ formValue, setFormValue, handleChange }) => {
  const classes = useStyles(getStyles());

  return (
    <div>
      <div className='flex flex-col mt-10 w-full bg-white relative px-2 md:px-8 pt-6 pb-8 mb-4'>
        <div className='flex-col'>
          <div className='w-full md:w-5/6 lg:w-3/5 mx-auto'>
            <div style={classes.carrier}>
              <h3 className='text-black text-xl font-bold'>
                {' '}
                Your informations{' '}
              </h3>
              <ComplementField
                label='Company'
                name='carrierCompany'
                type='text'
                value={formValue?.carrierCompany}
                placeholder='Company Name'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='First Name'
                name='carrierFirstName'
                type='text'
                placeholder='My First Name'
                value={formValue?.carrierFirstName}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Last Name'
                name='carrierLastName'
                type='text'
                placeholder='My Last Name'
                value={formValue?.carrierLastName}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Phone'
                name='carrierPhone'
                type='text'
                value={formValue?.carrierPhone}
                placeholder='##########'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Company address'
                name='carrierAddress'
                value={formValue?.carrierAddress}
                type='text'
                placeholder='123 E Example St'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Company address complement'
                name='carrierComplement'
                type='text'
                placeholder='Unit, Apt, Suite...'
                value={formValue?.carrierComplement}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='State'
                name='carrierState'
                type='text'
                placeholder='State Name'
                value={formValue?.carrierState}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='City'
                name='carrierCity'
                type='text'
                placeholder='City Name'
                value={formValue?.carrierCity}
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              <ComplementField
                label='Zip Code'
                name='carrierZipcode'
                type='text'
                value={formValue?.carrierZipcode}
                placeholder='#####'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
              {/*<ComplementField*/}
              {/*  label="EIN number"*/}
              {/*  name="carrierEINNumber"*/}
              {/*  type="text"*/}
              {/*  placeholder="12-3456789"*/}
              {/*  value={formValue?.carrierEINNumber}*/}
              {/*  onChange={(e) => {*/}
              {/*    handleChange(e);*/}
              {/*    setFormValue((prevState) => ({*/}
              {/*      ...prevState,*/}
              {/*      [e.target.name]: e.target.value,*/}
              {/*    }));*/}
              {/*  }}*/}
              {/*/>*/}
              <ComplementField
                label='Internal reference'
                name='carrierInternalRef'
                type='text'
                value={formValue?.carrierInternalRef}
                placeholder='Internal file ref'
                onChange={(e) => {
                  handleChange(e);
                  setFormValue((prevState) => ({
                    ...prevState,
                    [e.target.name]: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <style>{`
    input {
      vertical-align: middle;
    }
    hr {
      margin-bottom: 0.5rem;
    }
  `}</style>
    </div>
  );
};

export default EventCarrier;
