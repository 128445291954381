import React from 'react';
import Header from '../../../components/Header';
import ComplementRetailer from '../../../components/Complements/ComplementRetailer';

export default function CarrierInfo() {
  return (
    <div>
      <div className='static'>
        <Header />
      </div>
      <div className='absolute lg:top-40 top-32 inset-x-0'>
        <ComplementRetailer />
      </div>
    </div>
  );
}
