import React from 'react';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
 import configureStore, { history } from './configureStore';

const store = configureStore();

const ProviderWrapper = (children) => (
  <Provider store={store}>
    <HistoryRouter history={history}>{children}</HistoryRouter>
  </Provider>
);

export default ProviderWrapper;
