import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import checkCurrentRole from '../../utils/checkCurrentRole';
import checkUserLogo from '../../utils/checkUserLogo';
import { useNavigate } from 'react-router-dom';
import { logoutLaunched } from '../../service/reducer';
import Picture from '../../assets/img/DefaultIcon.svg';

function ConnectedUserInformation() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));
  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);

  const logout = () => {
    dispatch(logoutLaunched());
    navigate('/loginas');
  };

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);

  const currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);
  const userInfo = currUserInfo[currentRole];
  const userIcon = checkUserLogo(currentRole);

  return (
    <Menu as='div' className='flex items-center mr-8 '>
      <img src={userIcon} alt='logo user' className='h-9 mr-4' />
      {window.innerWidth < 1024 ? (
        <Menu.Button disabled className='flex items-center'>
          {userInfo?.pictureFileKey ? (
            <div className='relative w-10 h-10 mr-4'>
              <img
                src={
                  userInfo?.pictureFileKey
                    ? 'https://' +
                      process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                      '.s3.' +
                      process.env.REACT_APP_AWS_REGION +
                      '.amazonaws.com/' +
                      userInfo?.pictureFileKey
                    : Picture
                }
                className='absolute object-cover w-full h-full rounded-full'
                alt={userInfo?.name}
              />
            </div>
          ) : (
            <div className='rounded-full bg-icon-D bg-[#F3F4F6] p-4 	h-9 w-9	bg-no-repeat bg-center mr-4'></div>
          )}
          <p className='text-white'>
            Welcome, <br />
            <span className='font-bold'> {userInfo?.firstName}</span>
          </p>
        </Menu.Button>
      ) : (
        <Menu.Button className='flex items-center'>
          {userInfo?.pictureFileKey ? (
            <div className='relative w-10 h-10 mr-4'>
              <img
                src={
                  'https://' +
                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                  '.s3.' +
                  process.env.REACT_APP_AWS_REGION +
                  '.amazonaws.com/' +
                  userInfo?.pictureFileKey
                }
                className='absolute object-cover w-full h-full rounded-full'
                alt={userInfo?.name}
              />
            </div>
          ) : (
            <div className='rounded-full bg-icon-D bg-[#F3F4F6] p-4 	h-9 w-9	bg-no-repeat bg-center mr-4'></div>
          )}
          <p className='text-white'>
            Welcome, <br />{' '}
            <span className='font-bold'> {userInfo?.firstName}</span>
          </p>
        </Menu.Button>
      )}
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-8 mt-28 w-44 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none '>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <button
                  type='submit'
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm'
                  )}
                  onClick={() => {
                    logout();
                  }}
                >
                  Sign out
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export default ConnectedUserInformation;
