import React, { useEffect } from 'react';
import { useState } from 'react';
import 'yup-phone-lite';
import Header from '../../components/Header';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserEventsLaunched } from './reducer';
import TableEvent from '../../components/EventsManager/TableEvent';
import TableEventLoading from '../../components/EventsManager/TableEventLoading';
import BookingUser from '../../components/EventsManager/BookingUser';
import { commonDateFormat } from '../../utils/formatCommonDate';
import DropdownSelector from '../../components/EventsManager/Dropdown';
import { resetGetBuilding } from '../../service/reducer';

const EventManager = ({ role, isAuthenticated, userDynamo }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Init search Value
  const [eventStatusSearch, setEventStatusSearch] = useState('IN_PROGRESS');
  const [typeOfUser, setTypeOfUser] = useState();
  const [userEmail, setUserEmail] = useState();
  const [eventSelectedId, setEventSelectedId] = useState();
  const [currentEvent, setCurrentEvent] = useState();
  // filter by building
  const [buildingList, setBuildingList] = useState([
    { buildingId: 0, buildingName: 'All Buildings' },
  ]);
  const [buildingSelected, setBuildingSelected] = useState(buildingList[0]);

  const { createEventId, userEvents } = useSelector((state) => ({
    createEventId: state.getIn(['app', 'createEventId']),
    userEvents: state.getIn(['eventStore', 'userEvents']),
  }));

  dispatch(resetGetBuilding());

  // check if the user events array contains the new created event
  const [gettingNewEvent, setGettingNewEvent] = useState(null);
  const [displayOnNewEvent, setDisplayNewEvent] = useState(null);
  useEffect(() => {
    setGettingNewEvent(false);
    setDisplayNewEvent(false);
    if (
      userEvents &&
      createEventId &&
      location.state?.from !== undefined &&
      location.state?.from === '/account/events'
    ) {
      setGettingNewEvent(true);
      const newEventIsFound = userEvents.some(
        (event) => event['id'] === createEventId
      );
      if (newEventIsFound) {
        setGettingNewEvent(false);
        setDisplayNewEvent(true);
      } else {
        setGettingNewEvent(true);
      }
    } else if (
      (!createEventId && location.state?.from === undefined) ||
      location.state?.from !== '/account/events'
    ) {
      setGettingNewEvent(false);
      setDisplayNewEvent(true);
    }
  }, [createEventId, userEvents]);

  const {
    getUserEventsLoading,
    updateDeliveryEventSuccessMessage,
    updateDeliveryEventLoading,
    createEventLoading,
    createEventSuccessMessage,
  } = useSelector((state) => ({
    getUserEventsLoading: state.getIn(['eventStore', 'getUserEventsLoading']),
    updateDeliveryEventSuccessMessage: state.getIn([
      'getEventStore',
      'updateDeliveryEventSuccessMessage',
    ]),
    updateDeliveryEventLoading: state.getIn([
      'getEventStore',
      'updateDeliveryEventLoading',
    ]),
    createEventLoading: state.getIn(['app', 'createEventLoading']),
    createEventSuccessMessage: state.getIn([
      'app',
      'createEventSuccessMessage',
    ]),
  }));

  // define type of user for request
  useEffect(() => {
    switch (role) {
      case 'buildingManager':
        setTypeOfUser('buildingManagerEmail');
        break;
      case 'carrier':
        setTypeOfUser('carrierEmail');
        break;
      case 'customer':
        setTypeOfUser('customerEmail');
        break;
      case 'retailer':
        setTypeOfUser('retailerEmail');
        break;
      default:
        break;
    }
  }, [role]);
  // define user email for request
  useEffect(() => {
    if (isAuthenticated && userDynamo) {
      if (userDynamo.buildingManager !== undefined) {
        setUserEmail(userDynamo.buildingManager.email);
        if (userDynamo.buildingManager?.buildings) {
          setBuildingList((buildingList) => [
            ...buildingList,
            ...userDynamo.buildingManager?.buildings,
          ]);
        }
      } else if (userDynamo.carrier !== undefined) {
        setUserEmail(userDynamo.carrier.email);
      } else if (userDynamo.customer !== undefined) {
        setUserEmail(userDynamo.customer.email);
      } else if (userDynamo.retailer !== undefined) {
        setUserEmail(userDynamo.retailer.email);
      }
    }
  }, [isAuthenticated, userDynamo]);

  // function to request api
  const getUserEvents = () => {
    dispatch(
      getUserEventsLaunched({
        role: role,
        typeOfUser: typeOfUser,
        eventStatus: eventStatusSearch,
        userEmail: userEmail,
        buildingId:
          buildingSelected?.buildingId === 0
            ? null
            : buildingSelected?.buildingId,
      })
    );
  };

  useEffect(() => {
    if (typeOfUser !== undefined && eventStatusSearch !== undefined) {
      getUserEvents();
    }
    if (
      updateDeliveryEventLoading === false &&
      updateDeliveryEventSuccessMessage === true &&
      typeOfUser !== undefined &&
      eventStatusSearch !== undefined
    ) {
      getUserEvents();
    }
    if (
      createEventLoading === false &&
      createEventSuccessMessage === true &&
      typeOfUser !== undefined &&
      eventStatusSearch !== undefined
    ) {
      getUserEvents();
    }
    if (gettingNewEvent) {
      getUserEvents();
    }
  }, [
    typeOfUser,
    userEmail,
    eventStatusSearch,
    buildingSelected,
    updateDeliveryEventLoading,
    updateDeliveryEventSuccessMessage,
    createEventLoading,
    createEventSuccessMessage,
    gettingNewEvent,
  ]);

  // define default event selected
  useEffect(() => {
    if (!getUserEventsLoading && userEvents && userEvents?.length !== 0) {
      setEventSelectedId(userEvents[0]?.id);
    }
  }, [userEvents, getUserEventsLoading]);

  useEffect(() => {
    if (!getUserEventsLoading && userEvents && userEvents?.length !== 0) {
      const index = userEvents.findIndex((item) => item.id === eventSelectedId);
      setCurrentEvent(userEvents[index]);
    }
  }, [eventSelectedId, userEvents]);

  return (
    <div>
      <div className='static'>
        <Header />
      </div>
      <div className='absolute lg:top-40 top-32 inset-x-0'>
        <div>
          <div className='flex flex-col mt-10 w-full'>
            <h1 className='text-white text-4xl font-bold mb-5 lg:ml-20 ml-10 md:ml-40'>
              Events
            </h1>
            <div className='relative px-2 md:px-8 w-full'>
              <div className='bg-white shadow-md rounded-lg px-4 lg:px-8 pt-6 pb-8 w-full max-w-screen-2xl mx-auto'>
                <div className='flex flex-col items-start space-y-2 md:space-y-0 md:flex-row md:items-center justify-evenly'>
                  <Link
                    to='/account/events'
                    className='bg-bg-button rounded-lg text-white text-lg px-8 py-3'
                  >
                    + Create event
                  </Link>
                  <button
                    onClick={() => setEventStatusSearch('IN_PROGRESS')}
                    className={`text-xl font-semibold pt:6 md:pt-0 ${
                      eventStatusSearch === 'IN_PROGRESS'
                        ? 'text-primary'
                        : 'text-neutral-light'
                    }`}
                  >
                    In Progress
                  </button>
                  <button
                    onClick={() => setEventStatusSearch('FINISHED')}
                    className={`text-xl font-semibold ${
                      eventStatusSearch === 'FINISHED'
                        ? 'text-primary'
                        : 'text-neutral-light'
                    }`}
                  >
                    Finished
                  </button>
                  {role &&
                    role === 'buildingManager' &&
                    buildingList &&
                    buildingList.length > 1 && (
                      <div className='self-end md:self-center'>
                        <DropdownSelector
                          selected={buildingSelected}
                          setSelected={setBuildingSelected}
                          buildings={buildingList}
                        />
                      </div>
                    )}
                </div>
                {getUserEventsLoading && <TableEventLoading />}
                {!getUserEventsLoading &&
                  userEvents &&
                  userEvents.length !== 0 &&
                  displayOnNewEvent && (
                    <>
                      <TableEvent
                        role={role}
                        events={userEvents}
                        eventSelectedId={eventSelectedId}
                        setEventSelectedId={setEventSelectedId}
                      />
                      <div className='hidden xl:flex flex-row mt-5 w-full'>
                        <div className='w-1/2'>
                          <h2 className='text-primary text-lg font-semibold	'>
                            Event{' '}
                            {currentEvent ? currentEvent.eventNumber : null}
                          </h2>
                          <div className='bg-primary-lighter py-3 pl-2 mt-4 mr-10 w-96 rounded-lg'>
                            {currentEvent?.commonDate &&
                              currentEvent.commonDate?.length !== 0 && (
                                <>
                                  <p>Event scheduled for :</p>
                                  <p className='font-light'>{`Date : ${
                                    commonDateFormat(
                                      currentEvent?.commonDate[0]
                                    ).date
                                  }, Time slot : ${
                                    commonDateFormat(currentEvent.commonDate[0])
                                      .timeStart
                                  } - ${
                                    commonDateFormat(currentEvent.commonDate[0])
                                      .timeEnd
                                  }`}</p>
                                </>
                              )}
                            {(!currentEvent?.commonDate ||
                              currentEvent.commonDate?.length === 0) && (
                              <p className='font-light'>{`No Common Date`}</p>
                            )}
                          </div>
                        </div>
                        <div className='w-1/2 flex flex-col'>
                          <div className='flex flex-col'>
                            <h2 className='text-primary text-lg font-semibold	pb-5'>
                              Insurance & Ref
                            </h2>
                            {currentEvent?.insuranceFileKey &&
                            currentEvent?.insuranceFileKey !== '' ? (
                              <a
                                href={
                                  'https://' +
                                  process.env.REACT_APP_UPLOAD_FILE_S3BUCKET +
                                  '.s3.' +
                                  process.env.REACT_APP_AWS_REGION +
                                  '.amazonaws.com/' +
                                  currentEvent.insuranceFileKey
                                }
                                target='_blank'
                                rel='noreferrer'
                                className='underline'
                              >
                                Insurance certificate file
                              </a>
                            ) : (
                              <p>Pending Insurance</p>
                            )}
                            <div className='pt-3'>
                              <p className='flex flex-row space-x-2 items-center'>
                                <span>Internal reference:</span>
                                {role === 'buildingManager' ? (
                                  <span className='border border-neutral rounded-md px-2 p1-2 text-xs'>
                                    {currentEvent?.buildingManager?.internalRef
                                      ? currentEvent?.buildingManager
                                          ?.internalRef
                                      : 'N/A'}
                                  </span>
                                ) : null}
                                {role === 'carrier' ? (
                                  <span className='border border-neutral rounded-md px-2 p1-2 text-xs'>
                                    {currentEvent?.carrier?.internalRef
                                      ? currentEvent?.carrier?.internalRef
                                      : 'N/A'}
                                  </span>
                                ) : null}
                                {role === 'retailer' ? (
                                  <span className='border border-neutral rounded-md px-2 p1-2 text-xs'>
                                    {currentEvent?.retailer?.internalRef
                                      ? currentEvent?.retailer?.internalRef
                                      : 'N/A'}
                                  </span>
                                ) : null}
                                {role === 'customer' ? (
                                  <span className='border border-neutral rounded-md px-2 p1-2 text-xs'>
                                    {currentEvent?.customer?.internalRef
                                      ? currentEvent?.customer?.internalRef
                                      : 'N/A'}
                                  </span>
                                ) : null}
                              </p>
                            </div>
                            <button className='border-2 text-colors-btn rounded-lg text-[#1B5EDB] text-lg  self-end p-2 mr-2'>
                              <Link to={`/update-events/${currentEvent?.id}`}>
                                {currentEvent?.eventStatus === 'FINISHED'
                                  ? 'See the event'
                                  : 'Modify the event'}
                              </Link>
                            </button>
                          </div>
                          <div>
                            <h2 className='text-primary text-lg font-semibold	pb-5'>
                              Booking
                            </h2>
                            {currentEvent && (
                              <BookingUser event={currentEvent} />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {!getUserEventsLoading &&
                  (!userEvents || userEvents.length === 0) && <p>No events</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventManager;
