import React from 'react';
import { nanoid } from 'nanoid';
import { now } from 'moment';
import moment from 'moment/moment';

export const AddTimeSlot = ({
  userChoosenDate,
  setUserChoosenDate,
  deleteForbidden,
  setDeleteForbidden,
  ...props
}) => {
  // Add a new time slot with a new id and set day + 1 and timeslot 1200 //
  function addNewTimeSlot() {
    const newId = nanoid();
    let day = now();
    day = moment(day).add(1, 'days');
    const newChoosenDate = {
      id: newId,
      day: day.format('YYYY/MM/DD'),
      timeSlot: '', //default value was 1200, but now it is set to ''
    };
    setUserChoosenDate((userChoosenDate) => [
      ...userChoosenDate,
      newChoosenDate,
    ]);
  }

  return (
    <>
      <button
        type='button'
        onClick={() => {
          addNewTimeSlot();
          // reset forbidden message
          if (deleteForbidden) {
            setDeleteForbidden(false);
          }
        }}
        disabled={userChoosenDate.length >= 3 ? true : false}
        className={`${
          userChoosenDate.length >= 3
            ? 'bg-neutral'
            : ' bg-bg-button hover:scale-105 ease-out duration-300'
        }  w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg mt-2 mb-3`}
      >
        Add New TimeSlot
      </button>
    </>
  );
};

export default AddTimeSlot;
