import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';

const initialState = Immutable.Map({
  getDeliveryEventsLoading: false,
  getDeliveryEventsError: null,
  deliveryEvents: null,
  updateDeliveryEventLoading: false,
  updateDeliveryEventSuccess: null,
  updateDeliveryEventErrorMessage: null,
});

const { actions, reducer } = createSlice({
  slice: 'getEventStore',
  name: 'getEventStore',
  initialState,
  reducers: {
    // get Events
    getDeliveryEventsLaunched: (state, action) =>
      state
        .set('getDeliveryEventsLoading', true)
        .set('deliveryEvents', null)
        .set('getDeliveryEventsError', null),
    getDeliveryEventsSuccess: (state, action) =>
      state
        .set('getDeliveryEventsLoading', false)
        .set('deliveryEvents', action.payload)
        .set('getDeliveryEventsError', null),
    getDeliveryEventsFailure: (state, action) =>
      state
        .set('getDeliveryEventsLoading', false)
        .set('deliveryEvents', null)
        .set('getDeliveryEventsError', true),

        // Update Events
          updateDeliveryEventLaunched: (state, action) =>
          state
            .set('updateDeliveryEventLoading', true)
            .set('updateDeliveryEventSuccessMessage', null)
            .set('updateDeliveryEventErrorMessage', null),
            updateDeliveryEventSuccess: (state, action) =>
          state
            .set('updateDeliveryEventLoading', false)
            .set('updateDeliveryEventSuccessMessage', true)
            .set('updateDeliveryEventErrorMessage', null),
            updateDeliveryEventFailure: (state, action) =>
          state
            .set('updateDeliveryEventLoading', false)
            .set('updateDeliveryEventSuccessMessage', null)
            .set('updateDeliveryEventErrorMessage', 'An error has occurred. Please try again.'),
  
  },
});

export const {
  getDeliveryEventsLaunched,
  getDeliveryEventsSuccess,
  getDeliveryEventsFailure,
  updateDeliveryEventLaunched,
  updateDeliveryEventSuccess,
  updateDeliveryEventFailure
} = actions;

export default reducer;