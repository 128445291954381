function roleToUppercase(role) {
    switch (role) {
      case 'retailer':
        return 'RETAILER';
      case 'customer':
        return 'CUSTOMER';
      case 'buildingManager':
        return 'BUILDINGMANAGER';
      case 'carrier':
        return 'CARRIER';
      default:
        return null;
    }
  }
  export default roleToUppercase;
  