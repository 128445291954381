import React from 'react';
import Header from '../../../components/Header';
import ComplementCustomer from '../../../components/Complements/ComplementCustomer';

export default function CustomerInfo() {
  return (
    <div>
      <div className='static'>
        <Header />
      </div>
      <div className='absolute lg:top-40 top-32 inset-x-0'>
        <ComplementCustomer />
      </div>
    </div>
  );
}
