import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/MyCommandLogoWhite.png';
import checkCurrentRole from '../../utils/checkCurrentRole';
import ConnectedUserInformation from '../ConnectedUserInformation';

function NavBar() {
  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(['app', 'userDynamo']),
  }));
  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);

  const currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  return (
    <>
      <nav className='justify-between pt-6 hidden md:flex md:mr-5'>
        <ul className='flex items-center lg:ml-32 md:ml-16 '>
          <li>
            <Link to='/account' className='lg:ml-32 lg:mr-20'>
              <img src={logo} alt='logo' className='lg:h-14 md:h-12 ' />
            </Link>
          </li>
          <li className='text-white hover:bg-[#4338CA] rounded-md px-3 md:ml-10 mr-10 py-2'>
            <Link to='/account/eventsmanager'>My Events</Link>
          </li>{' '}
          <li className='text-white hover:bg-[#4338CA] rounded-md px-3 py-2 mr-10'>
            <Link to={'/account/' + currentRole + 'info'}>
              My Profile Details{' '}
            </Link>
          </li>
          <li className='text-white hover:bg-[#4338CA] rounded-md px-3 py-2  '>
            <Link to='/account'>My Account</Link>
          </li>
        </ul>
        <ConnectedUserInformation />
      </nav>
      <div className='bg-gradient-to-r from-regal-blue via-regal-purple to-transparent pb-1 md:w-3/4 lg:ml-32 md:ml-20 md:mt-6'></div>
    </>
  );
}

export default NavBar;
