import React, { useEffect, useState } from "react";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import LoginForm from '../../../components/LoginForm/index';
import Mycommand from '../../../assets/img/typo-mycommand.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginLaunched, sessionReset } from '../../../service/reducer';
import CircularProgress from '@mui/material/CircularProgress';

function LoginAsCarrier() {
  const dispatch = useDispatch();
  const login = (formik) => {
    dispatch(loginLaunched(formik));
  };
  const { loginErrorMessage, loginLoading, getCurrentSessionErrorMessage } =
    useSelector((state) => ({
      loginErrorMessage: state.getIn(["app", "loginErrorMessage"]),
      getCurrentSessionErrorMessage: state.getIn([
        "app",
        "getCurrentSessionErrorMessage",
      ]),
      loginLoading: state.getIn(["app", "loginLoading"]),
    }));
  const validate = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email required'),
    password: Yup.string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.'),
  });

  const [errorLoginRole, setErrorLoginRole] = useState(null);
  useEffect(() => {
    if (!loginLoading && getCurrentSessionErrorMessage?.statusCode === 401) {
      setErrorLoginRole(true)
    }
  }, [loginLoading, getCurrentSessionErrorMessage])

  return (
    <div className='bg-bg-carrier min-h-screen bg-no-repeat bg-cover font-sans bg-center relative flex flex-col justify-center'>
      <div className='py-10 text-center pt-10'>
        <img
          src={Mycommand}
          className='h-20 m-auto my-8 px-5'
          alt='MyCommand'
        ></img>
        <h1 className='text-white font-bold text-2xl'>
          Log in to your Carrier/Contractor  account
        </h1>
      </div>
      <div className='text-center text-white font-normal md:mt-10 lg:mt-0'>
        <p className='py-2'> You don't have a carrier/contractor  account ? </p>
        <button
          className='hover:font-bold underline underline-offset-2 flex mx-auto'
          type='button'
          onClick={() => dispatch(sessionReset())}
        >
          <Link to='/loginas' className='flex mx-auto'>
            {' '}
            Switch to another role !{' '}
          </Link>
        </button>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
          role: 'carrier',
        }}
        validationSchema={validate}
        onSubmit={(formik) => {
          login(formik);
        }}
      >
        {(formik) => (
          <Form className='flex flex-col items-center mt-10 px-4'>
            {' '}
            <div className='py-2 px-2 lg:w-2/5 md:w-3/4 w-full'>
              <div className='mb-2'>
                <LoginForm
                  label='Email'
                  name='email'
                  placeholder='Email address'
                  type='email'
                />
              </div>
              <LoginForm
                label='Password'
                name='password'
                placeholder='Password'
                type='password'
              />
            </div>
            {errorLoginRole && (
              <p className="text-center text-white mt-4 bg-red-500 md:w-3/4 lg:w-2/6 mx-auto mb-2">
                You are not authorized to connect to a carrier/contractor profile.
                <br></br>Please switch to another role.
              </p>
            )}
            {loginErrorMessage && (
              <p className='text-center font-bold text-white mt-4 bg-red-500 md:w-3/4 lg:w-2/6'>
                {loginErrorMessage}
              </p>
            )}
            <div className='flex text-white font-normal mt-2 mb-5 lg:w-2/5 md:w-3/4 w-full justify-center'>
              <button
                type='button'
                className='hover:font-bold underline underline-offset-2'
              >
                <Link to='/password'> Forgot your password ? </Link>
              </button>
            </div>
            {loginLoading && (
              <button
                className='bg-[#1A5DDA] hover:bg-blue-700 text-white font-bold py-4 rounded focus:outline-none focus:shadow-outline w-full md:w-3/4 lg:w-2/5'
                type='submit'
              >
                <CircularProgress size={20} style={{ color: 'white' }} />
              </button>
            )}
            {!loginLoading && (
              <button
                className='bg-[#1A5DDA] hover:bg-blue-700 text-white font-bold py-4 rounded focus:outline-none focus:shadow-outline w-full md:w-3/4 lg:w-2/5'
                type='submit'
              >
                Log in
              </button>
            )}
            <div className='flex text-white font-normal mt-4'>
              <p className='px-4'> Or </p>
              <Link to='/signup'>
                <p className='hover:font-bold underline underline-offset-2'>
                  {' '}
                  Sign up{' '}
                </p>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LoginAsCarrier;
