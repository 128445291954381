import React from 'react';
import NavBurger from '../NavBurger';
import NavBar from '../NavBar/NavBar';

function Header() {
  
  return (
    <header className='bg-bg-navbar h-96 '>
      <NavBurger />
      <NavBar />
    </header>
  );
}

export default Header;
