import React from 'react';
import { commonDateFormat } from '../../../utils/formatCommonDate';
import CircleIcon from '@mui/icons-material/Circle';
import { formatTime, reverseFormatForAPI } from "../../../utils/formatEventDate";

const BookingUser = ({ event }) => {
  return (
    <ul
      role='list'
      className='divide-y divide-gray-200 border-neutral-light border rounded-md px-2 md:px-4'
    >
      <li className='flex flex-col md:flex-row py-4 space-y-2 md:space-y-0 w-full md:justify-between'>
        <div className='flex flex-row items-center space-x-1'>
          <CircleIcon className='text-[#E2AA18]' fontSize='string' />
          <div className='w-full'>
            <p className='text-sm font-medium text-gray-900'>
              Customer/Resident
            </p>
            <p className='text-sm text-gray-500 break-words	'>
              {event?.customerEmail}
            </p>
          </div>
        </div>
        <div>
          <p className='text-sm font-medium text-gray-900'>Selected Date</p>
          {event?.customerDate &&
            event.customerDate.length !== 0 &&
            (event?.customerDate).map((date, index) => (
              <div
                key={date.day + index}
                className='text-sm text-gray-500 space-x-2'
              >
                <span>{commonDateFormat(date).date}</span>
                <span>{formatTime(reverseFormatForAPI(date.timeSlot))}</span>
              </div>
            ))}
          {(!event?.customerDate || event.customerDate.length === 0) && (
            <p className='text-sm text-gray-500'>Waiting</p>
          )}
        </div>
      </li>

      {event?.buildingManagerEmail && event?.buildingManagerEmail !== '' && (
        <li className='flex flex-col md:flex-row py-4 space-y-2 md:space-y-0 w-full md:justify-between'>
          <div className='flex flex-row items-center space-x-1'>
            <CircleIcon className='text-[#4F46E5]' fontSize='string' />
            <div className='w-full'>
              <p className='text-sm font-medium text-gray-900'>
                Building Manager
              </p>
              <p className='text-sm text-gray-500 break-words	'>
                {event?.buildingManagerEmail}
              </p>
            </div>
          </div>
          <div>
            <p className='text-sm font-medium text-gray-900'>Selected Date</p>
            {event?.buildingManagerDate &&
              event.buildingManagerDate.length !== 0 &&
              (event?.buildingManagerDate).map((date, index) => (
                <div
                  key={date.day + index}
                  className='text-sm text-gray-500 space-x-2'
                >
                  <span>{commonDateFormat(date).date}</span>
                  <span>{formatTime(reverseFormatForAPI(date.timeSlot))}</span>
                </div>
              ))}
            {(!event?.buildingManagerDate ||
              event.buildingManagerDate.length === 0) && (
              <p className='text-sm text-gray-500'>Waiting</p>
            )}
          </div>
        </li>
      )}
      <li className='flex flex-col md:flex-row  space-y-2 md:space-y-0 py-4  w-full md:justify-between'>
        <div className='flex flex-row items-center space-x-1'>
          <CircleIcon className='text-[#8940B5]' fontSize='string' />
          <div className='w-full'>
            <p className='text-sm font-medium text-gray-900'>
              Carrier/Contractor
            </p>
            <p className='text-sm text-gray-500 break-words'>
              {event?.carrierEmail}
            </p>
          </div>
        </div>
        <div>
          <p className='text-sm font-medium text-gray-900'>Selected Date</p>
          {event?.carrierDate &&
            event.carrierDate.length !== 0 &&
            (event?.carrierDate).map((date, index) => (
              <div
                key={date.day + index}
                className='text-sm text-gray-500 space-x-2'
              >
                <span>{commonDateFormat(date).date}</span>
                <span>{formatTime(reverseFormatForAPI(date.timeSlot))}</span>
              </div>
            ))}
          {(!event?.carrierDate || event.carrierDate.length === 0) && (
            <p className='text-sm text-gray-500'>Waiting</p>
          )}
        </div>
      </li>
      {event?.retailerEmail && event?.retailerEmail !== '' && (
        <li className='flex flex-col space-y-2 md:space-y-0 md:flex-row py-4  w-full md:justify-between'>
          <div className='flex flex-row items-center space-x-1'>
            <CircleIcon className='text-[#DE2B56]' fontSize='string' />
            <div className='w-full'>
              <p className='text-sm font-medium text-gray-900'>Retailer</p>
              <p className='text-sm text-gray-500 break-words'>
                {event?.retailerEmail}
              </p>
            </div>
          </div>
          <div>
            <p className='text-sm font-medium text-gray-900'>Selected Date</p>
            {event?.retailerDate &&
              event.retailerDate.length !== 0 &&
              (event?.retailerDate).map((date, index) => (
                <div
                  key={date.day + index}
                  className='text-sm text-gray-500 space-x-2'
                >
                  <span>{commonDateFormat(date).date}</span>
                  <span>{formatTime(reverseFormatForAPI(date.timeSlot))}</span>
                </div>
              ))}
            {(!event?.retailerDate || event.retailerDate.length === 0) && (
              <p className='text-sm text-gray-500'>Waiting</p>
            )}
          </div>
        </li>
      )}
    </ul>
  );
};

export default BookingUser;
