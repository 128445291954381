const getStyles = () => ({
  newBuilding: {
    // background: "linear-gradient(rgb(224, 233, 255), #F1E0FFFF)", was cool but not chosen 😪
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    // display: "inline-block",
  },
  particularity: {
    // background: "rgb(224, 233, 255)", was cool but not chosen 😪
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "5px",
  },
  freightEntranceInformations: {
    // background: "#F1E0FFFF", was cool but not chosen 😪
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  otherInformations: {
    // background: "rgb(224, 233, 255)", was cool but not chosen 😪
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
});

export default getStyles;