import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react"; // import state
import logo from "../../assets/img/MyCommandLogoWhite.png";
import ConnectedUserInformation from "../ConnectedUserInformation";
import { logoutLaunched } from "../../service/reducer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import checkCurrentRole from "../../utils/checkCurrentRole";

function NavBurger() {
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutLaunched());
    navigate("/loginas");
  };

  const { userDynamo } = useSelector((state) => ({
    userDynamo: state.getIn(["app", "userDynamo"]),
  }));
  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);

  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);

  const currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  return (
    <div className="flex items-center justify-between py-8 md:hidden">
      <Link to="/account">
        <img src={logo} alt="logo" className="h-10 ml-4" />
      </Link>
      <nav>
        <section className="MOBILE-MENU flex">
          <div
            className="HAMBURGER-ICON space-y-2 bg-[#4F46E5] p-2 rounded"
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <span className="block h-0.5 w-6  bg-[#C7D2FE]	"></span>
            <span className="block h-0.5 w-6  bg-[#C7D2FE]	"></span>
            <span className="block h-0.5 w-6  bg-[#C7D2FE]"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
              <li className="flex my-8">
                <ConnectedUserInformation />
              </li> <Link to="/account/eventsmanager">
                <li className="border-b border-gray-400 my-8 text-white hover:bg-[#4338CA] px-3 py-2  uppercase">
                My Events
                </li>
              </Link>
              <Link to="/account">
                <li className="border-b border-gray-400 my-8 text-white hover:bg-[#4338CA] px-3 py-2 rounded-md uppercase">
                My Account
                </li>
              </Link>
              <Link to={"/account/" + currentRole + "info"}>
                <li className="border-b border-gray-400 my-8 text-white hover:bg-[#4338CA] px-3 py-2  uppercase">
                  My Profile Details
                </li>
              </Link>
             
              <li className="border-b border-gray-400 my-8 text-white hover:bg-[#4338CA] px-3 py-2  uppercase">
                <button
                  type="button"
                  onClick={() => {
                    logout();
                  }}
                >
                  Signout
                </button>
              </li>
            </ul>
          </div>
        </section>
      </nav>

      <style>{`
    .hideMenuNav {
      display: none;
    }
    .showMenuNav {
      display: block;
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background: linear-gradient(89.46deg, #1C5FDC -2.19%, #8477F5 105.74%);;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  `}</style>
    </div>
  );
}

export default NavBurger;
