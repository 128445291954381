import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';

function AddResident({
  invitResident,
  setResidentEmail,
  invitResidentInProgress,
  invitResidentErrorMessage,
  invitResidentSuccessMessage,
}) {
  const [showModal, setShowModal] = useState(false);
  const cancelButtonRef = useRef(null);
  const [isSendWithSuccess, setIsSendWithSuccess] = useState(null);

  // Set success message //
  useEffect(() => {
    if (!invitResidentInProgress && invitResidentSuccessMessage) {
      setIsSendWithSuccess(invitResidentSuccessMessage);
    }
  }, [invitResidentInProgress, invitResidentSuccessMessage]);

  // auto close modal after 4s ONLY if form is submitted
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    const timer = setTimeout(() => {
      setShowModal();
      setIsSendWithSuccess(null);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, [isSendWithSuccess]);

  return (
    <>
      <button
        className='bg-bg-button text-white rounded-full py-2.5 px-4 ease-linear transition-all duration-150'
        type='button'
        onClick={() => setShowModal(true)}
      >
        + Invit resident
      </button>
      {showModal ? (
        <>
          <Transition.Root show={showModal} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-50'
              initialFocus={cancelButtonRef}
              onClose={setShowModal}
            >
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
              </Transition.Child>

              <div className='fixed inset-0 z-10 overflow-y-auto'>
                <div className='flex min-h-full justify-center p-4 text-center items-center sm:p-0'>
                  <Transition.Child
                    as={Fragment}
                    enter='ease-out duration-300'
                    enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                    enterTo='opacity-100 translate-y-0 sm:scale-100'
                    leave='ease-in duration-200'
                    leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                    leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  >
                    <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 w-full max-w-xl sm:p-6'>
                      <div>
                        <div className='mt-3 text-center sm:mt-5'>
                          <Dialog.Title
                            as='h3'
                            className='text-lg font-medium leading-6 text-gray-900 pb-6'
                          >
                            Invit a new resident
                          </Dialog.Title>
                          <div className='flex flex-col md:flex-row md:items-center space-y-1 md:space-y-0 md:space-x-2  mb-8'>
                            <label
                              htmlFor='residentEmail'
                              className='w-full md:w-1/5'
                            >
                              {' '}
                              Email :{' '}
                            </label>
                            <input
                              className='border border-2 rounded-lg py-1 px-2 w-full'
                              name='residentEmail'
                              placeholder='johndoe@mail.com'
                              type='email'
                              onChange={(e) => {
                                setResidentEmail(e.target.value);
                              }}
                            ></input>
                          </div>
                          <div className='mt-2'>
                            {invitResidentInProgress && (
                              <p className='text-sm text-gray-500'>
                                In progress...
                              </p>
                            )}
                            {/* success message */}
                            {!invitResidentInProgress &&
                              invitResidentSuccessMessage && (
                                <p className='text-sm text-primary'>
                                  {isSendWithSuccess}
                                </p>
                              )}
                            {/* error message */}
                            {!invitResidentInProgress &&
                              invitResidentErrorMessage && (
                                <p className='text-sm text-error'>
                                  {invitResidentErrorMessage}
                                </p>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className='mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
                        <button
                          disabled={invitResidentInProgress}
                          type='button'
                          className={`inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm`}
                          onClick={() => {
                            invitResident();
                          }}
                        >
                          Send
                        </button>
                        <button
                          type='button'
                          className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm'
                          onClick={() => setShowModal(false)}
                          ref={cancelButtonRef}
                        >
                          {isSendWithSuccess ? 'Close' : 'Cancel'}
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* <div className='justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              <div className='max-w-3xl border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t'>
                  <h3 className='text-2xl font-medium'>
                    Invit a new resident{' '}
                  </h3>
                  <button
                    className='p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none'
                    onClick={() => setShowModal(false)}
                  >
                    <span className='bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none'>
                      ×
                    </span>
                  </button>
                </div>
                <div className='relative p-6 flex-auto'>
                  <label htmlFor='residentEmail'>
                    {' '}
                    Email :{' '}
                    <input
                      className='border border-2 rounded-lg'
                      name='residentEmail'
                      type='email'
                      onChange={(e) => {
                        setResidentEmail(e.target.value);
                      }}
                    ></input>
                  </label>
                </div>
                <div className='flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className='bg-bg-button text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150'
                    type='button'
                    onClick={() => {
                      setShowModal(false);
                      invitResident();
                    }}
                  >
                    Invit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>*/}
        </>
      ) : null}
    </>
  );
}

export default AddResident;
