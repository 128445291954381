import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function BuildingResident({ disabledResident }) {
  const { buildingResidentList } = useSelector((state) => ({
    buildingResidentList: state.getIn(['app', 'getCurrentBuildingResident']),
  }));

  const [residents, setResidents] = useState();


  useEffect(() => {
    if (buildingResidentList) {
      setResidents(buildingResidentList);
    }
  }, [buildingResidentList]);
  
  return (
    <main className='w-full'>
      {residents && (
        <>
          <h2 className='text-xl mb-4'> General</h2>
          <table className='w-full'>
            <thead className='bg-[#F9FAFB] border rounded-lg '>
              <tr>
                <td className='px-4 py-2.5 text-[#6B7280]'>NAME</td>
                <td className='px-4 text-[#6B7280]'>EMAIL</td>
                <td className='px-4 text-[#6B7280]'>Unit </td>
                <td className='px-4 text-[#6B7280]'>... </td>
              </tr>
            </thead>

            <tbody className='border  rounded-full w-96 '>
              {residents?.map(
                (resident) =>
                  resident.isActive && (
                    <tr className='border mx-10' key={resident.id}>
                      <td className='px-4'>
                        {resident.firstName} {resident.lastName}
                      </td>
                      <td className='p-4 text-[#6B7280]'> {resident.email}</td>
                      <td className='px-4'>{resident.complement}</td>
                      <td className='px-4'>
                        <button
                          className='text-red-500'
                          onClick={() =>
                            disabledResident(resident.id, resident.customerId)
                          }
                        >
                          {' '}
                          Delete{' '}
                        </button>
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </>
      )}
      {!residents && (
        <p>No resident</p>
      )}
    </main>
  );
}

export default BuildingResident;
