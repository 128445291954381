const getStyles = () => ({
  newBuilding: {
    // background: "linear-gradient(rgb(224, 233, 255), #F1E0FFFF)", was cool but not chosen 😪
    background: "#eaf4fd",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    // display: "inline-block",
  },
});

export default getStyles;