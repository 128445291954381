import React from 'react';
import { amplifyConfig } from './config/amplify';
import Amplify from 'aws-amplify';
import './index.css';
import ReactDOM from 'react-dom/client';
import App from './App';
import ProviderWrapper from './utils/provider';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


Amplify.configure(amplifyConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(ProviderWrapper(<App />));
