const getStyles = () => ({
  company: {
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
  contact: {
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
  billing: {
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
});

export default getStyles;