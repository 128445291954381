import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';

const initialState = Immutable.Map({
  isAuthenticated: false,
  isAuthenticating: true,
  changePasswordLoading: false,
  changePasswordSuccessMessage: null,
  changePasswordErrorMessage: null,
  userDynamo: null,
  getCurrentSessionErrorMessage: null,
  loginLoading: false,
  loginErrorMessage: null,
  logoutLoading: false,
  signupLoading: false,
  signupErrorMessage: null,
  requestCodeLoading: false,
  requestCodeErrorMessage: null,
  submitPasswordLoading: false,
  submitPasswordErrorMessage: null,
  submitPasswordSuccessMessage: null,
  forgotPasswordStep: 1,
  updateUserLoading: false,
  updateUserSuccessMessage: null,
  updateUserErrorMessage: null,
  snackBarOpen: null,
  snackBarMessage: null,
  snackBarType: null,
  popupOpen: false,
  popupMessage: null,
  popupError: false,
  popupId: null,
  createBuildingLoading: false,
  createBuildingErrorMessage: null,
  createBuildingSuccessMessage: null,
  invitResidentLoading: false,
  invitResidentErrorMessage: null,
  invitResidentSuccessMessage: null,
  getBuildingLoading: false,
  getBuildingErrorMessage: null,
  getCurrentBuilding: null,
  deleteBuildingLoading: false,
  deleteBuildingErrorMessage: null,
  deleteBuildingSuccessMessage: null,
  disabledResidentLoading: false,
  disabledResidentErrorMessage: null,
  disabledResidentSuccessMessage: null,
  updateBuildingLoading: false,
  updateBuildingErrorMessage: null,
  getBuildingResidentLoading: false,
  updateBuildingSuccess: null,
  getBuildingResidentErrorMessage: null,
  getCurrentBuildingResident: null,
  createEventLoading: false,
  createEventErrorMessage: null,
  createEventId: null,
  createEventSuccessMessage: null,
  uploadInsuranceFileLoading: false,
  uploadEntranceDoorPhotoFileLoading: false,
  uploadInsuranceFileError: null,
  uploadInsuranceFileSuccess: null,
  uploadPictureFileLoading: false,
  uploadPictureFileError: null,
  uploadPictureFileSuccess: null,
  uploadInvoiceFileLoading: false,
  uploadInvoiceFileError: null,
  uploadInvoiceFileSuccess: null,
});

const { actions, reducer } = createSlice({
  slice: 'App',
  name: 'App',
  initialState,
  reducers: {
    // GETCURRENT
    sessionReset: (state, action) =>
      state
        .set('isAuthenticating', false)
        .set('isAuthenticated', false)
        .set('userInfo', null)
        .set('userDynamo', null)
        .set('getCurrentSessionErrorMessage', null),
    getCurrentSessionLaunched: (state, action) =>
      state
        .set('isAuthenticating', true)
        .set('isAuthenticated', false)
        .set('userInfo', null)
        .set('userDynamo', null)
        .set('getCurrentSessionErrorMessage', null),
    getCurrentSessionSuccess: (state, action) =>
      state
        .set('isAuthenticated', true)
        .set('isAuthenticating', false)
        .set('userInfo', action.payload.userInfo)
        .set('userDynamo', action.payload.userDynamo)
        .set('getCurrentSessionErrorMessage', null),
    getCurrentSessionFailure: (state, action) =>
      state
        .set('isAuthenticating', false)
        .set('isAuthenticated', false)
        .set('userInfo', null)
        .set('userDynamo', null)
        .set('getCurrentSessionErrorMessage', action.payload),
    // LOGIN
    loginLaunched: (state, action) =>
      state.set('loginLoading', true).set('loginErrorMessage', null),
    loginSuccess: (state, action) =>
      state.set('loginLoading', false).set('loginErrorMessage', null),
    loginFailure: (state, action) =>
      state.set('loginLoading', false).set('loginErrorMessage', action.payload),
    // LOGOUT
    logoutLaunched: (state, action) => state.set('logoutLoading', true),
    logoutSuccess: (state, action) => initialState,
    logoutFailure: (state, action) => state.set('logoutLoading', false),
    // SIGNUP
    signupLaunched: (state, action) =>
      state.set('signupLoading', true).set('signupErrorMessage', null),
    signupSuccess: (state, action) =>
      state.set('signupLoading', false).set('signupErrorMessage', null),
    signupFailure: (state, action) =>
      state
        .set('signupLoading', false)
        .set('signupErrorMessage', action.payload),
    // REQUEST PASSWORD CODE
    requestPasswordCodeLaunched: (state, action) =>
      state
        .set('requestCodeLoading', true)
        .set('requestCodeErrorMessage', null)
        .set('forgotPasswordStep', 1),
    requestPasswordCodeSuccess: (state, action) =>
      state
        .set('requestCodeLoading', false)
        .set('requestCodeErrorMessage', null)
        .set('forgotPasswordStep', 2),
    requestPasswordCodeFailure: (state, action) =>
      state
        .set('requestCodeLoading', false)
        .set('requestCodeErrorMessage', action.payload)
        .set('forgotPasswordStep', 1),
    // SUBMIT NEW PASSWORD
    submitNewPasswordLaunched: (state, action) =>
      state
        .set('submitPasswordLoading', true)
        .set('submitPasswordErrorMessage', null),
    submitNewPasswordSuccess: (state, action) =>
      state
        .set('submitPasswordLoading', false)
        .set('submitPasswordErrorMessage', null)
        .set('submitPasswordSuccessMessage', 'Your password has been changed'),
    submitNewPasswordFailure: (state, action) =>
      state
        .set('submitPasswordLoading', false)
        .set('submitPasswordErrorMessage', action.payload),
    // CHANGE PASSWORD
    changePasswordLaunched: (state, action) =>
      state
        .set('changePasswordLoading', true)
        .set('changePasswordSuccessMessage', null)
        .set('changePasswordErrorMessage', null),
    changePasswordSuccess: (state, action) =>
      state
        .set('changePasswordLoading', false)
        .set('changePasswordSuccessMessage', action.payload)
        .set('changePasswordErrorMessage', null),
    changePasswordFailure: (state, action) =>
      state
        .set('changePasswordLoading', false)
        .set('changePasswordSuccessMessage', null)
        .set('changePasswordErrorMessage', action.payload),
    // FIRST LOGIN
    updateUserLaunched: (state, action) =>
      state
        .set('updateUserLoading', true)
        .set('updateUserSuccessMessage', null)
        .set('updateUserErrorMessage', null),
    updateUserSuccess: (state, action) =>
      state
        .set('updateUserLoading', false)
        .set('updateUserSuccessMessage', true)
        .set('updateUserErrorMessage', null),
    updateUserFailure: (state, action) =>
      state
        .set('updateUserLoading', false)
        .set('updateUserSuccessMessage', null)
        .set('updateUserErrorMessage', action.payload),
    // SnackBar
    openSnackBar: (state, action) =>
      state
        .set('snackBarOpen', true)
        .set('snackBarMessage', action.payload.message)
        .set('snackBarType', action.payload.type),
    closeSnackBar: (state, action) => state.set('snackBarOpen', false),
    clearSnackBar: (state, action) =>
      state.set('snackBarMessage', null).set('snackBarType', null),
    // Popup
    openPopup: (state, action) =>
      state
        .set('popupOpen', action.payload.open)
        .set('popupMessage', action.payload.message)
        .set('popupError', action.payload.error)
        .set('popupId', action.payload.popupId),
    // CreateBuilding
    resetCreateBuilding: (state, action) =>
      state
        .set('createBuildingLoading', false)
        .set('createBuildingErrorMessage', null)
        .set('createBuildingSuccessMessage', null),
    createBuildingLauched: (state, action) =>
      state
        .set('createBuildingLoading', true)
        .set('createBuildingErrorMessage', null)
        .set('createBuildingSuccessMessage', null),
    createBuildingSuccess: (state, action) =>
      state
        .set('createBuildingLoading', false)
        .set('createBuildingErrorMessage', null)
        .set('createBuildingSuccessMessage', true),
    createBuildingFailure: (state, action) =>
      state
        .set('createBuildingLoading', false)
        .set('createBuildingErrorMessage', action.payload)
        .set('createBuildingSuccessMessage', null),
    // getBuilding
    resetGetBuilding: (state, action) =>
      state
        .set('getBuildingLoading', false)
        .set('getCurrentBuilding', null)
        .set('getBuildingError', null),
    getBuildingLoading: (state, action) =>
      state
        .set('getBuildingLoading', true)
        .set('getCurrentBuilding', null)
        .set('getBuildingError', null),
    getBuildingSuccess: (state, action) =>
      state
        .set('getBuildingLoading', false)
        .set('getCurrentBuilding', action.payload)
        .set('getBuildingError', null),
    getBuildingFailure: (state, action) =>
      state
        .set('getBuildingLoading', false)
        .set('getCurrentBuilding', null)
        .set('getBuildingError', action.payload),
    // deleteBuilding
    deleteBuildingLoading: (state, action) =>
      state
        .set('deleteBuildingLoading', true)
        .set('deleteBuildingErrorMessage', null)
        .set('deleteBuildingSuccessMessage', null),
    deleteBuildingSuccess: (state, action) =>
      state
        .set('deleteBuildingLoading', false)
        .set('deleteBuildingErrorMessage', null)
        .set('deleteBuildingSuccessMessage', 'Building delete with success'),
    deleteBuildingFailure: (state, action) =>
      state
        .set('deleteBuildingLoading', false)
        .set('deleteBuildingErrorMessage', action.payload)
        .set('deleteBuildingSuccessMessage', null),
    // Update a building
    resetUpdateBuilding: (state, action) =>
      state
        .set('updateBuildingLoading', false)
        .set('updateBuildingSuccess', null)
        .set('updateBuildingErrorMessage', null),
    updateBuildingLaunched: (state, action) =>
      state
        .set('updateBuildingLoading', true)
        .set('updateBuildingSuccess', null)
        .set('updateBuildingErrorMessage', null),
    updateBuildingSuccess: (state, action) =>
      state
        .set('updateBuildingLoading', false)
        .set('updateBuildingSuccess', true)
        .set('updateBuildingErrorMessage', null),
    updateBuildingFailure: (state, action) =>
      state
        .set('updateBuildingLoading', false)
        .set('updateBuildingSuccess', null)
        .set('updateBuildingErrorMessage', action.payload),

    // getBuildingResident
    getBuildingResidentLoading: (state, action) =>
      state
        .set('getBuildingResidentLoading', true)
        .set('getCurrentBuildingResident', null)
        .set('getBuildingResidentError', null),
    getBuildingResidentSuccess: (state, action) =>
      state
        .set('getBuildingResidentLoading', false)
        .set('getCurrentBuildingResident', action.payload)
        .set('getBuildingResidentError', null),
    getBuildingResidentFailure: (state, action) =>
      state
        .set('getBuildingResidentLoading', false)
        .set('getCurrentBuildingResident', null)
        .set('getBuildingResidentError', action.payload),

    // InvitResident
    invitResidentLoading: (state, action) =>
      state
        .set('invitResidentLoading', true)
        .set('invitResidentErrorMessage', null)
        .set('invitResidentSuccessMessage', null),
    invitResidentSuccess: (state, action) =>
      state
        .set('invitResidentLoading', false)
        .set('invitResidentErrorMessage', null)
        .set('invitResidentSuccessMessage', 'Invitation send with success.'),
    invitResidentFailure: (state, action) =>
      state
        .set('invitResidentLoading', false)
        .set(
          'invitResidentErrorMessage',
          'An error has occurred, please try again later.'
        )
        .set('invitResidentSuccessMessage', null),
    // CREATE EVENT
    createEventLoading: (state, action) =>
      state
        .set('createEventLoading', true)
        .set('createEventSuccessMessage', null)
        .set('createEventErrorMessage', null)
        .set('createEventId', null),
    createEventSuccess: (state, action) =>
      state
        .set('createEventLoading', false)
        .set('createEventErrorMessage', null)
        .set('createEventSuccessMessage', true)
        .set('createEventId', action.payload),
    createEventFailure: (state, action) =>
      state
        .set('createEventLoading', false)
        .set('createEventSuccessMessage', null)
        .set('createEventErrorMessage', action.payload)
        .set('createEventId', null),

    // disabledResident
    disabledResidentLoading: (state, action) =>
      state
        .set('disabledResidentLoading', true)
        .set('disabledResidentErrorMessage', null),
    disabledResidentSuccess: (state, action) =>
      state
        .set('disabledResidentLoading', false)
        .set('disabledResidentErrorMessage', null)
        .set(
          'disabledResidentSuccessMessage',
          'Votre mot Building a bien été réinitialisé'
        ),
    disabledResidentFailure: (state, action) =>
      state
        .set('disabledResidentLoading', false)
        .set('disabledResidentErrorMessage', action.payload),

    // upload Insurance File
    uploadInsuranceFileLauched: (state, action) =>
      state
        .set('uploadInsuranceFileLoading', true)
        .set('uploadInsuranceFileSuccess', null)
        .set('uploadInsuranceFileError', null),
    uploadInsuranceFileSuccess: (state, action) =>
      state
        .set('uploadInsuranceFileLoading', false)
        .set('uploadInsuranceFileSuccess', true)
        .set('uploadInsuranceFileError', null),
    uploadInsuranceFileFailure: (state, action) =>
      state
        .set('uploadInsuranceFileLoading', false)
        .set('uploadInsuranceFileSuccess', null)
        .set(
          'uploadInsuranceFileError',
          'An error has occurred. Please try again.'
        ),

    // upload Entrance door picture
    uploadEntranceDoorPhotoFileLaunched: (state, action) =>
      state
      .set('uploadEntranceDoorPhotoFileLoading', true)
      .set('uploadEntranceDoorPhotoFileSuccess', null)
      .set('uploadEntranceDoorPhotoFileError', null),
    uploadEntranceDoorPhotoFileSuccess: (state, action) =>
      state
      .set('uploadEntranceDoorPhotoFileLoading', false)
      .set('uploadEntranceDoorPhotoFileSuccess', true)
      .set('uploadEntranceDoorPhotoFileError', null),
    uploadEntranceDoorPhotoFileFailure: (state, action) =>
      state
      .set('uploadEntranceDoorPhotoFileLoading', false)
      .set('uploadEntranceDoorPhotoFileSuccess', null)
      .set(
        'uploadEntranceDoorPhotoFileError',
        'An error has occurred. Please try again.'
      ),

    // upload Freight door picture
    uploadFreightDoorPhotoFileLaunched: (state, action) =>
      state
      .set('uploadFreightDoorPhotoFileLoading', true)
      .set('uploadFreightDoorPhotoFileSuccess', null)
      .set('uploadFreightDoorPhotoFileError', null),
    uploadFreightDoorPhotoFileSuccess: (state, action) =>
      state
      .set('uploadFreightDoorPhotoFileLoading', false)
      .set('uploadFreightDoorPhotoFileSuccess', true)
      .set('uploadFreightDoorPhotoFileError', null),
    uploadFreightDoorPhotoFileFailure: (state, action) =>
      state
      .set('uploadFreightDoorPhotoFileLoading', false)
      .set('uploadFreightDoorPhotoFileSuccess', null)
      .set(
        'uploadFreightDoorPhotoFileError',
        'An error has occurred. Please try again.'
      ),

    // upload COI Document File
    uploadCOIDocumentFilePhotoFileLaunched: (state, action) =>
      state
      .set('uploadCOIDocumentFilePhotoFileLoading', true)
      .set('uploadCOIDocumentFilePhotoFileSuccess', null)
      .set('uploadCOIDocumentFilePhotoFileError', null),
    uploadCOIDocumentFilePhotoFileSuccess: (state, action) =>
      state
      .set('uploadCOIDocumentFilePhotoFileLoading', false)
      .set('uploadCOIDocumentFilePhotoFileSuccess', true)
      .set('uploadCOIDocumentFilePhotoFileError', null),
    uploadCOIDocumentFilePhotoFileFailure: (state, action) =>
      state
      .set('uploadCOIDocumentFilePhotoFileLoading', false)
      .set('uploadCOIDocumentFilePhotoFileSuccess', null)
      .set(
        'uploadCOIDocumentFilePhotoFileError',
        'An error has occurred. Please try again.'
      ),
    // upload Building Document File
    uploadBuildingDocumentFilePhotoFileLaunched: (state, action) =>
      state
      .set('uploadBuildingDocumentFilePhotoFileLoading', true)
      .set('uploadBuildingDocumentFilePhotoFileSuccess', null)
      .set('uploadBuildingDocumentFilePhotoFileError', null),
    uploadBuildingDocumentFilePhotoFileSuccess: (state, action) =>
      state
      .set('uploadBuildingDocumentFilePhotoFileLoading', false)
      .set('uploadBuildingDocumentFilePhotoFileSuccess', true)
      .set('uploadBuildingDocumentFilePhotoFileError', null),
    uploadBuildingDocumentFilePhotoFileFailure: (state, action) =>
      state
      .set('uploadBuildingDocumentFilePhotoFileLoading', false)
      .set('uploadBuildingDocumentFilePhotoFileSuccess', null)
      .set(
        'uploadBuildingDocumentFilePhotoFileError',
        'An error has occurred. Please try again.'
      ),

    uploadPictureFileLauched: (state, action) =>
      state
        .set('uploadPictureFileLoading', true)
        .set('uploadPictureFileSuccess', null)
        .set('uploadPictureFileError', null),
    uploadPictureFileSuccess: (state, action) =>
      state
        .set('uploadPictureFileLoading', false)
        .set('uploadPictureFileSuccess', true)
        .set('uploadPictureFileError', null),
    uploadPictureFileFailure: (state, action) =>
      state
        .set('uploadPictureFileLoading', false)
        .set('uploadPictureFileSuccess', null)
        .set(
          'uploadPictureFileError',
          'An error has occurred. Please try again.'
        ),

    uploadInvoiceFileLauched: (state, action) =>
      state
        .set('uploadInvoiceFileLoading', true)
        .set('uploadInvoiceFileSuccess', null)
        .set('uploadInvoiceFileError', null),
    uploadInvoiceFileSuccess: (state, action) =>
      state
        .set('uploadInvoiceFileLoading', false)
        .set('uploadInvoiceFileSuccess', true)
        .set('uploadInvoiceFileError', null),
    uploadInvoiceFileFailure: (state, action) =>
      state
        .set('uploadInvoiceFileLoading', false)
        .set('uploadInvoiceFileSuccess', null)
        .set(
          'uploadInvoiceFileError',
          'An error has occurred. Please try again.'
        ),
  },
});

export const {
  sessionReset,
  getCurrentSessionLaunched,
  getCurrentSessionSuccess,
  getCurrentSessionFailure,
  loginLaunched,
  loginSuccess,
  loginFailure,
  logoutSuccess,
  logoutFailure,
  logoutLaunched,
  signupLaunched,
  signupSuccess,
  signupFailure,
  requestPasswordCodeLaunched,
  requestPasswordCodeSuccess,
  requestPasswordCodeFailure,
  changePasswordLaunched,
  changePasswordSuccess,
  changePasswordFailure,
  submitNewPasswordLaunched,
  submitNewPasswordSuccess,
  submitNewPasswordFailure,
  updateUserLaunched,
  updateUserSuccess,
  updateUserFailure,
  openSnackBar,
  closeSnackBar,
  clearSnackBar,
  openPopup,
  createBuildingLauched,
  createBuildingSuccess,
  createBuildingFailure,
  resetGetBuilding,
  getBuildingLoading,
  getBuildingSuccess,
  getBuildingFailure,
  deleteBuildingLoading,
  deleteBuildingSuccess,
  deleteBuildingFailure,
  disabledResidentLoading,
  disabledResidentSuccess,
  disabledResidentFailure,
  resetCreateBuilding,
  resetUpdateBuilding,
  updateBuildingLaunched,
  updateBuildingSuccess,
  updateBuildingFailure,
  getBuildingResidentLoading,
  getBuildingResidentSuccess,
  getBuildingResidentFailure,
  invitResidentLoading,
  invitResidentSuccess,
  invitResidentFailure,
  createEventLoading,
  createEventSuccess,
  createEventFailure,
  uploadInsuranceFileLauched,
  uploadInsuranceFileSuccess,
  uploadInsuranceFileFailure,
  uploadEntranceDoorPhotoFileLaunched,
  uploadEntranceDoorPhotoFileSuccess,
  uploadEntranceDoorPhotoFileFailure,
  uploadFreightDoorPhotoFileLaunched,
  uploadFreightDoorPhotoFileSuccess,
  uploadFreightDoorPhotoFileFailure,
  uploadCOIDocumentFilePhotoFileLaunched,
  uploadCOIDocumentFilePhotoFileSuccess,
  uploadCOIDocumentFilePhotoFileFailure,
  uploadBuildingDocumentFilePhotoFileLaunched,
  uploadBuildingDocumentFilePhotoFileSuccess,
  uploadBuildingDocumentFilePhotoFileFailure,
  uploadPictureFileLauched,
  uploadPictureFileSuccess,
  uploadPictureFileFailure,
  uploadInvoiceFileLauched,
  uploadInvoiceFileSuccess,
  uploadInvoiceFileFailure,
} = actions;

export default reducer;
