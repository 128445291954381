import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePasswordLaunched } from "../../service/reducer";
import { translateErrorChangePassword } from "../../utils/cognito";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { capitalizeFirst } from "../../utils/format";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";

export const ModalEditPassword = ({ showModal, hideModal }) => {
  const dispatch = useDispatch();

  const { changePasswordLoading, successStatut, errorStatut } = useSelector((state) => ({
    changePasswordLoading: state.getIn(["root", "changePasswordLoading"]),
    successStatut: state.getIn(["root", "changePasswordSuccessMessage"]),
    errorStatut: state.getIn(["root", "changePasswordErrorMessage"]),
  }));

  // auto close modal after 3s ONLY if password changed with success
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return undefined;
    }
    let timer;
    if (successStatut) {
      timer = setTimeout(() => hideModal(), 3000);
    } else {
      return undefined;
    }

    return () => {
      clearTimeout(timer);
    };
  }, [successStatut]);

  // Set error message & success message
  const [successMessage, setSuccessMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();
  useEffect(() => {
    if (successStatut === "SUCCESS") {
      setSuccessMessage("Changement effectué avec succès");
    }
    if (errorStatut) {
      setErrorMessage(translateErrorChangePassword(errorStatut.code));
    }
  }, [successStatut, errorStatut]);

  const [formValue, setFormValue] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const initialValues = {
    oldPassword: formValue.oldPassword,
    password: formValue.password,
    confirmPassword: formValue.confirmPassword,
  };

  // Validation schema
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required("Please enter your old password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        "Please enter at least 8 characters, a letter, a number, a capital letter and a special character"
      )
      .nullable(),
    password: Yup.string()
      .required("Please enter your new password")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$&+,:;=?@#|'<>.^*()%!-])[A-Za-z\d$&+,:;=?@#|'<>.^*()%!-]{8,}$/,
        "Please enter at least 8 characters, a letter, a number, a capital letter and a special character"
      )
      .nullable(),
    confirmPassword: Yup.string()
      .required("Confirmation of new password required")
      .oneOf([Yup.ref("password"), null], "The new password does not match")
      .nullable(),
  });

  // Form submit function
  const changePassord = () => {
    dispatch(changePasswordLaunched(formValue));
  };

  return (
    showModal && (
      <div
        role="button"
        className="cursor-auto middle absolute w-screen h-screen top-0 pt-0 inset-x-0 flex justify-center items-center md:items-center bg-bg-black"
        // close modal if anything outside modal content is clicked
        onClick={hideModal}
        onKeyPress={hideModal}
        tabIndex="0"
      >
        <div
          aria-hidden="true"
          className="relative bg-white w-full sm:w-5/6 md:w-3/4 lg:w-1/2 2xl:w-1/3 flex flex-col justify-center items-center px-4 md:px-12 py-3 md:py-8 rounded rounded-lg"
          // don't close modal if anything inside modal content is clicked or any key is pressed
          onClick={(e) => {
            e.stopPropagation();
          }}
          onKeyPress={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="w-3/4 md:w-full my-2">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={changePassord}
            >
              {({ errors, touched, values, handleChange }) => (
                <Form>
                  <h2 className="text-xl mb-2">Password change</h2>
                  <hr />
                  <div className="flex flex-col mt-4">
                    <div className="mt-4">
                      <label className="block text-xxms font-medium text-gray-700">
                        Please enter your old password
                      </label>
                      <div className="mt-1 mb-4">
                        <Field
                          type="password"
                          id="oldPassword"
                          name="oldPassword"
                          value={values.oldPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                          className="appearance-none py-3 px-4 block w-full shadow-sm rounded-md focus:ring-orange-dark focus:border-orange-dark border border-gray-300"
                        />
                        {errors.oldPassword && touched.oldPassword ? (
                          <div className="text-red-600">
                            {errors.oldPassword}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="block text-xxms font-medium text-gray-700">
                        Please enter a new password
                      </label>
                      <div className="mt-1 mb-2">
                        <Field
                          type="password"
                          id="password"
                          name="password"
                          value={values.password}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                          className="appearance-none py-3 px-4 block w-full shadow-sm rounded-md focus:ring-orange-dark focus:border-orange-dark border border-gray-300"
                        />
                        {errors.password && touched.password ? (
                          <div className="text-red-600">{errors.password}</div>
                        ) : null}
                      </div>
                    </div>
                    <div>
                      <label className="block text-xxms font-medium text-gray-700">
                        Confirm your new password
                      </label>
                      <div className="mt-1">
                        <Field
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={(e) => {
                            handleChange(e);
                            setFormValue((prevState) => ({
                              ...prevState,
                              [e.target.name]: e.target.value,
                            }));
                          }}
                          className="appearance-none py-3 px-4 block w-full shadow-sm rounded-md focus:ring-orange-dark focus:border-orange-dark border border-gray-300"
                        />
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <div className="text-red-600">
                            {errors.confirmPassword}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {!changePasswordLoading && (successMessage || errorMessage) && (
                      <div className="mt-6 flex justify-center">
                        <p>
                          {successMessage
                            ? successMessage
                            : errorMessage
                            ? errorMessage
                            : "A problem has occurred"}
                        </p>
                      </div>
                    )}
                    <div className="mt-6 flex justify-center">
                      {changePasswordLoading && (
                        <button
                          type="submit"
                          disabled={changePasswordLoading ? true : false}
                          className="bg-bg-button w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg hover:scale-105 ease-out duration-300"
                        >
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                            className="mx-auto"
                          />
                        </button>
                      )}
                      {!changePasswordLoading && (
                        <button
                          type="submit"
                          disabled={changePasswordLoading ? true : false}
                          className="bg-bg-button w-full md:w-52 px-3 py-1.5 border border-grey rounded-md shadow-sm text-xxms font-medium text-white text-lg hover:scale-105 ease-out duration-300"
                        ><SaveIcon fontSize="small" className="mr-2" />
                          Save password
                        </button>
                      )}
                    </div>
                    <div className="mt-3 flex justify-center">
                      <button
                        type="button"
                        onClick={hideModal}
                        className="w-full md:w-52 text-[#1a5dda] px-3 py-1.5 border border-[#1a5dda] rounded-md shadow-sm text-xxms font-medium hover:scale-105 hover:font-bold focus:outline-none focus:ring-0 focus:ring-offset-0"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <style>{`
    .middle {
      height: 84vh;
    }
  `}</style>
      </div>
    )
  );
};

export default ModalEditPassword;
