import React from "react";
import logo from "../../assets/img/MyCommandLogoWhite.png";
import BMLogo from "../../assets/img/BM_Logo_indigo.svg";
import RetailerLogo from "../../assets/img/Retailer_Logo_indigo.svg";
import CarrierLogo from "../../assets/img/Carrier_Logo_indigo.svg";
import CustomerLogo from "../../assets/img/Customer_Logo_indigo.svg";
import { Link } from "react-router-dom";

function LoginAs() {
  return (
    <div className="bg-bg-login min-h-screen bg-no-repeat bg-cover font-sans flex flex-col justify-evenly">
      <div className="text-center">
        <img src={logo} className="w-32 m-auto pt-10" alt="Logo" />
        <div className="self-center">
          <h1 className="text-white mt-8 font-bold text-2xl sm:text-3xl">
            Log in to your account
          </h1>
        </div>
      </div>
      <div className="flex flex-col mx-4 py-2 lg:py-10">
        <Link to="/loginascustomer" className="flex mx-auto w-full text-lg">
          <button
            className="bg-white my-3 sm:my-5 px-10 sm:px-20 py-3 sm:py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-indigo-600 text-lg hover:text-white hover:bg-indigo-500 hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={CustomerLogo}
              alt="Logo Customer"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Customer/Resident</p>
          </button>
        </Link>
        <Link to="/loginasretailer" className="flex mx-auto w-full text-lg">
          <button
            className="bg-white my-3 sm:my-5 px-10 sm:px-20 py-3 sm:py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-indigo-600 text-lg hover:text-white hover:bg-indigo-500 hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={RetailerLogo}
              alt="Logo Retailer"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Retailer </p>
          </button>
        </Link>
        <Link to="/loginascarrier" className="flex mx-auto w-full text-lg">
          <button
            className="bg-white my-3 sm:my-5 px-10 sm:px-20 py-3 sm:py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-indigo-600 text-lg hover:text-white hover:bg-indigo-500 hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={CarrierLogo}
              alt="Logo Carrier"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Carrier/Contractor </p>
          </button>
        </Link>
        <Link
          to="/loginasbuildingmanager"
          className="flex mx-auto w-full text-lg"
        >
          <button
            className="bg-white my-3 sm:my-5 px-10 sm:px-20 py-3 sm:py-6 rounded-lg group lg:w-2/5 flex mx-auto w-full text-indigo-600 text-lg hover:text-white hover:bg-indigo-500 hover:scale-105 ease-out duration-300"
            type="button"
          >
            <img
              className="h-6 self-center mr-5"
              src={BMLogo}
              alt="Logo Building Manager"
            ></img>
            <p className="mx-auto group-hover:font-bold"> Building Manager </p>
          </button>
        </Link>
      </div>
      <div className="text-center text-white font-normal md:mt-10 lg:mt-0 pb-4 md:pb-10">
        <p className="py-2"> You don't have an account yet ? </p>
        <Link to="/signup" className="flex mx-auto">
          <button
            className="hover:font-bold underline underline-offset-2 flex mx-auto"
            type="button"
          >
            {" "}
            Create one here{" "}
          </button>
        </Link>
      </div>
    </div>
  );
}

export default LoginAs;
