import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import 'yup-phone-lite';
import ComplementField from '../ComplementField';
import { useDispatch, useSelector } from 'react-redux';
import checkCurrentRole from '../../../utils/checkCurrentRole';
import { updateUserLaunched } from '../../../service/reducer';
import { CircularProgress } from '@mui/material';
import { useStyles } from "react-styles-hook";
import getStyles from "./style";

const ComplementCarrier = () => {
  const dispatch = useDispatch();
  const {userDynamo, updateUserSuccessMessage, updateUserLoading} =
    useSelector((state) => ({
      userDynamo: state.getIn(['app', 'userDynamo']),
      updateUserSuccessMessage: state.getIn([
        'app',
        'updateUserSuccessMessage',
      ]),
      updateUserLoading: state.getIn(['app', 'updateUserLoading']),
    }));

  // set success messsage //
  const [successMessage, setSuccessMessage] = useState();
  const isInitialMount = useRef(true);
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }
    if (!updateUserLoading && updateUserSuccessMessage) {
      setSuccessMessage('Your info was updated !');
    }
  }, [updateUserLoading, updateUserSuccessMessage]);

  const [currUserInfo, setCurrUserInfo] = useState(userDynamo);
  useEffect(() => {
    if (userDynamo !== null) {
      setCurrUserInfo(userDynamo);
    }
  }, [userDynamo]);
  let currentRole = checkCurrentRole(Object.keys(currUserInfo)[0]);

  const userInfo = currUserInfo[currentRole];
  const [carrierStatus, setCarrierStatus] = useState(
    userInfo.carrierStatus || 'Freight Company'
  );
  // update complement information with formik form //
  const updateComplement = (formik) => {
    formik.carrierStatus = carrierStatus;
    setSuccessMessage(updateUserSuccessMessage);
    dispatch(updateUserLaunched(formik));
  };
  const classes = useStyles(getStyles());

  return (
    <div className="h-screen">
      <Formik
        initialValues={{
          id: userInfo.id || null,
          company: userInfo.company || null,
          address: userInfo.address || null,
          complement: userInfo.complement || null,
          addressState: userInfo.addressState || null,
          city: userInfo.city || null,
          zipcode: userInfo.zipcode || null,
          EINNumber: userInfo.EINNumber || null,
          carrierStatus: userInfo.carrierStatus || null,
          deliveriesPerYear: userInfo.deliveriesPerYear || null,
          billingCompany: userInfo.billingCompany || null,
          billingAddress: userInfo.billingAddress || null,
          billingComplement: userInfo.billingComplement || null,
          billingState: userInfo.billingState || null,
          billingCity: userInfo.billingCity || null,
          billingZipcode: userInfo.billingZipcode || null,
          billingEmail: userInfo.billingEmail || null,
          billingPhone: userInfo.billingPhone || null,
          role: currentRole,
        }}
        onSubmit={(formik) => {
          updateComplement(formik);
        }}
      >
        {(formik) => (
          <div className="flex flex-col mt-10 w-full">
            <h1 className="text-white text-4xl font-bold mb-5 ml-10 md:ml-20">
              {' '}
              Complements{' '}
            </h1>
            <div className="relative px-2 md:px-8 w-full">
              <Form className="bg-white shadow-md rounded-lg px-4 lg:px-8 pt-6 pb-8 w-full max-w-screen-2xl mx-auto">
                <div className="flex-col">
                  <div className="w-full md:w-5/6 lg:w-3/5 mx-auto">
                    <div style={classes.company}>
                      <h2 className="text-black py-4 text-2xl font-bold text-[#2260DD]">
                        {' '}
                        Informations{' '}
                      </h2>
                      <h3 className="text-black py-4 text-xl"> Company </h3>
                      <ComplementField
                        label="Company Name"
                        name="company"
                        type="text"
                        placeholder="Company Name"
                      />
                      <ComplementField
                        label="Address"
                        name="address"
                        type="text"
                        placeholder="123 E Example St"
                      />
                      <ComplementField
                        label="Complement"
                        name="complement"
                        type="text"
                        placeholder="Unit, Apt, Suite..."
                      />
                      <ComplementField
                        label="City"
                        name="city"
                        type="text"
                        placeholder="City Name"
                      />
                      <ComplementField
                        label="State"
                        name="addressState"
                        type="text"
                        placeholder="State Name"
                      />
                      <ComplementField
                        label="Zip Code"
                        name="zipcode"
                        type="text"
                        placeholder="#####"
                      />
                      <ComplementField
                        label="EIN Number"
                        name="EINNumber"
                        type="text"
                        placeholder="12-3456789"
                      />
                      <div className="flex my-8 ">
                        <p className="self-center w-2/5 block text-gray-700 text-smc">
                          {' '}
                          Who are you{' '}
                        </p>
                        <ul
                          className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600 dark:text-white ">
                          <li
                            className="w-full border-b border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3 ${
                                carrierStatus === 'Freight Company' &&
                                'bg-primary-lighter  rounded-t-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="Freight Company"
                                checked={carrierStatus === 'Freight Company'}
                                onChange={(e) => {
                                  setCarrierStatus(e.target.value);
                                }}
                                name="carrierStatus"
                                className="w-4 h-4 bg-gray-100 border-gray-300  dark:focus:ring-purple-500 dark:ring-offset-gray-700 dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="carrierStatus"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                              >
                                {' '}
                                A Freight Company{' '}
                              </label>
                            </div>
                          </li>
                          <li className="w-full  border-gray-200 dark:border-gray-600  focus-within:bg-[#EEF2FF]">
                            <div
                              className={`flex items-center pl-3 ${
                                carrierStatus === 'Movers Company' &&
                                'bg-primary-lighter  rounded-b-lg'
                              }`}
                            >
                              <Field
                                type="radio"
                                value="Movers Company"
                                checked={carrierStatus === 'Movers Company'}
                                onChange={(e) => {
                                  setCarrierStatus(e.target.value);
                                }}
                                name="carrierStatus"
                                className="w-4 h-4 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700  dark:bg-gray-600 dark:border-gray-500 accent-[#4F46E5]"
                              />
                              <label
                                htmlFor="carrierStatus"
                                className="py-3 ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300 "
                              >
                                {' '}
                                A Movers Company{' '}
                              </label>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <ComplementField
                        label="How many deliveries per year ?"
                        name="deliveriesPerYear"
                        type="number"
                        placeholder="#####"
                      />
                    </div>
                    <div style={classes.billing}>


                      <h3 className="text-black py-4 text-xl"> Billing </h3>

                      <ComplementField
                        label="Company Name"
                        name="billingCompany"
                        type="text"
                        placeholder="Billing Company Name"
                      />
                      <ComplementField
                        label="Address"
                        name="billingAddress"
                        type="text"
                        placeholder="123 E Example St"
                      />
                      <ComplementField
                        label="Complement"
                        name="billingComplement"
                        type="text"
                        placeholder="Unit, Apt, Suite..."
                      />
                      <ComplementField
                        label="City"
                        name="billingCity"
                        type="text"
                        placeholder="City Name"
                      />
                      <ComplementField
                        label="State"
                        name="billingState"
                        type="text"
                        placeholder="State Name"
                      />
                      <ComplementField
                        label="Zip Code"
                        name="billingZipcode"
                        type="text"
                        placeholder="#####"
                      />
                      <ComplementField
                        label="Email"
                        name="billingEmail"
                        type="email"
                        placeholder="blilling@example.com"
                      />
                      <ComplementField
                        label="Phone"
                        name="billingPhone"
                        type="text"
                        placeholder="##########"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  {/* Disabled button when loading to stop spam click*/}
                  {updateUserLoading && (
                    <button
                      disabled={true}
                      type="submit"
                      className="bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-10 text-white text-lg hover:scale-105 ease-out duration-300"
                    >
                      <CircularProgress style={{color: 'white'}} size={20}/>
                    </button>
                  )}

                  {!updateUserLoading && !successMessage && (
                    <button
                      type="submit"
                      className="bg-bg-button rounded-lg group text-center mx-auto w-full lg:w-1/4 my-5 px-16 py-4 lg:mt-10 mt-5 text-white text-lg hover:scale-105 ease-out duration-300"
                      onChange={updateComplement}
                    >
                      {' '}
                      Save changes{' '}
                    </button>
                  )}
                </div>
                {/* success*/}
                {successMessage && (
                  <p
                    className="text-green-700 text-center text-white rounded-lg bg-green-100 mt-4 md:w-3/4 lg:w-2/6 mx-auto">
                    {successMessage}
                  </p>
                )}
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default ComplementCarrier;
