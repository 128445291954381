import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';

const initialState = Immutable.Map({
  getUserEventsLoading: false,
  getUserEventsError: null,
  userEvents: null,
});

const { actions, reducer } = createSlice({
  slice: 'eventStore',
  name: 'eventStore',
  initialState,
  reducers: {
    // get Events
    getUserEventsLaunched: (state, action) =>
      state
        .set('getUserEventsLoading', true)
        .set('userEvents', null)
        .set('getUserEventsError', null),
    getUserEventsSuccess: (state, action) =>
      state
        .set('getUserEventsLoading', false)
        .set('userEvents', action.payload)
        .set('getUserEventsError', null),
    getUserEventsFailure: (state, action) =>
      state
        .set('getUserEventsLoading', false)
        .set('userEvents', null)
        .set('getUserEventsError', true),
  },
});

export const {
  getUserEventsLaunched,
  getUserEventsSuccess,
  getUserEventsFailure
} = actions;

export default reducer;