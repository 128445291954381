import React from 'react';
import { ErrorMessage, useField } from 'formik';

function EventMailField({ label, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div>
      <div className='flex w-full'>
        <div className='w-2/5 self-center'>
          <label className='block text-gray-700 text-xs pl-2 sm:text-sm' htmlFor={field.name}>
            {label}
          </label>
        </div>
        <div className='w-full'>
          <input
            className={`text-xs sm:text-sm shadow appearance-none bg-white border rounded w-full md:w-4/5 lg:w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline placeholder:text-slate-300 ${meta.touched && meta.error && 'border-red-500'
              }`}
            type='text'
            {...field}
            {...props}
          />
        </div>
      </div>
      <ErrorMessage
        component='div'
        name={field.name}
        className='text-red-500'
      />
    </div>
  );
}

export default EventMailField;
