import Immutable from 'immutable';
import { createSlice } from '@reduxjs/toolkit';

const initialState = Immutable.Map({
  getUserLoading: false,
  getUserError: null,
  user: null,

  getBuildingManagerLoading: false,
  getBuildingManagerError: null,
  buildingManager: null,

  getCustomerLoading: false,
  getCustomerError: null,
  customer: null,

  getRetailerLoading: false,
  getRetailerError: null,
  retailer: null,

  getCarrierLoading: false,
  getCarrierError: null,
  carrier: null,

  userDynamo: null,
  userInfo: null,
});

const { actions, reducer } = createSlice({
  slice: 'store',
  name: 'store',
  initialState,
  reducers: {
    // getUser
    getUserLaunched: (state, action) =>
      state
        .set('getUserLoading', true)
        .set('user', null)
        .set('getUserError', null),
    getUserSuccess: (state, action) =>
      state
        .set('getUserLoading', false)
        .set('user', action.payload)
        .set('getUserError', null),
    getUsersFailure: (state, action) =>
      state
        .set('getUserLoading', false)
        .set('user', null)
        .set('getUserError', action.payload),

    // getBuildingManager
    getBuildingManagerLaunched: (state, action) =>
      state
        .set('getBuildingManagerLoading', true)
        .set('buildingManager', null)
        .set('getBuildingManagerError', null),
    getBuildingManagerSuccess: (state, action) =>
      state
        .set('getBuildingManagerLoading', false)
        .set('BuildingManager', action.payload)
        .set('getBuildingManagerError', null),
    getBuildingManagerFailure: (state, action) =>
      state
        .set('getBuildingManagerLoading', false)
        .set('BuildingManager', null)
        .set('getBuildingManagerError', action.payload),

    // getCustomer
    getCustomerLaunched: (state, action) =>
      state
        .set('getCustomerLoading', true)
        .set('Customer', null)
        .set('getCustomerError', null),
    getCustomerSuccess: (state, action) =>
      state
        .set('getCustomerLoading', false)
        .set('Customer', action.payload)
        .set('getCustomerError', null),
    getCustomerFailure: (state, action) =>
      state
        .set('getCustomerLoading', false)
        .set('Customer', null)
        .set('getCustomerError', action.payload),

    // getCarrier
    getCarrierLaunched: (state, action) =>
      state
        .set('getCarrierLoading', true)
        .set('Carrier', null)
        .set('getCarrierError', null),
    getCarrierSuccess: (state, action) =>
      state
        .set('getCarrierLoading', false)
        .set('Carrier', action.payload)
        .set('getCarrierError', null),
    getCarrierFailure: (state, action) =>
      state
        .set('getCarrierLoading', false)
        .set('Carrier', null)
        .set('getCarrierError', action.payload),

    // getRetailer
    getRetailerLaunched: (state, action) =>
      state
        .set('getRetailerLoading', true)
        .set('Retailer', null)
        .set('getRetailerError', null),
    getRetailerSuccess: (state, action) =>
      state
        .set('getRetailerLoading', false)
        .set('Retailer', action.payload)
        .set('getRetailerError', null),
    getRetailerFailure: (state, action) =>
      state
        .set('getRetailerLoading', false)
        .set('Retailer', null)
        .set('getRetailerError', action.payload),
        
        // UPDATE CARRIER INFO
        carrierInfoLaunched: (state, action) =>
          state.set('carrierInfoLoading', true).set('carrierInfoError', null),
        carrierInfoSuccess: (state, action) =>
          state.set('carrierInfoLoading', false).set('carrierInfoError', null),
        carrierInfoFailure: (state, action) =>
          state
            .set('carrierInfoLoading', false)
            .set('carrierInfoError', action.payload),

            // UPDATE RETAILER INFO
        retailerInfoLaunched: (state, action) =>
        state.set('retailerInfoLoading', true).set('retailerInfoError', null),
      retailerInfoSuccess: (state, action) =>
        state.set('retailerInfoLoading', false).set('retailerInfoError', null),
      retailerInfoFailure: (state, action) =>
        state
          .set('retailerInfoLoading', false)
          .set('retailerInfoError', action.payload),

           // UPDATE BUILDINGMANAGER INFO
        buildingManagerInfoLaunched: (state, action) =>
        state.set('buildingManagerInfoLoading', true).set('buildingManagerInfoError', null),
      buildingManagerInfoSuccess: (state, action) =>
        state.set('buildingManagerInfoLoading', false).set('buildingManagerInfoError', null),
      buildingManagerInfoFailure: (state, action) =>
        state
          .set('buildingManagerInfoLoading', false)
          .set('buildingManagerInfoError', action.payload),

           // UPDATE CUSTOMER INFO
        customerInfoLaunched: (state, action) =>
        state.set('customerInfoLoading', true).set('customerInfoError', null),
      customerInfoSuccess: (state, action) =>
        state.set('customerInfoLoading', false).set('customerInfoError', null),
      customerInfoFailure: (state, action) =>
        state
          .set('customerInfoLoading', false)
          .set('customerInfoError', action.payload),
  },
});


export const {
  getUserLaunched,
  getUserSuccess,
  getUsersFailure,
  getBuildingManagerLaunched,
  getBuildingManagerSuccess,
  getBuildingManagerFailure,
  getRetailerLaunched,
  getRetailerSuccess,
  getRetailerFailure,
  getCarrierLaunched,
  getCarrierSuccess,
  getCarrierFailure,
  getCustomerLaunched,
  getCustomerSuccess,
  getCustomerFailure,
  updateCarrierOrderLaunched,
  updateCarrierOrderSuccess,
  updateCarrierOrderFailure,
  carrierInfoLaunched,
  carrierInfoSuccess,
  carrierInfoFailure,
  retailerInfoLaunched,
  retailerInfoSuccess,
  retailerInfoFailure,
  buildingManagerInfoLaunched,
  buildingManagerInfoSuccess,
  buildingManagerInfoFailure,
  customerInfoLaunched,
  customerInfoSuccess,
  customerInfoFailure,
} = actions;

export default reducer;
