const getStyles = () => ({
  deliveryAddress: {
    // background: "linear-gradient(rgb(224, 233, 255), #F1E0FFFF)", was cool but not chosen 😪
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
    // display: "inline-block",
  },
  freightEntranceTitle: {
    // background: "rgb(224, 233, 255)", was cool but not chosen 😪
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  elevatorTitle: {
    // background: "#F1E0FFFF", was cool but not chosen 😪
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  buildingManagerTitle: {
    // background: "rgb(224, 233, 255)", was cool but not chosen 😪
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "1px 20px",
    marginBottom: "15px",
  },
  freightEntrance: {
    // background: "linear-gradient(rgb(224, 233, 255), #F1E0FFFF)", was cool but not chosen 😪
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
  elevator: {
    // background: "linear-gradient(#F1E0FFFF, rgb(224, 233, 255))", was cool but not chosen 😪
    background: "#f9fafc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
  buildingManager: {
    // background: "linear-gradient(rgb(224, 233, 255), #F1E0FFFF)", was cool but not chosen 😪
    background: "#ebf1fc",
    borderRadius: "10px",
    padding: "20px",
    marginBottom: "15px",
  },
});

export default getStyles;