import React from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import add from '../../assets/img/Add-button.svg';
import { useDispatch } from 'react-redux';
import { resetCreateBuilding } from '../../service/reducer';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
function DropDownMenu({
  userInfo,
  setForm,
  getBuildingResident,
  getBuilding,
  currentBuilding,
  setCurrentBuilding,
  resetFileInfo,
  resetUpdateState,
}) {
  const dispatch = useDispatch();
  return currentBuilding ? (
    <Menu as='div' className='flex flex-row justify-end'>
      <Menu.Button className='text-clip flex justify-center rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100'>
        <>
          <p className=''>{currentBuilding}</p>
          <svg
            aria-hidden='true'
            focusable='false'
            data-prefix='fas'
            data-icon='caret-down'
            className='w-2 ml-2'
            role='img'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 320 512'
          >
            <path
              fill='currentColor'
              d='M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z'
            ></path>
          </svg>
        </>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none '>
          <Menu.Item className='bg-gray-100 text-gray-900 flex w-full px-2 pt-2 pb-4 text-[#2260DD]'>
            <button
              type='button'
              name='newBuilding'
              onClick={() => {
                setForm('newBuilding');
                resetFileInfo();
                dispatch(resetCreateBuilding());
                dispatch(resetUpdateState());
              }}
            >
              {' '}
              <img src={add} alt='add icon' />
              Add a building
            </button>
          </Menu.Item>
          <div>
            {userInfo.buildings?.map((building, index, active) => (
              <Menu.Items
                key={index}
                className={classNames(
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                  'block px-4 py-2 text-sm'
                )}
              >
                <button
                  type='submit'
                  name='getBuilding'
                  value={building.buildingName}
                  onClick={(e) => {
                    setForm('getBuilding');
                    getBuilding(building.buildingId);
                    getBuildingResident(building.buildingId);
                    setCurrentBuilding(e.target.value);
                  }}
                >
                  {building.buildingName}
                </button>
              </Menu.Items>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  ) : null;
}

export default DropDownMenu;
