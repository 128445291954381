import dayjs from "dayjs";


// Transforme 1300 en 01:00 PM
export function formatTime(time) {
  // Extraire l'heure et les minutes de l'entrée
  time = reverseFormatForAPI(time);

  const hours = time.substring(0, 2);
  const minutes = time.substring(2, 4);

  // Convertir l'heure au format 12 heures
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Ajouter un zéro devant les minutes si nécessaire
  const formattedMinutes = minutes.padStart(2, '0');

  // Concaténer les éléments formatés en une chaîne de caractères
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedTime;
}

// Transforme 1300 en 01:30 PM
export function formatTimePlus30Minutes(time) {
  // Extraire l'heure et les minutes de l'entrée
  time = reverseFormatForAPI(time);
  let hours = parseInt(time.substring(0, 2));
  let minutes = parseInt(time.substring(2, 4));

  // Ajouter 30 minutes à l'heure
  minutes += 30;
  if (minutes >= 60) {
    hours += Math.floor(minutes / 60);
    minutes = minutes % 60;
  }
  hours = hours % 24; // Gérer les dépassements de minuit

  // Convertir l'heure au format 12 heures
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Ajouter un zéro devant les minutes si nécessaire
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Concaténer les éléments formatés en une chaîne de caractères
  const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;

  return formattedTime;
}

export function formatForAPI(str) {
  // Supprimer tous les zéros en début de chaîne
  if (str === "0000" || str === "0") {
    return "0";
  }
  while (str.startsWith('0')) {
    str = str.slice(1);
  }

  return str;
}

// Permet de reverse ce qui est envoyé à l'API
export function reverseFormatForAPI(str) {
  // Ajoute tous les zéros en début de chaîne
  while (str.length < 4) {
    str = '0' + str;
  }

  return str;
}

// Transforme l'objet date en MM/DD/YYYY
export function dateFormatted(date) {
  return dayjs(date).format('MM/DD/YYYY');
}

