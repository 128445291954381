import React from 'react';
import Check from '../../assets/img/greencheck.svg';
import { Link } from 'react-router-dom';

function ConfirmPasswordChange() {
  return (
    <div className='bg-indigo-500 lg:bg-bg-color min-h-screen font-sans bg-no-repeat bg-cover'>
      <div className='py-10 text-center'>
        <div className='mx-5 md:mx-20 md:mt-40 lg:mt-40 mt-20 block p-6 lg:w-2/5 bg-white rounded-lg border border-gray-200 shadow-md lg:m-auto'>
          <img src={Check} className='h-16 my-5 m-auto' alt='Logo' />
          <h1 className='my-5 font-bold text-2xl tracking-tight text-gray-900 dark:text-white'>
            {' '}
            Your password has been changed{' '}
          </h1>
          <div className='flex flex-col lg:py-4'>
            <Link to='/loginas'>
              <button
                type='button'
                className='bg-bg-button rounded-lg group flex mx-auto w-full lg:w-4/5 py-2 text-white text-lg hover:scale-105 ease-out duration-300'
              >
                <p className='mx-auto group-hover:font-bold'> Go to Login </p>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmPasswordChange;
