import React from 'react';

const TableEventLoading = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="-mx-4 mt-8 overflow-hidden lg:overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
        <div className="min-w-full divide-y divide-gray-300">
          <div className="animate-pulse py-4 px-4">
            <p className="h-4 bg-neutral-light rounded-md px-2"></p>
          </div>
          <div className="divide-y divide-gray-200 bg-white">
            <div className="animate-pulse  space-y-4 py-4 px-4">
              <p className="h-4 bg-neutral-light rounded-md px-2"></p>
              <p className="h-4 bg-neutral-light rounded-md px-2"></p>
              <p className="h-4 bg-neutral-light rounded-md px-2"></p>
              <p className="h-4 bg-neutral-light rounded-md px-2"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TableEventLoading;
